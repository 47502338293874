import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Image1Slice from './Image1Slice';
import { imageSlice } from './imageSlice';
import styleSlice from './Styleslice';
import Image3Slice from './Image3Slice';

const rootReducer = combineReducers({
  images:imageSlice.reducer,
    table:Image1Slice,
    style:styleSlice,
    table:Image3Slice,

 
}); 

const persistConfig = {
  key: 'root', 
  storage,
};  

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
