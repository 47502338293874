import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
  tableCellClasses,
} from '@mui/material';
import ArtProductData from './ArtProductData';
import Popup from 'reactjs-popup';
import { httpClient } from '../../axios';
import { useDetectClickOutside } from 'react-detect-click-outside';

import Switch from '@mui/material/Switch';
import Canvass from './Canvass';

import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Recycle } from './Icon_Reset.svg';
import Slider from '@mui/material/Slider';
import SliderButton from './sliderButton1.svg';
import scale from './scale.png';
import { ReactComponent as DropArrow } from './Down arrow.svg';
import { ReactComponent as IIcon } from './iIcon.svg';
import { ReactComponent as Icon_Checkmark } from './Icon_Checkmark.svg';
const styleDrop = [
  { a: `Men's T-Shirt` },
  { a: `Women's T-Shirt` },
  { a: `Kid's T-Shirt` },
];
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 48,
  height: 24,
  padding: 0,

  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      top: '1px',
      left: '1px',
      width: 25,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
      right: '1px',
      top: '1px',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    top: '1px',
    left: '1px',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      right: '1px',
      top: '1px',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor:
          theme.palette.mode === 'dark' ? '#8e8e8e' : '#8e8e8e',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 18,
    height: 18,
    top: '1px',
    borderRadius: 100,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 24 / 2,
    opacity: 1,
    top: '1px',
    backgroundColor:
      theme.palette.mode === 'dark' ? '#8e8e8e' : '#8e8e8e',
    // backgroundColor:
    //   theme.palette.mode === 'dark'
    //     ? 'rgba(255,255,255,.35)'
    //     : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));
const AdminArtProduct = () => {
  const [tempData, setTempData] = useState({
    adminArtProductName: '',
    date: '',
    status: '',
    qty: '',
    artProductUniqueNo: '',
    canvasSize: '',
    userId: '',
    artId: '',
    productId: '',
    artProductId: '',
    cartId: '',
    canvasX: '',
    canvasY: '',
  });

  const [addedRecord, setAddedRecord] = useState(null); // New state for temporary record

  const addRecord = () => {
    console.log('Adding record:', tempData);
    setAddedRecord(tempData); // Set added record
    setTempData({
      /* Resetting state */
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTempData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [imageName, setImageName] = useState('');
  const fileInputRef = useRef(null);
  const handleFileChange = async (event, linkNumber) => {
    const img = event.target.files[0];
    if (img) {
      let formData = new FormData();
      formData.append('file', img);
      const res = await httpClient.post(
        '/CloudinaryImageUpload?parameter=false',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setImageName(img.name);
      console.log(res.data);
      // setcategoryObj({ ...categoryObj, image: res.data })
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'rgb(115 103 240)',
      color: theme.palette.common.white,
      fontSize: 20,
    },
  }));

  const [dottedLine, setDottedLine] = useState(false);
  const [alignVerticalFocus, setAlignVerticalFocus] = useState(true);
  const [horzontalAlign, setHorizontalAlign] = useState(null);
  const horizontalAlign = () => {
    if (horzontalAlign === null) {
      setHorizontalAlign(true);
    } else if (horzontalAlign === true || horzontalAlign === false) {
      setHorizontalAlign(!horzontalAlign);
    }
    setAlignVerticalFocus(false);
  };

  const [vertcalAlign, setVerticalAlign] = useState(null);

  const verticalAlign = () => {
    if (vertcalAlign === null) {
      setVerticalAlign(true);
    } else if (vertcalAlign === true || vertcalAlign === false) {
      setVerticalAlign(!vertcalAlign);
    }
    setAlignVerticalFocus(true);
  };

  const [HoriHover, setHoriHover] = useState(false);
  const [VertHover, setVertHover] = useState(false);

  const [sizeRangeValue, setSizeRangeValue] = useState(50);

  const sizeRange = (event) => {
    const value = event.target.value;
    if (value !== 0) {
      setSizeRangeValue(value);
      // console.log(value);
    }
  };
  const [isAllStyleOpen, setIsAllStyleOpen] = useState(false);
  const handleClickOutsideCate = () => {
    setIsAllStyleOpen(false);
  };
  const CateClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideCate,
  });

  const [halfSleevesHover, setHalfSleevesHover] = useState(false);
  const [fullSleevesHover, setFullSleevesHover] = useState(false);
  const [sleevelessHover, setSleevelessHover] = useState(false);
  const [printOnFrontHover, setPrintOnFrontHover] = useState(false);
  const [printOnBackHover, setPrintOnBackHover] = useState(false);
  const [photo, setPhoto] = useState(
    'http://res.cloudinary.com/artnstockimg/image/upload/ar_9:16,c_crop/v1/artnstock/lp6ztcrbordkvuuhrffc.jpg'
  );
  const [artList, setartList] = useState(null);

  const getUserIdWiseArts = async () => {
    try {
      const res = await httpClient.get(
        `/draft_master/getDraftMasterByStatusAndActiveStatus/adddetails/true/${userId}`
      );
      setartList(res.data.imageMaster);
      handleClick(res.data.imageMaster[0]);
      console.log(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const dispatch = useDispatch();

  const userId = useSelector((state) => state.auth.userId);
  const [artName, setartName] = useState(null);
  const [checkedId, setCheckedId] = useState(null);
  const handleClick = (card) => {
    if (card.imageId === checkedId) {
      setCheckedId(null);
      setPhoto(null);
      setartName(null);
    } else {
      setCheckedId(card.imageId);
      setPhoto(card.imageOrientation?.verticalUrl);
      setartName(card.artName);
    }
  };

  const [circle, setCircle] = useState([
    {
      id: 'icon1',
      backgroundColor: '#304f71',
      borderColor: '#304f71',
      textColor: '#FFFFFF',
      tick: true,
    },
    {
      id: 'icon2',
      backgroundColor: '#9cafdb',
      borderColor: '#9cafdb',
      textColor: '#FFFFFF',
      tick: true,
    },
    {
      id: 'icon3',
      backgroundColor: '#05a9f0',
      borderColor: '#05a9f0',
      textColor: '#FFFFFF',
      tick: true,
    },
    {
      id: 'icon4',
      backgroundColor: '#9ad15c',
      borderColor: '#9ad15c',
      textColor: '#FFFFFF',
      tick: true,
    },
    {
      id: 'icon5',
      backgroundColor: '#fdc506',
      borderColor: '#fdc506',
      textColor: 'white',
      tick: true,
    },
    {
      id: 'icon6',
      backgroundColor: '#f18c15',
      borderColor: '#f18c15',
      textColor: 'white',
      tick: true,
    },
    {
      id: 'icon7',
      backgroundColor: '#f884bb',
      borderColor: '#f884bb',
      textColor: 'black',
      tick: true,
    },
    {
      id: 'icon8',
      backgroundColor: '#de4492',
      borderColor: '#de4492',
      textColor: 'black',
      tick: true,
    },
    {
      id: 'icon9',
      backgroundColor: '#dddddd',
      borderColor: '#dddddd',
      textColor: 'black',
      tick: true,
    },
    {
      id: 'icon10',
      backgroundColor: '#010101',
      borderColor: '#010101',
      textColor: 'black',
      tick: true,
    },
    {
      id: 'icon11',
      backgroundColor: '#ffffff',
      borderColor: '#D6D6D6',
      textColor: 'black',
      tick: false,
    },
  ]);

  const [MarkUpIButton, setMarkUpIButton] = useState(false);
  const [XYCanvas, setXYCanvas] = useState({});

  const handleDataFromChild = (data) => {
    setXYCanvas(data);
  };

  const [artproduct, setArtproduct] = useState([]);

  const getArtProduct = async () => {
    const res = await httpClient.get('/admin_Art_Product_master');
    // .then((res) => {
    console.log(res.data);
    setArtproduct(res.data);

    // });
  };
  useEffect(() => {
    getArtProduct();
  }, []);

  const [admin, setAdmin] = useState([]);
  const getAdmin = async () => {
    try {
      const res = await httpClient.get(
        '/art_product_master/getActiveArtProductMaster'
      );
      console.log(res.data);
      setAdmin(res.data);
    } catch (error) {
      console.error('Error fetching pincode media:', error);
    }
  };
  useEffect(() => {
    getAdmin();
  }, []);

  const [product, setProduct] = useState([]);
  const getProduct = async () => {
    try {
      const res = await httpClient.get(
        '/product_master/getActiveProductMaster'
      );
      console.log(res.data);
      setProduct(res.data);
    } catch (error) {
      console.error('Error fetching pincode media:', error);
    }
  };

  useEffect(() => {
    getProduct();
  }, []);

  const [searchQuery, setSearchQuery] = useState('');

  const filteredData = artproduct.filter((list) =>
    list.adminArtProductName
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    // setPage(0);
  };

  return (
    <>
      <Grid justifyContent='center'>
        <Grid item xs={12} md={10} lg={8}>
          <div className='mt-[20px] pageMainDiv'>
            <p className='text-[25px] font-bold mt-[40px]'>
              Admin Art-Product Master
            </p>
            <input
              type='text'
              name='name'
              placeholder='Search...'
              value={searchQuery}
              onChange={(e) => handleSearch(e)}
              className='mt-[10px] h-[50px] rounded-md border border-[#ABB2B9] pl-4 w-[100%]'
            />
            <div className='mt-2 w-[100%] border rounded-md border-[white] bg-[white]   '>
              <div className=' w-[100%] overflow-auto lg:overflow-hidden '>
                <TableContainer className='h-[400px]'>
                  <Table
                    stickyHeader
                    className='border border-2 w-[100%]'
                  >
                    <TableHead className='bg-[#7367F0]'>
                      <TableRow>
                        <StyledTableCell>
                          <div className='w-[200px]'>
                            Admin Art Product Name
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>Date</StyledTableCell>
                        <StyledTableCell>Status</StyledTableCell>
                        <StyledTableCell>Image</StyledTableCell>
                        <StyledTableCell>
                          Size And Prices
                        </StyledTableCell>
                        <StyledTableCell>Qty</StyledTableCell>
                        {/* <StyledTableCell>Art Product Unique No</StyledTableCell> */}
                        <StyledTableCell>CanvasSize</StyledTableCell>

                        <StyledTableCell>Images</StyledTableCell>

                        <StyledTableCell>CanvasX</StyledTableCell>
                        <StyledTableCell>CanvasY</StyledTableCell>
                        <StyledTableCell>Action</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredData.map((art) => (
                        <TableRow key={art.adminArtProductId}>
                          <TableCell>
                            <span className='text-[16px] text-black'>
                              {art.adminArtProductName}
                            </span>
                          </TableCell>
                          <TableCell>
                            <span className='text-[16px] text-black'>
                              {art.date}
                            </span>
                          </TableCell>
                          <TableCell align='center'>
                            <span className='text-[16px] text-black'>
                              {art.status}
                            </span>
                          </TableCell>
                          <TableCell>
                            <div>
                              <Popup
                                trigger={
                                  <div className='text-center'>
                                    <Button
                                      style={{
                                        backgroundColor: 'orange',
                                        color: 'white',
                                      }}
                                    >
                                      Image
                                    </Button>
                                  </div>
                                }
                                modal
                                closeOnDocumentClick
                              >
                                {(close) => (
                                  <div className='w-[300px] lg:w-[400px] lg:h-[350px] h-[250px] rounded-lg  bg-[white] shadow'>
                                    <img
                                      src={art.image}
                                      className='w-[100%] h-[100%]'
                                    />
                                  </div>
                                )}
                              </Popup>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div>
                              <Popup
                                trigger={
                                  <div className='text-center'>
                                    <button className='bg-[#7367F0] h-[40px] w-[120px] rounded-lg text-[white] font-medium text-[17px]'>
                                      Show
                                    </button>
                                  </div>
                                }
                                modal
                                closeOnDocumentClick
                              >
                                {(close) => (
                                  <div className='rounded-lg flex bg-[white] shadow'>
                                    <TableContainer className=' shadow'>
                                      <Table
                                        stickyHeader
                                        className='border border-2'
                                      >
                                        <TableHead className='bg-[#7367F0]'>
                                          <TableRow>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell>
                                              Size
                                            </StyledTableCell>
                                            <StyledTableCell>
                                              Base Price
                                            </StyledTableCell>
                                            <StyledTableCell align='center'>
                                              Sell Price
                                            </StyledTableCell>
                                            <StyledTableCell align='center'>
                                              Size Name
                                            </StyledTableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {art?.sizeAndPrices?.map(
                                            (size, index) => (
                                              <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell>
                                                  {size.size}
                                                </TableCell>

                                                <TableCell>
                                                  {size.basePrice}
                                                </TableCell>
                                                <TableCell align='center'>
                                                  {size.sellPrice}
                                                </TableCell>
                                                <TableCell align='center'>
                                                  {size.sizeName}
                                                </TableCell>
                                              </TableRow>
                                            )
                                          )}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </div>
                                )}
                              </Popup>
                            </div>
                          </TableCell>
                          <TableCell align='center'>
                            <span className='text-[16px] text-black'>
                              {art.qty}
                            </span>
                          </TableCell>
                          {/* <TableCell>
                            <span className='text-[16px] text-black'>
                              {art.artProductUniqueNo}
                            </span>
                          </TableCell> */}

                          <TableCell align='center'>
                            <span className='text-[16px] text-black'>
                              {art.canvasSize}
                            </span>
                          </TableCell>
                          {/* <TableCell>
                            <span className='text-[16px] text-black'>
                              {art.userId}
                            </span>
                          </TableCell>
                          <TableCell>
                            <span className='text-[16px] text-black'>
                              {art.artId}
                            </span>
                          </TableCell>
                          <TableCell>
                            <span className='text-[16px] text-black'>
                              {art.productId}
                            </span>
                          </TableCell> */}
                          <TableCell>
                            <div>
                              <Popup
                                trigger={
                                  <div className='text-center'>
                                    <button className='bg-[#7367F0] h-[40px] w-[120px] rounded-lg text-[white] font-medium text-[17px]'>
                                      Select
                                    </button>
                                  </div>
                                }
                                modal
                                closeOnDocumentClick
                              >
                                {(close) => (
                                  <div className='rounded-lg flex bg-[white] shadow'>
                                    <TableContainer className=' shadow'>
                                      <Table
                                        stickyHeader
                                        className='border border-2'
                                      >
                                        <TableHead className='bg-[#7367F0]'>
                                          <TableRow>
                                            <StyledTableCell></StyledTableCell>

                                            <StyledTableCell>
                                              Colour
                                            </StyledTableCell>
                                            <StyledTableCell align='center'>
                                              Image
                                            </StyledTableCell>
                                            <StyledTableCell align='center'>
                                              Colourcode
                                            </StyledTableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {art?.images?.map(
                                            (image, index) => (
                                              <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell>
                                                  {image.color}
                                                </TableCell>

                                                <TableCell>
                                                  <Popup
                                                    trigger={
                                                      <div className='text-center'>
                                                        <Button
                                                          style={{
                                                            backgroundColor:
                                                              'orange',
                                                            color:
                                                              'white',
                                                          }}
                                                        >
                                                          Image
                                                        </Button>
                                                      </div>
                                                    }
                                                    modal
                                                    closeOnDocumentClick
                                                  >
                                                    {(close) => (
                                                      <div className='w-[300px] lg:w-[400px] lg:h-[350px] h-[250px] rounded-lg  bg-[white] shadow'>
                                                        <img
                                                          src={
                                                            image.image
                                                          }
                                                          className='w-[100%] h-[100%]'
                                                        />
                                                      </div>
                                                    )}
                                                  </Popup>
                                                </TableCell>
                                                <TableCell align='center'>
                                                  {image.colorCode}
                                                </TableCell>
                                              </TableRow>
                                            )
                                          )}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </div>
                                )}
                              </Popup>
                            </div>
                          </TableCell>

                          {/* <TableCell>
                            <span className='text-[16px] text-black'>
                              {art.artProductId}
                            </span>
                          </TableCell> */}

                          <TableCell>
                            <span className='text-[16px] text-black'>
                              {art.canvasX.toFixed(2)}{' '}
                            </span>
                          </TableCell>

                          <TableCell>
                            <span className='text-[16px] text-black'>
                              {art.canvasY.toFixed(2)}
                            </span>
                          </TableCell>
                          <TableCell>
                            <div>
                              <Popup
                                trigger={
                                  <Button
                                    style={{
                                      backgroundColor: 'orange',
                                      color: 'white',
                                    }}
                                  >
                                    Update
                                  </Button>
                                }
                                modal
                                closeOnDocumentClick
                              >
                                {(close) => (
                                  <>
                                    <div className='pageMainDiv bg-[white] pb-[10px]'>
                                      <div className='w-full flex justify-center pt-[30px]'>
                                        <div
                                          className='w-[1250px] flex p-[30px] rounded-[30px] '
                                          style={{
                                            boxShadow:
                                              '#f0f0f0 0px 0px 4.3px 4px',
                                          }}
                                        >
                                          <div className='w-[50%]'>
                                            <div className='myDiv relative w-[540px] h-[540px] rounded-[16.01px] bg-[#f5f5f7] flex flex-col justify-center  items-center'>
                                              {/* <Shirt fill={`${shirt}`} /> */}
                                              <img
                                                src={
                                                  'https://res.cloudinary.com/artnstockimg/image/upload/v1689158418/artnstock/xrugpgtjoq6odhpakwry.png'
                                                }
                                                alt=''
                                              />
                                              <div
                                                className={`${
                                                  dottedLine
                                                    ? ''
                                                    : 'border border-dashed border-[#d6d6d6]'
                                                } absolute `}
                                              >
                                                <div>
                                                  <Canvass
                                                    sendDataToParent={
                                                      handleDataFromChild
                                                    }
                                                    photo={photo}
                                                    sizeRangeValue={
                                                      sizeRangeValue
                                                    }
                                                    vertcalAlign={
                                                      vertcalAlign
                                                    }
                                                    horzontalAlign={
                                                      horzontalAlign
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div>
                                              <div className='w-full flex justify-between'>
                                                <div className='flex gap-[8px] pt-[8px]'>
                                                  <div
                                                    className='cursor-pointer'
                                                    onClick={
                                                      horizontalAlign
                                                    }
                                                  >
                                                    <svg
                                                      onMouseEnter={() =>
                                                        setHoriHover(
                                                          true
                                                        )
                                                      }
                                                      onMouseLeave={() =>
                                                        setHoriHover(
                                                          false
                                                        )
                                                      }
                                                      width='24'
                                                      height='24'
                                                      viewBox='0 0 24 24'
                                                      fill='none'
                                                      xmlns='http://www.w3.org/2000/svg'
                                                    >
                                                      <rect
                                                        x='23.5'
                                                        y='0.5'
                                                        width='23'
                                                        height='23'
                                                        rx='5.5'
                                                        transform='rotate(90 23.5 0.5)'
                                                        className={`${
                                                          HoriHover
                                                            ? 'stroke-[#333333]'
                                                            : 'stroke-[#888888]'
                                                        }`}
                                                      />
                                                      <rect
                                                        x='13'
                                                        y='3'
                                                        width='18'
                                                        height='2'
                                                        rx='1'
                                                        transform='rotate(90 13 3)'
                                                        className={`${
                                                          HoriHover
                                                            ? 'fill-[#333333]'
                                                            : 'fill-[#888888]'
                                                        }`}
                                                      />
                                                      <path
                                                        fill-rule='evenodd'
                                                        clip-rule='evenodd'
                                                        d='M22 12C22 11.4477 21.5522 11 21 11L17.2395 11L18.7214 9.56848C19.0929 9.20972 19.0929 8.62793 18.7214 8.26916C18.35 7.91028 17.7477 7.91028 17.3762 8.26916L14.2021 11.3352C14.0727 11.4602 14 11.6298 14 11.8066L14 12L14 12.1935C14 12.3702 14.0727 12.5398 14.2021 12.6648L17.3762 15.731C17.7477 16.0897 18.35 16.0897 18.7214 15.731C19.0929 15.3721 19.0929 14.7903 18.7214 14.4315L17.2395 13L21 13C21.5522 13 22 12.5523 22 12Z'
                                                        className={`${
                                                          HoriHover
                                                            ? 'fill-[#333333]'
                                                            : 'fill-[#888888]'
                                                        }`}
                                                      />
                                                      <path
                                                        fill-rule='evenodd'
                                                        clip-rule='evenodd'
                                                        d='M2 12C2 11.4477 2.44775 11 3 11L6.7605 11L5.27856 9.56848C4.9071 9.20972 4.9071 8.62793 5.27856 8.26917C5.65002 7.91028 6.25232 7.91028 6.62378 8.26917L9.79785 11.3352C9.92725 11.4602 10 11.6298 10 11.8066L10 12L10 12.1935C10 12.3702 9.92725 12.5398 9.79785 12.6648L6.62378 15.731C6.25232 16.0897 5.65002 16.0897 5.27856 15.731C4.9071 15.3721 4.9071 14.7903 5.27856 14.4315L6.7605 13L3 13C2.44775 13 2 12.5523 2 12Z'
                                                        className={`${
                                                          HoriHover
                                                            ? 'fill-[#333333]'
                                                            : 'fill-[#888888]'
                                                        }`}
                                                      />
                                                    </svg>

                                                    {/* <AlignHori /> */}
                                                  </div>
                                                  <div
                                                    className='cursor-pointer'
                                                    onClick={
                                                      verticalAlign
                                                    }
                                                  >
                                                    <svg
                                                      onMouseEnter={() =>
                                                        setVertHover(
                                                          true
                                                        )
                                                      }
                                                      onMouseLeave={() =>
                                                        setVertHover(
                                                          false
                                                        )
                                                      }
                                                      width='24'
                                                      height='24'
                                                      viewBox='0 0 24 24'
                                                      fill='none'
                                                      xmlns='http://www.w3.org/2000/svg'
                                                    >
                                                      <rect
                                                        x='0.5'
                                                        y='0.5'
                                                        width='23'
                                                        height='23'
                                                        rx='5.5'
                                                        className={`${
                                                          VertHover
                                                            ? 'stroke-[#333333]'
                                                            : 'stroke-[#888888]'
                                                        }`}
                                                      />
                                                      <rect
                                                        x='3'
                                                        y='11'
                                                        width='18'
                                                        height='2'
                                                        rx='1'
                                                        className={`${
                                                          VertHover
                                                            ? 'fill-[#333333]'
                                                            : 'fill-[#888888]'
                                                        }`}
                                                      />
                                                      <path
                                                        fill-rule='evenodd'
                                                        clip-rule='evenodd'
                                                        d='M12 2C11.4477 2 11 2.44775 11 3V6.7605L9.56848 5.27856C9.20972 4.9071 8.62793 4.9071 8.26917 5.27856C7.91028 5.65002 7.91028 6.25232 8.26917 6.62378L11.3352 9.79785C11.4602 9.92725 11.6298 10 11.8066 10H12H12.1935C12.3702 10 12.5398 9.92725 12.6648 9.79785L15.731 6.62378C16.0897 6.25232 16.0897 5.65002 15.731 5.27856C15.3721 4.9071 14.7903 4.9071 14.4315 5.27856L13 6.7605V3C13 2.44775 12.5523 2 12 2Z'
                                                        className={`${
                                                          VertHover
                                                            ? 'fill-[#333333]'
                                                            : 'fill-[#888888]'
                                                        }`}
                                                      />
                                                      <path
                                                        fill-rule='evenodd'
                                                        clip-rule='evenodd'
                                                        d='M12 22C11.4477 22 11 21.5522 11 21V17.2395L9.56848 18.7214C9.20972 19.0929 8.62793 19.0929 8.26917 18.7214C7.91028 18.35 7.91028 17.7477 8.26917 17.3762L11.3352 14.2021C11.4602 14.0727 11.6298 14 11.8066 14H12H12.1935C12.3702 14 12.5398 14.0727 12.6648 14.2021L15.731 17.3762C16.0897 17.7477 16.0897 18.35 15.731 18.7214C15.3721 19.0929 14.7903 19.0929 14.4315 18.7214L13 17.2395V21C13 21.5522 12.5523 22 12 22Z'
                                                        className={`${
                                                          VertHover
                                                            ? 'fill-[#333333]'
                                                            : 'fill-[#888888]'
                                                        }`}
                                                      />
                                                    </svg>

                                                    {/* <AlignVert /> */}
                                                  </div>
                                                  <div>
                                                    <Recycle />
                                                  </div>
                                                </div>

                                                <div>
                                                  <Slider
                                                    onChange={
                                                      sizeRange
                                                    }
                                                    sx={{
                                                      width: '203px',
                                                      height: '8px',
                                                      padding: '0',
                                                      '& .MuiSlider-rail':
                                                        {
                                                          opacity:
                                                            '50%',
                                                          background:
                                                            'linear-gradient(to right, #fd4b9e, #9794d0, #46d59a, #beed10)',
                                                        },
                                                      '& .MuiSlider-track':
                                                        {
                                                          opacity:
                                                            '0',
                                                        },
                                                      '& .MuiSlider-thumb':
                                                        {
                                                          width:
                                                            '11px',
                                                          height:
                                                            '13px',
                                                          borderStyle:
                                                            'solid',
                                                          borderRadius:
                                                            '1px',
                                                          borderBottomLeftRadius:
                                                            '21px',
                                                          borderBottomRightRadius:
                                                            '21px',
                                                          backgroundColor:
                                                            '#888888',
                                                        },
                                                      '& .MuiSlider-thumb':
                                                        {
                                                          background:
                                                            'none',
                                                          boxShadow:
                                                            'none!important',
                                                          '-webkit-box-shadow':
                                                            'none!important',
                                                          backgroundImage: `url(${SliderButton})`,

                                                          boxShadow:
                                                            '0 0 #0000!important',
                                                        },
                                                      '& .css-eg0mwd-MuiSlider-thumb:before':
                                                        {
                                                          boxShadow:
                                                            'none!important',
                                                        },
                                                      '& .MuiSlider-thumb:before':
                                                        {
                                                          boxShadow:
                                                            'none!important',
                                                        },
                                                    }}
                                                    defaultValue={50}
                                                    aria-label='Disabled slider'
                                                  />

                                                  <img
                                                    className='relative bottom-[6px]'
                                                    src={scale}
                                                    alt=''
                                                  />
                                                </div>

                                                <div className='w-[94px]'></div>
                                              </div>
                                            </div>
                                            {/* <div className='w-full flex gap-[5px] justify-center pt-[5px]'>
                      {alignVerticalFocus == true ? (
                        <button
                          onClick={verticalAlign}
                          className='w-[105px] h-[28px] bg-[#bbbbbb] text-[12px] rounded-[14px]'
                        >
                          Align Vertically
                        </button>
                      ) : (
                        <button
                          onClick={verticalAlign}
                          className='w-[105px] h-[28px] bg-[#ffffff] text-[#878787] text-[12px] rounded-[14px] border border-[#eaeaea]'
                        >
                          Align Vertically
                        </button>
                      )}
                      {alignVerticalFocus == true ? (
                        <button
                          onClick={horizontalAlign}
                          className='w-[105px] h-[28px] bg-[#ffffff] text-[#878787] text-[12px] rounded-[14px] border border-[#eaeaea]'
                        >
                          Align Horizontally
                        </button>
                      ) : (
                        <button className='w-[105px] h-[28px] bg-[#bbbbbb] text-[12px] rounded-[14px]'>
                          Align Horizontally
                        </button>
                      )}
                    </div> */}
                                            <div>
                                              <div className='w-[540px] bg-[#ffffff] rounded-gradient-border pt-[12px] pb-[15px] px-[12px] mt-[30px]'>
                                                <div className='flex flex-col h-[100%] w-[100%] bg-[#ffffff] rounded-2xl text-primaryGray text-[12px]'>
                                                  <p className='text-[#bbbbbb] text-[11px] leading-[14px]'>
                                                    You have selected
                                                  </p>

                                                  <div className='mt-[8px] border-t border-[#EFEFEF] w-[100%] flex '>
                                                    <div
                                                      style={{
                                                        width:
                                                          'max-content',
                                                      }}
                                                      className='flex flex-col '
                                                    >
                                                      <p className='text-primaryGray text-sm12 font-medium leading-4 py-[1px]  border-b border-[#EFEFEF] whitespace-no-wrap'>
                                                        Category:
                                                      </p>
                                                      <p className='text-primaryGray text-sm12 font-medium leading-4  py-[1px]  border-b border-[#EFEFEF] whitespace-no-wrap'>
                                                        T-Shirt Style:
                                                      </p>
                                                      <p className='text-primaryGray text-sm12 font-medium leading-4   py-[1px] border-b border-[#EFEFEF] whitespace-no-wrap'>
                                                        Colours:
                                                      </p>
                                                      <p className='text-primaryGray text-sm12 font-medium leading-4  py-[1px] border-b border-[#EFEFEF] whitespace-no-wrap'>
                                                        T-Shirt Size:
                                                      </p>
                                                      <p
                                                        style={{
                                                          whiteSpace:
                                                            'nowrap',
                                                        }}
                                                        className='text-primaryGray text-sm12 font-medium leading-4  py-[1px] border-b border-[#EFEFEF] whitespace-no-wrap'
                                                      >
                                                        Art Print
                                                        Size:
                                                      </p>
                                                    </div>
                                                    <div
                                                      // style={{
                                                      //   width: 'fit-content',
                                                      // }}
                                                      className='flex flex-col w-[100%]'
                                                    >
                                                      <p className='text-primaryGray text-sm12 w-[100%] pl-[8px]  py-[1px] leading-4  border-b border-[#EFEFEF]'>
                                                        Men’s T-Shirt,
                                                        Women’s
                                                        T-Shirt, Kid’s
                                                        T-Shirt
                                                      </p>
                                                      <p className='text-primaryGray text-sm12  pl-[8px] leading-4  py-[1px]  border-b border-[#EFEFEF]'>
                                                        Half Sleeve,
                                                        Full Sleeve
                                                      </p>
                                                      <div className='text-primaryGray flex items-center text-sm12  py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                                                        <div className='flex gap-[4px]'>
                                                          <div className='w-[16px] h-[16px] bg-[#335077] rounded-[5000px]'></div>
                                                          <div className='w-[16px] h-[16px] bg-[#27d3c9] rounded-[5000px]'></div>
                                                          <div className='w-[16px] h-[16px] bg-[#e4d045] rounded-[5000px]'></div>
                                                          <div className='w-[16px] h-[16px] bg-[#dddddd] rounded-[5000px]'></div>
                                                        </div>
                                                      </div>
                                                      <p className='text-primaryGray text-sm12  pl-[8px] leading-4  py-[1px] border-b border-[#EFEFEF]'>
                                                        XS, S, M, L,
                                                        XL, 2XL, 3XL
                                                      </p>
                                                      <p className='text-primaryGray text-sm12  pl-[8px] leading-4  py-[1px] border-b border-[#EFEFEF]'>
                                                        2400 x 3200
                                                        Pixels
                                                      </p>
                                                    </div>
                                                  </div>

                                                  {/* <div className='text-[11px] leading-[1]'>
                            <div className='flex border-b border-t border-[#efefef]  h-[19px] flex items-center '>
                              <p className='w-[80px] text-primaryGray text-sm12 font-medium leading-4'>
                                Category:
                              </p>
                              <p className=' text-primaryGray text-sm12 leading-4'>
                                Men’s T-Shirt, Women’s T-Shirt, Kid’s
                                T-Shirt
                              </p>
                            </div>
                            <div className='flex border-b border-[#efefef]  h-[19px] flex items-center'>
                              <p className='w-[80px] font-medium text-primaryGray text-sm12 leading-4'>
                                T-Shirt Style:
                              </p>
                              <p className=' text-primaryGray text-sm12 leading-4'>
                                Half Sleeve, Full Sleeve
                              </p>
                            </div>
                            <div className='flex border-b border-[#efefef]  h-[19px] flex items-center'>
                              <p className='w-[80px] font-medium text-primaryGray text-sm12 leading-4'>
                                Colours:
                              </p>
                              <div className='flex gap-[4px]'>
                                <div className='w-[16px] h-[16px] bg-[#335077] rounded-[5000px]'></div>
                                <div className='w-[16px] h-[16px] bg-[#27d3c9] rounded-[5000px]'></div>
                                <div className='w-[16px] h-[16px] bg-[#e4d045] rounded-[5000px]'></div>
                                <div className='w-[16px] h-[16px] bg-[#dddddd] rounded-[5000px]'></div>
                              </div>
                            </div>
                            <div className='flex border-b border-[#efefef]  h-[19px] flex items-center'>
                              <p className='w-[80px] font-medium text-primaryGray text-sm12 leading-4'>
                                T-Shirt Size:
                              </p>
                              <p className=' text-primaryGray text-sm12 leading-4'>
                                XS, S, M, L, XL, 2XL, 3XL
                              </p>
                            </div>
                            <div className='flex border-b border-[#efefef]  h-[19px] flex items-center'>
                              <p className='w-[80px] font-medium text-primaryGray text-sm12 leading-4'>
                                Art Print Size:
                              </p>
                              <p className=' text-primaryGray text-sm12 leading-4'>
                                2400 x 3200 Pixels
                              </p>
                            </div>
                          </div> */}
                                                </div>
                                              </div>

                                              <p className='text-[#bbbbbb] text-[11px] leading-[12px] mt-[15px]'>
                                                Product Activation
                                                Status
                                              </p>

                                              <div className='flex mt-[10px]'>
                                                <div className='flex flex-col pr-[10px] border-r border-r-[2px] border-r-[#efefef]'>
                                                  <p className='text-[54px] text-primaryBlack font-light leading-[45px]'>
                                                    95
                                                  </p>
                                                  <p className='text-[11px] leading-[12px] text-primaryGray text-center'>
                                                    Products
                                                  </p>
                                                </div>
                                                <div className='flex flex-col pr-[10px] pl-[10px] border-r border-r-[2px] border-r-[#efefef]'>
                                                  <p className='text-[54px] text-pinkColor font-thin leading-[45px]'>
                                                    26
                                                  </p>
                                                  <p className='text-[11px] leading-[12px] text-primaryGray text-center'>
                                                    Activated
                                                  </p>
                                                </div>
                                                <div className='flex flex-col  pr-[10px] pl-[10px] border-r border-r-[2px] border-r-[#efefef]'>
                                                  <p className='text-[54px] text-[#b3b3b3] font-thin leading-[45px]'>
                                                    24
                                                  </p>
                                                  <p className='text-[11px] leading-[12px] text-primaryGray text-center'>
                                                    De-Activated
                                                  </p>
                                                </div>
                                                <div className='flex flex-col  pr-[10px] pl-[10px] '>
                                                  <p className='text-[54px] text-primaryGray font-thin leading-[45px]'>
                                                    45
                                                  </p>
                                                  <p className='text-[11px] leading-[12px] text-primaryGray text-center'>
                                                    Remaining
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className='w-[50%]'>
                                            <p className='text-[25px] font-medium leading-[29px] text-primaryBlack mb-[7px]'>
                                              T-Shirt
                                            </p>

                                            <div className='flex flex-col'>
                                              <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mb-[8px]'>
                                                Select Category
                                              </p>
                                              {/* <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mb-[8px]'>
                        Select Category
                      </p> */}

                                              <div className='relative h-[40px]'>
                                                <div
                                                  ref={
                                                    CateClickOutside
                                                  }
                                                  className={`${
                                                    isAllStyleOpen ===
                                                    true
                                                      ? 'shadow-dropShadow'
                                                      : ''
                                                  } absolute rounded-[20px] w-[228px] z-[99] max-h-[260px]`}
                                                >
                                                  <button
                                                    onClick={() => {
                                                      setIsAllStyleOpen(
                                                        !isAllStyleOpen
                                                      );
                                                    }}
                                                    className={`${
                                                      isAllStyleOpen ===
                                                      true
                                                        ? 'rounded-t-[20px] border-b border-[#EFEFEF]'
                                                        : 'border rounded-[20px] border-[#d6d6d6]'
                                                    } flex items-center justify-between px-[15px]  text-sm14 font-medium cursor-pointer w-[228px] h-[40px] bg-[#FFFFFF]`}
                                                  >
                                                    <span className='text-[#bbbbbb]'>
                                                      Select Category
                                                    </span>
                                                    {/* <img
                              className='inline-block'
                              src={dropdown}
                              alt=''
                            /> */}
                                                    <DropArrow
                                                      className={`fill-[#757575]  ${
                                                        isAllStyleOpen
                                                          ? 'rotate-180'
                                                          : ''
                                                      }`}
                                                    />
                                                  </button>

                                                  {isAllStyleOpen && (
                                                    <ul
                                                      onClick={
                                                        handleClickOutsideCate
                                                      }
                                                      className='cursor-pointer rounded-b-2xl bg-[#ffffff] overflow w-[228px] text-left text-[13px] text-primaryGray max-h-[220px] overflow-y-auto'
                                                    >
                                                      {styleDrop.map(
                                                        (obj) => (
                                                          <li className='flex h-[40px] items-center px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'>
                                                            {obj.a}
                                                          </li>
                                                        )
                                                      )}
                                                    </ul>
                                                  )}
                                                </div>
                                              </div>
                                            </div>

                                            <div className='flex flex-col mt-[22px] '>
                                              <div className='flex gap-[4px] items-center mb-[8px]'>
                                                <p className='text-primaryBlack text-[15px] font-medium leading-[18px]'>
                                                  Style & Print
                                                  Location
                                                </p>
                                                <div>
                                                  <IIcon />
                                                </div>
                                              </div>

                                              <div className='flex gap-[16px]'>
                                                <div className='flex'>
                                                  <div className='w-[48px] flex flex-col mr-[6px] cursor-pointer'>
                                                    <div className='mx-[auto]'>
                                                      <svg
                                                        onMouseEnter={() =>
                                                          setHalfSleevesHover(
                                                            true
                                                          )
                                                        }
                                                        onMouseLeave={() =>
                                                          setHalfSleevesHover(
                                                            false
                                                          )
                                                        }
                                                        className='z-[9999]'
                                                        width='48'
                                                        height='48'
                                                        viewBox='0 0 48 48'
                                                        fill='none'
                                                        xmlns='http://www.w3.org/2000/svg'
                                                      >
                                                        <rect
                                                          x='0.5'
                                                          y='0.5'
                                                          width='47'
                                                          height='47'
                                                          rx='9.5'
                                                          stroke={`${
                                                            halfSleevesHover
                                                              ? '#333333'
                                                              : '#BBBBBB'
                                                          }`}
                                                        />
                                                        <path
                                                          fill-rule='evenodd'
                                                          clip-rule='evenodd'
                                                          d='M36.4615 8.89151C35.5019 8.31625 34.4071 8 33.2883 8H29C28.8006 9.16315 28.1815 10.2128 27.26 10.95C26.3385 11.6872 25.1785 12.0608 24 12C19.445 12 18.971 8.125 18.971 8.125L14.8001 8.03768C13.6259 8.0131 12.473 8.33871 11.4677 8.94583C10.3378 9.62818 9.41092 10.155 8 11L9 19H14V38C14 39.1046 14.8954 40 16 40H32C33.1046 40 34 39.1046 34 38V19H39L40 11C38.684 10.1963 37.6648 9.61281 36.4615 8.89151Z'
                                                          fill={`${
                                                            halfSleevesHover
                                                              ? '#333333'
                                                              : '#BBBBBB'
                                                          }`}
                                                        />
                                                      </svg>
                                                    </div>
                                                    <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                                                      Half
                                                      <br /> Sleeves
                                                    </p>
                                                  </div>
                                                  <div className='flex flex-col w-[60px] cursor-pointer'>
                                                    <div className='mx-[auto]'>
                                                      <svg
                                                        onMouseEnter={() =>
                                                          setFullSleevesHover(
                                                            true
                                                          )
                                                        }
                                                        onMouseLeave={() =>
                                                          setFullSleevesHover(
                                                            false
                                                          )
                                                        }
                                                        width='48'
                                                        height='48'
                                                        viewBox='0 0 48 48'
                                                        fill='none'
                                                        xmlns='http://www.w3.org/2000/svg'
                                                      >
                                                        <rect
                                                          x='0.5'
                                                          y='0.5'
                                                          width='47'
                                                          height='47'
                                                          rx='9.5'
                                                          stroke={`${
                                                            fullSleevesHover
                                                              ? '#333333'
                                                              : '#BBBBBB'
                                                          }`}
                                                        />
                                                        <path
                                                          fill-rule='evenodd'
                                                          clip-rule='evenodd'
                                                          d='M9 13C9 10.2386 11.2386 8 14 8H19C19 8 19.445 12 24 12C25.1786 12.0608 26.3385 11.6872 27.26 10.95C28.1815 10.2128 28.8006 9.16315 29 8H34C36.7614 8 39 10.2386 39 13V36C39 37.1046 38.1046 38 37 38H34V19H33V38C33 39.1046 32.1046 40 31 40H17C15.8954 40 15 39.1046 15 38V19H14V38H11C9.89543 38 9 37.1046 9 36V13Z'
                                                          fill={`${
                                                            fullSleevesHover
                                                              ? '#333333'
                                                              : '#BBBBBB'
                                                          }`}
                                                        />
                                                      </svg>
                                                    </div>

                                                    <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                                                      Full
                                                      <br /> Sleeves
                                                    </p>
                                                  </div>
                                                  <div className='flex flex-col w-[60px] cursor-pointer'>
                                                    <div className='mx-[auto]'>
                                                      <svg
                                                        onMouseEnter={() =>
                                                          setSleevelessHover(
                                                            true
                                                          )
                                                        }
                                                        onMouseLeave={() =>
                                                          setSleevelessHover(
                                                            false
                                                          )
                                                        }
                                                        width='48'
                                                        height='48'
                                                        viewBox='0 0 48 48'
                                                        fill='none'
                                                        xmlns='http://www.w3.org/2000/svg'
                                                      >
                                                        <rect
                                                          x='0.5'
                                                          y='0.5'
                                                          width='47'
                                                          height='47'
                                                          rx='9.5'
                                                          stroke={`${
                                                            sleevelessHover
                                                              ? '#333333'
                                                              : '#BBBBBB'
                                                          }`}
                                                        />
                                                        <path
                                                          fill-rule='evenodd'
                                                          clip-rule='evenodd'
                                                          d='M20 8C20 10.2091 21.7909 12 24 12C26.2091 12 28 10.2091 28 8H28.9H29H30H31C31.5523 8 32 8.44772 32 9V15C32 16.6569 33.3431 18 35 18H36V39C36 39.5523 35.5523 40 35 40H24H13C12.4477 40 12 39.5523 12 39V18H13C14.6569 18 16 16.6569 16 15V9C16 8.44772 16.4477 8 17 8H18H19H19.1H20Z'
                                                          fill={`${
                                                            sleevelessHover
                                                              ? '#333333'
                                                              : '#BBBBBB'
                                                          }`}
                                                        />
                                                      </svg>
                                                    </div>
                                                    <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                                                      Sleeveless
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className='flex'>
                                                  <div className='w-[48px] flex flex-col mr-[6px] cursor-pointer'>
                                                    <div className='mx-[auto]'>
                                                      <svg
                                                        onMouseEnter={() =>
                                                          setPrintOnFrontHover(
                                                            true
                                                          )
                                                        }
                                                        onMouseLeave={() =>
                                                          setPrintOnFrontHover(
                                                            false
                                                          )
                                                        }
                                                        width='48'
                                                        height='48'
                                                        viewBox='0 0 48 48'
                                                        fill='none'
                                                        xmlns='http://www.w3.org/2000/svg'
                                                      >
                                                        <rect
                                                          x='0.5'
                                                          y='0.5'
                                                          width='47'
                                                          height='47'
                                                          rx='9.5'
                                                          stroke={`${
                                                            printOnFrontHover
                                                              ? '#333333'
                                                              : '#BBBBBB'
                                                          }`}
                                                        />
                                                        <path
                                                          fill-rule='evenodd'
                                                          clip-rule='evenodd'
                                                          d='M29 8H33.2883C34.4071 8 35.5018 8.31617 36.4614 8.89144C36.9346 9.1751 37.3793 9.43745 37.8248 9.70025C38.5122 10.1058 39.2015 10.5123 40 11L39 19H34V38C34 39.1046 33.1046 40 32 40H16C14.8954 40 14 39.1046 14 38V19H9L8 11C8.68129 10.592 9.24972 10.2581 9.78264 9.94516C10.3534 9.60993 10.8835 9.29862 11.4679 8.94572C12.4732 8.33859 13.6259 8.0131 14.8001 8.03768L18.971 8.125C18.971 8.125 19.445 12 24 12C25.1785 12.0608 26.3385 11.6872 27.26 10.95C28.1815 10.2128 28.8006 9.16315 29 8ZM27.75 17C28.9927 17 30 18.0074 30 19.25V26.75C30 27.9927 28.9927 29 27.75 29H20.25C19.0074 29 18 27.9927 18 26.75V19.25C18 18.0074 19.0074 17 20.25 17H27.75ZM24 21.6232C23.2582 20.775 22.3417 20.793 21.75 21.6232L19.5 24.7507H26.25L24 21.6232ZM24.7502 21.75H25.3127C25.9028 21.75 26.4586 22.0279 26.8127 22.5L28.5002 24.75L27.0001 24.7501L24.7502 21.75Z'
                                                          fill={`${
                                                            printOnFrontHover
                                                              ? '#333333'
                                                              : '#BBBBBB'
                                                          }`}
                                                        />
                                                      </svg>
                                                    </div>

                                                    <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                                                      Print on
                                                      <br /> Front
                                                    </p>
                                                  </div>

                                                  <div className='flex flex-col  w-[60px] cursor-pointer'>
                                                    <div className='mx-[auto]'>
                                                      <svg
                                                        width='48'
                                                        height='48'
                                                        viewBox='0 0 48 48'
                                                        fill='none'
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        onMouseEnter={() =>
                                                          setPrintOnBackHover(
                                                            true
                                                          )
                                                        }
                                                        onMouseLeave={() =>
                                                          setPrintOnBackHover(
                                                            false
                                                          )
                                                        }
                                                      >
                                                        <rect
                                                          x='0.5'
                                                          y='0.5'
                                                          width='47'
                                                          height='47'
                                                          rx='9.5'
                                                          stroke={`${
                                                            printOnBackHover
                                                              ? '#333333'
                                                              : '#BBBBBB'
                                                          }`}
                                                        />
                                                        <path
                                                          fill-rule='evenodd'
                                                          clip-rule='evenodd'
                                                          d='M29 8H33.2883C34.4071 8 35.5018 8.31617 36.4614 8.89144C36.9346 9.1751 37.3793 9.43745 37.8248 9.70025C38.5122 10.1058 39.2015 10.5123 40 11L39 19H34V38C34 39.1046 33.1046 40 32 40H16C14.8954 40 14 39.1046 14 38V19H9L8 11C8.68129 10.592 9.24972 10.2581 9.78264 9.94516C10.3534 9.60993 10.8835 9.29862 11.4679 8.94572C12.4732 8.33859 13.6259 8.0131 14.8001 8.03768L18.971 8.125C18.971 8.125 19.445 12 24 12C25.1785 12.0608 26.3385 11.6872 27.26 10.95C28.1815 10.2128 28.8006 9.16315 29 8ZM27.75 17C28.9927 17 30 18.0074 30 19.25V26.75C30 27.9927 28.9927 29 27.75 29H20.25C19.0074 29 18 27.9927 18 26.75V19.25C18 18.0074 19.0074 17 20.25 17H27.75ZM24 21.6232C23.2582 20.775 22.3417 20.793 21.75 21.6232L19.5 24.7507H26.25L24 21.6232ZM24.7502 21.75H25.3127C25.9028 21.75 26.4586 22.0279 26.8127 22.5L28.5002 24.75L27.0001 24.7501L24.7502 21.75Z'
                                                          fill={`${
                                                            printOnBackHover
                                                              ? '#333333'
                                                              : '#BBBBBB'
                                                          }`}
                                                        />
                                                      </svg>
                                                    </div>
                                                    <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                                                      Print on
                                                      <br /> Back
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            {/* <div className='flex flex-col mt-[21px]'>
                      <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mb-[8px]'>
                        Fabric Material
                      </p>

                      <div className='relative h-[40px]'>
                        <div
                          ref={FabClickOutside}
                          className={`${
                            isFabricDropOpen === true
                              ? 'shadow-dropShadow'
                              : ''
                          } absolute rounded-[20px] w-[228px] z-[99] max-h-[260px]`}
                        >
                          <button
                            onClick={() => {
                              setisFabricDropOpen(!isFabricDropOpen);
                            }}
                            className={`${
                              isFabricDropOpen === true
                                ? 'rounded-t-[20px] border-b border-[#EFEFEF]'
                                : 'border rounded-[20px] border-[#d6d6d6]'
                            } flex items-center justify-between px-[15px] text-primaryGray text-sm14 font-medium cursor-pointer w-[228px] h-[40px] bg-[#FFFFFF]`}
                          >
                            <span className='text-[#bbbbbb]'>
                              Select Fabric
                            </span>
                            <img
                              className='inline-block'
                              src={dropdown}
                              alt=''
                            />
                          </button>

                          {isFabricDropOpen && (
                            <ul
                              onClick={handleClickOutsideFab}
                              className='cursor-pointer rounded-b-2xl bg-[#ffffff] overflow  w-[228px] text-left text-[13px] text-primaryGray max-h-[220px] overflow-y-auto'
                            >
                              <li className='flex h-[40px] items-center px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'>
                                Cotton Fabric
                              </li>
                            </ul>
                          )}
                        </div>
                      </div>
                    </div> */}

                                            <div className='mt-[22px]'>
                                              <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mb-[8px]'>
                                                Select Colours
                                              </p>
                                              <div className='flex gap-[8px]'>
                                                {circle.map(
                                                  (item) => (
                                                    <div
                                                      key={item.id}
                                                      className={`w-[30px] h-[30px] rounded-[5000px] border flex justify-center items-center cursor-pointer`}
                                                      style={{
                                                        color: `${item.textColor}`,
                                                        backgroundColor: `${item.backgroundColor}`,
                                                        borderColor: `${item.borderColor}`,
                                                      }}
                                                      // onClick={() => check(item)}
                                                      // onClick={() => {
                                                      //   toggleColorTick(item.id);
                                                      // }}
                                                    >
                                                      {/* {item.tick && ( */}
                                                      <i
                                                        className={`bi bi-check-lg`}
                                                      ></i>
                                                      <div>
                                                        <Icon_Checkmark
                                                          className={`${
                                                            item.tick
                                                              ? 'fill-[#ffffff]'
                                                              : 'fill-[#8C8C8C]'
                                                          } `}
                                                        />
                                                      </div>
                                                      {/* )} */}
                                                    </div>
                                                  )
                                                )}

                                                {/* {productData?.productDetails?.map(
                          (item, index) => (
                            <div
                              key={index}
                              className={`w-[32px] h-[32px] rounded-full border flex justify-center items-center cursor-pointer`}
                              style={{
                                color: `${
                                  item.hexCode === '#ffffff'
                                    ? '#000000'
                                    : '#ffffff'
                                }`,
                                backgroundColor: `${item.hexCode}`,
                                borderColor: `${
                                  item.hexCode === '#ffffff'
                                    ? '#000000'
                                    : ''
                                }`,
                              }}
                              onClick={() => check(item)}
                            >
                              {checked?.find(
                                (obj) => obj.color === item.color
                              ) && <CheckIcon />}
                            </div>
                          )
                        )} */}
                                              </div>
                                            </div>

                                            <div className='flex flex-col mt-[22px]'>
                                              <div className='flex gap-[5px] items-center mb-[8px]'>
                                                <p className='text-primaryBlack text-[15px] font-medium leading-[18px] '>
                                                  Your Markup
                                                </p>
                                                <div
                                                  onMouseEnter={() =>
                                                    setMarkUpIButton(
                                                      true
                                                    )
                                                  }
                                                  onMouseLeave={() =>
                                                    setMarkUpIButton(
                                                      false
                                                    )
                                                  }
                                                  className='max-h-full flex items-center cursor-pointer'
                                                >
                                                  <IIcon />
                                                </div>
                                                {MarkUpIButton && (
                                                  <p className='text-[12px] text-primaryGray '>
                                                    Need help?
                                                  </p>
                                                )}
                                              </div>
                                              <p className='text-sm11 text-primaryGray'>
                                                Please enter your
                                                mark-up below. Your
                                                markup gets added to
                                                the base price to
                                                arrive at the final
                                                sell price. Mark-up is
                                                10% fixed for all
                                                prints on products.
                                              </p>

                                              <div className='mt-[11px]'>
                                                <table className='border-separate border-spacing-0 '>
                                                  <tbody>
                                                    <tr className='text-[14px] font-medium bg-[#ececec] '>
                                                      <td className='pl-[8px] w-[114px] text-primaryBlack h-[36px] border border-[#d6d6d6] rounded-tl-[16px]'>
                                                        Size
                                                      </td>

                                                      <td className='pl-[8px] w-[114px] text-primaryBlack h-[36px] border-t border-r border-b border-[#d6d6d6]'>
                                                        Your Markup
                                                      </td>
                                                      <td className='pl-[8px] w-[114px] text-primaryBlack h-[36px] border-t border-r border-b border-[#d6d6d6]'>
                                                        Base Price
                                                      </td>
                                                      {/* <td className='pl-[8px] w-[114px] text-primaryBlack h-[36px] border-t border-r border-b border-[#d6d6d6]'>
                                Your Markup
                              </td> */}
                                                      <td className='pl-[8px] w-[114px] text-primaryBlack h-[36px] border-t border-r border-b border-[#d6d6d6] rounded-tr-[16px]'>
                                                        Selling Price
                                                      </td>
                                                    </tr>

                                                    <tr className='text-[14px] font-medium'>
                                                      <td className='pl-[8px] text-primaryBlack text-[13px] h-[36px] border border-[#d6d6d6] bg-[#f7f7f7] '>
                                                        XS
                                                      </td>
                                                      <td className='pl-[8px] border-t border-r border-b border-[#d6d6d6]'>
                                                        <input
                                                          style={{
                                                            '::-webkit-inner-spin-button':
                                                              {
                                                                display:
                                                                  'none',
                                                              },
                                                            '::-webkit-outer-spin-button':
                                                              {
                                                                display:
                                                                  'none',
                                                              },
                                                            '-moz-appearance':
                                                              'textfield',
                                                            appearance:
                                                              'none',
                                                            MozAppearance:
                                                              'textfield!important',
                                                            WebkitAppearance:
                                                              'none!important',
                                                            appearance:
                                                              'none!important',
                                                          }}
                                                          type='number'
                                                          name='price'
                                                          className='w-[90px] h-[28px] border border-[#d6d6d6] text-primaryGray text-[13px] font-normal rounded-[5000px] pl-[8px] outline-none'
                                                          placeholder='10%'
                                                          // onChange={handleInputChangePrice}
                                                          // value={formData.price}
                                                        />
                                                      </td>
                                                      <td className='pl-[8px]  text-[13px] h-[36px] text-primaryGray border-t border-r border-b border-[#d6d6d6] bg-[#f7f7f7]'>
                                                        <span className='opacity-[40%]'>
                                                          18.00
                                                        </span>
                                                      </td>

                                                      <td className='pl-[8px]  text-[13px] h-[36px] border-t text-primaryGray border-r border-b border-[#d6d6d6] font-normal  '>
                                                        19.80
                                                      </td>
                                                    </tr>

                                                    <tr className='text-[14px] font-medium'>
                                                      <td className='pl-[8px] text-primaryBlack text-[13px] h-[36px] border-l border-b border-r border-[#d6d6d6] bg-[#f7f7f7] '>
                                                        S
                                                      </td>

                                                      <td className='pl-[8px] border-r border-b border-[#d6d6d6]'>
                                                        <input
                                                          style={{
                                                            '::-webkit-inner-spin-button':
                                                              {
                                                                display:
                                                                  'none',
                                                              },
                                                            '::-webkit-outer-spin-button':
                                                              {
                                                                display:
                                                                  'none',
                                                              },
                                                            '-moz-appearance':
                                                              'textfield',
                                                            appearance:
                                                              'none',
                                                            MozAppearance:
                                                              'textfield!important',
                                                            WebkitAppearance:
                                                              'none!important',
                                                            appearance:
                                                              'none!important',
                                                          }}
                                                          type='number'
                                                          name='price'
                                                          className='w-[90px] h-[28px] border border-[#d6d6d6] text-primaryGray text-[13px] font-normal rounded-[5000px] pl-[8px] outline-none'
                                                          placeholder='10%'
                                                          // onChange={handleInputChangePrice}
                                                          // value={formData.price}
                                                        />
                                                      </td>
                                                      <td className='pl-[8px]  text-[13px] h-[36px] text-primaryGray border-r border-b border-[#d6d6d6] bg-[#f7f7f7]'>
                                                        <span className='opacity-[40%]'>
                                                          18.00
                                                        </span>
                                                      </td>

                                                      <td className='pl-[8px]  text-[13px] h-[36px] text-primaryGray border-r border-b border-[#d6d6d6] font-normal  '>
                                                        19.80
                                                      </td>
                                                    </tr>
                                                    <tr className='text-[14px] font-medium'>
                                                      <td className='pl-[8px] text-primaryBlack text-[13px] h-[36px] border-l border-b border-r border-[#d6d6d6] bg-[#f7f7f7] '>
                                                        M
                                                      </td>
                                                      <td className='pl-[8px] border-r border-b border-[#d6d6d6]'>
                                                        <input
                                                          style={{
                                                            '::-webkit-inner-spin-button':
                                                              {
                                                                display:
                                                                  'none',
                                                              },
                                                            '::-webkit-outer-spin-button':
                                                              {
                                                                display:
                                                                  'none',
                                                              },
                                                            '-moz-appearance':
                                                              'textfield',
                                                            appearance:
                                                              'none',
                                                            MozAppearance:
                                                              'textfield!important',
                                                            WebkitAppearance:
                                                              'none!important',
                                                            appearance:
                                                              'none!important',
                                                          }}
                                                          type='number'
                                                          name='price'
                                                          className='w-[90px] h-[28px] border border-[#d6d6d6] text-primaryGray text-[13px] font-normal rounded-[5000px] pl-[8px] outline-none'
                                                          placeholder='10%'
                                                          // onChange={handleInputChangePrice}
                                                          // value={formData.price}
                                                        />
                                                      </td>
                                                      <td className='pl-[8px]  text-[13px] h-[36px] text-primaryGray border-r border-b border-[#d6d6d6] bg-[#f7f7f7]'>
                                                        <span className='opacity-[40%]'>
                                                          18.00
                                                        </span>
                                                      </td>

                                                      <td className='pl-[8px]  text-[13px] h-[36px] text-primaryGray border-r border-b border-[#d6d6d6] font-normal  '>
                                                        19.80
                                                      </td>
                                                    </tr>

                                                    <tr className='text-[14px] font-medium'>
                                                      <td className='pl-[8px] text-primaryBlack text-[13px] h-[36px] border-b border-r border-l border-[#d6d6d6] bg-[#f7f7f7] '>
                                                        L
                                                      </td>
                                                      <td className='pl-[8px] border-r border-b border-[#d6d6d6]'>
                                                        <input
                                                          style={{
                                                            '::-webkit-inner-spin-button':
                                                              {
                                                                display:
                                                                  'none',
                                                              },
                                                            '::-webkit-outer-spin-button':
                                                              {
                                                                display:
                                                                  'none',
                                                              },
                                                            '-moz-appearance':
                                                              'textfield',
                                                            appearance:
                                                              'none',
                                                            MozAppearance:
                                                              'textfield!important',
                                                            WebkitAppearance:
                                                              'none!important',
                                                            appearance:
                                                              'none!important',
                                                          }}
                                                          type='number'
                                                          name='price'
                                                          placeholder='10%'
                                                          className='w-[90px] h-[28px] border border-[#d6d6d6] text-primaryGray text-[13px] font-normal rounded-[5000px] pl-[8px] outline-none'
                                                          // onChange={handleInputChangePrice}
                                                          // value={formData.price}
                                                        />
                                                      </td>
                                                      <td className='pl-[8px]  text-[13px] h-[36px] text-primaryGray border-r border-b border-[#d6d6d6] bg-[#f7f7f7]'>
                                                        <span className='opacity-[40%]'>
                                                          21.00
                                                        </span>
                                                      </td>

                                                      <td className='pl-[8px]  text-[13px] h-[36px] text-primaryGray border-r border-b border-[#d6d6d6] font-normal  '>
                                                        19.80
                                                      </td>
                                                    </tr>

                                                    <tr className='text-[14px] font-medium'>
                                                      <td className='pl-[8px] text-primaryBlack text-[13px] h-[36px] border-b border-r border-l border-[#d6d6d6] bg-[#f7f7f7] '>
                                                        XL
                                                      </td>
                                                      <td className='pl-[8px] border-r border-b border-[#d6d6d6]'>
                                                        <input
                                                          style={{
                                                            '::-webkit-inner-spin-button':
                                                              {
                                                                display:
                                                                  'none',
                                                              },
                                                            '::-webkit-outer-spin-button':
                                                              {
                                                                display:
                                                                  'none',
                                                              },
                                                            '-moz-appearance':
                                                              'textfield',
                                                            appearance:
                                                              'none',
                                                            MozAppearance:
                                                              'textfield!important',
                                                            WebkitAppearance:
                                                              'none!important',
                                                            appearance:
                                                              'none!important',
                                                          }}
                                                          type='number'
                                                          name='price'
                                                          placeholder='10%'
                                                          className='w-[90px] h-[28px] border border-[#d6d6d6] text-primaryGray text-[13px] font-normal rounded-[5000px] pl-[8px] outline-none'
                                                          // onChange={handleInputChangePrice}
                                                          // value={formData.price}
                                                        />
                                                      </td>
                                                      <td className='pl-[8px]  text-[13px] h-[36px] text-primaryGray border-r border-b border-[#d6d6d6] bg-[#f7f7f7]'>
                                                        <span className='opacity-[40%]'>
                                                          18.00
                                                        </span>
                                                      </td>

                                                      <td className='pl-[8px]  text-[13px] h-[36px] text-primaryGray border-r border-b border-[#d6d6d6] font-normal  '>
                                                        19.80
                                                      </td>
                                                    </tr>

                                                    <tr className='text-[14px] font-medium'>
                                                      <td className='pl-[8px] text-primaryBlack text-[13px] h-[36px] border-b border-l border-r border-[#d6d6d6] bg-[#f7f7f7]'>
                                                        2XL
                                                      </td>
                                                      <td className='pl-[8px] border-r border-b border-[#d6d6d6]'>
                                                        <input
                                                          style={{
                                                            '::-webkit-inner-spin-button':
                                                              {
                                                                display:
                                                                  'none',
                                                              },
                                                            '::-webkit-outer-spin-button':
                                                              {
                                                                display:
                                                                  'none',
                                                              },
                                                            '-moz-appearance':
                                                              'textfield',
                                                            appearance:
                                                              'none',
                                                            MozAppearance:
                                                              'textfield!important',
                                                            WebkitAppearance:
                                                              'none!important',
                                                            appearance:
                                                              'none!important',
                                                          }}
                                                          type='number'
                                                          name='price'
                                                          placeholder='10%'
                                                          className='w-[90px] h-[28px] border border-[#d6d6d6] text-primaryGray text-[13px] font-normal rounded-[5000px] pl-[8px] outline-none'
                                                          // onChange={handleInputChangePrice}
                                                          // value={formData.price}
                                                        />
                                                      </td>
                                                      <td className='pl-[8px]  text-[13px] h-[36px] text-primaryGray border-r border-b border-[#d6d6d6] bg-[#f7f7f7]'>
                                                        <span className='opacity-[40%]'>
                                                          18.00
                                                        </span>
                                                      </td>

                                                      <td className='pl-[8px]  text-[13px] h-[36px] text-primaryGray border-r border-b border-[#d6d6d6] font-normal  '>
                                                        23.10
                                                      </td>
                                                    </tr>

                                                    <tr className='text-[14px] font-medium'>
                                                      <td className='pl-[8px] text-primaryBlack text-[13px] h-[36px] border-b border-l border-r border-[#d6d6d6] bg-[#f7f7f7] rounded-bl-[16px]'>
                                                        3XL
                                                      </td>
                                                      <td className='pl-[8px] border-r border-b border-[#d6d6d6]'>
                                                        <input
                                                          style={{
                                                            '::-webkit-inner-spin-button':
                                                              {
                                                                display:
                                                                  'none',
                                                              },
                                                            '::-webkit-outer-spin-button':
                                                              {
                                                                display:
                                                                  'none',
                                                              },
                                                            '-moz-appearance':
                                                              'textfield',
                                                            appearance:
                                                              'none',
                                                            MozAppearance:
                                                              'textfield!important',
                                                            WebkitAppearance:
                                                              'none!important',
                                                            appearance:
                                                              'none!important',
                                                          }}
                                                          type='number'
                                                          name='price'
                                                          placeholder='10%'
                                                          className='w-[90px] h-[28px] border border-[#d6d6d6] text-primaryGray text-[13px] font-normal rounded-[5000px] pl-[8px] outline-none'
                                                          // onChange={handleInputChangePrice}
                                                          // value={formData.price}
                                                        />
                                                      </td>
                                                      <td className='pl-[8px]  text-[13px] h-[36px] text-primaryGray border-r border-b border-[#d6d6d6] bg-[#f7f7f7]'>
                                                        <span className='opacity-[40%]'>
                                                          23.00
                                                        </span>
                                                      </td>

                                                      <td className='pl-[8px]  text-[13px] h-[36px] text-primaryGray border-r border-b border-[#d6d6d6] font-normal rounded-br-[16px] '>
                                                        25.30
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>

                                            <div className='flex flex-col mt-[32px]'>
                                              <AntSwitch
                                                defaultChecked
                                                inputProps={{
                                                  'aria-label':
                                                    'ant design',
                                                }}
                                              />

                                              <p className='text-[11px] text-primaryGray'>
                                                Product Activated
                                              </p>
                                            </div>
                                            <div className='pt-[20px] flex gap-[8px]'>
                                              <button
                                                // onClick={onSave}
                                                className='blackBtn'
                                              >
                                                Save
                                              </button>
                                              <button className='outlineBtn'>
                                                Reset
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </Popup>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>

              <div>
                <Popup
                  trigger={
                    <button className='bg-[#7367F0] h-[40px] w-[150px] rounded-lg text-[white] text-[16px]'>
                      Add
                    </button>
                  }
                  modal
                  closeOnDocumentClick
                >
                  {(close) => (
                    <div className='w-[370px] lg:w-[651px] lg:h-[542px] h-[820px] rounded-lg bg-[white] shadow'>
                      <div className='flex'>
                        <p className=' pt-[20px] text-[23px] font-medium ml-4 '>
                          Admin Art-Product Master
                        </p>
                        <button
                          className='ml-auto h-[40px] w-[40px]'
                          onClick={close}
                        >
                          &times;
                        </button>
                      </div>
                      <hr className='mt-[25px] lg:w-[651px] h-[1px] bg-[white]'></hr>
                      <div className='mx-[auto]  lg:h-[305px] h-[590px] w-[350px] lg:w-[585px] lg:mt-[20px] mt-[10px] overflow-y-auto'>
                        <div className='lg:flex lg:ml-[22px] ml-[15px]'>
                          <div className='flex flex-col'>
                            <p className='formhead lg:mt-[20px] mt-[10px] '>
                              Admin Art Product Name{' '}
                            </p>
                            <textarea
                              type='text'
                              className='forminput  mt-3 w-[320px] lg:w-[537px] rounded-lg'
                              name='adminArtProductName'
                              onChange={handleInputChange}
                              value={tempData.adminArtProductName}

                              // value={framedata.frameName}
                            />

                            <div className='lg:flex '>
                              <div className='flex flex-col'>
                                <p className='formhead lg:mt-[20px] mt-[10px] '>
                                  Date
                                </p>
                                <input
                                  type='text'
                                  className='forminput  mt-3 w-[320px] lg:w-[258px] rounded-lg'
                                  name='date'
                                  onChange={handleInputChange}
                                  value={tempData.date}

                                  // value={framedata.frameProductNo}
                                />
                              </div>
                              <div className='flex flex-col lg:ml-[20px]'>
                                <p className='formhead lg:mt-[20px] mt-[10px]'>
                                  Status
                                </p>
                                <input
                                  type='text'
                                  className='forminput  mt-3  w-[320px] lg:w-[258px] '
                                  name='status'
                                  onChange={handleInputChange}
                                  value={tempData.status}
                                />
                              </div>
                            </div>

                            <div className='lg:flex '>
                              <div className='flex flex-col'>
                                <p className='formhead  mt-[25px] '>
                                  {' '}
                                  Image
                                </p>
                                <div className='forminput text-[20px] mt-3 w-[100%] lg:w-[258px] rounded-lg leading-none pl-[5px]'>
                                  <input
                                    type='file'
                                    style={{ display: 'none' }}
                                    accept='image/*'
                                    // onChange={handleFileChange}
                                    // ref={fileInputRef}
                                    name='image'
                                    multiple
                                  />

                                  {imageName ? (
                                    <span>{imageName}</span>
                                  ) : (
                                    <a
                                      className='cursor-pointer text-[#808080] text-[30px] '
                                      onClick={handleButtonClick}
                                    >
                                      +
                                    </a>
                                  )}
                                </div>
                              </div>
                              <div className='flex flex-col lg:ml-[20px]'>
                                <p className='formhead lg:mt-[20px] mt-[10px]'>
                                  Size
                                </p>
                                <input
                                  type='text'
                                  className='forminput  mt-3  w-[320px] lg:w-[258px] '
                                  name='size'
                                  onChange={handleInputChange}
                                  value={tempData.size}
                                />
                              </div>
                            </div>

                            <div className='lg:flex '>
                              <div className='flex flex-col'>
                                <p className='formhead lg:mt-[20px] mt-[10px] '>
                                  BasePrice
                                </p>
                                <input
                                  type='text'
                                  className='forminput  mt-3 w-[320px] lg:w-[258px] rounded-lg'
                                  name='basePrice'
                                  onChange={handleInputChange}
                                  value={tempData.basePrice}
                                />
                              </div>
                              <div className='flex flex-col lg:ml-[20px]'>
                                <p className='formhead lg:mt-[20px] mt-[10px]'>
                                  Sell Price
                                </p>
                                <input
                                  type='text'
                                  className='forminput  mt-3  w-[320px] lg:w-[258px] '
                                  name='sellPrice'
                                  onChange={handleInputChange}
                                  value={tempData.sellPrice}
                                />
                              </div>
                            </div>
                            <div className='lg:flex '>
                              <div className='flex flex-col'>
                                <p className='formhead lg:mt-[20px] mt-[10px] '>
                                  SizeName
                                </p>
                                <input
                                  type='text'
                                  className='forminput  mt-3 w-[320px] lg:w-[258px] rounded-lg'
                                  name='sizeName'
                                  onChange={handleInputChange}
                                  value={tempData.sizeName}
                                />
                              </div>
                              <div className='flex flex-col lg:ml-[20px]'>
                                <p className='formhead lg:mt-[20px] mt-[10px]'>
                                  Qty
                                </p>
                                <input
                                  type='text'
                                  className='forminput  mt-3  w-[320px] lg:w-[258px] '
                                  name='qty'
                                  onChange={handleInputChange}
                                  value={tempData.qty}
                                />
                              </div>
                            </div>
                            <div className='lg:flex '>
                              <div className='flex flex-col'>
                                <p className='formhead lg:mt-[20px] mt-[10px] '>
                                  Art Product Unique No
                                </p>
                                <input
                                  type='text'
                                  className='forminput  mt-3 w-[320px] lg:w-[258px] rounded-lg'
                                  name='artProductUniqueNo'
                                  onChange={handleInputChange}
                                  value={tempData.artProductUniqueNo}
                                />
                              </div>
                              <div className='flex flex-col lg:ml-[20px]'>
                                <p className='formhead lg:mt-[20px] mt-[10px]'>
                                  CanvasSize
                                </p>
                                <input
                                  type='text'
                                  className='forminput  mt-3  w-[320px] lg:w-[258px] '
                                  name='canvasSize'
                                  onChange={handleInputChange}
                                  value={tempData.canvasSize}
                                />
                              </div>
                            </div>
                            <div className='lg:flex '>
                              <div className='flex flex-col'>
                                <p className='formhead lg:mt-[20px] mt-[10px] '>
                                  User Id
                                </p>
                                <input
                                  type='text'
                                  className='forminput  mt-3 w-[320px] lg:w-[258px] rounded-lg'
                                  name='userId'
                                  onChange={handleInputChange}
                                  value={tempData.userId}
                                />
                              </div>
                              <div className='flex flex-col lg:ml-[20px]'>
                                <p className='formhead lg:mt-[20px] mt-[10px]'>
                                  Art-Product Id
                                </p>
                                {/* <input type='text' className='forminput  mt-3  w-[320px] lg:w-[258px] '
                                  name='artId'
                                  onChange={handleInputChange}
                                  value={tempData.artId}
                                />  */}
                                <select
                                  className='forminput  mt-3  w-[320px] lg:w-[258px] bg-[#DCDCDC]   '
                                  placeholder='select art-product id'
                                  // value={selectCountry}
                                  // onChange={handlechange2}
                                >
                                  <option
                                    value=''
                                    disabled
                                    selected
                                    hidden
                                  >
                                    {' '}
                                    Select Art Id
                                  </option>
                                  {admin.map((art) => (
                                    <option
                                      key={art.artProductId}
                                      value={art.artProductId}
                                    >
                                      {art.artProductName}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div className='lg:flex '>
                              <div className='flex flex-col'>
                                <p className='formhead lg:mt-[20px] mt-[10px] '>
                                  Product Id
                                </p>
                                {/* <input type='text' className='forminput  mt-3 w-[320px] lg:w-[258px] rounded-lg'
                                  name='productId'
                                  onChange={handleInputChange}
                                  value={tempData.productId}
                                /> */}
                                <select
                                  className='forminput  mt-3  w-[320px] lg:w-[258px] bg-[#DCDCDC]   '
                                  placeholder='select Product id'
                                  // value={selectCountry}
                                  // onChange={handlechange2}
                                >
                                  <option
                                    value=''
                                    disabled
                                    selected
                                    hidden
                                  >
                                    {' '}
                                    Select Product Id
                                  </option>
                                  {product.map((pro) => (
                                    <option
                                      key={pro.productId}
                                      value={pro.productId}
                                    >
                                      {pro.productName}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className='flex flex-col lg:ml-[20px]'>
                                <p className='formhead lg:mt-[20px] mt-[10px]'>
                                  Color
                                </p>
                                <input
                                  type='text'
                                  className='forminput  mt-3  w-[320px] lg:w-[258px] '
                                  name='color'
                                  onChange={handleInputChange}
                                  value={tempData.color}
                                />
                              </div>
                            </div>
                            <div className='lg:flex '>
                              <div className='flex flex-col'>
                                <p className='formhead  mt-[25px] '>
                                  {' '}
                                  Image
                                </p>
                                <div className='forminput text-[20px] mt-3 w-[100%] lg:w-[258px] rounded-lg leading-none pl-[5px]'>
                                  <input
                                    type='file'
                                    style={{ display: 'none' }}
                                    accept='image/*'
                                    // onChange={handleFileChange}
                                    // ref={fileInputRef}
                                    name='image'
                                    multiple
                                  />

                                  {imageName ? (
                                    <span>{imageName}</span>
                                  ) : (
                                    <a
                                      className='cursor-pointer text-[#808080] text-[30px] '
                                      onClick={handleButtonClick}
                                    >
                                      +
                                    </a>
                                  )}
                                </div>
                              </div>
                              <div className='flex flex-col lg:ml-[20px]'>
                                <p className='formhead lg:mt-[20px] mt-[10px]'>
                                  Color Code
                                </p>
                                <input
                                  type='text'
                                  className='forminput  mt-3  w-[320px] lg:w-[258px] '
                                  name='colorCode'
                                  onChange={handleInputChange}
                                  value={tempData.colorCode}
                                />
                              </div>
                            </div>

                            <div className='lg:flex '>
                              <div className='flex flex-col'>
                                <p className='formhead lg:mt-[20px] mt-[10px] '>
                                  Art Id
                                </p>
                                {/* <input type='text' className='forminput  mt-3 w-[320px] lg:w-[258px] rounded-lg'
                                  name='artId'
                                  onChange={handleInputChange}
                                  value={tempData.artProductId}
                                /> */}
                                <select
                                  className='forminput  mt-3  w-[320px] lg:w-[258px] bg-[#DCDCDC]   '
                                  placeholder='select art-product id'
                                  // value={selectCountry}
                                  // onChange={handlechange2}
                                >
                                  <option
                                    value=''
                                    disabled
                                    selected
                                    hidden
                                  >
                                    {' '}
                                    Select Art Id
                                  </option>
                                  {admin?.artMaster?.map((art) => (
                                    <option
                                      key={art.artId}
                                      value={art.artId}
                                    >
                                      {art?.artName}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className='flex flex-col lg:ml-[20px]'>
                                <p className='formhead lg:mt-[20px] mt-[10px]'>
                                  CartId
                                </p>
                                <input
                                  type='text'
                                  className='forminput  mt-3  w-[320px] lg:w-[258px] '
                                  name='cartId'
                                  onChange={handleInputChange}
                                  value={tempData.cartId}
                                />
                              </div>
                            </div>

                            <div className='lg:flex '>
                              <div className='flex flex-col'>
                                <p className='formhead lg:mt-[20px] mt-[10px] '>
                                  CanvasX
                                </p>
                                <input
                                  type='text'
                                  className='forminput  mt-3 w-[320px] lg:w-[258px] rounded-lg'
                                  name='canvasX'
                                  onChange={handleInputChange}
                                  value={tempData.canvasX}
                                />
                              </div>
                              <div className='flex flex-col lg:ml-[20px]'>
                                <p className='formhead lg:mt-[20px] mt-[10px]'>
                                  CanvasY
                                </p>
                                <input
                                  type='text'
                                  className='forminput  mt-3  w-[320px] lg:w-[258px] '
                                  name='canvasY'
                                  onChange={handleInputChange}
                                  value={tempData.canvasX}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className='mt-[35px] lg:w-[651px] h-[1px] bg-[white]'></hr>
                      <button
                        className=' rounded-lg text-[white] bg-[blue] ml-[280px] text-[18px] font-semibold h-[41px] w-[80px] mt-[20px]'
                        onClick={() => {
                          // addFrame();
                          close();
                        }}
                      >
                        Add
                      </button>
                    </div>
                  )}
                </Popup>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default AdminArtProduct;
