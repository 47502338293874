import { Button, Grid, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, styled, tableCellClasses } from '@mui/material';
import React, { useEffect, useState } from 'react'

import Popup from 'reactjs-popup';
import { httpClient } from '../../axios';

const Draft = () => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'rgb(115 103 240)',
      color: theme.palette.common.white,
      fontSize: 20,
    },
  }));
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [draft, setDraft] = useState([]);
  const getDraft = async () => {
    const res = await httpClient
      .get('/draft_master/getDraftMasterList')
    // .then((res) => {
    console.log(res.data);
    setDraft(res.data);

    // });
  };
  useEffect(() => {
    getDraft();
  }, []);

  const deleteData = async (draftId) => {
    const sureDelete = window.confirm("Are You Sure The Delete Record..?");
    if (sureDelete) {
      const res = await httpClient.delete(
        `/draft_master/deleteDraftByDraftId/${draftId}`
      );
      console.log(res.data);
      getDraft();
    }
  };

  const [searchQuery, setSearchQuery] = useState('');

  const filteredData = draft.filter(list =>
    list.userMaster?.userId?.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const handleSearch = event => {
    setSearchQuery(event.target.value);
    // setPage(0);
  };

  return (
    <>

      <Grid justifyContent='center' >
        <Grid item xs={12} md={10} lg={8} >
          <div className='mt-[20px] pageMainDiv'>
            <p className='text-[25px] font-bold mt-[40px]'>Draft Master</p>
            <input type="text" name='name' placeholder="Search By User Id..."
              value={searchQuery} onChange={(e) => handleSearch(e)}
              className='mt-[10px] h-[50px] rounded-md border border-[#ABB2B9] pl-4 w-[100%]' />
            <div className="mt-2 w-[100%] border rounded-md border-[white] bg-[white]   ">
              <div className=' w-[100%] overflow-auto lg:overflow-hidden '>
                <Table stickyHeader className='border border-2 w-[100%]' >
                  <TableHead className='bg-[#7367F0]' >
                    <TableRow >
                      <StyledTableCell>Draft Id</StyledTableCell>
                      <StyledTableCell align='center'>Date</StyledTableCell>
                      <StyledTableCell align='center'>Image</StyledTableCell>
                      <StyledTableCell align='center'>User Id</StyledTableCell>
                      <StyledTableCell align='center'>Display Name</StyledTableCell>
                      <StyledTableCell>Action</StyledTableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody >
                    {filteredData.map((dra) => (
                      <TableRow key={dra.draftId}>

                        <TableCell>
                          <span className='text-[16px] text-black'>
                            {dra.draftId}
                          </span>
                        </TableCell >
                        <TableCell align='center'>
                          <span className='text-[16px] text-black'>
                            {dra.date}
                          </span>
                        </TableCell>

                        <TableCell>
                          <div>
                            <Popup trigger={<div className='text-center'>
                              <Button

                                style={{
                                  backgroundColor: 'orange',
                                  color: 'white',
                                }}
                              >
                                Image
                              </Button>
                            </div>}
                              modal
                              closeOnDocumentClick
                            >
                              {(close) => (
                                <div className='w-[300px] p-[8px] lg:w-[500px]  h-[570px] ml-[2px] rounded-lg grid grid-cols-4 gap-4 gap-[10px] bg-[white] shadow '
                                  style={{
                                    color: 'rgba(47, 43, 61, 0.68)',
                                    overflowY: 'auto', // Add this line for scrollability
                                    maxHeight: 'calc(100vh - 160px)', // Adjust the max height as needed
                                  }} >
                                  {dra?.imageMaster?.map((draft, index) => (
                                    <TableRow key={draft.imageId}>

                                      <img src={draft.secureUrl} className='w-[150px]  rounded h-[130px]' />


                                    </TableRow>
                                  ))}
                                </div>
                              )}
                            </Popup>
                          </div>

                        </TableCell>
                        <TableCell align='center'>
                          <span className='text-[16px] text-black'>
                            {dra?.userMaster?.userId} {/* Accessing user ID directly */}
                          </span>
                        </TableCell>
                        <TableCell align='center'>
                          <span className='text-[16px] text-black'>
                            {dra?.userMaster?.displayName} {/* Accessing user ID directly */}
                          </span>
                        </TableCell>
                        <TableCell>
                          <Button
                            style={{
                              backgroundColor: 'red',
                              color: 'white',
                            }}

                          // onClick={() => deleteData(draft.draftId)}

                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>

                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15]}
                  component="div"
                  count={draft.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />

              </div>
            </div>
          </div>

        </Grid>
      </Grid>
    </>
  )
}

export default Draft