import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, tableCellClasses } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { httpClient } from '../../axios';
import Popup from 'reactjs-popup';

const MainCategory = () => {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: 'rgb(115 103 240)',
            color: theme.palette.common.white,
            fontSize: 20,
        },
    }));

    const [category, setcategory] = useState([]);
    const [categoryObj, setcategoryObj] = useState({
        // productMainCategoryId: "",
        productMainCategoryName: "",
        image: "",
        status: ""

    });
    const [imageName, setImageName] = useState('');
    const fileInputRef = useRef(null);
    const getMainCat = async () => {
        const res = await httpClient
            .get('/product_main_category_master')

        console.log(res.data);
        setcategory(res.data);

    };
    useEffect(() => {
        getMainCat();
    }, []);

    const handleInputChange = (e) => {
        setcategoryObj({
            ...categoryObj,
            [e.target.name]: e.target.value,

        });
    };

    const handleFileChange = async (event, linkNumber) => {
        const img = event.target.files[0];
        if (img) {

            let formData = new FormData();
            formData.append('file', img);
            const res = await httpClient.post(
                '/CloudinaryImageUpload?parameter=false',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            setImageName(img.name);
            console.log(res.data)
            setcategoryObj({ ...categoryObj, image: res.data })
        };
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const resetImageName = () => {
        setImageName('');
    };
    const handleCloseForm = () => {
        resetImageName();
        console.log('........');
    };

    const UpdateCategory = async () => {
        const res = await httpClient.put(
            '/product_main_category_master',
            { ...categoryObj }
        );
        getMainCat();
        console.log(res.data);

    };

    const addCategory = async () => {

        const res = await httpClient.post(
            '/product_main_category_master/create', { ...categoryObj }
        );
        console.log(res.data);
        getMainCat();
    };

    return (
        <>
            <Grid justifyContent='center'>
                <Grid item xs={12} md={10} lg={8}>
                    <div className='pageMainDiv mt-[20px]'>
                        <p className='text-[25px] font-bold mt-[40px]'>Main Category Master</p>
                        <TableContainer className='h-[350px] mt-[20px] '>
                            <Table size='small' className='border border-[#CECECE] ' stickyHeader>

                                <TableHead>
                                    <TableRow className='border border-[#CECECE] '>
                                        <StyledTableCell>product MainCategory Id</StyledTableCell>
                                        <StyledTableCell align='center'>product MainCategory Name</StyledTableCell>
                                        <StyledTableCell align='center'>image</StyledTableCell>
                                        <StyledTableCell>Status</StyledTableCell>
                                        <StyledTableCell>Action</StyledTableCell>

                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {category.map((cat, index) => (<TableRow key={cat.productMainCategoryId}>
                                        <TableCell>
                                            <span className='text-[16px]'>
                                                {cat.productMainCategoryId}
                                            </span>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <span className='text-[16px]'>
                                                {cat.productMainCategoryName}
                                            </span>
                                        </TableCell>

                                        <TableCell >
                                            <div>
                                                <Popup trigger={<div className='text-center'>
                                                    <Button

                                                        style={{
                                                            backgroundColor: 'orange',
                                                            color: 'white',
                                                        }}
                                                    >
                                                        Image
                                                    </Button>
                                                </div>}
                                                    modal
                                                    closeOnDocumentClick
                                                >
                                                    {(close) => (
                                                            <div  className='w-[300px] lg:w-[400px] lg:h-[350px] h-[250px] rounded-lg  bg-[white] shadow'>
                                                        <img src={cat.image} className='w-[100%] h-[100%]' />
                                                        </div>
                                                    )}
                                                </Popup>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <span className='text-[16px]'>
                                                {cat.status}
                                            </span>
                                        </TableCell>
                                        <TableCell>
                                            <div className='flex space-x-2 mx-auto'>
                                                <Popup trigger={<div className='text-center'>
                                                    <Button

                                                        style={{
                                                            backgroundColor: 'orange',
                                                            color: 'white',
                                                        }}
                                                        onClick={() => {
                                                            setcategoryObj(cat);
                                                            handleCloseForm();
                                                        }}
                                                    >
                                                        Update
                                                    </Button>
                                                </div>}
                                                    modal
                                                    closeOnDocumentClick
                                                >
                                                    {(close) => (<div className='w-[300px] lg:w-[600px]  rounded-lg bg-[white] shadow p-[30px] relative'>
                                                        <div className='flex'>

                                                            <p className='text-[23px] font-medium ml-4 '>
                                                                Update Medium
                                                            </p>
                                                            <button
                                                                className='ml-auto h-[40px] w-[40px]'
                                                                onClick={close}
                                                            >
                                                                &times;
                                                            </button>
                                                        </div>

                                                        <div className='lg:flex   lg:space-x-7'>

                                                            <div className='flex flex-col '>
                                                                <p className='formhead  mt-[25px] '>product MainCategory Name</p>
                                                                <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                                    onChange={handleInputChange}
                                                                    name='productMainCategoryName'
                                                                    value={categoryObj.productMainCategoryName}
                                                                />
                                                            </div>


                                                            <div className='flex flex-col'>
                                                                <p className='formhead  mt-[25px] '>Medium Image</p>
                                                                <div className='forminput text-[20px] mt-3 w-[100%] lg:w-[258px] rounded-lg leading-none pl-[5px]'>
                                                                    <input type='file'
                                                                        style={{ display: 'none' }}
                                                                        accept='image/*'
                                                                        onChange={(e) => { handleFileChange(e, index) }}
                                                                        ref={fileInputRef}
                                                                        name='image' multiple
                                                                    />

                                                                    {imageName ? (
                                                                        <span>{imageName}</span>
                                                                    ) : (
                                                                        <a className='cursor-pointer text-[#808080]  ' onClick={handleButtonClick}>Img {index + 1}</a>
                                                                    )}

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='flex flex-col'>
                                                            <p className='formhead mt-[25px] '>Medium Status</p>

                                                            <select
                                                                className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                                value={categoryObj.status}
                                                                onChange={handleInputChange}
                                                                name='status'
                                                            >
                                                                <option
                                                                    value='Active'

                                                                >
                                                                    Active
                                                                </option>
                                                                <option
                                                                    value='InActive'

                                                                >
                                                                    InActive
                                                                </option>
                                                            </select>
                                                        </div>

                                                        <div className='mx-[auto] h-[41px] w-[150px]  mt-[20px]'>
                                                            <button className='rounded-lg text-[white] bg-[blue] text-[17px] font-semibold h-[41px] w-[150px] '
                                                                onClick={() => {
                                                                    UpdateCategory();
                                                                    handleCloseForm();
                                                                    close();

                                                                }}
                                                            >
                                                                Save</button></div>

                                                    </div>
                                                    )}
                                                </Popup></div>
                                        </TableCell>
                                    </TableRow>))}
                                </TableBody>
                            </Table>


                        </TableContainer>
                        <Popup trigger={<div className='text-center'>
                            <Button

                                style={{
                                    backgroundColor: 'blue',
                                    color: 'white',
                                }}

                            >
                                Add
                            </Button>
                        </div>}
                            modal
                            closeOnDocumentClick
                        >
                            {(close) => (<div className='w-[300px] lg:w-[600px]  rounded-lg bg-[white] shadow p-[30px] relative'>
                                <div className='flex'>

                                    <p className='text-[23px] font-medium ml-4 '>
                                        Add Main Category
                                    </p>
                                    <button
                                        className='ml-auto h-[40px] w-[40px]'
                                        onClick={close}
                                    >
                                        &times;
                                    </button>
                                </div>

                                <div className='lg:flex   lg:space-x-7'>
                                    <div className='flex flex-col '>
                                        <p className='formhead  mt-[25px] '>product MainCategory Name</p>
                                        <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                            onChange={handleInputChange}
                                            name='productMainCategoryName'
                                        // value={categoryObj.productMainCategoryId}
                                        />
                                    </div>



                                    <div className='flex flex-col'>
                                        <p className='formhead  mt-[25px] '>Medium Image</p>
                                        <div className='forminput text-[20px] mt-3 w-[100%] lg:w-[258px] rounded-lg leading-none pl-[5px]'>
                                            <input type='file'
                                                style={{ display: 'none' }}
                                                accept='image/*'
                                                onChange={handleFileChange}
                                                ref={fileInputRef}
                                                name='image' multiple
                                            />

                                            {imageName ? (
                                                <span>{imageName}</span>
                                            ) : (
                                                <a className='cursor-pointer text-[#808080] text-[30px] ' onClick={handleButtonClick}>+</a>
                                            )}

                                        </div>
                                    </div>

                                </div>
                                <div className='flex flex-col'>
                                    <p className='formhead mt-[25px] '>Medium Status</p>

                                    <select
                                        className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                        value={categoryObj.status}
                                        onChange={handleInputChange}
                                        name='status'
                                    >
                                        <option
                                            value='Active'

                                        >
                                            Active
                                        </option>
                                        <option
                                            value='InActive'

                                        >
                                            InActive
                                        </option>
                                    </select>
                                </div>

                                <div className='mx-[auto] h-[41px] w-[150px]  mt-[20px]'>
                                    <button className='rounded-lg text-[white] bg-[blue] text-[17px] font-semibold h-[41px] w-[150px] '
                                        onClick={() => {
                                            addCategory();
                                            handleCloseForm();
                                            close();

                                        }}
                                    >
                                        Save</button></div>

                            </div>
                            )}
                        </Popup>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default MainCategory