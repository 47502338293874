// ImageSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const imageSlice = createSlice({
  name: 'images',
  initialState: { 
    link1: '',
    link2: '',
    link3: '',
  },
  reducers: {
    setLink1: (state, action) => {
      state.link1 = action.payload;
 
      // localStorage.setItem('link1',action.payload);
    },
    setLink2: (state, action) => {
      state.link2 = action.payload;
      // localStorage.setItem('link2',action.payload);
    },
    setLink3: (state, action) => {
      state.link3 = action.payload;
      // localStorage.setItem('link3',action.payload);
    },
  },
});

export const { setLink1, setLink2, setLink3 } = imageSlice.actions;

export default imageSlice.reducer;
