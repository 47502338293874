import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, styled, tableCellClasses } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { httpClient } from '../../axios';
import Popup from 'reactjs-popup';
import SendIcon from '@mui/icons-material/Send';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const PromoCode = () => {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: 'rgb(115 103 240)',
            color: theme.palette.common.white,
            fontSize: 20,
        },
    }));

    const [isOpen, open] = useState(false);
    const [userList, setUserList] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [updatedData, setupdatedData] = useState({});
    const [selectedDateTime, setSelectedDateTime] = useState(new Date());

    const getUser = async () => {
        const res = await httpClient
            .get('/promo_code_master/getAllPromoCodeMaster');
        if (res) {
            console.log(res.data);
            setUserList(res.data);
        }

    };
    useEffect(() => {
        getUser();
    }, []);

    const addUserDetails = async () => {
        const res = await httpClient.post('/promo_code_master/create', { ...updatedData });
        // .then((res) => {
        console.log(res.data);
        getUser();
    };
    useEffect(() => {
        getUser();
    }, []);

    const handleupdated = (e, date, time) => {
        setupdatedData({
            ...updatedData,
            [e.target.name]: e.target.value,

        });
        setSelectedDateTime(date);
        setSelectedDateTime(time);


    }

    const handleSearch = event => {
        setSearchQuery(event.target.value);
        setPage(0);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const update = async () => {
        console.log(updatedData);
        const res = await httpClient.put(
            '/promo_code_master/update', updatedData
        );
        getUser();
        console.log(res.data);

    }


    useEffect(() => {
        console.log(updatedData);
    }, [updatedData]);

    const filteredData = userList.filter(list =>
        list.promoCode.toLowerCase().includes(searchQuery.toLowerCase())
    );


    return (
        <>
            <Grid justifyContent="center">
                <Grid item xs={12} md={10} lg={8} >

                    <div className='mx-auto pageMainDiv'>
                        <p className='lg:text-[25px] text-[16px] mt-[20px] font-semibold'>
                            Promo Code
                        </p>
                        <div className="mt-4   w-[100%] border rounded-md border-[white] bg-[white]   ">

                            <input type="text" name='name' placeholder="Search by promocode..."
                                value={searchQuery}
                                onChange={(e) => handleSearch(e)}
                                className=' w-[100%] h-[50px] rounded-md border border-[#ABB2B9] pl-4 ' />
                            <div className=' w-[100%] overflow-auto lg:overflow-hidden mt-[20px]'>
                                <TableContainer style={{ height: '450px' }}>
                                    <Table stickyHeader >
                                        <TableHead className='bg-[#7367F0]' >
                                            <TableRow >
                                                <StyledTableCell>PromoCode</StyledTableCell>
                                                <StyledTableCell align='center' >Status</StyledTableCell>
                                                <StyledTableCell align='center' >Description</StyledTableCell>
                                                <StyledTableCell align='center' >Start Date</StyledTableCell>
                                                <StyledTableCell align='center' >End Date</StyledTableCell>
                                                <StyledTableCell align='center' >Discount</StyledTableCell>
                                                <StyledTableCell align='center' >Max Amount</StyledTableCell>
                                                <StyledTableCell align='center' >Action</StyledTableCell>



                                            </TableRow>
                                        </TableHead>
                                        <TableBody >
                                            {filteredData
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((promo, index) => (<TableRow key={index}>

                                                    <TableCell >
                                                        <span className='text-[16px] font-normal text-[#696969]'>{promo.promoCode}</span></TableCell>
                                                    <TableCell align='center'>
                                                        <span className='text-[16px]  font-normal text-[#696969]'>{promo.status}</span></TableCell>
                                                    <TableCell align='center'>
                                                        <span className='text-[16px]  font-normal text-[#454545]'>{promo.description}</span></TableCell>
                                                    <TableCell align='center'>
                                                        <span className='text-[16px]  font-normal text-[#454545]'>{promo.startDate}</span></TableCell>
                                                    <TableCell align='center'>
                                                        <span className='text-[16px]  font-normal text-[#454545]'>{promo.endDate}</span></TableCell>
                                                    <TableCell align='center'>
                                                        <span className='text-[16px]  font-normal text-[#454545]'>{promo.discount}</span></TableCell>
                                                    <TableCell align='center'>
                                                        <span className='text-[16px]  font-normal text-[#454545]'>{promo.maxAmount}</span></TableCell>

                                                    <TableCell align='center'>
                                                        <div className='flex space-x-2 '>
                                                            {/* <Popup
                                                                trigger={
                                                                    <div className=''>
                                                                        <Button
                                                                            onClick={() => {
                                                                                setupdatedData(promo);
                                                                            }}
                                                                            style={{
                                                                                backgroundColor: 'orange',
                                                                                color: 'white',
                                                                            }}
                                                                        >
                                                                            Update
                                                                        </Button>
                                                                    </div>
                                                                }
                                                                modal
                                                                open={isOpen}
                                                                closeOnDocumentClick
                                                            >
                                                                {close => (
                                                                    <div className="  lg:w-[550px] w-[440px] lg:h-[100%] h-[490px] bg-[white] shadow rounded   ">
                                                                        <h1 className='lg:ml-[180px] ml-[100px] lg:text-[25px] text-[16px] lg:pt-[30px] font-semibold'>Update Records</h1>

                                                                        <div className=' mt-2 flex'>
                                                                            <div className='flex-col'>
                                                                                <h className='lg:pt-[10px] lg:mt-[10px] mt-[8px] lg:ml-[35px] ml-[30px] font-semibold lg:text-[18px] text-[12px]'>status : </h>
                                                                                <div className='rounded lg:mt-[4px] mt-[18px] rounded lg:ml-[35px]  ml-[20px] lg:w-[230px] w-[150px] bg-gray-100'>
                                                                                    <textarea
                                                                                        className='mt-[10px] lg:w-[230px]  w-[180px] pl-4 pt-2 text-[18px] lg:h-[40px] h-[40px] bg-[#DADBDD]'
                                                                                        name='status'
                                                                                        placeholder='Enter status'
                                                                                        //   onChange={(e) => changeName(e, index)}
                                                                                        onChange={handleupdated}

                                                                                        value={updatedData.status}
                                                                                    ></textarea>
                                                                                </div>
                                                                            </div>
                                                                            <div className='flex-col'>
                                                                                <h className='lg:pt-[10px] mt-[10px] lg:ml-[16px] ml-[60px] font-semibold lg:text-[18px] text-[12px]'>Description : </h>
                                                                                <div className='rounded lg:mt-[4px] mt-[18px] rounded lg:ml-[20px]  ml-[50px] lg:w-[230px] bg-gray-100'>
                                                                                    <textarea
                                                                                        className='mt-[10px] lg:w-[230px]  w-[180px]  pl-4 pt-2 text-[18px] h-[40px] lg:h-[40px] bg-[#DADBDD]'
                                                                                        name='description'
                                                                                        //   onChange={(e) => changeName(e, index)}
                                                                                        placeholder='Enter subjectDescription'
                                                                                        value={updatedData.description}
                                                                                        onChange={handleupdated}
                                                                                    ></textarea>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex'>
                                                                            <div className='flex-col'>

                                                                                <h className='lg:pt-[10px] lg:mt-[10px] mt-[8px] lg:ml-[35px] ml-[30px] font-semibold lg:text-[18px] text-[12px]'>Start Date : </h>
                                                                                <div className='rounded lg:mt-[4px] mt-[18px] rounded lg:ml-[35px]  ml-[20px] lg:w-[230px] w-[200px] bg-gray-100'>
                                                                                    <DatePicker
                                                                                        selected={updatedData.startDate ? new Date(updatedData.startDate) : null}
                                                                                        onChange={(date) => handleupdated({ target: { name: 'startDate', value: date } })}
                                                                                        showTimeSelect
                                                                                        timeFormat="HH:mm:ss"
                                                                                        dateFormat="yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
                                                                                        className='mt-1 hover:bg-[#DADBDD] bg-[#DADBDD] lg:w-[230px] w-[180px] cursor-pointer lg:h-[50px] h-[34px]'
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className='flex-col'>

                                                                                <h className='lg:pt-[10px] lg:mt-[10px] mt-[8px] lg:ml-[35px]  font-semibold lg:text-[18px] text-[12px]'>End Date : </h>
                                                                                <div className='rounded lg:mt-[4px] mt-[18px] rounded lg:ml-[35px] lg:w-[230px] w-[100px] bg-gray-100'>
                                                                                    <DatePicker
                                                                                        selected={updatedData.endDate ? new Date(updatedData.endDate) : null}
                                                                                        onChange={(date) => handleupdated({ target: { name: 'endDate', value: date } })}
                                                                                        showTimeSelect
                                                                                        timeFormat="HH:mm"
                                                                                        dateFormat="yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
                                                                                        className='mt-1 hover:bg-[#DADBDD] bg-[#DADBDD] lg:w-[230px] w-[180px] cursor-pointer lg:h-[50px] h-[34px]'
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                        <div className=' mt-2 flex'>
                                                                            <div className='flex-col'>
                                                                                <h className='lg:pt-[10px] lg:mt-[10px] mt-[8px] lg:ml-[35px] ml-[30px] font-semibold lg:text-[18px] text-[12px]'>Grid Height </h>
                                                                                <div className='rounded lg:mt-[4px] mt-[18px] rounded lg:ml-[35px]  ml-[20px] lg:w-[230px] w-[150px] bg-gray-100'>
                                                                                    <textarea
                                                                                        className='mt-[10px] lg:w-[230px]  w-[180px] pl-4 pt-2 text-[18px] lg:h-[40px] h-[40px] bg-[#DADBDD]'
                                                                                        name='discount'
                                                                                        //   onChange={(e) => changeName(e, index)}
                                                                                        placeholder='Enter discount'
                                                                                        value={updatedData.discount}
                                                                                        onChange={handleupdated}
                                                                                    ></textarea>
                                                                                </div>

                                                                            </div>
                                                                            <div className='flex-col'>
                                                                                <h className='lg:pt-[10px] mt-[10px] lg:ml-[16px] ml-[60px] font-semibold lg:text-[18px] text-[12px]'>Grid Column </h>
                                                                                <div className='rounded lg:mt-[4px] mt-[18px] rounded lg:ml-[20px]  ml-[50px] lg:w-[230px] bg-gray-100'>
                                                                                    <textarea
                                                                                        className='mt-[10px] lg:w-[230px]  w-[180px]  pl-4 pt-2 text-[18px] h-[40px] lg:h-[40px] bg-[#DADBDD]'
                                                                                        name='maxAmount'
                                                                                        //   onChange={(e) => changeName(e, index)}
                                                                                        placeholder='Enter max Amount'
                                                                                        value={updatedData.maxAmount}
                                                                                        onChange={handleupdated}
                                                                                    ></textarea>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <button className='h-[40px] rounded w-[110px] mx-[auto] pb-[10px] mt-[10px] ml-[220px] bg-[#1F45FC] text-[white]'
                                                                            onClick={() => { update(); close(); }} endIcon={<SendIcon />}  >
                                                                            Update Record
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </Popup> */}

                                                            <Popup
                                                                trigger={<div >
                                                                    <Button
                                                                        onClick={() => {
                                                                            setupdatedData(promo);
                                                                        }}
                                                                        style={{
                                                                            backgroundColor: 'orange',
                                                                            color: 'white',
                                                                        }}
                                                                    >
                                                                        Update
                                                                    </Button></div>
                                                                }
                                                                modal
                                                                closeOnDocumentClick
                                                            >
                                                                {(close) => (
                                                                    <div className='w-[300px] lg:w-[600px]  rounded-lg bg-[white] shadow p-[30px] relative'>
                                                                        <div className='flex'>

                                                                            <p className='text-[23px] font-medium ml-4 '>
                                                                                Update Promocode
                                                                            </p>
                                                                            <button
                                                                                className='ml-auto h-[40px] w-[40px]'
                                                                                onClick={close}
                                                                            >
                                                                                &times;
                                                                            </button>
                                                                        </div>

                                                                        <div className='flex flex-col'>
                                                                            <p className='formhead mt-[25px] '>Medium Status</p></div>
                                                                        <select
                                                                            className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                                            value={updatedData.status}
                                                                            onChange={handleupdated}
                                                                            name='status'
                                                                        >
                                                                            <option
                                                                                value='Active'

                                                                            >
                                                                                Active
                                                                            </option>
                                                                            <option
                                                                                value='InActive'

                                                                            >
                                                                                InActive
                                                                            </option>
                                                                        </select>

                                                                        <div className='flex flex-col '>
                                                                            <p className='formhead  mt-[25px] '>Description </p>
                                                                            <textarea type='text'
                                                                                className=' lg:w-[100%] h-[100px]  bg-[#F8F8F4] pl-[10px] mt-[15px]'
                                                                                value={updatedData.description}
                                                                                onChange={handleupdated}
                                                                                name='description' />


                                                                        </div>
                                                                        <div className='lg:flex   lg:space-x-7'>
                                                                            <div className='flex flex-col'>
                                                                                <p className='formhead  mt-[25px] '>Discount</p>
                                                                                <div className='forminput   mt-3 w-[100%] lg:w-[258px] rounded-lg leading-none pl-[5px]'>
                                                                                    <input type='text' className='text-[15px] p-[4px] forminput    w-[100%] lg:w-[258px] rounded-lg leading-none '

                                                                                        onChange={handleupdated}
                                                                                        value={updatedData.discount}
                                                                                        name='discount' />

                                                                                </div>
                                                                            </div>

                                                                            <div className='flex flex-col '>
                                                                                <p className='formhead lg:mt-[20px] mt-[10px] '>Maximum Amount</p>
                                                                                <div className='forminput   mt-3 w-[100%] lg:w-[258px] rounded-lg leading-none pl-[5px]'>
                                                                                    <input type='text' className='text-[15px] p-[4px] forminput    w-[100%] lg:w-[258px] rounded-lg leading-none '
                                                                                        value={updatedData.maxAmount}
                                                                                        onChange={handleupdated}

                                                                                        name='maxAmount' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='lg:flex   lg:space-x-7'>
                                                                            <div className='flex flex-col '>
                                                                                <p className='formhead  mt-[25px] '>Start Date</p>
                                                                                <DatePicker
                                                                                    selected={updatedData.startDate ? new Date(updatedData.startDate) : null}
                                                                                    onChange={(date) => handleupdated({ target: { name: 'startDate', value: date } })}
                                                                                    showTimeSelect
                                                                                    timeFormat="HH:mm:ss"
                                                                                    dateFormat="yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
                                                                                    className='text-[15px] p-[4px] forminput    w-[100%] lg:w-[258px] rounded-lg leading-none cursor-pointer'
                                                                                />
                                                                            </div>
                                                                            <div className='flex flex-col'>
                                                                                <p className='formhead mt-[25px] '>End Date</p>
                                                                                <DatePicker
                                                                                    selected={updatedData.endDate ? new Date(updatedData.endDate) : null}
                                                                                    onChange={(date) => handleupdated({ target: { name: 'endDate', value: date } })}
                                                                                    showTimeSelect
                                                                                    timeFormat="HH:mm"
                                                                                    dateFormat="yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
                                                                                    className='text-[15px] p-[4px] forminput    w-[100%] lg:w-[258px] rounded-lg leading-none cursor-pointer'
                                                                                />



                                                                            </div>
                                                                        </div>

                                                                        <div className='mx-[auto] h-[41px] w-[120px]  mt-[20px]'>
                                                                            <button className='h-[40px] rounded w-[110px] mx-[auto] pb-[10px] mt-[10px] bg-[#1F45FC]  text-[white]'
                                                                                onClick={() => { update(); close(); }} endIcon={<SendIcon />}  >
                                                                                Save
                                                                            </button></div>
                                                                    </div>
                                                                )}
                                                            </Popup>
                                                            <Button
                                                                style={{
                                                                    backgroundColor: 'red',
                                                                    color: 'white',
                                                                }}

                                                            // onClick={() => deleteData(subject.subjectId)}

                                                            >
                                                                Delete
                                                            </Button></div>

                                                    </TableCell>

                                                </TableRow>))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15]}
                            component="div"
                            count={userList.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />



                        <Popup
                            trigger={<div >
                                <button
                                    // onClick={() => { handleCloseForm() }}
                                    className='bg-[#7367F0] h-[40px] w-[150px] rounded-lg text-[white] text-[16px]'>
                                    Add Promocode
                                </button></div>
                            }
                            modal
                            closeOnDocumentClick
                        >
                            {(close) => (
                                <div className='w-[300px] lg:w-[600px]  rounded-lg bg-[white] shadow p-[30px] relative'>
                                    <div className='flex'>

                                        <p className='text-[23px] font-medium ml-4 '>
                                            Add Promocode
                                        </p>
                                        <button
                                            className='ml-auto h-[40px] w-[40px]'
                                            onClick={close}
                                        >
                                            &times;
                                        </button>
                                    </div>

                                    <div className='flex flex-col'>
                                        <p className='formhead mt-[25px] '>Medium Status</p></div>
                                    <select
                                        className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                        value={updatedData.status}
                                        onChange={handleupdated}
                                        name='status'
                                    >
                                        <option
                                            value='Active'

                                        >
                                            Active
                                        </option>
                                        <option
                                            value='InActive'

                                        >
                                            InActive
                                        </option>
                                    </select>

                                    <div className='flex flex-col '>
                                        <p className='formhead  mt-[25px] '>Description </p>
                                        <textarea type='text'
                                            className=' lg:w-[100%] h-[100px]  bg-[#F8F8F4] pl-[10px] mt-[15px]'

                                            onChange={handleupdated}
                                            name='description' />


                                    </div>
                                    <div className='lg:flex   lg:space-x-7'>
                                        <div className='flex flex-col'>
                                            <p className='formhead  mt-[25px] '>Discount</p>
                                            <div className='forminput   mt-3 w-[100%] lg:w-[258px] rounded-lg leading-none pl-[5px]'>
                                                <input type='text' className='text-[15px] p-[4px] forminput    w-[100%] lg:w-[258px] rounded-lg leading-none '
                                                    value={updatedData.discount}
                                                    onChange={handleupdated}

                                                    name='discount' />

                                            </div>
                                        </div>

                                        <div className='flex flex-col '>
                                            <p className='formhead lg:mt-[20px] mt-[10px] '>Maximum Amount</p>
                                            <div className='forminput   mt-3 w-[100%] lg:w-[258px] rounded-lg leading-none pl-[5px]'>
                                                <input type='text' className='text-[15px] p-[4px] forminput    w-[100%] lg:w-[258px] rounded-lg leading-none '

                                                    onChange={handleupdated}

                                                    name='maxAmount' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='lg:flex   lg:space-x-7'>
                                        <div className='flex flex-col '>
                                            <p className='formhead  mt-[25px] '>Start Date</p>
                                            <DatePicker
                                                selected={updatedData.startDate ? new Date(updatedData.startDate) : null}
                                                onChange={(date) => handleupdated({ target: { name: 'startDate', value: date } })}
                                                showTimeSelect
                                                timeFormat="HH:mm:ss"
                                                dateFormat="yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
                                                className='text-[15px] p-[4px] forminput    w-[100%] lg:w-[258px] rounded-lg leading-none cursor-pointer'
                                            />
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='formhead mt-[25px] '>End Date</p>
                                            <DatePicker
                                                selected={updatedData.endDate ? new Date(updatedData.endDate) : null}
                                                onChange={(date) => handleupdated({ target: { name: 'endDate', value: date } })}
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                dateFormat="yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
                                                className='text-[15px] p-[4px] forminput    w-[100%] lg:w-[258px] rounded-lg leading-none cursor-pointer'
                                            />



                                        </div>
                                    </div>

                                    <div className='mx-[auto] h-[41px] w-[120px]  mt-[20px]'>
                                        <button className='rounded-lg text-[white] bg-[blue] text-[17px] font-semibold h-[41px] w-[120px] '
                                            onClick={() => {
                                                addUserDetails();
                                                close();

                                            }}
                                        >
                                            Add</button></div>
                                </div>
                            )}
                        </Popup>
                    </div>
                </Grid>
            </Grid>
        </>

    )
}

export default PromoCode