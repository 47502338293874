import React from 'react';
import Popup6 from '../components/Popup6';
import PopupFrom from '../components/PopupFrom';
import DashboardMain from '../Dashboard/DashboardMain';

const Dashboard = () => {
  return <div className='pageMainDiv bg-[#F5F5F5] '
  >

  <DashboardMain/>
{/* <Popup6/> */}

{/* <PopupFrom/> */}
</div>

};
export default Dashboard;
