import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, styled, tableCellClasses } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { httpClient } from '../../axios';


const FileUploadLimit = () => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: 'rgb(115 103 240)',
            color: theme.palette.common.white,
            fontSize: 20,
        },
    }));

    const [filelimit, setFilelimit] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchQuery, setSearchQuery] = useState('');

    const getFilelimit = async () => {
        const res = await httpClient
            .get('/fileUploadLimit')
        console.log(res.data);
        setFilelimit(res.data);
    }
    useEffect(() => {
        getFilelimit();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleSearch = event => {
        setSearchQuery(event.target.value);
        setPage(0);
    };

    const filteredData = filelimit.filter(flimit =>
        flimit?.userMaster?.userFirstName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    return (
        <>
            <Grid justifyContent='center'>
                <Grid item xs={12} md={10} lg={8}>
                    <div className='pageMainDiv mt-[20px]'>
                        <p className='text-[25px] font-bold mt-[40px]'>File Upload Limit</p>
                        <div className="mt-4 lg:w-[full]  w-[full] border rounded-md border-[white] bg-[white]   ">
                            <input type="text" name='userFirstName' placeholder="Search by User Name..." value={searchQuery} onChange={(e) => handleSearch(e)}
                                className=' w-[100%] h-[50px] rounded-md border border-[#ABB2B9] pl-4 ' /></div>
                        <TableContainer className='h-[400px] mt-[10px] '>
                            <Table size='small' className='border border-[#CECECE] ' stickyHeader>

                                <TableHead>
                                    <TableRow className=' '>
                                        <StyledTableCell> <div className='w-[150px]'>User FileUpload Id</div> </StyledTableCell>
                                        <StyledTableCell align='center'><div className='w-[100px]'>User First Name</div></StyledTableCell>
                                        <StyledTableCell align='center'><div className='w-[130px]'>User Last Name</div></StyledTableCell>
                                        <StyledTableCell align='center'><div className='w-[100px]'>Art Count</div></StyledTableCell>
                                        <StyledTableCell align='center'><div className='w-[100px]'>Photos Count</div></StyledTableCell>
                                        <StyledTableCell align='center'><div className='w-[100px]'>Footage Count</div></StyledTableCell>
                                        <StyledTableCell align='center'><div className='w-[100px]'>Music Count</div></StyledTableCell>
                                        <StyledTableCell align='center'><div className='w-[100px]'>Templates Counts</div></StyledTableCell>
                                        <StyledTableCell align='center'><div className='w-[100px]'>Level</div></StyledTableCell>
                                        <StyledTableCell align='center'><div className='w-[100px]'>Approved Percentage</div></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((file, index) => (
                                            <tr key={file?.userFileUploadId}>
                                                <TableCell>
                                                    <span className='text-[16px] '>
                                                        {(page * rowsPerPage) + index + 1}
                                                    </span>
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {' '}
                                                    <span className='text-[16px]'>
                                                        {file?.userMaster?.userFirstName}
                                                    </span>
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {' '}
                                                    <span className='text-[16px]'>
                                                        {file?.userMaster?.userLastName}
                                                    </span>
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <span className='text-[16px] '>
                                                        {file.artCount}
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className='text-[16px] '>
                                                        {file.photosCount}
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className='text-[16px] '>
                                                        {file.footageCount}
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className='text-[16px] '>
                                                        {file.musicCount}
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span className='text-[16px] '>
                                                        {file.templatesCounts}
                                                    </span>
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <span className='text-[16px] '>
                                                        {file.level}
                                                    </span>
                                                </TableCell>
                                                <TableCell align='center'>
                                                    <span className='text-[16px] '>
                                                        {file.approvedPercentage}
                                                    </span>
                                                </TableCell>
                                            </tr>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 15]}
                            component="div"
                            count={filelimit.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default FileUploadLimit