import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, styled, tableCellClasses } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { httpClient } from '../../axios';
import Popup from 'reactjs-popup';

const AddDetails = () => {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: 'rgb(115 103 240)',
            color: theme.palette.common.white,
            fontSize: 20,
        },
    }));

    const [details, setDetails] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [searchQuery, setSearchQuery] = useState('');
    const getDetails = async () => {
        const res = await httpClient.get(
            '/Add_Details_Master/getAllAddDetails'
        );

        console.log(res.data);
        setDetails(res.data);

    };
    useEffect(() => {
        getDetails();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const filteredData = details.filter(del =>
        del.artName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const handleSearch = event => {
        setSearchQuery(event.target.value);
        setPage(0);
    };
    return (
        <>
            <Grid justifyContent='center' >
                <Grid item xs={12} md={10} lg={8} >
                    <div className='mt-[20px] pageMainDiv'>
                        <p className='text-[25px] font-bold mt-[40px]'>Add Details Master</p>
                        <input type="text" name='name' placeholder="Search by Product Subcategory Name..."
                        value={searchQuery}
                        onChange={(e) => handleSearch(e)}
                        className=' w-[100%] h-[50px] rounded-md border border-[#ABB2B9] pl-4 ' />
                        <div className="mt-2 w-[100%] border rounded-md border-[white] bg-[white]   ">
                            <div className=' w-[100%] overflow-auto lg:overflow-hidden '>
                                <TableContainer className='h-[400px]' >
                                    <Table stickyHeader className='border border-2 w-[100%]' >
                                        <TableHead className='bg-[#7367F0]' >
                                            <TableRow >
                                                <StyledTableCell><div className='w-[150px]'>Art Details Id</div></StyledTableCell>
                                                <StyledTableCell align='center'><div className='w-[150px]'>Art Name</div></StyledTableCell>
                                                <StyledTableCell align='center'><div className='w-[150px]'>Commercial User</div></StyledTableCell>
                                                <StyledTableCell align='center'><div className='w-[150px]'>Type Of Content</div></StyledTableCell>
                                                <StyledTableCell align='center'><div className='w-[400px]'>Description</div></StyledTableCell>
                                                <StyledTableCell align='center'><div className='w-[150px]'>Keywords</div></StyledTableCell>
                                                <StyledTableCell align='center'><div className='w-[150px]'>Medium Type</div></StyledTableCell>
                                                <StyledTableCell align='center'><div className='w-[150px]'>Subject Name</div></StyledTableCell>
                                                <StyledTableCell align='center'><div className='w-[150px]'>Style Name</div></StyledTableCell>
                                                <StyledTableCell align='center'><div className='w-[150px]'>Image</div></StyledTableCell>
                                                <StyledTableCell align='center'><div className='w-[150px]'>User Name</div></StyledTableCell>
                                                <StyledTableCell align='center'><div className='w-[150px]'>Secure Url</div></StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredData
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((art, index) => (<tr key={art.artDetailsId}>
                                                <TableCell>
                                                    {' '}
                                                    <span className='text-[16px]'>
                                                        {/* {(page * rowsPerPage) + index + 1} */}
                                                        {art.artDetailsId}
                                                    </span>
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {' '}
                                                    <span className='text-[16px]'>
                                                        {art?.artName}
                                                    </span>
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {' '}
                                                    <span className='text-[16px]'>
                                                        {art?.commercialUser.map((user, index) => (
                                                            <React.Fragment key={index}>
                                                                {user}
                                                                {index !== art.commercialUser.length - 1 && ', '}
                                                            </React.Fragment>
                                                        ))}
                                                    </span>
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {' '}
                                                    <span className='text-[16px]'>
                                                        {art?.typeOfContent.map((type, index) => (
                                                            <React.Fragment key={index}>
                                                                {type}
                                                                {index !== art.typeOfContent.length - 1 && ', '}
                                                            </React.Fragment>
                                                        ))}
                                                    </span>
                                                </TableCell>

                                                <TableCell align='center'>
                                                    {' '}
                                                    <span className='text-[16px]'>
                                                        {art?.description}
                                                    </span>
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {' '}
                                                    <span className='text-[16px]'>
                                                        {art?.keywords.map((type, index) => (
                                                            <React.Fragment key={index}>
                                                                {type}
                                                                {index !== art.keywords.length - 1 && ', '}
                                                            </React.Fragment>
                                                        ))}
                                                    </span>
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {' '}
                                                    <span className='text-[16px]'>
                                                        {art?.mediumMaster?.mediumType}
                                                    </span>
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {' '}
                                                    <span className='text-[16px]'>
                                                        {art?.subjectMaster?.subjectName}
                                                    </span>
                                                </TableCell>

                                                <TableCell align='center'>
                                                    {' '}
                                                    <span className='text-[16px]'>
                                                        {art?.styleMaster?.name}
                                                    </span>
                                                </TableCell>
                                                <TableCell align='center'>

                                                    <Popup trigger={<div className='text-center'>
                                                        <Button

                                                            style={{
                                                                backgroundColor: 'orange',
                                                                color: 'white',
                                                            }}
                                                        >
                                                            Image
                                                        </Button>
                                                    </div>}
                                                        modal
                                                        closeOnDocumentClick
                                                    >
                                                        {(close) => (<div
                                                            className='w-[300px] lg:w-[400px] lg:h-[350px] h-[250px] rounded-lg  bg-[white] shadow'>

                                                            <img src={art?.image}
                                                                className=' w-[100%] h-[100%]' alt="Product" />

                                                        </div>)}

                                                    </Popup>
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {' '}
                                                    <span className='text-[16px]'>
                                                        {art?.userMaster?.displayName}
                                                    </span>
                                                </TableCell>
                                                <TableCell>

                                                    <Popup trigger={<div className='text-center'>
                                                        <Button

                                                            style={{
                                                                backgroundColor: 'orange',
                                                                color: 'white',
                                                            }}
                                                        >
                                                            Image
                                                        </Button>
                                                    </div>}
                                                        modal
                                                        closeOnDocumentClick
                                                    >
                                                        {(close) => (<div
                                                            className='w-[300px] lg:w-[400px] lg:h-[350px] h-[250px] rounded-lg  bg-[white] shadow'>
                                                            {art?.imageMaster && (
                                                                <img src={art?.imageMaster?.secureUrl}
                                                                    className=' w-[100%] h-[100%]' alt="Product" />
                                                            )}
                                                        </div>)}

                                                    </Popup>
                                                </TableCell>
                                            </tr>))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination className='ml-auto'
                                rowsPerPageOptions={[5, 10, 15]}
                                component="div"
                                count={details.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                            </div>
                        </div>
                    </div>

                </Grid>
            </Grid>
        </>
    )
}

export default AddDetails