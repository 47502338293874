import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, styled, tableCellClasses } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { httpClient } from '../../axios';
import Popup from 'reactjs-popup';

const ArtMaster = () => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: 'rgb(115 103 240)',
            color: theme.palette.common.white,
            fontSize: 20,
        },
    }));

    const [artData, setArtData] = useState([]);
    const [earnData, setEarnData] = useState([]);
    const [status, setstatus] = useState('Submitted');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const [updateObj, setupdateObj] = useState({
        artId: "",
        artName: "",
        status: "",
        description: ""
    });

    useEffect(() => {
        console.log(updateObj);
    }, [updateObj])


    const updateArt = async () => {
        try {
            const res = await httpClient.put(`/art_master/updateDArtMaster`, updateObj);
            console.log(res.data);
            getArt();

        } catch (error) {
            console.log(error)
        }
    }

    const getArt = async () => {
        const res = await httpClient
            .get(`/art_master/getAllArtMasterByStatus/${status}`)
        // .then((res) => {
        console.log(res.data);
        setArtData(res.data);
    }
    useEffect(() => {
        getArt();
    }, [status]);



    const handleStatusChange = (event) => {
        setstatus(event.target.value);


    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [isOpen, setIsOpen] = useState(false);



    const handleTypeChange = (event) => {
        console.log(event.target.value);
        setupdateObj({ ...updateObj, status: event.target.value })

        // setType(event.target.value);
    };


    const SetObject = (obj) => {
        setupdateObj({ ...updateObj, artId: obj.artId, artName: obj.artName, description: obj.description, status: obj.status, })
        // setupdateObj({...updateObj, status: obj.status})
        // setupdateObj({...updateObj, status: obj.status})



    }

    const getEarn = async (artId) => {
        const res = await httpClient
            .get(`/art_master/getContrubuterMarkupByArtId/${artId}`)
        // .then((res) => {
        console.log(res.data);
        setEarnData(res.data);
    }

    // useEffect(() => {
    //     getEarn();
    // }, []);
    return (
        <>
            <Grid justifyContent='center'>
                <Grid item xs={12} md={10} lg={8}>
                    <div className='pageMainDiv mt-[20px]'>
                        <p className='text-[25px] font-bold mt-[40px]'>ArtMaster</p>
                        <select
                            className='h-[50px] rounded-md border border-[#ABB2B9] pl-4 w-[100%]'
                            value={status}
                            onChange={handleStatusChange}
                        >
                            <option
                                // onClick={() => setType('art')}
                                value='Submitted'

                            >
                                <p>Submitted</p>
                            </option>
                            <option
                                // onClick={() => setType('product')}
                                value='Approved'
                            >
                                Approved
                            </option>
                            <option
                                // onClick={() => setType('product')}
                                value='Rejected'
                            >
                                Rejected
                            </option>
                            <option
                                // onClick={() => setType('product')}
                                value='InReview'
                            >
                                InReview
                            </option>
                        </select>

                        <TableContainer className='h-[350px] mt-[10px] '>
                            <Table size='small' className='border border-[#CECECE] ' stickyHeader>

                                <TableHead>
                                    <TableRow className='border border-[#CECECE] '>

                                        <StyledTableCell>Art Id </StyledTableCell>
                                        <StyledTableCell ><div className='w-[200px]'>Art Name</div></StyledTableCell>
                                        <StyledTableCell><div className='w-[150px]'>Image</div></StyledTableCell>
                                        <StyledTableCell ><div className='w-[150px]'>Price</div></StyledTableCell>
                                        <StyledTableCell ><div className='w-[150px]'>Status</div></StyledTableCell>
                                        <StyledTableCell ><div className='w-[150px]'>Oriantation</div></StyledTableCell>
                                        <StyledTableCell > <div className='w-[200px]'>Art Product No</div></StyledTableCell>
                                        <StyledTableCell ><div className='w-[150px]'>Size</div></StyledTableCell>
                                        <StyledTableCell ><div className='w-[150px]'>Type</div></StyledTableCell>
                                        <StyledTableCell align='center'> <div className='w-[600px]'>Description</div></StyledTableCell>
                                        <StyledTableCell ><div className='w-[150px]'>Stock</div></StyledTableCell>
                                        <StyledTableCell ><div className='w-[150px]'>Art Medium </div></StyledTableCell>
                                        <StyledTableCell ><div className='w-[150px]'>Stock Status</div> </StyledTableCell>
                                        <StyledTableCell ><div className='w-[150px]'>Height</div></StyledTableCell>
                                        <StyledTableCell ><div className='w-[150px]'>Width</div></StyledTableCell>
                                        <StyledTableCell ><div className='w-[150px]'>Prev Image</div></StyledTableCell>
                                        <StyledTableCell ><div className='w-[150px]'>Notes</div></StyledTableCell>
                                        <StyledTableCell ><div className='w-[150px]'>Submitted Date</div></StyledTableCell>
                                        <StyledTableCell ><div className='w-[150px]'>Review Data </div></StyledTableCell>
                                        <StyledTableCell ><div className='w-[150px]'>Approve Date</div></StyledTableCell>
                                        <StyledTableCell ><div className='w-[150px]'>Final Art Price</div></StyledTableCell>
                                        <StyledTableCell ><div className='w-[150px]'>Discount Percentage</div></StyledTableCell>
                                        <StyledTableCell align='center' ><div className='w-[150px] '>show Details</div></StyledTableCell>
                                        <StyledTableCell align='center'><div className='w-[150px] '>Secure Url</div></StyledTableCell>
                                        <StyledTableCell align='center' ><div className='w-[150px] '>Action</div></StyledTableCell>



                                    </TableRow>
                                </TableHead>
                                <TableBody className=''>
                                    {artData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((art) => (<tr key={art.artId}>
                                            <TableCell>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {art.artId}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {art.artName}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {art.image}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {art.price}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {art.status}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {art.orientation}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {art.arProductNo}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {art.size}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {art.type}
                                                </span>
                                            </TableCell>
                                            <TableCell  >
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {art.description}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {art.stock}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {art.artMedium}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {art.stockStatus}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {art.height}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {art.width}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {art.previewImage}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {art.notes}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {art.submittedDate}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {art.reviewData}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {art.approveDate}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {art.finalArtPrice}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {art.discountPercentage}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <div>
                                                    <Popup trigger={<div className='text-center'>
                                                        <Button

                                                            style={{
                                                                backgroundColor: 'orange',
                                                                color: 'white',
                                                            }}
                                                            onClick={() => { getEarn(art.artId) }}
                                                        >
                                                            View
                                                        </Button>
                                                    </div>}
                                                        modal
                                                        closeOnDocumentClick
                                                    >
                                                        {(close) => (<div className='h-[500px] w-[1395px]  rounded-lg  bg-[white] shadow pt-[80px] pl-[40px] pr-[40px] pb-[40px] overflow-y-auto'>
                                                            <div className='leading-tight'>
                                                                <p className='text-[17px] text-[#C0C0C0] font-thin leading-none ml-[2px]'>Admin Panel</p>
                                                                <p className='text-[26px]  text-[#808080] font-semibold'>Contributor Art Print Markup</p>
                                                            </div>
                                                            <div className='w-[160px] h-[45px] rounded-3xl border-[2px] border-[#C0C0C0]  mt-[20px] pl-[20px] pt-[2px] leading-tight'>
                                                                <p className='mx-[auto] text-[12px] text-[#C0C0C0] leading-none'>Set Minimum Markup<br />
                                                                    <span className='text-[16px] font-medium text-[#808080]'> 2500</span></p>
                                                            </div>
                                                            <div className=''>
                                                                <TableContainer className=' mt-[10px] '>
                                                                    <Table size='small' className='border border-[#CECECE] rounded-lg' stickyHeader>
                                                                        <TableHead >
                                                                           
                                                                                <TableRow  className=' '>
                                                                                    <TableCell className='border border-[#C0C0C0]'  >
                                                                                        <div className='w-[98px] text-[13px] text-[#3A3B3C] font-semibold'>Art Print Size </div>
                                                                                    </TableCell>
                                                                                    <TableCell className='border border-[#C0C0C0]' colSpan={2}>
                                                                                        <div className='w-[152px] text-[13px] text-[#3A3B3C] font-semibold text-center'>Contributor Master</div></TableCell>
                                                                                    <TableCell className='border border-[#C0C0C0]' align="center" colSpan={2}>
                                                                                        <div className='w-[110px] text-[13px] text-[#3A3B3C] font-semibold'>Artnstock Margin</div></TableCell>
                                                                                    <TableCell className='border border-[#C0C0C0] ' colSpan={6}>
                                                                                        <div className='w-[330px] text-[13px] text-[#3A3B3C] font-semibold text-center'>Artnstock Expenses</div></TableCell>
                                                                                    <TableCell className='border border-[#C0C0C0]' rowspan={2}>
                                                                                        <div className='w-[80px] text-[13px] text-[#3A3B3C] font-semibold'>Base Price</div>
                                                                                    </TableCell>
                                                                                    <TableCell className='border border-[#C0C0C0]' rowspan={2}>
                                                                                        <div className='w-[83px] text-[13px] text-[#3A3B3C] font-semibold'>Sell Price</div>
                                                                                    </TableCell>

                                                                                </TableRow>
                                                                                <TableRow >
                                                                                    <TableCell className='border border-[#C0C0C0]'>
                                                                                        <div className='text-[10px] h-[25px] w-[110px] text-[#808080] leading-tight font-medium'>Horizontal,Vertical and <br />Square Orientation</div></TableCell>
                                                                                    <TableCell className='border border-[#C0C0C0]'align="center" colSpan={2}>
                                                                                        <div className='text-[10px] h-[25px] text-[#808080] leading-tight font-medium text-center w-[152px]' >Percentage and Markup amount <br />based on Art Print Size</div></TableCell>
                                                                                    <TableCell className='border border-[#C0C0C0] leading-tight'>
                                                                                        <div className='text-[10px] h-[25px] w-[55px] text-[#808080] leading-tight font-medium text-center'>Set Margin Percentage</div></TableCell>
                                                                                    <TableCell className='border border-[#C0C0C0]'>
                                                                                        <div className='text-[10px] h-[25px] text-[#3A3B3C]  w-[55px] leading-tight font-semibold text-center'>Margin Amount</div></TableCell>
                                                                                    <TableCell className='border border-[#C0C0C0] leading-tight'>
                                                                                        <div className='text-[10px] h-[25px] w-[55px] text-[#808080] leading-tight font-medium text-center w-[60px]'>Artnstock Expenses 1</div></TableCell>
                                                                                    <TableCell className='border border-[#C0C0C0] leading-tight'>
                                                                                        <div className='text-[10px] h-[25px] w-[55px] text-[#808080] leading-tight font-medium text-center w-[60px]'>Artnstock Expenses 2</div></TableCell>
                                                                                    <TableCell className='border border-[#C0C0C0] leading-tight'>
                                                                                        <div className='text-[10px] h-[25px] w-[55px] text-[#808080] leading-tight font-medium text-center w-[60px]'>Artnstock Expenses 3</div></TableCell>
                                                                                    <TableCell className='border border-[#C0C0C0] leading-tight'>
                                                                                        <div className='text-[10px] h-[25px] w-[55px] text-[#808080] leading-tight font-medium text-center w-[60px]'>Subtotal Expenses </div></TableCell>
                                                                                    <TableCell className='border border-[#C0C0C0] leading-tight'>
                                                                                        <div className='text-[10px] h-[25px] w-[55px] text-[#808080] leading-tight font-medium text-right  w-[70px]'>Add Margin<br /> on Expenses </div></TableCell>
                                                                                    <TableCell className='border border-[#C0C0C0] leading-tight'>
                                                                                        <div className='text-[10px] h-[25px] w-[55px] text-[#808080] leading-tight font-medium text-center w-[60px]'>Total Expenses </div></TableCell>
                                                                                </TableRow>
                                                                                {/* <TableCell  className=' '>
                                                                                    <div className='w-[75px] text-[13px] text-[#3A3B3C] font-semibold'>Base Price</div></TableCell>
                                                                                <TableCell  className='border border-[#C0C0C0]'>
                                                                                    <div className='w-[75px] text-[13px] text-[#3A3B3C] font-semibold'> Sell Price</div></TableCell> */}
                                                                            
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {earnData?.orientationMasters?.map((earn,index) => (<TableRow key={earn.contributorArtMarkupId}>
                                                                                <TableCell className='border border-[#C0C0C0]'>Print size {index+1} </TableCell>
                                                                                <TableCell className='border border-[#C0C0C0]'><div className='w-[60px]'>{earn.contributorMarkupPercentage}%</div></TableCell>
                                                                               
                                                                                <TableCell className='border border-[#C0C0C0]'><div className=''>4000</div></TableCell>
                                                                                <TableCell className='border border-[#C0C0C0]'>{earn.artMarginPercentage}%</TableCell>
                                                                               <TableCell className='border border-[#C0C0C0]'>{earn.contributorCalculatedPrice}</TableCell>
                                                                               <TableCell className='border border-[#C0C0C0]'>{earn.artExpensesOne}.00</TableCell>
                                                                               <TableCell className='border border-[#C0C0C0]'>{earn.artExpensesTwo}.00</TableCell>
                                                                               <TableCell className='border border-[#C0C0C0]'>{earn.artExpensesThree}.00</TableCell>
                                                                               <TableCell className='border border-[#C0C0C0]'>{earn.subTotalExpenses}.00</TableCell>
                                                                               <TableCell className='border border-[#C0C0C0]'>{earn.marginPercentage}%</TableCell>
                                                                               <TableCell className='border border-[#C0C0C0]'>{earn.totalExpenses}.00</TableCell>
                                                                               <TableCell className='border border-[#C0C0C0]'>{earn.basePrice}.00</TableCell>
                                                                               <TableCell className='border border-[#C0C0C0]'>{earn.sellPrice}.00</TableCell>


                                                                               
                                                                            </TableRow>))}
                                                                        </TableBody>

                                                                    </Table>
                                                                </TableContainer>
                                                            </div>
                                                        </div>)}
                                                    </Popup>
                                                </div>


                                            </TableCell>
                                            <TableCell >

                                                <div>
                                                    <Popup trigger={<div className='text-center'>
                                                        <Button

                                                            style={{
                                                                backgroundColor: 'orange',
                                                                color: 'white',
                                                            }}
                                                        >
                                                            Image
                                                        </Button>
                                                    </div>}
                                                        modal
                                                        closeOnDocumentClick
                                                    >
                                                        {(close) => (<div
                                                            className='w-[400px] lg:w-[600px] lg:h-[350px] h-[250px] rounded-lg flex bg-[white] shadow'>

                                                            <img className='w-[100%]' src={art.imageMaster.secureUrl} />
                                                        </div>)}
                                                    </Popup>
                                                </div>

                                            </TableCell>

                                            <TableCell align='center'>
                                                <div className='mx-[auto] '>
                                                    <Popup
                                                        trigger={<div > <button onClick={() => { SetObject(art) }} className='bg-[#7367F0] h-[40px] w-[100px] rounded-lg text-[white] mx-[auto] text-[16px]'>
                                                            Update  </button></div>}
                                                        modal
                                                        open={isOpen}
                                                        closeOnDocumentClick
                                                    >
                                                        {close => (
                                                            <div className="  lg:w-[500px] w-[440px] relative bg-[white] shadow rounded p-[40px]  ">
                                                                <p className=' lg:text-[25px] text-[16px]  font-semibold'>Update Records</p>
                                                                <p className=' mt-[12px]   font-semibold lg:text-[18px] text-[12px]'>Status : </p>
                                                                <div >
                                                                    <select
                                                                        className='h-[40px] pl-[4px]  w-[100%] bg-[#DADBDD] mt-[8px] font-medium'
                                                                        value={updateObj.status}
                                                                        onChange={handleTypeChange}
                                                                    >
                                                                        <option className='text-[15px] font- '
                                                                            // onClick={() => setupdateObj({...updateObj, status: 'Submitted'})}
                                                                            value='Submitted'
                                                                        >
                                                                            Submitted
                                                                        </option>
                                                                        <option
                                                                            // onClick={() => setType('approve')}
                                                                            // onClick={() => setupdateObj({...updateObj, status: 'Approved'})}
                                                                            value='Approved'
                                                                        >
                                                                            Approved
                                                                        </option>
                                                                        <option

                                                                            value='Rejected'
                                                                        >
                                                                            Rejected
                                                                        </option>
                                                                        <option

                                                                            value='InReview'
                                                                        >
                                                                            InReview
                                                                        </option>
                                                                    </select>
                                                                    <br /></div>
                                                                <p className=' mt-[10px]   font-semibold lg:text-[18px] text-[12px]'>Artname : </p>
                                                                <div className='rounded rounded   w-[100%] bg-gray-100'>
                                                                    <textarea
                                                                        value={updateObj?.artName}
                                                                        className='  w-[100%] mt-[8px] text-[18px] lg:h-[80px] h-[70px] bg-[#DADBDD] p-[4px]'
                                                                        name='artname'
                                                                        placeholder='Enter Artname'
                                                                        onChange={(e) => { setupdateObj({ ...updateObj, artName: e.target.value }) }}
                                                                    //   onChange={(e) => changeName(e, index)}
                                                                    //   value={postObj?.subjectName}
                                                                    ></textarea>
                                                                </div>
                                                                <p className=' mt-[10px]   font-semibold lg:text-[18px] text-[12px]'>Description : </p>
                                                                <div className='rounded rounded   w-[100%] bg-gray-100 '>
                                                                    <textarea
                                                                        value={updateObj?.description}
                                                                        onChange={(e) => { setupdateObj({ ...updateObj, description: e.target.value }) }}
                                                                        className='  w-[100%] mt-[8px] text-[18px] lg:h-[150px] h-[120px] bg-[#DADBDD] p-[4px]'
                                                                        name='description'
                                                                        placeholder='Enter Description'
                                                                    //   onChange={(e) => changeName(e, index)}
                                                                    //   value={postObj?.subjectName}
                                                                    ></textarea>
                                                                </div>
                                                                <button onClick={() => { updateArt(); close() }} className='h-[40px] rounded w-[110px] mt-[10px] bg-[#1F45FC] text-[white]'>
                                                                    Update Record
                                                                </button>
                                                            </div>
                                                        )}
                                                    </Popup>
                                                </div>
                                            </TableCell>
                                        </tr>))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15]}
                            component="div"
                            count={artData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default ArtMaster
