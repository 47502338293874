// styleSlice.js
import { createSlice } from '@reduxjs/toolkit';

const styleSlice = createSlice({
  name: 'style',
  initialState: {
    style: null,
    loginState: false,
  },
  reducers: {
    setStyle: (state, action) => {
      state.style = action.payload;
    },
    setLogin: (state, action) => {
      state.loginState = action.payload;
    },
   
  },
 

});

export const { setLogin } = styleSlice.actions;
export const styleSliceAction = styleSlice.actions;

export default styleSlice.reducer;
