import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, styled, tableCellClasses } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { httpClient } from '../../axios';
import Popup from 'reactjs-popup';

const Orientation = () => {

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'rgb(115 103 240)',
      color: theme.palette.common.white,
      fontSize: 20,
    },
  }));
  const [shapeData, setShapeData] = useState([]);
  const [shape1, setShape] = useState('Horizontal');

  const [shapeObj, setShapeObj] = useState({



    shape: "string",
    shapeStatus: "string",
    price: 0,
    height: "string",
    width: "string",
    contributorMarkupPercentage: 0,
    artMarginPercentage: 0,
    artExpensesOne: 0,
    artExpensesTwo: 0,
    artExpensesThree: 0,
    marginPercentage: 0,
    printSize: 'string'
  })

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const getShape = async () => {
    const res = await httpClient
      .get(`/shape_master/getShapeWiseList/${shape1}`)
    // .then((res) => {
    console.log(res.data);
    setShapeData(res.data);
  }
  useEffect(() => {
    getShape();
  }, [shape1]);


  const handleShapeChange = (event) => {
    setShape(event.target.value);



  };
  const handleInputChange = (event) => {
    console.log(event.target.value);
    setShapeObj({ ...shapeObj, shape: event.target.value })


  };

  //   const handleInputChange = (e) => {
  //     setShapeObj({
  //         ...shapeObj,
  //         [e.target.name]: e.target.value,

  //     })
  //         ;
  // };

  const addOrientation = async () => {

    const res = await httpClient.post('/shape_master/create', { ...shapeObj, shape1 });
    console.log(res.data);
    getShape(shape1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const UpdateOrientation = async () => {
    const res = await httpClient.put(
      '/shape_master/update',
      { ...shapeObj,shape1 }
    );
    getShape(shape1);
    console.log(res.data);

  };
  return (
    <>
      <Grid justifyContent='center'>
        <Grid item xs={12} md={10} lg={8}>
          <div className='pageMainDiv mt-[20px]'>
            <p className='text-[25px] font-bold mt-[40px]'>Orientation</p>

            <select
              className='h-[50px] rounded-md border border-[#ABB2B9] pl-4 w-[100%] cursor-pointer'
              value={shape1}
              onChange={handleShapeChange}
            >
              <option
                value='Horizontal'
              >
                <p>Horizontal</p>
              </option>
              <option
                value='Vertical'
              >
                Vertical
              </option>
              <option
                value='Square'
              >
                Square
              </option>
            </select>
            <TableContainer className='h-[400px] mt-[10px] '>
              <Table size='small' className='border border-[#CECECE] ' stickyHeader>

                <TableHead>
                  <TableRow className=' '>

                    <StyledTableCell> <div className='w-[150px]'>Orientation Id</div> </StyledTableCell>
                    <StyledTableCell align='center'><div className='w-[100px]'>Shape</div></StyledTableCell>
                    <StyledTableCell align='center'><div className='w-[130px]'>Shape Status</div></StyledTableCell>
                    <StyledTableCell align='center'><div className='w-[100px]'>Print Size</div></StyledTableCell>

                    <StyledTableCell align='center'><div className='w-[100px]'>Price</div></StyledTableCell>
                    <StyledTableCell align='center'><div className='w-[100px]'>Height</div></StyledTableCell>
                    <StyledTableCell align='center'><div className='w-[100px]'>Width</div></StyledTableCell>
                    <StyledTableCell align='center'> <div className='w-[200px]'>Contributor Markup Percentage</div></StyledTableCell>
                    <StyledTableCell align='center'><div className='w-[200px]'>Contributor Calculated Price</div></StyledTableCell>
                    <StyledTableCell align='center'><div className='w-[200px]'>Art Margin Percentage </div></StyledTableCell>
                    <StyledTableCell align='center'> <div className='w-[200px]'>Margin Amount</div></StyledTableCell>
                    <StyledTableCell align='center'><div className='w-[150px]'>Art Expenses One</div></StyledTableCell>
                    <StyledTableCell align='center'><div className='w-[150px]'>Art Expenses Two</div></StyledTableCell>
                    <StyledTableCell align='center'><div className='w-[150px]'>Art Expenses Three</div> </StyledTableCell>
                    <StyledTableCell align='center'><div className='w-[150px]'>Sub Total Expenses</div></StyledTableCell>
                    <StyledTableCell align='center'><div className='w-[150px]'>Margin Percentage </div></StyledTableCell>
                    <StyledTableCell align='center'><div className='w-[150px]'>Total Expenses</div></StyledTableCell>
                    <StyledTableCell align='center'><div className='w-[150px]'>Base Price</div></StyledTableCell>
                    <StyledTableCell align='center'><div className='w-[150px]'>Sell Price</div></StyledTableCell>
                    <StyledTableCell align='center'><div className='w-[200px]'>Action</div></StyledTableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {shapeData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((shape, index) => (<tr key={shape.orientationId}>
                      <TableCell >
                        {' '}
                        <span className='text-[16px]'>
                          {(page * rowsPerPage) + index + 1}
                        </span>
                      </TableCell>
                      <TableCell align='center'>
                        {' '}
                        <span className='text-[16px]'>
                          {shape.shape}
                        </span>
                      </TableCell>
                      <TableCell align='center'>
                        {' '}
                        <span className='text-[16px]'>
                          {shape.shapeStatus}
                        </span>
                      </TableCell>
                      <TableCell align='center'>
                        {' '}
                        <span className='text-[16px]'>
                          {shape.printSize}
                        </span>
                      </TableCell>
                      <TableCell align='center'>
                        {' '}
                        <span className='text-[16px]'>
                          {shape.price}
                        </span>
                      </TableCell>
                      <TableCell align='center'>
                        {' '}
                        <span className='text-[16px]'>
                          {shape.height}
                        </span>
                      </TableCell>
                      <TableCell align='center'>
                        {' '}
                        <span className='text-[16px]'>
                          {shape.width}
                        </span>
                      </TableCell>
                      <TableCell align='center'>
                        {' '}
                        <span className='text-[16px]'>
                          {shape.contributorMarkupPercentage}%
                        </span>
                      </TableCell>
                      <TableCell align='center'>
                        {' '}
                        <span className='text-[16px]'>
                          {shape.contributorCalculatedPrice}
                        </span>
                      </TableCell>
                      <TableCell align='center'>
                        {' '}
                        <span className='text-[16px]'>
                          {shape.artMarginPercentage}%
                        </span>
                      </TableCell>
                      <TableCell align='center'>
                        {' '}
                        <span className='text-[16px]'>
                          {shape.marginAmount}
                        </span>
                      </TableCell>
                      <TableCell align='center'>
                        {' '}
                        <span className='text-[16px]'>
                          {shape.artExpensesOne}
                        </span>
                      </TableCell>
                      <TableCell align='center'>
                        {' '}
                        <span className='text-[16px]'>
                          {shape.artExpensesTwo}
                        </span>
                      </TableCell>
                      <TableCell align='center'>
                        {' '}
                        <span className='text-[16px]'>
                          {shape.artExpensesThree}
                        </span>
                      </TableCell>
                      <TableCell align='center'>
                        {' '}
                        <span className='text-[16px]'>
                          {shape.subTotalExpenses}
                        </span>
                      </TableCell>
                      <TableCell align='center'>
                        {' '}
                        <span className='text-[16px]'>
                          {shape.marginPercentage}%
                        </span>
                      </TableCell>
                      <TableCell align='center'>
                        {' '}
                        <span className='text-[16px]'>
                          {shape.totalExpenses}
                        </span>
                      </TableCell>
                      <TableCell align='center'>
                        {' '}
                        <span className='text-[16px]'>
                          {shape.basePrice}
                        </span>
                      </TableCell>
                      <TableCell align='center'>
                        {' '}
                        <span className='text-[16px]'>
                          {shape.sellPrice}
                        </span>
                      </TableCell>
                      <TableCell>
                        <div className='flex space-x-2 mx-auto'>
                          <Popup trigger={<div className='text-center'>
                            <Button

                              style={{
                                backgroundColor: 'orange',
                                color: 'white',
                              }}
                              onClick={() => {
                                setShapeObj(shape);

                              }}
                            >
                              Update
                            </Button>
                          </div>}
                            modal
                            closeOnDocumentClick
                          >
                            {(close) => (
                              <div className='w-[300px] lg:w-[600px]  rounded-lg bg-[white] shadow p-[30px] relative'>
                                <div className='flex'>

                                  <p className='text-[23px] font-medium ml-4 '>
                                    Update Orientation
                                  </p>
                                  <button
                                    className='ml-auto h-[40px] w-[40px]'
                                    onClick={close}
                                  >
                                    &times;
                                  </button>
                                </div>


                                <div className='lg:flex   lg:space-x-7'>
                                  <div className='flex flex-col '>
                                    <p className='formhead  mt-[25px] '>Shape</p>
                                    <select
                                      className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                      value={shapeObj.shape}
                                      onChange={handleInputChange}
                                    >
                                      <option
                                        value='Horizontal'
                                      // onClick={() => setShape('Horizontal')}
                                      >
                                        <p>Horizontal</p>
                                      </option>
                                      <option
                                        value='Vertical'
                                      // onClick={() => setShape('Vertical')}
                                      >
                                        Vertical
                                      </option>
                                      <option
                                        value='Square'
                                      // onClick={() => setShape('Square')}
                                      >
                                        Square
                                      </option>
                                    </select>
                                  </div>
                                  <div className='flex flex-col'>
                                    <p className='formhead  mt-[25px] '>Shape Status</p>
                                    <select
                                      className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'

                                      onChange={(e) => { setShapeObj({ ...shapeObj, shapeStatus: e.target.value }) }}
                                      name='shapeStatus'
                                      value={shapeObj.shapeStatus}
                                    >
                                      <option
                                        value='Active'

                                      >
                                        Active
                                      </option>
                                      <option
                                        value='InActive'

                                      >
                                        InActive
                                      </option>
                                    </select>
                                  </div>
                                </div>

                                <div className='lg:flex   lg:space-x-7'>


                                  <div className='flex flex-col '>
                                    <p className='formhead  mt-[20px] '>Print Size</p>
                                    <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                      onChange={(e) => { setShapeObj({ ...shapeObj, printSize: e.target.value }) }}
                                      value={shapeObj.printSize}
                                      name='printSize' />
                                  </div>
                                  <div className='flex flex-col '>
                                    <p className='formhead  mt-[20px] '>Price</p>
                                    <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                      onChange={(e) => { setShapeObj({ ...shapeObj, price: e.target.value }) }}
                                      name='price'
                                      value={shapeObj.price} />
                                  </div>
                                </div>
                                <div className='lg:flex   lg:space-x-7'>
                                  <div className='flex flex-col '>
                                    <p className='formhead  mt-[20px] '>Height</p>
                                    <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                      onChange={(e) => { setShapeObj({ ...shapeObj, height: e.target.value }) }}
                                      name='height'
                                      value={shapeObj.height} />
                                  </div>
                                  <div className='flex flex-col '>
                                    <p className='formhead  mt-[20px] '>Width</p>
                                    <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                      onChange={(e) => { setShapeObj({ ...shapeObj, width: e.target.value }) }}
                                      value={shapeObj.width}
                                      name='width' />
                                  </div>
                                </div>


                                <div className='lg:flex   lg:space-x-7'>
                                  <div className='flex flex-col '>
                                    <p className='formhead  mt-[20px] '>Contributor Markup Percentage</p>
                                    <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                      onChange={(e) => { setShapeObj({ ...shapeObj, contributorMarkupPercentage: e.target.value }) }}
                                      value={shapeObj.contributorMarkupPercentage}
                                      name='contributorMarkupPercentage' />
                                  </div>
                                  <div className='flex flex-col '>
                                    <p className='formhead  mt-[20px] '>Art Margin Percentage</p>
                                    <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                      onChange={(e) => { setShapeObj({ ...shapeObj, artMarginPercentage: e.target.value }) }}
                                      value={shapeObj.artMarginPercentage}
                                      name='artMarginPercentage' />
                                  </div>
                                </div>

                                <div className='lg:flex   lg:space-x-7'>

                                  <div className='flex flex-col '>
                                    <p className='formhead  mt-[20px] '>Art Expenses One</p>
                                    <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                      onChange={(e) => { setShapeObj({ ...shapeObj, artExpensesOne: e.target.value }) }}
                                      value={shapeObj.artExpensesOne}
                                      name='artExpensesOne' />
                                  </div>




                                  <div className='flex flex-col '>
                                    <p className='formhead  mt-[20px] '>Art Expenses Two</p>
                                    <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                      onChange={(e) => { setShapeObj({ ...shapeObj, artExpensesTwo: e.target.value }) }}
                                      name='artExpensesTwo'
                                      value={shapeObj.artExpensesTwo}
                                    />
                                  </div>
                                </div>
                                <div className='lg:flex   lg:space-x-7'>
                                  <div className='flex flex-col '>
                                    <p className='formhead  mt-[20px] '>Art Expenses Three</p>
                                    <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                      onChange={(e) => { setShapeObj({ ...shapeObj, artExpensesThree: e.target.value }) }}
                                      value={shapeObj.artExpensesThree}

                                      name='artExpensesThree' />
                                  </div>



                                  <div className='flex flex-col '>
                                    <p className='formhead  mt-[20px] '>Margin Percentage</p>
                                    <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                      onChange={(e) => { setShapeObj({ ...shapeObj, marginPercentage: e.target.value }) }}
                                      value={shapeObj.marginPercentage}
                                      name='marginPercentage' />
                                  </div>

                                </div>

                                <div className=' h-[41px] w-[120px]  mt-[30px] mx-auto'>
                                  <button className='rounded-lg text-[white] bg-[blue] text-[17px] font-semibold h-[41px] w-[120px] '
                                    onClick={() => {
                                      UpdateOrientation();
                                      close();

                                    }}
                                  >
                                    Save</button>
                                </div>
                              </div>
                            )}
                          </Popup>
                          <Button
                            // onClick={() =>
                            //     deleteData(medi.mediumId)
                            // }
                            style={{
                              backgroundColor: 'red',
                              color: 'white',
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      </TableCell>
                    </tr>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className='flex'>
              <Popup
                trigger={<div >
                  <button
                    // onClick={() => { handleCloseForm() }}
                    className='bg-[#7367F0] h-[40px] w-[150px] rounded-lg text-[white] text-[16px]'>
                    Add Orientation
                  </button></div>
                }
                modal
                closeOnDocumentClick
              >
                {(close) => (
                  <div className='w-[300px] lg:w-[600px]  rounded-lg bg-[white] shadow p-[30px] relative'>
                    <div className='flex'>

                      <p className='text-[23px] font-medium ml-4 '>
                        Add Orientation
                      </p>
                      <button
                        className='ml-auto h-[40px] w-[40px]'
                        onClick={close}
                      >
                        &times;
                      </button>
                    </div>


                    <div className='lg:flex   lg:space-x-7'>
                      <div className='flex flex-col '>
                        <p className='formhead  mt-[25px] '>Shape</p>
                        <select
                          className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                          value={shapeObj.shape}
                          onChange={handleInputChange}
                        >
                          <option
                            value='Horizontal'
                          // onClick={() => setShape('Horizontal')}
                          >
                            <p>Horizontal</p>
                          </option>
                          <option
                            value='Vertical'
                          // onClick={() => setShape('Vertical')}
                          >
                            Vertical
                          </option>
                          <option
                            value='Square'
                          // onClick={() => setShape('Square')}
                          >
                            Square
                          </option>
                        </select>
                      </div>
                      <div className='flex flex-col'>
                        <p className='formhead  mt-[25px] '>Shape Status</p>
                        <select
                          className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                          // value={mediumObj.mediumStatus}
                          onChange={(e) => { setShapeObj({ ...shapeObj, shapeStatus: e.target.value }) }}
                          name='shapeStatus'
                          value={shapeObj.shapeStatus}
                        >
                          <option
                            value='Active'

                          >
                            Active
                          </option>
                          <option
                            value='InActive'

                          >
                            InActive
                          </option>
                        </select>
                      </div>
                    </div>

                    <div className='lg:flex   lg:space-x-7'>


                      <div className='flex flex-col '>
                        <p className='formhead  mt-[20px] '>Print Size</p>
                        <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                          onChange={(e) => { setShapeObj({ ...shapeObj, printSize: e.target.value }) }}

                          name='printSize' />
                      </div>
                      <div className='flex flex-col '>
                        <p className='formhead  mt-[20px] '>Price</p>
                        <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                          onChange={(e) => { setShapeObj({ ...shapeObj, price: e.target.value }) }}
                          name='price' />
                      </div>
                    </div>
                    <div className='lg:flex   lg:space-x-7'>
                      <div className='flex flex-col '>
                        <p className='formhead  mt-[20px] '>Height</p>
                        <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                          onChange={(e) => { setShapeObj({ ...shapeObj, height: e.target.value }) }}
                          name='height' />
                      </div>
                      <div className='flex flex-col '>
                        <p className='formhead  mt-[20px] '>Width</p>
                        <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                          onChange={(e) => { setShapeObj({ ...shapeObj, width: e.target.value }) }}

                          name='width' />
                      </div>
                    </div>


                    <div className='lg:flex   lg:space-x-7'>
                      <div className='flex flex-col '>
                        <p className='formhead  mt-[20px] '>Contributor Markup Percentage</p>
                        <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                          onChange={(e) => { setShapeObj({ ...shapeObj, contributorMarkupPercentage: e.target.value }) }}

                          name='contributorMarkupPercentage' />
                      </div>
                      <div className='flex flex-col '>
                        <p className='formhead  mt-[20px] '>Art Margin Percentage</p>
                        <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                          onChange={(e) => { setShapeObj({ ...shapeObj, artMarginPercentage: e.target.value }) }}

                          name='artMarginPercentage' />
                      </div>
                    </div>

                    <div className='lg:flex   lg:space-x-7'>

                      <div className='flex flex-col '>
                        <p className='formhead  mt-[20px] '>Art Expenses One</p>
                        <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                          onChange={(e) => { setShapeObj({ ...shapeObj, artExpensesOne: e.target.value }) }}

                          name='artExpensesOne' />
                      </div>




                      <div className='flex flex-col '>
                        <p className='formhead  mt-[20px] '>Art Expenses Two</p>
                        <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                          onChange={(e) => { setShapeObj({ ...shapeObj, artExpensesTwo: e.target.value }) }}
                          name='artExpensesTwo' />
                      </div>
                    </div>
                    <div className='lg:flex   lg:space-x-7'>
                      <div className='flex flex-col '>
                        <p className='formhead  mt-[20px] '>Art Expenses Three</p>
                        <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                          onChange={(e) => { setShapeObj({ ...shapeObj, artExpensesThree: e.target.value }) }}

                          name='artExpensesThree' />
                      </div>



                      <div className='flex flex-col '>
                        <p className='formhead  mt-[20px] '>Margin Percentage</p>
                        <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                          onChange={(e) => { setShapeObj({ ...shapeObj, marginPercentage: e.target.value }) }}

                          name='marginPercentage' />
                      </div>

                    </div>

                    <div className=' h-[41px] w-[120px]  mt-[30px] mx-auto'>
                      <button className='rounded-lg text-[white] bg-[blue] text-[17px] font-semibold h-[41px] w-[120px] '
                        onClick={() => {
                          addOrientation();
                          close();

                        }}
                      >
                        Add</button>
                    </div>
                  </div>
                )}
              </Popup>

              <TablePagination className='ml-auto'
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={shapeData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </Grid >
      </Grid >
    </>
  )
}

export default Orientation