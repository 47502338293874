import React, { useEffect, useState } from 'react'
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, styled, tableCellClasses } from '@mui/material';
import { httpClient } from '../../axios';



const Contributorearning = () => {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: 'rgb(115 103 240)',
            color: theme.palette.common.white,
            fontSize: 20,
        },
    }));
    const [earn, setEarn] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const getEarning = async () => {
        const res = await httpClient
            .get('/contributorearning/get')
        // .then((res) => {
        console.log(res.data);
        setEarn(res.data);

        // });
    };
    useEffect(() => {
        getEarning();
      }, []);

      const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <>
            <Grid  justifyContent='center' >
            <Grid item xs={12} md={10} lg={8} >
                    <div className='pageMainDiv mt-[20px]'>
                        <p className='text-[25px] font-bold mt-[40px]'>Contributorearning</p>
                        <TableContainer className='h-[400px] mt-[10px] ' >
                            <Table  className='border border-[#CECECE] ' stickyHeader>

                                <TableHead>
                                    <TableRow className='border border-[#CECECE] '>
                                        <StyledTableCell>contributor Earning Id</StyledTableCell>
                                        <StyledTableCell align='center'>Art Id</StyledTableCell>
                                        <StyledTableCell align='center'><div className='w-[150px]'>Date</div></StyledTableCell>
                                        <StyledTableCell align='center'>Order Status </StyledTableCell>
                                        <StyledTableCell  align='center'> <div className='w-[150px]'>Quantity</div></StyledTableCell>
                                        <StyledTableCell  align='center'> <div className='w-[150px]'>Amount</div></StyledTableCell>
                                        <StyledTableCell  align='center'> <div className='w-[150px]'>Payment Status</div></StyledTableCell>
                                        <StyledTableCell  align='center'>User Id</StyledTableCell>
                                        <StyledTableCell  align='center'> <div className='w-[150px]'>Product Price</div></StyledTableCell>
                                        <StyledTableCell> Cart Art Frame Master</StyledTableCell>
                                        <StyledTableCell  align='center'><div className='w-[150px]'>Cart Product Master</div> </StyledTableCell>
                                        <StyledTableCell  align='center'><div className='w-[150px]'>Base Price</div></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {earn
                                     .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((conearn,index) => (<TableRow key={conearn.conEarningId}>
                                        <TableCell>

                                            <span className='text-[16px]'>
                                                {(page * rowsPerPage) + index+1}
                                            </span>
                                        </TableCell>
                                        <TableCell>

                                            <span className='text-[16px]'>
                                                {conearn.artId}
                                            </span>
                                        </TableCell>
                                        <TableCell align='center'>

                                            <span className='text-[16px]'>
                                                {conearn.date}
                                            </span>
                                        </TableCell>
                                        <TableCell>

                                            <span className='text-[16px]'>
                                                {conearn.orderStatus}
                                            </span>
                                        </TableCell>
                                        <TableCell align='center'>

                                            <span className='text-[16px]'>
                                                {conearn.quantity}
                                            </span>
                                        </TableCell>
                                        <TableCell  align='center'>

                                            <span className='text-[16px]'>
                                                {conearn.amount}
                                            </span>
                                        </TableCell>
                                        <TableCell  align='center'>

                                            <span className='text-[16px]'>
                                                {conearn.paymentStatus}
                                            </span>
                                        </TableCell>
                                        <TableCell  align='center'>

                                            <span className='text-[16px]'>
                                                {conearn.userId}
                                            </span>
                                        </TableCell>
                                        <TableCell  align='center'>

                                            <span className='text-[16px]'>
                                                {conearn.productPrice}
                                            </span>
                                        </TableCell>
                                        <TableCell>

                                            <span className='text-[16px]'>
                                                {conearn.cartArtFrameMaster}
                                            </span>
                                        </TableCell>
                                        <TableCell  align='center'>

                                            <span className='text-[16px]'>
                                                {conearn.cartProductMaster}
                                            </span>
                                        </TableCell>
                                        <TableCell  align='center'>

                                            <span className='text-[16px]'>
                                                {conearn.basePrice}
                                            </span>
                                        </TableCell>
                                    </TableRow>))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination className='ml-auto'
                                rowsPerPageOptions={[5, 10, 15]}
                                component="div"
                                count={earn.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                    </div>

                </Grid>
            </Grid>

        </>
    )
}

export default Contributorearning