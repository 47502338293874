import React, { useState } from 'react';
import { useEffect } from 'react';
import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, styled, tableCellClasses } from '@mui/material';
import { httpClient } from '../../axios';
import Popup from 'reactjs-popup';
import SendIcon from '@mui/icons-material/Send';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


const Giftcode = () => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'rgb(115 103 240)',
      color: theme.palette.common.white,
      fontSize: 20,
    },
  }));

  const [isOpen, setIsOpen] = useState(false);

  const [userList, setUserList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [updatedData, setUpdatedData] = useState({});
  const [selectedDateTime, setSelectedDateTime] = useState(new Date());
  const [status, setstatus] = useState('Active');


  const handleDateTimeChange = (date) => {
    setSelectedDateTime(date);
  };

  const handleInputChange = (e, date, time) => {

    setUpdatedData({
      ...updatedData,
      [e.target.name]: e.target.value,
    });
    setSelectedDateTime(date);
    setSelectedDateTime(time);


  };

  useEffect(() => {
    console.log(updatedData);
  }, [updatedData]);

  const Update = async () => {
    console.log(updatedData);
    const res = await httpClient.put(
      '/gift_code_master/update',
      updatedData
    );

    getUser();

    console.log(res.data);
  };



  const getUser = async () => {
    try {
      const res = await httpClient.get(
        `/gift_code_master/getGiftCodeMasterByStatus/${status}`);
      if (res) {
        setUserList(res.data);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    getUser();
  }, [status]);

  const handleTypeChange = (event) => {
    setstatus(event.target.value); // Update the 'type' state with the selected value

    // console.log(event.target.value);
    // getMaster();
  };


  const addUserDetails = async () => {
    const res = await httpClient.post(
      '/gift_code_master/create',
      { ...updatedData, status }
    );
    // .then((res) => {
    console.log('post api', res.data);
    getUser(status);
  };


  const filteredData = userList.filter(list =>
    list.description && list.description.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const handleSearch = event => {
    setSearchQuery(event.target.value);
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const deleteData = async (giftCodeId) => {
    const sureDelete = window.confirm("Are You Sure The Delete Record..?");
    if (sureDelete) {
      const res = await httpClient.delete(
        `/gift_code_master/deleteGiftCodeByGiftCodeId/${giftCodeId}`
      );
      console.log(res.data);
      getUser(status);
    }
  };
  return (
    <>
      <Grid justifyContent='center'>
        <Grid item xs={12} md={10} lg={8} >
          <div className='pageMainDiv mt-[20px]'>
            <p className='lg:text-[25px] text-[16px] mt-[20px] font-semibold'>
              Gift Code</p>
            <select
              className='lg:h-[50px] lg:w-[300px] h-[40px] w-[140px] bg-[#F8F8F4] mt-[20px]'
              value={status}
              onChange={handleTypeChange}
            >
              <option
                // onClick={() => setType('art')}
                value='Active'
                className=''
              >
                Active
              </option>
              <option
                // onClick={() => setType('product')}
                value='InActive'
              >
                InActive
              </option>
            </select>

            <TableContainer style={{ height: '430px' }} className=' mt-[20px]  '>
              <Table sx={{ minWidth: 450 }} className='border border-[#CECECE] ' stickyHeader>
                <TableHead className='bg-[#7367F0]' >
                  <TableRow >

                    <StyledTableCell align='center' >Gift Code</StyledTableCell>
                    <StyledTableCell align='center' >Status</StyledTableCell>
                    <StyledTableCell align='center'  >Description</StyledTableCell>
                    <StyledTableCell align='center' >Discount</StyledTableCell>
                    <StyledTableCell align='center'>Max Amount</StyledTableCell>
                    <StyledTableCell align='center' >Start Date</StyledTableCell>
                    <StyledTableCell align='center' >End Date</StyledTableCell>
                    <StyledTableCell align='center' >Action</StyledTableCell>

                  </TableRow>
                </TableHead>
                <TableBody >
                  {filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(transaction => (<TableRow key={transaction.giftCodeId}>
                      <TableCell align='center'>
                        <span className='text-[20px] font-bold text-[#454545]'>{transaction.giftCode}</span></TableCell>
                      {/* <TableCell align='center'>
                      <span className=''>{transaction.status}</span></TableCell> */}
                      <TableCell>
                        {
                          transaction.status === 'Active' ? (
                            <Button
                              style={{ backgroundColor: 'green', color: 'white' }}>Active</Button>
                          ) : (
                            <Button
                              style={{ backgroundColor: 'yellow', color: 'black' }} >InActive</Button>
                          )
                        }
                      </TableCell>

                      <TableCell align='center'>  {transaction.description}</TableCell>
                      <TableCell align='center' >
                        <span className='text-[17px] font-medium text-[#3A3B3C]'>{transaction.discount} {" "}
                        </span>
                      </TableCell>
                      <TableCell align='center'> {transaction.maxAmount}</TableCell>
                      <TableCell align='center'>
                        <span className='text-[17px] font-bold text-[#3A3B3C]' > {transaction.startDate}</span></TableCell>
                      <TableCell align='center'>
                        <span className='text-[17px] font-bold text-[#3A3B3C]' > {transaction.endDate}</span></TableCell>

                      <TableCell>
                        <div className='flex space-x-2 '>
                          <Popup
                            trigger={<div >
                              <Button
                                onClick={() => {
                                  setUpdatedData(transaction);
                                }}
                                style={{
                                  backgroundColor: 'orange',
                                  color: 'white',
                                }}
                              >
                                Update
                              </Button>
                            </div>
                            }
                            modal
                            closeOnDocumentClick
                          >
                            {(close) => (
                              <div className='w-[300px] lg:w-[600px]  rounded-lg bg-[white] shadow p-[30px] relative'>
                                <div className='flex'>

                                  <p className='text-[23px] font-medium ml-4 '>
                                    Update Giftcode
                                  </p>
                                  <button
                                    className='ml-auto h-[40px] w-[40px]'
                                    onClick={close}
                                  >
                                    &times;
                                  </button>
                                </div>

                                <div className='flex flex-col'>
                                  <p className='formhead mt-[25px] '>Medium Status</p></div>
                                <select
                                  className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                  value={updatedData.status}
                                  onChange={handleInputChange}
                                  name='status'
                                >
                                  <option
                                    value='Active'

                                  >
                                    Active
                                  </option>
                                  <option
                                    value='InActive'

                                  >
                                    InActive
                                  </option>
                                </select>

                                <div className='flex flex-col '>
                                  <p className='formhead  mt-[25px] '>Description </p>
                                  <textarea type='text'
                                    className=' lg:w-[100%] h-[100px]  bg-[#F8F8F4] pl-[10px] mt-[15px]'
                                    value={updatedData.description}
                                    onChange={handleInputChange}
                                    name='description' />


                                </div>
                                <div className='lg:flex   lg:space-x-7'>
                                  <div className='flex flex-col'>
                                    <p className='formhead  mt-[25px] '>Discount</p>
                                    <div className='forminput   mt-3 w-[100%] lg:w-[258px] rounded-lg leading-none pl-[5px]'>
                                      <input type='text' className='text-[15px] p-[4px] forminput    w-[100%] lg:w-[258px] rounded-lg leading-none '
                                        value={updatedData.discount}
                                        onChange={handleInputChange}

                                        name='discount' />

                                    </div>
                                  </div>

                                  <div className='flex flex-col '>
                                    <p className='formhead lg:mt-[20px] mt-[10px] '>Maximum Amount</p>
                                    <div className='forminput   mt-3 w-[100%] lg:w-[258px] rounded-lg leading-none pl-[5px]'>
                                      <input type='text' className='text-[15px] p-[4px] forminput    w-[100%] lg:w-[258px] rounded-lg leading-none '
                                        value={updatedData.maxAmount}
                                        onChange={handleInputChange}

                                        name='maxAmount' />
                                    </div>
                                  </div>
                                </div>
                                <div className='lg:flex   lg:space-x-7'>
                                  <div className='flex flex-col '>
                                    <p className='formhead  mt-[25px] '>Start Date</p>
                                    <DatePicker
                                      selected={updatedData.startDate ? new Date(updatedData.startDate) : null}
                                      onChange={(date) => handleInputChange({ target: { name: 'startDate', value: date } })}
                                      showTimeSelect
                                      timeFormat="HH:mm:ss"
                                      dateFormat="yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
                                      className='text-[15px] p-[4px] forminput    w-[100%] lg:w-[258px] rounded-lg leading-none cursor-pointer'
                                    />
                                  </div>
                                  <div className='flex flex-col'>
                                    <p className='formhead mt-[25px] '>End Date</p>
                                    <DatePicker
                                      selected={updatedData.endDate ? new Date(updatedData.endDate) : null}
                                      onChange={(date) => handleInputChange({ target: { name: 'endDate', value: date } })}
                                      showTimeSelect
                                      timeFormat="HH:mm"
                                      dateFormat="yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
                                      className='text-[15px] p-[4px] forminput    w-[100%] lg:w-[258px] rounded-lg leading-none cursor-pointer'
                                    />



                                  </div>
                                </div>

                                <div className='mx-[auto] h-[41px] w-[120px]  mt-[20px]'>
                                  <button className='rounded-lg text-[white] bg-[blue] text-[17px] font-semibold h-[41px] w-[120px] '
                                    onClick={() => {
                                      Update();
                                      close();

                                    }}
                                  >
                                    Save</button></div>
                              </div>
                            )}
                          </Popup>
                          <Button
                            style={{
                              backgroundColor: 'red',
                              color: 'white',
                            }}

                            onClick={() => deleteData(transaction.giftCodeId)}
                          >
                            Delete
                          </Button>

                        </div>

                      </TableCell>

                    </TableRow>))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              count={userList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />



            <Popup
              trigger={<div >
                <button
                  // onClick={() => { handleCloseForm() }}
                  className='bg-[#7367F0] h-[40px] w-[150px] rounded-lg text-[white] text-[16px]'>
                  Add Giftcode
                </button></div>
              }
              modal
              closeOnDocumentClick
            >
              {(close) => (
                <div className='w-[300px] lg:w-[600px]  rounded-lg bg-[white] shadow p-[30px] relative'>
                  <div className='flex'>

                    <p className='text-[23px] font-medium ml-4 '>
                      Add Giftcode
                    </p>
                    <button
                      className='ml-auto h-[40px] w-[40px]'
                      onClick={close}
                    >
                      &times;
                    </button>
                  </div>

                  <div className='flex flex-col'>
                    <p className='formhead mt-[25px] '>Medium Status</p></div>
                  <select
                    className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                    value={updatedData.status}
                    onChange={handleInputChange}
                    name='status'
                  >
                    <option
                      value='Active'

                    >
                      Active
                    </option>
                    <option
                      value='InActive'

                    >
                      InActive
                    </option>
                  </select>

                  <div className='flex flex-col '>
                    <p className='formhead  mt-[25px] '>Description </p>
                    <textarea type='text'
                      className=' lg:w-[100%] h-[100px]  bg-[#F8F8F4] pl-[10px] mt-[15px]'

                      onChange={handleInputChange}
                      name='description' />


                  </div>
                  <div className='lg:flex   lg:space-x-7'>
                    <div className='flex flex-col'>
                      <p className='formhead  mt-[25px] '>Discount</p>
                      <div className='forminput   mt-3 w-[100%] lg:w-[258px] rounded-lg leading-none pl-[5px]'>
                        <input type='text' className='text-[15px] p-[4px] forminput    w-[100%] lg:w-[258px] rounded-lg leading-none '

                          onChange={handleInputChange}

                          name='discount' />

                      </div>
                    </div>

                    <div className='flex flex-col '>
                      <p className='formhead lg:mt-[20px] mt-[10px] '>Maximum Amount</p>
                      <div className='forminput   mt-3 w-[100%] lg:w-[258px] rounded-lg leading-none pl-[5px]'>
                        <input type='text' className='text-[15px] p-[4px] forminput    w-[100%] lg:w-[258px] rounded-lg leading-none '

                          onChange={handleInputChange}

                          name='maxAmount' />
                      </div>
                    </div>
                  </div>
                  <div className='lg:flex   lg:space-x-7'>
                    <div className='flex flex-col '>
                      <p className='formhead  mt-[25px] '>Start Date</p>
                      <DatePicker
                        selected={updatedData.startDate ? new Date(updatedData.startDate) : null}
                        onChange={(date) => handleInputChange({ target: { name: 'startDate', value: date } })}
                        showTimeSelect
                        timeFormat="HH:mm:ss"
                        dateFormat="yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
                        className='text-[15px] p-[4px] forminput    w-[100%] lg:w-[258px] rounded-lg leading-none cursor-pointer'
                      />
                    </div>
                    <div className='flex flex-col'>
                      <p className='formhead mt-[25px] '>End Date</p>
                      <DatePicker
                        selected={updatedData.endDate ? new Date(updatedData.endDate) : null}
                        onChange={(date) => handleInputChange({ target: { name: 'endDate', value: date } })}
                        showTimeSelect
                        timeFormat="HH:mm"
                        dateFormat="yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
                        className='text-[15px] p-[4px] forminput    w-[100%] lg:w-[258px] rounded-lg leading-none cursor-pointer'
                      />



                    </div>
                  </div>

                  <div className='mx-[auto] h-[41px] w-[120px]  mt-[20px]'>
                    <button className='rounded-lg text-[white] bg-[blue] text-[17px] font-semibold h-[41px] w-[120px] '
                      onClick={() => {
                        addUserDetails();
                        close();

                      }}
                    >
                      Add</button></div>
                </div>
              )}
            </Popup>
          </div>
        </Grid>
      </Grid >

    </>
  )
}

export default Giftcode;