import React, { useEffect, useState } from 'react';
import Popup6 from '../components/Popup6';
import PopupFrom from '../components/PopupFrom';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import { Bar, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';
import { BarChart } from "recharts";
import { AccessTime, AttachMoney, Money, MoneyOff } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Chart from 'react-apexcharts';

const SecondComp = () => {

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 4,
        width: 100,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? '#8467D7' : '#8467D7',
        },
    }));
    const BorderLinearProgress1 = styled(LinearProgress)(({ theme }) => ({
        height: 4,
        width: 100,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? '#79BAEC' : '#79BAEC',
        },
    }));
    const BorderLinearProgress2 = styled(LinearProgress)(({ theme }) => ({
        height: 4,
        width: 100,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? '#C11B17' : '#C11B17',
        },
    }));


    const options = {
        chart: {
            height: 360,
            type: "radialBar",
        },
        series: [67],
        colors: ["#20E647"],
        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 0,
                    size: "70%",
                    background: "#293450"
                },
                track: {
                    dropShadow: {
                        enabled: true,
                        top: 2,
                        left: 0,
                        blur: 4,
                        opacity: 0.15
                    }
                },
                dataLabels: {
                    name: {
                        offsetY: -10,
                        color: "#fff",
                        fontSize: "13px"
                    },
                    value: {
                        color: "#fff",
                        fontSize: "30px",
                        show: true
                    }
                }
            }
        },
        fill: {
            type: "gradient",
            gradient: {
                shade: "dark",
                type: "vertical",
                gradientToColors: ["#87D4F9"],
                stops: [0, 100]
            }
        },
        stroke: {
            lineCap: "round"
        },
        labels: ["Progress"]
    };

    useEffect(() => {
        updateChartWidth();
        window.addEventListener('resize', updateChartWidth);
        return () => window.removeEventListener('resize', updateChartWidth);
    }, []);

    const Data = [
        { name: 'Mon', Expense: 500 },
        { name: 'Tue', Expense: 1000 },
        { name: 'Wed', Expense: 1500 },
        { name: 'Thu', Expense: 4500 },
        { name: 'Fri', Expense: 6000 },
        { name: 'Sat', Expense: 5000 },
        { name: 'Sun', Expense: 3000 },
    ];

    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleChange = (event) => {
        setAuth(event.target.checked);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [chartWidth, setChartWidth] = useState(420); // Initial width

    const updateChartWidth = () => {
        const width = document.getElementById('chart-container').offsetWidth;
        setChartWidth(width);
    };

    useEffect(() => {
        updateChartWidth();
        window.addEventListener('resize', updateChartWidth);
        return () => window.removeEventListener('resize', updateChartWidth);
    }, []);

    return (
        <div className='mt-[30px]'>
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-7'>
                <div className='lg:flex flex-col rounded-[12px] shadow-regCardShadow bg-[white]'>
                    <div className='w-full sm:w-[583px] relative p-[10px]  lg:flex '>
                        <div className=' '>
                            {/* <div className='flex flex-wrap p-[30px] gap-[30px] shadow-regCardShadow max-w-[1168px] mt-[18px] w-[100%] mx-auto rounded-[32px]'> */}

                            <p className='text-[20px] font-medium sm:text-base'>Earning Report</p>
                            <p className='text-primaryGray text-[16px] sm:text-xs'>Weekly Earnings Overview</p>
                            <p className='mt-[32px] sm:mt-[16px] text-[38px] sm:text-[38px] font-semibold'>$468</p>
                            <button className='w-[73px] sm:w-[60px] h-[27px] sm:h-[32px] bg-[#DBE9FA] rounded-[10px]'>
                                <span className='text-[#B0CFDE]'>+4.2%</span>
                            </button>
                            <p className='text-primaryGray text-[15px] mt-[8px]'>You informed of this  week compared to last week</p>
                        </div>
                        <div className='w-full sm:w-[417px]'>
                            {auth && (
                                <div className='ml-auto lg:ml-[350px]'>
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleMenu}
                                        color="inherit"
                                    >
                                        <MoreIcon />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{ horizontal: 'left' }}
                                        keepMounted
                                        transformOrigin={{ horizontal: 'left' }}
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        <MenuItem onClick={handleClose}>View More</MenuItem>
                                        <MenuItem onClick={handleClose}>Delete</MenuItem>
                                    </Menu>
                                </div>
                            )}
                            <div className='w-full lg:w-[417px]'>
                                {/* Menu and Chart */}
                                <div id="chart-container" className=''>
                                    <BarChart
                                        width={chartWidth} // Set width dynamically
                                        height={220}
                                        data={Data}
                                        margin={{ top: 10, right: 30, left: 30, bottom: 5 }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                                        <XAxis dataKey="name" tickLine={false} axisLine={false} />
                                        <Tooltip />
                                        <Bar dataKey="Expense" barSize={25} fill="#00FFFF" />
                                    </BarChart>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='lg:flex-col w-full p-[10px] mb-[20px] border border-[#E0E5E5] lg:w-[520px] '>
                        <div className='grid grid-cols-1 lg:grid-cols-3 gap-4'>
                            <div className=''>
                                <div className='lg:w-[30px] w-[40px] lg:h-[30px] h-[40px]  ml-[10px] relative rounded-[4px] bg-[#C8C4DF] flex'>
                                    <AttachMoney style={{ color: '#8467D7', marginLeft: 4, marginTop: 3 }} />
                                    <p className='text-[22px] ml-[16px]  text-[#6D6968] font-medium'>Earnings</p>
                                </div>
                                <p className='ml-[10px] text-[28px]   text-[#3A3B3C] font-semibold'>$545.69</p>
                                <BorderLinearProgress variant="determinate" value={80} />
                            </div>

                            <div className=''>
                                {/* Profit */}
                                <div className='lg:w-[30px] w-[40px] lg:h-[30px] h-[40px] lg:ml-[10px] ml-[10px] relative rounded-[4px] bg-[#BDEDFF] flex'>
                                    <AccessTime style={{ color: '#79BAEC', marginLeft: 4, marginTop: 3 }} />
                                    <p className='text-[22px]  ml-[16px]  text-[#6D6968] font-medium'>Profit</p>
                                </div>
                                <p className='ml-[10px]  text-[28px]  sm:text-[30px] text-[#3A3B3C] font-semibold'>$256.34</p>
                                <BorderLinearProgress1 variant="determinate" value={50} />
                            </div>

                            <div className=''>
                                {/* Expense */}
                                <div className='lg:w-[30px] w-[40px] lg:h-[30px] h-[40px]  ml-[10px] relative rounded-[4px] bg-[#FFDFDD] flex'>
                                    <MoneyOff style={{ color: '#C11B17', marginTop: 3 }} />
                                    <p className='text-[22px]   ml-[16px]  text-[#6D6968] font-medium'>Expense</p>
                                </div>
                                <p className='ml-[10px]  text-[28px]   text-[#3A3B3C] font-semibold'>$74.19</p>
                                <BorderLinearProgress2 variant="determinate" value={60} color1="#00FF00" color2="#0000FF" />
                            </div>
                        </div>
                    </div>

                </div>
                <div className='lg:flex rounded-[12px] gap-4 p-[10px] shadow-regCardShadow bg-[white]'>

                    <div className=' w-[160px] relative'>


                        <p className='text-[20px] font-medium sm:text-base'>Support Tracker</p>
                        <p className='text-primaryGray text-[16px] sm:text-xs'>Last 7 Days</p>
                        <p className='mt-[32px]  text-[38px]  font-semibold'>168</p>
                        <p className='text-[black] text-[16px] '>Total Tickets</p>

                        <div className='flex'>
                            <div className='lw-[40px]  h-[40px]  mt-[20px] relative rounded-[4px] bg-[#C8C4DF] flex p-[6px]' >
                                <AttachMoney style={{ color: '#8467D7' }} /></div>
                            <div className='flex-col leading-[20px] '>
                                <p className='text-[18px]  text-[#6D6968] font-medium mt-[20px] ml-[16px]' >New Ticket</p>
                                <p className='text-primaryGray text-[16px] ml-[16px]'>142</p></div>
                        </div>
                        <div className='flex'>
                            <div className='lw-[40px]  h-[40px]  mt-[20px] relative rounded-[4px] bg-[#BDEDFF] flex p-[6px]' >
                                <AccessTime style={{ color: '#79BAEC' }} />  </div>

                            <div className='flex-col leading-[20px] '>
                                <p className='text-[18px]  text-[#6D6968] font-medium mt-[20px] ml-[16px]' >Open Ticket</p>
                                <p className='text-primaryGray text-[16px] ml-[16px]'>142</p></div>
                        </div>

                        <div className='flex'>
                            <div className='lw-[40px]  h-[40px]  mt-[20px] relative rounded-[4px] bg-[#FFDFDD]  flex p-[6px]' >
                                <MoneyOff style={{ color: '#C11B17' }} /></div>
                            <div className='flex-col leading-[20px] '>
                                <p className='text-[18px]  text-[#6D6968] font-medium mt-[20px] ml-[16px]' >Response </p>
                                <p className='text-primaryGray text-[16px] ml-[16px]'>28</p></div>
                        </div>

                    </div>

                    <div className=''>
                        {auth && (
                            <div className='ml-auto lg:ml-[350px]'>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                >
                                    <MoreIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{ horizontal: 'left' }}
                                    keepMounted
                                    transformOrigin={{ horizontal: 'left' }}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={handleClose}>View More</MenuItem>
                                    <MenuItem onClick={handleClose}>Delete</MenuItem>
                                </Menu>
                            </div>
                        )}
                        <Chart
                            options={options}
                            series={[67]}
                            type="radialBar"
                            height={360}
                        />
                    </div>

                </div>
            </div>
        </div>
    );
};


export default SecondComp