import React from 'react'
import FirstComp from './FirstComp'
import SecondComp from './SecondComp'

const DashboardMain = () => {
  return (
   <>
   <FirstComp/>
   <SecondComp/>
   </>
  )
}

export default DashboardMain