import React from 'react'
import { httpClient } from '../../axios';
import { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';


function Keyword() {
  const [addedKeywords, setAddedKeywords] = useState([]);

  const [keywords, setKeywords] = useState([]);
  const [textWord, setTextWord] = useState('');

  const keywordText = (e) => {
    const text = e.target.value;
    setTextWord(text);
  };



  const addKeyword = () => {
    if (textWord.trim() !== '') {
      setAddedKeywords((prevKeywords) => [...prevKeywords, textWord.trim()]);
      setTextWord('');
    }
  };

  const getkeyword = async () => {
    const res = await httpClient.get('/Keywords_Master');
    setKeywords(res.data);
    console.log(res.data)

  }


  useEffect(() => {
    getkeyword();
  }, []);

  const addUserDetails = async () => {
    try {
      const res = await httpClient.post('/Keywords_Master', {
        keywordId: "string",
        keywords: addedKeywords,
      });
      console.log(res.data);
      getkeyword(res.data);
    } catch (error) {
      console.error("Error adding keywords:", error);
    }
  };
  const deleteKeyword = (keyword) => {
    setAddedKeywords((prevKeywords) => prevKeywords.filter((kw) => kw !== keyword));
  };

  useEffect(() => {
    getkeyword();
  }, []);

  const deletkey = async (keyword) => {
    const sureDelete = window.confirm("Are you sure to delete this keyword?");
    if (sureDelete) {
      try {
        const res = await httpClient.delete(`/Keywords_Master/deleteByKeyword/${keyword}`);
        console.log(res.data);
        console.log('delete keyword');
        getkeyword();
      } catch (error) {
        console.error("Error deleting keyword:", error);
      }
    }
  };

  return (
    <>
      <p className='text-[25px] pl-[30px] font-bold mt-[40px]'>Keyword </p>

      <div className='w-[100%] pageMainDiv flex gap-[10px] flex-wrap pt-[30px] '>

        {keywords.map((obj) => (

          <div key={obj}  className='text-[16px] flex items-center text-primaryGray leading-[1] border border-[#dddddd] rounded-[5000px] px-[16px] h-[38px] relative'>

              {obj}
            <span
              onClick={() => {deletkey(obj);
              }}
              className='text-[15px] text-primaryGray cursor-pointer absolute top-0 right-0 p-[4px]'
            >
              x
            </span>
          </div>
        ))
        }


        <Popup
          trigger={<button className="button  rounded-lg lg:ml-[150px]  ml-[50px] mt-10 lg:w-[180px] w-[100px] h-[50px] bg-[blue]  font-bold  text-[white] "> Add New Keyword </button>}

          modal
          closeOnDocumentClick>
          {close => (
            <div className="  lg:w-[500px] w-[540px] lg:h-[230px] p-[20px] h-[300px] bg-[white] shadow rounded   ">
              <p className='text-[15px] text-primaryBlack font-medium'>
                Add Keywords
              </p>
              <div className='pt-[10px]'>
<div className='w-[390px] h-[50px] border text-[16px] border-[#d6d6d6] pl-[4px] rounded-[19px]  outline-none'>
                <input
                  type='text'
                  className='outline-none w-[310px] p-[4px] mt-[5px]'
                  placeholder='Enter Keywords'
                  onChange={keywordText}
                  value={textWord}
                />
                <button
                  onClick={addKeyword}
                  className='w-[70px] h-[35px] bg-[#eeeeee] text-primaryGray text-[16px] rounded-[15px] relative mt-[8px] bottom-[2px]'
                >
                  Add
                </button>
                </div>
                <div className='flex flex-wrap gap-[5px] mt-[10px]'>
                  {addedKeywords.map((keyword) => (
                    <div key={keyword} className='text-[16px] text-primaryGray border border-[#dddddd] rounded-[13px] px-[8px] h-[26px]'>
                      {keyword} &nbsp;&nbsp;
                      <span
                        onClick={() => {
                          deleteKeyword(keyword);
                        }}
                        className='text-[15px] text-primaryGray cursor-pointer'
                      >
                        x
                      </span>


                    </div>

                  ))}
                </div>
              </div>
              <button className='h-[40px] rounded w-[110px] mx-[auto] mt-[20px] ml-[180px] bg-[#1F45FC] text-[white] '
                onClick={() => { addUserDetails(); close(); }}
              // endIcon={<SendIcon />}  
              >
                Add Record
              </button>
            </div>
          )}
        </Popup>
      </div>    </>

  )
}

export default Keyword;