import { Table, TableCell, TableContainer, TableHead, TableRow, tableCellClasses, styled, TableBody, Button, TablePagination, Grid } from '@mui/material'
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { httpClient } from '../axios';
import Popup from 'reactjs-popup';
import star from '../Review-Master/star.png';
function Review() {
  const [maping, setMapping] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [reviewObj, setreviewObj] = useState({
    customerReviewId: "",
    reviewMsg: "",
    reviewTitle: "",
    status: "",
    reviewStar: 0,
    reviewImage: [
      ""
    ],
    artId: "",
    userId: ""
  });
  const updateReviewInActive = (obj) => {
    setreviewObj({
      ...reviewObj, customerReviewId: obj.customerReviewId, reviewMsg: obj.reviewMsg, reviewTitle: obj.reviewTitle, status: 'InActive',
      reviewStar: obj.reviewStar, reviewImage: [obj.reviewImage], artId: obj.artMaster.artId, userId: obj.userMaster.userId
    })
  }
  const updateReviewActive = (obj) => {
    setreviewObj({
      ...reviewObj, customerReviewId: obj.customerReviewId, reviewMsg: obj.reviewMsg, reviewTitle: obj.reviewTitle, status: 'Active',
      reviewStar: obj.reviewStar, reviewImage: [obj.reviewImage], artId: obj.artMaster.artId, userId: obj.userMaster.userId
    })
  }
  useEffect(() => {
    // console.log(reviewObj);
    UpdatedStatus();
  }, [reviewObj]);



  const filteredData = maping.filter(review =>
    review.reviewMsg.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSearch = event => {
    setSearchQuery(event.target.value);
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'rgb(115 103 240)',
        color: theme.palette.common.white,
        fontSize: 20,
    },
}));
  const getSociMedia = async () => {
    try {
      const res = await httpClient.get('/customer_review_master/');
      console.log(res.data);
      setMapping(res.data);
    } catch (error) {
      console.error('Error fetching social media:', error);
    }
  };
  useEffect(() => {
    getSociMedia();
  }, []);



  const UpdatedStatus = async () => {
    try {
      console.log(reviewObj);
      await httpClient.put('/customer_review_master/update/', reviewObj);

      getSociMedia();
    } catch (error) {
      console.error('Error updating record:', error);
    }
  };

  const StarRating = ({ count }) => {
    const starImages = [];
    for (let i = 0; i < count; i++) {
      starImages.push(<img key={i} src={star} alt="star" width="30" height="30" />);
    }
    return starImages;
  };

  return (
    <Grid  justifyContent="center">
      <Grid item xs={12} md={10} lg={8} >
        <div className='  pageMainDiv'>
        <p className='text-[25px] font-bold mt-[40px]'>Reviews</p>
          <input
            type="text"
            placeholder="Search Review Msg"
            value={searchQuery}
            onChange={handleSearch}
            className=' h-[50px] rounded-md border border-[#ABB2B9] pl-4 w-[100%]'
          />
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table  stickyHeader size="small" className='mt-4 border border-[gray] w-[100%]'   >
              <TableHead>
                <TableRow>
                  <StyledTableCell>Review Msg</StyledTableCell>
                  <StyledTableCell align='center'>Review Star</StyledTableCell>
                  <StyledTableCell align='center'>Review Image</StyledTableCell>
                  <StyledTableCell align='center'>Status</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((review, index) => (
                    <TableRow key={review.customerReviewId}>

                      <TableCell >
                        <span>{review.reviewMsg}</span></TableCell>
                      <TableCell align='center'>
                        <div className='flex'>
                          <StarRating count={review.reviewStar} />
                        </div>
                      </TableCell >

                      <TableCell align='center' >
                        <div className=''>
                          <Popup
                            trigger={<div className=''>
                              <Button align="center" style={{ backgroundColor: 'orange', color: 'white' }} className='w-[80px] ' >Image</Button>
                            </div>}
                            modal
                            nested
                          >
                            <img src={review.reviewImage} className='h-[200px] w-[200px] rounded-[4px] ' />
                          </Popup>
                        </div>
                      </TableCell>
                      <TableCell align='center'>
                        {
                          review.status === 'Active' ? (
                            <Button onClick={() => updateReviewInActive(review)}
                              style={{ backgroundColor: 'green', color: 'white' }}>Active</Button>
                          ) : (
                            <Button onClick={() => updateReviewActive(review)}
                              style={{ backgroundColor: 'yellow', color: 'black' }} >InActive</Button>
                          )
                        }
                      </TableCell>

                    </TableRow>))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={maping.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Grid>
    </Grid>
  )
}
export default Review