import { Grid, Table, TableCell, TableContainer, TableHead, styled, TableRow, tableCellClasses, TableBody, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { httpClient } from '../../axios';
import Popup from 'reactjs-popup';

const Discount = () => {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: 'rgb(115 103 240)',
            color: theme.palette.common.white,
            fontSize: 20,

        },

    }));

    const [getDiscount, setDiscount] = useState([]);
    const [updatedData, setUpdatedData] = useState({

        firstDiscount: 0,
        seasonalDiscount: 0,
        festivalDiscount: 0,
        seasonalDiscountStatus: true,
        festivalDiscountStatus: true,
        status: ""
    });

    const getuser = async () => {
        try {
            const res = await httpClient.get('/Discount_Master');
            if (res) {
                setDiscount(res.data);
                console.log(res.data);
            }
        }
        catch {
            console.log('error')
        }
    }

    const handleInputChange = (e) => {
        setUpdatedData({
            ...updatedData,
            [e.target.name]: e.target.value,
        });
    };



    const addDiscount = async () => {

        const res = await httpClient.post('/Discount_Master', { ...updatedData }
        );
        console.log(res.data);
        getuser();
    };

    const UpdateDiscount = async () => {
        const res = await httpClient.put('/Discount_Master',
            { ...updatedData }
        );
        getuser();
        console.log(res.data);

    };

    useEffect(() => {
        getuser();
    }, [])


    const deleteData = async (discountId) => {
        const sureDelete = window.confirm("Are You Sure The Delete Record..?");
        if (sureDelete) {
            const res = await httpClient.delete(
                `/Discount_Master/deleteByDiscountId/${discountId}`
            );
            console.log(res.data);
            getuser();
        }
    };
    return (
        <Grid justifyContent='center'>
            <Grid item xs={12} md={10} lg={8} >

                <div className='pageMainDiv '>
                    <p className='lg:text-[25px] text-[16px] pb-[20px] font-semibold mt-[40px]'>
                        Discount Master
                    </p>


                    <TableContainer style={{ height: '400px' }}  >
                        <Table sx={{ minWidth: 650 }} className='border border-[#CECECE] ' stickyHeader>
                            <TableHead>
                                <TableRow >
                                    <StyledTableCell>First Discount</StyledTableCell>
                                    <StyledTableCell align='center'>Seasonal Discount</StyledTableCell>
                                    <StyledTableCell align='center'>Festival Discount</StyledTableCell>
                                    <StyledTableCell align='center'>Seasonal Discount Status</StyledTableCell>
                                    <StyledTableCell align='center'>Festival Discount Status</StyledTableCell>
                                    <StyledTableCell align='center'>Status</StyledTableCell>
                                    <StyledTableCell align='center'>Date</StyledTableCell>
                                    <StyledTableCell align='center'>Action</StyledTableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {getDiscount.map((discount, index) => (
                                    <TableRow key={index}>
                                        <TableCell align='center'>
                                            <span className='text-[16px] text-black'>
                                                {discount.firstDiscount}
                                            </span>
                                        </TableCell>

                                        <TableCell align='center'>
                                            <span className='text-[16px] text-black'>
                                                {discount.seasonalDiscount}
                                            </span>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <span className='text-[16px] text-black'>
                                                {discount.festivalDiscount}
                                            </span>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <span className='text-[16px] text-black'>
                                                {discount?.seasonalDiscountStatus ? 'true' : 'false'}
                                            </span>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <span className='text-[16px] text-black  '>
                                                {discount?.festivalDiscountStatus ? 'true' : 'false'}
                                            </span>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <span className='text-[16px] text-center text-black'>
                                                {discount?.status}
                                            </span>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <span className='text-[16px] text-black'>
                                                {discount?.date}
                                            </span>
                                        </TableCell>

                                        <TableCell align='center'>
                                        <div className='flex space-x-2 mx-auto'>

                                            <Popup
                                                trigger={<div >
                                                    <Button

                                                        style={{
                                                            backgroundColor: 'orange',
                                                            color: 'white',
                                                        }}
                                                        onClick={() => {
                                                            setUpdatedData(discount);
                                                            // handleCloseForm();
                                                        }}
                                                    >
                                                        Update
                                                    </Button></div>
                                                }
                                                modal
                                                closeOnDocumentClick
                                            >
                                                {(close) => (
                                                    <div className='w-[300px] lg:w-[600px]  rounded-lg bg-[white] shadow p-[30px] relative'>
                                                        <div className='flex'>

                                                            <p className='text-[23px] font-medium ml-4 '>
                                                                Add Discount
                                                            </p>
                                                            <button
                                                                className='ml-auto h-[40px] w-[40px]'
                                                                onClick={close}
                                                            >
                                                                &times;
                                                            </button>
                                                        </div>

                                                        <div className='lg:flex   lg:space-x-7'>
                                                            <div className='flex flex-col '>
                                                                <p className='formhead  mt-[25px] '>First Discount</p>
                                                                <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                                    value={updatedData.firstDiscount}
                                                                    onChange={handleInputChange}
                                                                    name='firstDiscount' />

                                                            </div>

                                                            <div className='flex flex-col '>
                                                                <p className='formhead  mt-[25px] '>Seasonal Discount</p>
                                                                <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                                    value={updatedData.seasonalDiscount}
                                                                    onChange={handleInputChange}
                                                                    name='seasonalDiscount' />
                                                            </div>

                                                        </div>

                                                        <div className='lg:flex   lg:space-x-7'>

                                                            <div className='flex flex-col '>
                                                                <p className='formhead  mt-[25px] '>Festival Discount</p>
                                                                <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                                    value={updatedData.festivalDiscount}
                                                                    onChange={handleInputChange}
                                                                    name='festivalDiscount' />
                                                            </div>
                                                            <div className='flex flex-col'>
                                                                <p className='formhead mt-[25px] '>Seasonal Discount Status</p>
                                                                {/* <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                                 onChange={handleInputChange}
                                                                  name='seasonalDiscountStatus' /> */}


                                                                <select
                                                                    className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                                    value={updatedData.seasonalDiscountStatus}
                                                                    onChange={handleInputChange}
                                                                    name='seasonalDiscountStatus'
                                                                >
                                                                    <option
                                                                        value='true'

                                                                    >
                                                                        true
                                                                    </option>
                                                                    <option
                                                                        value='false'

                                                                    >
                                                                        false
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className='lg:flex   lg:space-x-7'>

                                                            <div className='flex flex-col'>
                                                                <p className='formhead mt-[25px] '>festival Discount Status</p>
                                                                {/* <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                // onChange={handleInputChange}
                                                name='festivalDiscountStatus' /> */}


                                                                <select
                                                                    className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                                    value={updatedData.festivalDiscountStatus}
                                                                    onChange={handleInputChange}
                                                                    name='festivalDiscountStatus'
                                                                >
                                                                    <option
                                                                        value='true'

                                                                    >
                                                                        true
                                                                    </option>
                                                                    <option
                                                                        value='false'

                                                                    >
                                                                        false
                                                                    </option>
                                                                </select>
                                                            </div>

                                                            <div className='flex flex-col'>
                                                                <p className='formhead mt-[25px] '> Status</p>
                                                                {/* <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                // onChange={handleInputChange}
                                                name='status' /> */}


                                                                <select
                                                                    className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                                    value={updatedData.status}
                                                                    onChange={handleInputChange}
                                                                    name='status'
                                                                >
                                                                    <option
                                                                        value='Active'

                                                                    >
                                                                        Active
                                                                    </option>
                                                                    <option
                                                                        value='InActive'

                                                                    >
                                                                        InActive
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='mx-[auto] h-[41px] w-[120px]  mt-[20px]'>
                                                            <button className='rounded-lg text-[white] bg-[blue] text-[17px] font-semibold h-[41px] w-[120px] '
                                                                onClick={() => {
                                                                    UpdateDiscount();
                                                                    close();
                                                                    // handleCloseForm();
                                                                }}
                                                            >
                                                                Save</button></div>
                                                    </div>
                                                )}
                                            </Popup>

                                            <Button
                                                onClick={() =>
                                                    deleteData(discount.discountId)
                                                }
                                                style={{
                                                    backgroundColor: 'red',
                                                    color: 'white',
                                                }}
                                            >
                                                Delete
                                            </Button>
</div>
                                        </TableCell>

                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>


                    <Popup
                        trigger={<div >
                            <button
                                // onClick={() => { handleCloseForm() }}
                                className='bg-[#7367F0] mt-[20px]  h-[40px] w-[150px] rounded-lg text-[white] text-[16px]'>
                                Add Discount
                            </button></div>
                        }
                        modal
                        closeOnDocumentClick
                    >
                        {(close) => (
                            <div className='w-[300px] lg:w-[600px]  rounded-lg bg-[white] shadow p-[30px] relative'>
                                <div className='flex'>

                                    <p className='text-[23px] font-medium ml-4 '>
                                        Add Discount
                                    </p>
                                    <button
                                        className='ml-auto h-[40px] w-[40px]'
                                        onClick={close}
                                    >
                                        &times;
                                    </button>
                                </div>

                                <div className='lg:flex   lg:space-x-7'>
                                    <div className='flex flex-col '>
                                        <p className='formhead  mt-[25px] '>First Discount</p>
                                        <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                            onChange={handleInputChange}
                                            name='firstDiscount' />
                                    </div>

                                    <div className='flex flex-col '>
                                        <p className='formhead  mt-[25px] '>Seasonal Discount</p>
                                        <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                            onChange={handleInputChange}
                                            name='seasonalDiscount' />
                                    </div>

                                </div>

                                <div className='lg:flex   lg:space-x-7'>

                                    <div className='flex flex-col '>
                                        <p className='formhead  mt-[25px] '>Festival Discount</p>
                                        <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                            onChange={handleInputChange}
                                            name='festivalDiscount' />
                                    </div>
                                    <div className='flex flex-col'>
                                        <p className='formhead mt-[25px] '>Seasonal Discount Status</p>
                                        {/* <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                onChange={handleInputChange}
                                                name='seasonalDiscountStatus' /> */}


                                        <select
                                            className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                            value={updatedData.seasonalDiscountStatus}
                                            onChange={handleInputChange}
                                            name='seasonalDiscountStatus'
                                        >
                                            <option
                                                value='true'

                                            >
                                                true
                                            </option>
                                            <option
                                                value='false'

                                            >
                                                false
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div className='lg:flex   lg:space-x-7'>

                                    <div className='flex flex-col'>
                                        <p className='formhead mt-[25px] '>festival Discount Status</p>
                                        {/* <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                // onChange={handleInputChange}
                                                name='festivalDiscountStatus' /> */}


                                        <select
                                            className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                            value={updatedData.festivalDiscountStatus}
                                            onChange={handleInputChange}
                                            name='festivalDiscountStatus'
                                        >
                                            <option
                                                value='true'

                                            >
                                                true
                                            </option>
                                            <option
                                                value='false'

                                            >
                                                false
                                            </option>
                                        </select>
                                    </div>

                                    <div className='flex flex-col'>
                                        <p className='formhead mt-[25px] '> Status</p>
                                        {/* <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                // onChange={handleInputChange}
                                                name='status' /> */}


                                        <select
                                            className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                            value={updatedData.status}
                                            onChange={handleInputChange}
                                            name='status'
                                        >
                                            <option
                                                value='Active'

                                            >
                                                Active
                                            </option>
                                            <option
                                                value='InActive'

                                            >
                                                InActive
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className='mx-[auto] h-[41px] w-[120px]  mt-[20px]'>
                                    <button className='rounded-lg text-[white] bg-[blue] text-[17px] font-semibold h-[41px] w-[120px] '
                                        onClick={() => {
                                            addDiscount();
                                            close();
                                            // handleCloseForm();
                                        }}
                                    >
                                        Add Medium</button></div>
                            </div>
                        )}
                    </Popup>

                </div>
            </Grid>
        </Grid>
    )
}

export default Discount