import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  styled,
  tableCellClasses,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { httpClient } from '../../axios';

const MediumMaster = () => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'rgb(115 103 240)',
      color: theme.palette.common.white,
      fontSize: 20,
    },
  }));

  const [medium, setMedium] = useState([]);
  const [mediumObj, setMediumObj] = useState({
    artDropDown: '',
    mediumImage: '',
    mediumDescription: '',
    mediumType: '',
    mediumStatus: '',
  });
  const [imageName, setImageName] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const fileInputRef = useRef(null);
  const getMedium = async () => {
    const res = await httpClient.get('/medium_Master');
    // .then((res) => {
    console.log(res.data);
    setMedium(res.data);
    // });
  };
  useEffect(() => {
    getMedium();
  }, []);

  const handleInputChange = (e) => {
    setMediumObj({
      ...mediumObj,
      [e.target.name]: e.target.value,
    });
  };

  const addMedium = async () => {
    const res = await httpClient.post(
      '/medium_Master/createMediumMaster',
      { ...mediumObj }
    );
    console.log(res.data);
    getMedium();
  };

  const handleFileChange = async (event, linkNumber) => {
    const img = event.target.files[0];
    if (img) {
      let formData = new FormData();
      formData.append('file', img);
      const res = await httpClient.post(
        '/CloudinaryImageUpload?parameter=false',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setImageName(img.name);
      console.log(res.data);
      setMediumObj({ ...mediumObj, mediumImage: res.data });
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const resetImageName = () => {
    setImageName('');
  };
  const handleCloseForm = () => {
    resetImageName();
    console.log('........');
  };

  const UpdateMedium = async () => {
    const res = await httpClient.put(
      '/medium_Master/updateMediumMaster',
      { ...mediumObj }
    );
    getMedium();
    console.log(res.data);
  };

  const deleteData = async (mediumId) => {
    const sureDelete = window.confirm(
      'Are You Sure The Delete Record..?'
    );
    if (sureDelete) {
      const res = await httpClient.delete(
        `/medium_Master/deleteMediumMaster/${mediumId}`
      );
      console.log(res.data);
      getMedium();
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Grid justifyContent='center'>
        <Grid item xs={12} md={10} lg={8}>
          <div className='pageMainDiv mt-[20px]'>
            <p className='text-[25px] font-bold mt-[40px]'> Medium</p>
            <TableContainer className='h-[500px] mt-[20px] '>
              <Table
                size='small'
                className='border border-[#CECECE] '
                stickyHeader
              >
                <TableHead>
                  <TableRow className='border border-[#CECECE] '>
                    <StyledTableCell>Medium Id</StyledTableCell>
                    <StyledTableCell align='center'>
                      Art Dropdown
                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      Medium Image
                    </StyledTableCell>
                    <StyledTableCell align='left'>
                      Medium Description
                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      Medium Type
                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      Medium Status
                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      Action
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {medium
                    .slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((medi, index) => (
                      <tr key={medi.mediumId}>
                        <TableCell>
                          {' '}
                          <span className='text-[16px]'>
                            {medi.mediumId}
                          </span>
                        </TableCell>

                        <TableCell align='center'>
                          {' '}
                          <span className='text-[16px]'>
                            {medi?.artDropDown ? 'true' : 'false'}
                          </span>
                        </TableCell>
                        <TableCell align='center'>
                          <div>
                            <Popup
                              trigger={
                                <div className='text-center'>
                                  <Button
                                    style={{
                                      backgroundColor: 'orange',
                                      color: 'white',
                                    }}
                                  >
                                    Image
                                  </Button>
                                </div>
                              }
                              modal
                              closeOnDocumentClick
                            >
                              {(close) => (
                                <div className='w-[300px] lg:w-[400px] lg:h-[350px] h-[250px] rounded-lg  bg-[white] shadow'>
                                  <img
                                    src={medi.mediumImage}
                                    className='w-[100%] h-[100%]'
                                  />
                                </div>
                              )}
                            </Popup>
                          </div>
                        </TableCell>
                        <TableCell align='left'>
                          {' '}
                          <span className='text-[16px] '>
                            {medi?.mediumDescription}
                          </span>
                        </TableCell>
                        <TableCell align='center'>
                          {' '}
                          <span className='text-[16px]'>
                            {medi.mediumType}
                          </span>
                        </TableCell>
                        <TableCell align='center'>
                          {' '}
                          <span className='text-[16px]'>
                            {medi.mediumStatus}
                          </span>
                        </TableCell>
                        <TableCell>
                          <div>
                            <div className='flex space-x-2 mx-auto'>
                              <Popup
                                trigger={
                                  <div className='text-center'>
                                    <Button
                                      style={{
                                        backgroundColor: 'orange',
                                        color: 'white',
                                      }}
                                      onClick={() => {
                                        setMediumObj(medi);
                                        handleCloseForm();
                                      }}
                                    >
                                      Update
                                    </Button>
                                  </div>
                                }
                                modal
                                closeOnDocumentClick
                              >
                                {(close) => (
                                  <div className='w-[300px] lg:w-[600px]  rounded-lg bg-[white] shadow p-[30px] relative'>
                                    <div className='flex'>
                                      <p className='text-[23px] font-medium ml-4 '>
                                        Update Medium
                                      </p>
                                      <button
                                        className='ml-auto h-[40px] w-[40px]'
                                        onClick={close}
                                      >
                                        &times;
                                      </button>
                                    </div>

                                    <div className='lg:flex   lg:space-x-7'>
                                      <div className='flex flex-col '>
                                        <p className='formhead  mt-[25px] '>
                                          Art Dropdown
                                        </p>
                                        {/* <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                                                onChange={handleInputChange}
                                                                                name='artDropDown'
                                                                                value={dropDown} /> */}
                                        <select
                                          className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                          value={
                                            mediumObj.artDropDown
                                          }
                                          onChange={handleInputChange}
                                          name='artDropDown'
                                        >
                                          <option value='true'>
                                            true
                                          </option>
                                          <option value='false'>
                                            false
                                          </option>
                                        </select>
                                      </div>
                                      <div className='flex flex-col'>
                                        <p className='formhead  mt-[25px] '>
                                          Medium Image
                                        </p>
                                        <div className='forminput text-[20px] mt-3 w-[100%] lg:w-[258px] rounded-lg leading-none pl-[5px]'>
                                          <input
                                            type='file'
                                            style={{
                                              display: 'none',
                                            }}
                                            accept='image/*'
                                            onChange={(e) => {
                                              handleFileChange(
                                                e,
                                                index
                                              );
                                            }}
                                            ref={fileInputRef}
                                            name='mediumImage'
                                            multiple
                                          />

                                          {imageName ? (
                                            <span>{imageName}</span>
                                          ) : (
                                            <a
                                              className='cursor-pointer text-[#808080]  '
                                              onClick={
                                                handleButtonClick
                                              }
                                            >
                                              Img {index + 1}
                                            </a>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className='flex flex-col '>
                                      <p className='formhead lg:mt-[20px] mt-[10px] '>
                                        Medium Description
                                      </p>
                                      <textarea
                                        type='text'
                                        className=' lg:w-[100%] h-[100px]  bg-[#F8F8F4] pl-[10px] mt-[15px]'
                                        value={
                                          mediumObj.mediumDescription
                                        }
                                        name='mediumDescription'
                                        onChange={handleInputChange}
                                      />

                                      <div className='lg:flex   lg:space-x-7'>
                                        <div className='flex flex-col '>
                                          <p className='formhead  mt-[25px] '>
                                            Medium Type
                                          </p>
                                          <input
                                            type='text'
                                            className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                            onChange={
                                              handleInputChange
                                            }
                                            name='mediumType'
                                            value={
                                              mediumObj.mediumType
                                            }
                                          />
                                        </div>
                                        <div className='flex flex-col'>
                                          <p className='formhead mt-[25px] '>
                                            Medium Status
                                          </p>
                                          {/* <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                                                    onChange={handleInputChange}
                                                                                    name='mediumStatus'
                                                                                    value={mediumObj.mediumStatus}
                                                                                /> */}
                                          <select
                                            className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                            value={
                                              mediumObj.mediumStatus
                                            }
                                            onChange={
                                              handleInputChange
                                            }
                                            name='mediumStatus'
                                          >
                                            <option value='Active'>
                                              Active
                                            </option>
                                            <option value='InActive'>
                                              InActive
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='mx-[auto] h-[41px] w-[150px]  mt-[20px]'>
                                      <button
                                        className='rounded-lg text-[white] bg-[blue] text-[17px] font-semibold h-[41px] w-[150px] '
                                        onClick={() => {
                                          UpdateMedium();
                                          handleCloseForm();
                                          close();
                                        }}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </Popup>
                              <Button
                                onClick={() =>
                                  deleteData(medi.mediumId)
                                }
                                style={{
                                  backgroundColor: 'red',
                                  color: 'white',
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          </div>
                        </TableCell>
                      </tr>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Popup
              trigger={
                <div>
                  <button
                    onClick={() => {
                      handleCloseForm();
                    }}
                    className='bg-[#7367F0] h-[40px] w-[150px] rounded-lg text-[white] text-[16px]'
                  >
                    Add Medium
                  </button>
                </div>
              }
              modal
              closeOnDocumentClick
            >
              {(close) => (
                <div className='w-[300px] lg:w-[600px]  rounded-lg bg-[white] shadow p-[30px] relative'>
                  <div className='flex'>
                    <p className='text-[23px] font-medium ml-4 '>
                      Add Medium
                    </p>
                    <button
                      className='ml-auto h-[40px] w-[40px]'
                      onClick={close}
                    >
                      &times;
                    </button>
                  </div>

                  <div className='lg:flex   lg:space-x-7'>
                    <div className='flex flex-col '>
                      <p className='formhead  mt-[25px] '>
                        Art Dropdown
                      </p>
                      {/* <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                    onChange={handleInputChange}
                                                    name='artDropDown' /> */}

                      <select
                        className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                        value={mediumObj.artDropDown}
                        onChange={handleInputChange}
                        name='artDropDown'
                      >
                        <option value='true'>true</option>
                        <option value='false'>false</option>
                      </select>
                    </div>
                    <div className='flex flex-col'>
                      <p className='formhead  mt-[25px] '>
                        Medium Image
                      </p>
                      <div className='forminput   mt-3 w-[100%] lg:w-[258px] rounded-lg leading-none pl-[5px]'>
                        <input
                          type='file'
                          className='text-[15px] p-[4px]'
                          style={{ display: 'none' }}
                          accept='image/*'
                          onChange={handleFileChange}
                          ref={fileInputRef}
                          name='mediumImage'
                          multiple
                        />
                        {imageName ? (
                          <span>{imageName}</span>
                        ) : (
                          <a
                            className='cursor-pointer text-[#808080] text-[30px]'
                            onClick={handleButtonClick}
                          >
                            +
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-col '>
                    <p className='formhead lg:mt-[20px] mt-[10px] '>
                      Medium Description
                    </p>
                    <textarea
                      type='text'
                      className=' lg:w-[100%] h-[100px]  bg-[#F8F8F4] pl-[10px] mt-[15px]'
                      name='mediumDescription'
                      onChange={handleInputChange}
                    />

                    <div className='lg:flex   lg:space-x-7'>
                      <div className='flex flex-col '>
                        <p className='formhead  mt-[25px] '>
                          Medium Type
                        </p>
                        <input
                          type='text'
                          className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                          onChange={handleInputChange}
                          name='mediumType'
                        />
                      </div>
                      <div className='flex flex-col'>
                        <p className='formhead mt-[25px] '>
                          Medium Status
                        </p>
                        {/* <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                        onChange={handleInputChange}
                                                        name='mediumStatus' /> */}

                        <select
                          className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                          value={mediumObj.mediumStatus}
                          onChange={handleInputChange}
                          name='mediumStatus'
                        >
                          <option value='Active'>Active</option>
                          <option value='InActive'>InActive</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='mx-[auto] h-[41px] w-[120px]  mt-[20px]'>
                    <button
                      className='rounded-lg text-[white] bg-[blue] text-[17px] font-semibold h-[41px] w-[120px] '
                      onClick={() => {
                        addMedium();
                        close();
                        handleCloseForm();
                      }}
                    >
                      Add Medium
                    </button>
                  </div>
                </div>
              )}
            </Popup>

            <TablePagination
              className='ml-auto'
              rowsPerPageOptions={[5, 10, 15]}
              component='div'
              count={medium.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default MediumMaster;
