import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import artnstock from '../assets/icons/artnstock.png';
import symbol from '../assets/icons/symbol.png';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import Navbar from './Navbar';
import {
  ArtTrack,
  ArtTrackOutlined,
  AttachMoney,
  Book,
  CardGiftcard,
  Category,
  Dashboard,
  DensityMedium,
  Drafts,
  ExpandLess,
  ExpandMore,
  FiberPin,
  Groups,
  HelpOutline,
  KeyboardAlt,
  MoneyOff,
  Print,
  Reviews,
  WebAsset,
} from '@mui/icons-material';
import FilterFramesIcon from '@mui/icons-material/FilterFrames';
import FlagIcon from '@mui/icons-material/Flag';
import StyleIcon from '@mui/icons-material/Style';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import InventoryIcon from '@mui/icons-material/Inventory';
import DiscountIcon from '@mui/icons-material/Discount';
import KeyboardAltIcon from '@mui/icons-material/KeyboardAlt';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

import { Collapse, InputLabel, Select } from '@mui/material';

const Sidebar = ({ onSearchChange }) => {
  const [sidebarDisplay, setSidebarDisplay] = useState(true);
  const [selectedTab, setSelectedTab] = useState('');
  const handleToggleSidebar = () => {
    setSidebarDisplay(!sidebarDisplay);
  };

  const navigate = useNavigate();

  const location = useLocation(); // react-router's useLocation hook

  useEffect(() => {
    const path = location.pathname;

    console.log('path: ', path);
    // const tab = path.split('/').pop();
    setSelectedTab(path || '');
    // const path = location.pathname;
    // const tab = path.split('/').pop();
    // setSelectedTab(tab || '');
  }, [location.pathname]);

  const [filteredSidebarItems, setFilteredSidebarItems] = useState(
    []
  );

  const sidebarItems = [
    {
      label: 'Table',
      icon: <TableRowsOutlinedIcon />,
      path: '/table',
    },
    { label: 'User', icon: <PersonOutlinedIcon />, path: '/user' },
    { label: 'FAQ', icon: <HelpOutline />, path: '/faq' },
    { label: 'Social Media', icon: <Groups />, path: '/social' },
    { label: 'Review', icon: <Reviews />, path: '/review' },
    { label: 'Pincode', icon: <FiberPin />, path: '/pin' },
    { label: 'Country', icon: <FlagIcon />, path: '/country' },
    { label: 'Frame', icon: <FilterFramesIcon />, path: '/frame' },
    { label: 'Printing', icon: <Print />, path: '/print' },
    { label: 'Topbar', icon: <WebAsset />, path: '/top' },
    { label: 'Subject ', icon: <Book />, path: '/sub' },
    { label: 'Gift Code ', icon: <CardGiftcard />, path: '/gift' },
    { label: 'Promo Code', icon: <LocalOfferIcon />, path: '/promo' },
    { label: 'Style ', icon: <StyleIcon />, path: '/style' },
    {
      label: 'Art Master ',
      icon: <ArtTrackOutlined />,
      path: '/art',
    },
    {
      label: 'Contributor Earning',
      icon: <AttachMoney />,
      path: '/contri',
    },
    {
      label: 'Medium Master',
      icon: <DensityMedium />,
      path: '/medium',
    },

    {
      label: 'Orientation Master',
      icon: <Dashboard />,
      path: '/orient',
    },

    { label: 'Keyword Master', icon: <KeyboardAlt />, path: '/key' },
    {
      label: 'Discount Master',
      icon: <DiscountIcon />,
      path: '/discount',
    },
    {
      label: 'Product Master',
      icon: <InventoryIcon />,
      path: '/product',
    },
    {
      label: ' SubCategory Master ',
      icon: <Category />,
      path: '/subcat',
    },
    {
      label: ' MainCategory Master ',
      icon: <Category />,
      path: '/maincat',
    },
    {
      label: ' Release Master ',
      icon: <NewReleasesIcon />,
      path: '/release',
    },
    {
      label: ' Admin Art Product Master ',
      icon: <NewReleasesIcon />,
      path: '/adminartproduct',
    },
    {
      label: ' Art Product Master ',
      icon: <NewReleasesIcon />,
      path: '/artproduct',
    },
    {
      label: ' FileUploadLimit Master ',
      icon: <NewReleasesIcon />,
      path: '/fileuploadlimit',
    },
    {
      label: ' AddDetails Master ',
      icon: <NoteAddIcon />,
      path: '/adddetails',
    },
    { label: ' Draft Master ', icon: <Drafts />, path: '/draft' },
  ];

  const updateSidebarItems = (query) => {
    const filteredItems = sidebarItems.filter((item) =>
      item.label.toLowerCase().includes(query.toLowerCase())
    );
    const sortedFilteredItems = filteredItems.slice();
    // .sort((a, b) => a.label.localeCompare(b.label));
    setFilteredSidebarItems(sortedFilteredItems);
    console.log('sorting');
  };
  useEffect(() => {
    updateSidebarItems('');
  }, []);
  return (
    <div
      className='flex'
      style={{ transition: 'margin-left 0.3s ease' }}
    >
      <div
        style={{
          boxShadow: 'rgba(47, 43, 61, 0.14) 0px 2px 6px 0px',
        }}
        className={`${
          sidebarDisplay === true ? 'w-[100%]' : ''
        }   h-[100vh] max-w-[260px]`}
      >
        <div
          onClick={() => {
            setSidebarDisplay(!sidebarDisplay);
          }}
          className='flex items-center w-[100%] justify-start max-w-[200px]  h-[68px] pb-[5px] mx-[auto] cursor-pointer'
        >
          <img
            style={{
              marginLeft: `${sidebarDisplay === false ? 'auto' : ''}`,
              marginRight: `${
                sidebarDisplay === false ? 'auto' : ''
              }`,
            }}
            src={symbol}
            alt=''
          />{' '}
          {sidebarDisplay && <img src={artnstock} alt='' />}
        </div>

        <div
          style={{
            color: 'rgba(47, 43, 61, 0.68)',
          }}
          className='font-normal  text-[0.9375rem] mx-[auto]'
        >
          <button
            onClick={() => {
              setSelectedTab('/');
              navigate('/');
            }}
            className={`sidelinks ${
              selectedTab === '/'
                ? 'text-[#ffffff] shadow-buttonShadow gradient-background'
                : ''
            } ${
              sidebarDisplay === true ? 'w-[100%] max-w-[230px]' : ''
            }  `}
          >
            <DashboardCustomizeOutlinedIcon />{' '}
            <span
              className={`${
                sidebarDisplay === true ? 'block' : 'hidden'
              }`}
            >
              Dashboard
            </span>
          </button>

          <p
            className={`sidelabel ${
              sidebarDisplay === true ? '' : 'text-center'
            }`}
          >
            APPS & PAGES
          </p>
          <div
            style={{
              color: 'rgba(47, 43, 61, 0.68)',
              overflowY: 'auto', // Add this line for scrollability
              maxHeight: 'calc(100vh - 160px)', // Adjust the max height as needed
            }}
          >
            {filteredSidebarItems.map((item) => (
              <button
                key={item.label}
                onClick={() => {
                  setSelectedTab(item.path.toLowerCase());
                  navigate(item.path);
                }}
                className={`sidelinks ${
                  selectedTab === item.path.toLowerCase()
                    ? 'text-[#FFFFFF] shadow-buttonShadow gradient-background'
                    : ''
                } ${
                  sidebarDisplay === true
                    ? 'w-[100%] max-w-[230px]'
                    : ''
                }`}
              >
                {item.icon}{' '}
                <span
                  className={`${
                    sidebarDisplay === true ? 'block' : 'hidden'
                  }`}
                >
                  {item.label}
                </span>
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className='flex flex-col w-[100%]'>
        <div className='w-[100%] max-w-[1350px] mx-[auto]'>
          <Navbar onSearchChange={updateSidebarItems} />
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default Sidebar;
