import { ExpandMore } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import Popup from 'reactjs-popup';
function PopupForm() {

    const [countries, setContries] = useState([]);
    const [inputvalue, setInputvalue] = useState("");
    const [selected, setSelected] = useState("");
    const [open, setOpen] = useState(false);
    const [isOn, setIsOn] = useState(false);
    const [username, setUsername] = useState('');
    const [isValid, setIsValid] = useState(true);

    const toggle = () => {
        setIsOn(!isOn);
    };


    useEffect(() => {
        fetch("https://restcountries.com/v3.1/all?fields=name")
            .then(res => res.json())
            .then((data) => {
                console.log(data);
                setContries(data);
            });
    }, []);

    const handleUsernameChange = (e) => {
        const name = e.target.value;
        setIsValid(name.length >= 5 && /^[a-z]+$/.test(name));
        setUsername(name);
    };

    return (
        <Popup
            trigger={<button className='w-[180px] h-[50px]  rounded-lg text-[white] bg-[blue] text-[18px] font-semibold'>Add New Address</button>}
            modal
            closeOnDocumentClick>
            {close => (<div className='w-[370px] lg:w-[651px] lg:h-[542px] h-[820px] rounded-lg bg-[white] shadow'>
                <div className='flex'>
                    <p className='pt-[23px] text-[23px] font-medium ml-4'>Add New Address</p>
                    <button className="ml-auto h-[40px] w-[40px]" onClick={close}>
                        &times;
                    </button>
                </div>

                <hr className='mt-[25px] lg:w-[651px] h-[1px] bg-[white]'></hr>

                <div className='lg:h-[368px] h-[700px] lg:w-[651px] w-[370px]'>
                    <div className="mx-[auto]  lg:h-[305px] h-[590px] w-[350px] lg:w-[585px] lg:mt-[44px] mt-[25px] overflow-y-auto">
                        <div className='h-[90px] lg:w-[537px] w-[320px] border border-dashed border-[orange] rounded-lg bg-[#FEF8DD] mx-[auto]'>
                            <div className=' flex'>
                                <NewReleasesOutlinedIcon style={{ height: "23px", width: "23px", marginTop: "24px", marginLeft: "24px", }} />
                                <div className='lg:flex-col ml-4'>
                                    <p className=' lg:text-[18px]  text-[15px] mt-[19px] font-semibold'>Warning</p>
                                    <div className='lg:flex'>
                                        <p className='lg:text-[16px] text-[13px] text-[#ABB2B9]  font-normal'>Updating address may affter to your</p>
                                        <button className=' text-[#85C1E9] lg:text-[16px] text-[12px] font-normal lg:ml-2'> Tax Location</button></div>
                                </div>
                            </div>
                        </div>

                        <div className='lg:flex lg:ml-[22px] ml-[15px]'>
                            <div className='flex flex-col'>
                                <p className='formhead lg:mt-[35px] mt-[25px] '>First Name</p>
                                <input type='text' className='forminput  mt-3 w-[320px] lg:w-[258px] rounded-lg' value={username} onChange={handleUsernameChange} />
                                {!isValid && (
                                    <p className="text-[#F62217] text-[12px] ml-8">Username must contain only letters (a-z) and have a length of 5.</p>
                                )}
                            </div>
                            <div className='flex flex-col lg:ml-[20px]'>
                                <p className='formhead lg:mt-[35px] mt-[25px]'>Last Name</p>
                                <input type='text' className='forminput  mt-3  w-[320px] lg:w-[258px] ' /></div>
                        </div>

                        <p className='formhead mt-[23px] lg:ml-[22px] ml-[15px]'>Country</p>
                        <div className='forminput  mt-3 lg:w-[537px] w-[320px] C flex lg:ml-[22px] ml-[15px]' onClick={() => setOpen(!open)}>
                            <p className='text-[17px] font-medium text-[#ABB2B9] ml-4 pt-[6px]'>{selected ? selected : "Select a Country..."}</p>
                            <ExpandMore className='h-[25px] w[25px] ml-auto mt-[6px] text-[#ABB2B9] ' />
                        </div>
                        <div className={`bg-[white]  h-[326px] lg:w-[537px] w-[320px] lg:ml-[20px] ml-[15px] lg:ml-[22px] rounded-lg  ${open ? 'max-h-[250px] bg-[white] shadow' : 'max-h-0'}`}>
                            <input type='text' value={inputvalue} onChange={(e) => setInputvalue(e.target.value.toLowerCase())}
                                className={`h-[40px] lg:w-[535px] w-[320px] ml-[1px] mt-[4px] ${open ? 'max-h-[40px] bg-[white] border-[1px] border-[#eeeee4]  rounded-lg' : 'max-h-0'}`} />
                            <ul className={`mt-2 text-[black]  overflow-y-auto h-[280px] w-[320px] lg:w-[537px]  ${open ? 'max-h-[190px] ' : 'max-h-0'}`}>
                                <div className=''>
                                    {countries.map((country) => (
                                        <li key={country.name.common}
                                            className={`p-2 hover:bg-[#f8f8f4] cursor-pointer ${country?.name?.official?.toLowerCase().startsWith(inputvalue)
                                                ? "block"
                                                : "hidden"
                                                }`} onClick={() => {
                                                    if (country?.name?.official?.toLowerCase() !== selected.toLowerCase()) {
                                                        setSelected(country?.name?.official);
                                                        setOpen(false);
                                                    }
                                                }}>
                                            {country.name.official}
                                        </li>
                                    ))

                                    }
                                </div>

                            </ul>
                        </div>

                        <p className='formhead mt-[23px] lg:ml-[22px] ml-[15px] '>Address Line 1</p>
                        <input className='forminput mt-[6px]  lg:w-[537px] w-[320px] flex lg:ml-[22px] ml-[15px]'></input>
                        <p className='mt-[23px] lg:ml-[22px] ml-[15px] formhead'>Address Line 2</p>
                        <input className=' mt-[6px] lg:w-[537px] w-[320px]  forminput flex lg:ml-[22px] ml-[15px]'></input>
                        <p className='mt-[23px] lg:ml-[22px] ml-[15px] formhead'>Town</p>
                        <input className=' forminput  mt-[6px] lg:w-[537px] w-[320px] lg:ml-[22px] ml-[15px] flex'></input>

                        <div className='lg:flex  '>
                            <div className='flex flex-col'>
                                <p className=' mt-[23px] formhead lg:ml-[22px] ml-[15px]'>State / Province</p>
                                <input type='text' className=' mt-3 w-[320px] lg:w-[258px] lg:ml-[22px] ml-[15px] forminput' /></div>
                            <div className='flex flex-col lg:ml-[8px]'>
                                <p className=' mt-6 formhead lg:ml-[22px] ml-[15px]'>Post Code</p>
                                <input type='text' className='mt-3 w-[320px] lg:w-[258px] ml-[15px]  forminput' /></div>
                        </div>

                        <div className='lg:w-[537px] w-[320px] h-[68px] flex  lg:ml-[22px] ml-[15px]'>
                            <div className='flex flex-col'>
                                <p className='text-[18px] mt-6 formhead '>Use as a billing adderess?</p>
                                <p className='lg:text-[15px] text-[12px] text-[#ABB2B9] font-medium '>If you need more info,please check budget planning</p>
                            </div>
                            <label className="flex items-center cursor-pointer lg:ml-[auto] lg:mt-[23px] mt-[13px] ">
                                <div className={`relative w-[41px] h-[27px]  p-[1px]  ${isOn ? 'bg-[#6698FF]' : 'bg-[#d6d6cd]'} rounded-full `}>
                                    <div className={`absolute w-[21px] h-[21px] ml-[2px]   mt-[2px] ${isOn ? 'bg-[white]' : 'bg-[white]'} rounded-full transition-transform duration-300 transform ${isOn ? 'translate-x-4' : ''}`} />
                                </div>
                                <input type="checkbox" className="hidden" checked={isOn} onChange={toggle} />
                                <p className='text-[20px] font-mediun text-[#ABB2B9] ml-2 '>Yes</p>
                            </label>
                        </div>
                    </div>

                    <hr className="lg:w-[651px] h-[1px] bg-[gray]  mt-[28px]"></hr>

                    <div className='flex lg:mt-[15px] mt-[20px] h-[50px] w-[300px] mx-[auto] pl-[40px]'>
                        <button className='formbtn bg-[#f8f8f4] hover:bg-[#E5E4E2] text-[#ABB2B9]  '>Discard</button>
                        <button className='formbtn bg-[#645ed4] hover:bg-[#728FCE]  text-[white]  ml-4'>Submit</button>
                    </div>

                </div>
            </div>
            )}
        </Popup>
    )
}
export default PopupForm;