import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, styled, tableCellClasses } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { httpClient } from '../../axios';
import Popup from 'reactjs-popup';

const ProductMaster = () => {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: 'rgb(115 103 240)',
            color: theme.palette.common.white,
            fontSize: 20,
        },
    }));

    const [product, setProduct] = useState([]);
    const [imageName, setImageName] = useState('');
    const [imageName1, setImageName1] = useState('');
    const [imageName2, setImageName2] = useState('');
    const [imageName3, setImageName3] = useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const fileInputRef = useRef(null);
    const fileInputRef1 = useRef(null);
    const fileInputRef2 = useRef(null);
    const fileInputRef3 = useRef(null);
    const [pro, setPro] = useState([]);
    const [selectProduct, setselectProduct] = useState('');
    const [productObj, setProductObj] = useState(
        {

            productName: "string",
            description: "string",
            style: [""],
            productDetails: [
                {
                    bothSides: "string",
                    frontImage: "string",
                    backImage: "string",
                    color: "string",
                    hexCode: "string"
                }
            ],
            sizeAndPrices: [
                {
                    size: "string",
                    basePrice: 0,
                    sellPrice: 0,
                    sizeName: "string"
                }
            ],
            productNo: "string",
            stock: 0,
            stockStatus: "string",
            status: "",
            productSubCategoryId: "string",
            imageId: "string",
            index: 0
        }
    )
    const getProduct = async () => {
        const res = await httpClient.get(
            '/product_master'
        );

        console.log(res.data);
        setProduct(res.data);

    };
    useEffect(() => {
        getProduct();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleInputChange = (e) => {
        setProductObj({
            ...productObj,
            [e.target.name]: e.target.value,

        })
            ;
    };


    const handleStyleChange = (value) => {
        const updatedStyle = [...productObj.style, value];

        setProductObj({
            ...productObj,
            style: updatedStyle
        });
    };


    const handleInputChange1 = (event) => {
        setselectProduct(event.target.value);
    };

    const handleInputChange2 = (e) => {
        const { name, value } = e.target;
        setProductObj({
            ...productObj,
            productDetails: [{
                ...productObj.productDetails[0],
                [name]: value
            }]
        });
    };

    const handleInputChange3 = (e) => {
        const { name, value } = e.target;
        setProductObj({
            ...productObj,
            sizeAndPrices: [{
                ...productObj.sizeAndPrices[0],
                [name]: value
            }]
        });
    };
    const handleFileChange = async (event, linkNumber) => {
        const img = event.target.files[0];
        if (img) {
            let formData = new FormData();
            formData.append('file', img);
            const res = await httpClient.post(
                '/CloudinaryImageUpload?parameter=false',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            // Update the state based on the linkNumber
            if (linkNumber === 1) {
                setImageName1(img.name);
                setProductObj(prevState => ({
                    ...prevState,
                    productDetails: [{
                        ...prevState.productDetails[0],
                        bothSides: res.data
                    }]
                }));
            } else if (linkNumber === 2) {
                setImageName2(img.name);
                setProductObj(prevState => ({
                    ...prevState,
                    productDetails: [{
                        ...prevState.productDetails[0],
                        frontImage: res.data
                    }]
                }



                ));
            } else if (linkNumber === 3) {
                setImageName3(img.name);
                setProductObj(prevState => ({
                    ...prevState,
                    productDetails: [{
                        ...prevState.productDetails[0],
                        backImage: res.data
                    }]
                }



                ));
            }
        };

    }

    const handleFileChange2 = async (event, linkNumber) => {
        const img = event.target.files[0];
        if (img) {

            let formData = new FormData();
            formData.append('file', img);
            const res = await httpClient.post(
                '/CloudinaryImageUpload?parameter=true',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            setImageName(img.name);

            console.log(res.data)
            setProductObj({ ...productObj, imageId: res.data.imageId })
        };
    };




    const handleButtonClick = () => {
        fileInputRef1.current.click();
    };

    const handleButtonClick1 = () => {
        fileInputRef2.current.click();
    };
    const handleButtonClick2 = () => {
        fileInputRef3.current.click();
    };

    const handleButtonClick3 = () => {
        fileInputRef.current.click();
    };

    const UpdateProduct = async () => {
        const res = await httpClient.put(
            '/product_master/update',
            { ...productObj }
        );
        getProduct();
        console.log(res.data);

    };

    const addProduct = async () => {

        try {
            const selectedProducts = pro.find(prod => prod.productSubCategoryId === selectProduct);
            if (selectedProducts) {
                const newProduct = {
                    ...productObj,
                    productSubCategoryId: selectedProducts.productSubCategoryId
                };

                await httpClient.post('/product_master/create', newProduct);
                console.log('Product added successfully:', newProduct);
                getProduct();
            } else {
                console.error('No product selected or matching product found.');
            }
        } catch (error) {
            console.error('Error adding product:', error);
        }
    };


    useEffect(() => {
        console.log(productObj);
    }, [productObj]);

    const getprosub = async () => {
        try {
            const res = await httpClient.get('/product_sub_category_master');
            console.log(res.data);
            setPro(res.data);
        } catch (error) {
            console.error('Error fetching Product Main:', error);

        }
    };
    useEffect(() => {
        getprosub();
    }, []);


    const resetImageName = () => {
        setImageName('');
        setImageName1('');
        setImageName2('');
        setImageName3('');
    };
    const handleCloseForm = () => {
        resetImageName();
        console.log('........');
    };
    return (
        <>
            <Grid justifyContent='center'>
                <Grid item xs={12} md={10} lg={8}>
                    <div className='pageMainDiv mt-[20px]'>
                        <p className='text-[25px] font-bold mt-[40px]'> Products</p>
                        <TableContainer className='h-[400px] mt-[20px] '>
                            <Table size='small' className='border border-[#CECECE] ' stickyHeader>
                                <TableHead>
                                    <TableRow className='border border-[#CECECE] '>
                                        <StyledTableCell align='center'>Product Id</StyledTableCell>
                                        <StyledTableCell align='center'> <div className='w-[150px]'></div>Product Name</StyledTableCell>
                                        <StyledTableCell align='left'><div className='w-[400px]'>Description</div></StyledTableCell>
                                        <StyledTableCell align='left'> <div className='w-[150px]'>Style</div></StyledTableCell>
                                        <StyledTableCell align='center'> <div className='w-[150px]'>Product Details</div></StyledTableCell>
                                        <StyledTableCell align='center'> <div className='w-[150px]'>Size and Price</div></StyledTableCell>

                                        <StyledTableCell align='center'><div className='w-[150px]'>Stock</div> </StyledTableCell>
                                        <StyledTableCell align='center'><div className='w-[150px]'>Stock Status</div></StyledTableCell>
                                        <StyledTableCell align='center'><div className='w-[150px]'>Status</div></StyledTableCell>
                                        <StyledTableCell align='center'><div className='w-[150px]'>Image Master</div></StyledTableCell>
                                        <StyledTableCell align='center'><div className='w-[150px]'>Product SubCategory Name</div></StyledTableCell>
                                        <StyledTableCell align='center'><div className='w-[150px]'>Action</div></StyledTableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {product
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((prod, index) => (<tr key={prod.productId}>
                                            <TableCell>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {(page * rowsPerPage) + index + 1}
                                                </span>
                                            </TableCell>
                                            <TableCell align='center'>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {prod.productName}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {prod.description}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {prod.style}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                {/* {' '}
                                            <span className='text-[16px]'>
                                                {prod.productDetails}
                                            </span> */}

                                                <Popup trigger={<div className='text-center'>
                                                    <Button

                                                        style={{
                                                            backgroundColor: 'orange',
                                                            color: 'white',
                                                        }}
                                                    // onClick={() => { getProduct() }}
                                                    >
                                                        View
                                                    </Button>
                                                </div>}
                                                    modal
                                                    closeOnDocumentClick
                                                >


                                                    {(close) => (<div className='h-[500px] w-[1395px]  rounded-lg  bg-[white] shadow pt-[80px] pl-[40px] pr-[40px] pb-[40px] overflow-y-auto'>
                                                        <p className='text-[26px]  text-[#808080] font-semibold'>Product Details</p>

                                                        <TableContainer className=' mt-[10px] '>
                                                            <Table size='small' className='border border-[#CECECE] rounded-lg' stickyHeader>
                                                                <TableHead >

                                                                    <TableRow className=' '>
                                                                        <TableCell className='border border-[#C0C0C0]'  >Both Sides</TableCell>
                                                                        <TableCell className='border border-[#C0C0C0]'  >Front Image</TableCell>
                                                                        <TableCell className='border border-[#C0C0C0]'  > Back Image </TableCell>
                                                                        <TableCell className='border border-[#C0C0C0]'  >Color</TableCell>
                                                                        <TableCell className='border border-[#C0C0C0]'  >hexCode</TableCell>

                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {prod?.productDetails?.map((prodDetails, index) => (
                                                                        <tr key={prodDetails.hexCode}>
                                                                            <TableCell className='border border-[#C0C0C0]'><div className=''>{prodDetails.bothSides}</div></TableCell>
                                                                            <TableCell className='border border-[#C0C0C0]'><div className=''>{prodDetails.frontImage}</div></TableCell>
                                                                            <TableCell className='border border-[#C0C0C0]'><div className=''>{prodDetails.backImage}</div></TableCell>
                                                                            <TableCell className='border border-[#C0C0C0]'><div className=''>{prodDetails.color}</div></TableCell>
                                                                            <TableCell className='border border-[#C0C0C0]'><div className=''>{prodDetails.hexCode}</div></TableCell>

                                                                        </tr>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </div>
                                                    )}
                                                </Popup>

                                            </TableCell>
                                            <TableCell>
                                                <Popup trigger={<div className='text-center'>
                                                    <Button

                                                        style={{
                                                            backgroundColor: 'orange',
                                                            color: 'white',
                                                        }}
                                                    // onClick={() => { getEarn(art.artId) }}
                                                    >
                                                        View
                                                    </Button>
                                                </div>}
                                                    modal
                                                    closeOnDocumentClick
                                                >

                                                    {(close) => (<div className='h-[500px] w-[1395px]  rounded-lg  bg-[white] shadow pt-[80px] pl-[40px] pr-[40px] pb-[40px] overflow-y-auto'>
                                                        <p className='text-[26px]  text-[#808080] font-semibold'>Size And Prices</p>

                                                        <TableContainer className=' mt-[10px] '>
                                                            <Table size='small' className='border border-[#CECECE] rounded-lg' stickyHeader>
                                                                <TableHead >

                                                                    <TableRow className=' '>
                                                                        <TableCell className='border border-[#C0C0C0]'  >Size</TableCell>
                                                                        <TableCell className='border border-[#C0C0C0]'  >Base Price</TableCell>
                                                                        <TableCell className='border border-[#C0C0C0]'  >Sell Price</TableCell>
                                                                        <TableCell className='border border-[#C0C0C0]'  >Size Name</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {prod?.sizeAndPrices?.map((sizeprice, index) => (
                                                                        <tr key={sizeprice.size}>
                                                                            <TableCell className='border border-[#C0C0C0]'><div className=''>{sizeprice.size}</div></TableCell>
                                                                            <TableCell className='border border-[#C0C0C0]'><div className=''>{sizeprice.basePrice}</div></TableCell>
                                                                            <TableCell className='border border-[#C0C0C0]'><div className=''>{sizeprice.sellPrice}</div></TableCell>
                                                                            <TableCell className='border border-[#C0C0C0]'><div className=''>{sizeprice.sizeName}</div></TableCell>
                                                                        </tr>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </div>
                                                    )}

                                                </Popup>
                                            </TableCell>

                                            <TableCell align='center'>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {prod.stock}
                                                </span>
                                            </TableCell>
                                            <TableCell align='center'>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {prod.stockStatus}
                                                </span>
                                            </TableCell>
                                            <TableCell align='center'>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {prod.status}
                                                </span>
                                            </TableCell>
                                            <TableCell>

                                                <Popup trigger={<div className='text-center'>
                                                    <Button

                                                        style={{
                                                            backgroundColor: 'orange',
                                                            color: 'white',
                                                        }}
                                                    >
                                                        Image
                                                    </Button>
                                                </div>}
                                                    modal
                                                    closeOnDocumentClick
                                                >
                                                    {(close) => (<div
                                                        className='w-[300px] lg:w-[400px] lg:h-[350px] h-[250px] rounded-lg  bg-[white] shadow'>
                                                        {prod?.imageMaster && (
                                                            <img src={prod?.imageMaster?.secureUrl}
                                                                className=' w-[100%] h-[100%]' alt="Product" />
                                                        )}
                                                    </div>)}

                                                </Popup>
                                            </TableCell>
                                            <TableCell align='center'>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {prod?.productSubCategoryMaster?.productSubCategoryName}
                                                </span>
                                            </TableCell>
                                            {/* <TableCell align='center'>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {prod.productSubCategoryMaster?.productMainCategoryMaster?.productMainCategoryName}
                                                </span>
                                            </TableCell> */}

                                            <TableCell>
                                                {/* <Popup trigger={<div className='text-center'>
                                                    <Button

                                                        style={{
                                                            backgroundColor: 'orange',
                                                            color: 'white',
                                                        }}
                                                        onClick={() => {
                                                            setProductObj(prod);

                                                        }}
                                                    >
                                                        Update
                                                    </Button>
                                                </div>}
                                                    modal
                                                    closeOnDocumentClick
                                                >

                                                    {(close) => (
                                                        <div className='w-[300px] lg:w-[900px] lg:h-[500px] h-[590px] rounded-lg bg-[white] shadow p-[30px] relative'>
                                                            <div className='flex'>

                                                                <p className='text-[23px] font-medium ml-4 '>
                                                                    Update Product
                                                                </p>
                                                                <button
                                                                    className='ml-auto h-[40px] w-[40px]'
                                                                    onClick={close}
                                                                >
                                                                    &times;
                                                                </button>
                                                            </div>
                                                            <div className='lg:w-[100%] w-[250px] lg:h-[400px] h-[500px] overflow-y-auto '>
                                                                <div className='lg:flex   lg:space-x-6'>
                                                                    <div className='flex flex-col '>
                                                                        <p className='formhead  mt-[25px] '>Product Name</p>
                                                                        <input
                                                                            type='text'
                                                                            className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                                            value={productObj.productName}
                                                                            onChange={handleInputChange}
                                                                            name='productName'
                                                                        />
                                                                    </div>
                                                                    <div className='flex flex-col '>
                                                                        <p className='formhead  mt-[25px] '>Product Style</p>
                                                                        <input
                                                                            type='text'
                                                                            className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                                            value={productObj.style}
                                                                            onChange={handleInputChange}
                                                                            name='style'
                                                                        />
                                                                    </div>
                                                                    <div className='flex flex-col '>
                                                                        <p className='formhead  mt-[25px] '>Product Number</p>
                                                                        <input
                                                                            type='text'
                                                                            className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                                            value={productObj.productNo}
                                                                            onChange={handleInputChange}
                                                                            name='productNo'
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className='flex flex-col '>
                                                                    <p className='formhead  mt-[20px] '>Description</p>
                                                                    <textarea
                                                                        type='text'
                                                                        className=' lg:w-[820px] w-[100%] h-[70px]  bg-[#F8F8F4] pl-[10px] mt-[10px]'
                                                                        value={productObj.description}
                                                                        name='description'
                                                                        onChange={handleInputChange}

                                                                    />


                                                                </div>
                                                                <p className='text-[18px] font-semibold mt-[20px] '>Product Details :</p>
                                                                <div className='lg:flex   lg:space-x-6'>
                                                                    <div className='flex flex-col '>
                                                                        <p className='formhead  mt-[15px] '>Both Sides</p>
                                                                        <div className='forminput   mt-3 w-[100%] lg:w-[258px] rounded-lg leading-none pl-[5px]'>
                                                                            <input type='file' className='text-[15px] p-[4px]'
                                                                                style={{ display: 'none' }}
                                                                                accept='image/*'
                                                                                onChange={handleFileChange}
                                                                                ref={fileInputRef1}
                                                                                name='bothSides' multiple />
                                                                            {imageName1 ? (
                                                                                <span>{imageName1}</span>
                                                                            ) : (
                                                                                <a className='cursor-pointer text-[#808080] text-[30px]'
                                                                                    onClick={handleButtonClick}
                                                                                >+</a>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex flex-col '>
                                                                        <p className='formhead  mt-[15px] '>Front Image</p>
                                                                        <div className='forminput   mt-3 w-[100%] lg:w-[258px] rounded-lg leading-none pl-[5px]'>
                                                                            <input type='file' className='text-[15px] p-[4px]'
                                                                                style={{ display: 'none' }}
                                                                                accept='image/*'
                                                                                onChange={handleFileChange}
                                                                                ref={fileInputRef2}
                                                                                name='frontImage' multiple />
                                                                            {imageName2 ? (
                                                                                <span>{imageName2}</span>
                                                                            ) : (
                                                                                <a className='cursor-pointer text-[#808080] text-[30px]'
                                                                                    onClick={handleButtonClick}
                                                                                >+</a>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex flex-col '>
                                                                        <p className='formhead  mt-[15px] '>Back Image</p>
                                                                        <div className='forminput   mt-3 w-[100%] lg:w-[258px] rounded-lg leading-none pl-[5px]'>
                                                                            <input type='file' className='text-[15px] p-[4px]'
                                                                                style={{ display: 'none' }}
                                                                                accept='image/*'
                                                                                onChange={handleFileChange}
                                                                                ref={fileInputRef2}
                                                                                name='backImage' multiple />
                                                                            {imageName2 ? (
                                                                                <span>{imageName2}</span>
                                                                            ) : (
                                                                                <a className='cursor-pointer text-[#808080] text-[30px]'
                                                                                    onClick={handleButtonClick}
                                                                                >+</a>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='lg:flex   lg:space-x-6'>
                                                                    <div className='flex flex-col '>
                                                                        <p className='formhead  mt-[20px] '>Color</p>
                                                                        <input
                                                                            type='text'
                                                                            className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                                            value={productObj?.productDetails?.color}
                                                                            onChange={handleInputChange}
                                                                            name='color'
                                                                        />
                                                                    </div>
                                                                    <div className='flex flex-col '>
                                                                        <p className='formhead  mt-[20px] '>HexCode</p>
                                                                        <input
                                                                            type='text'
                                                                            className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                                            value={productObj?.productDetails?.hexCode}
                                                                            onChange={handleInputChange}
                                                                            name='hexCode'
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <p className='text-[18px] font-semibold mt-[20px] '>Sizes And Prices :</p>
                                                                <div className='lg:flex   lg:space-x-6'>
                                                                    <div className='flex flex-col '>
                                                                        <p className='formhead  mt-[15px] '>Size</p>
                                                                        <input
                                                                            type='text'
                                                                            className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                                        // value={shapeObj.shape}
                                                                        // onChange={handleInputChange}
                                                                        />
                                                                    </div>
                                                                    <div className='flex flex-col '>
                                                                        <p className='formhead  mt-[15px] '>Base Price</p>
                                                                        <input
                                                                            type='text'
                                                                            className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                                        // value={shapeObj.shape}
                                                                        // onChange={handleInputChange}
                                                                        />
                                                                    </div>
                                                                    <div className='flex flex-col '>
                                                                        <p className='formhead  mt-[15px] '>Sell Price</p>
                                                                        <input
                                                                            type='text'
                                                                            className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                                        // value={shapeObj.shape}
                                                                        // onChange={handleInputChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='lg:flex   lg:space-x-6'>
                                                                    <div className='flex flex-col '>
                                                                        <p className='formhead  mt-[20px] '>Size Name</p>
                                                                        <input
                                                                            type='text'
                                                                            className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                                        // value={shapeObj.shape}
                                                                        // onChange={handleInputChange}
                                                                        />
                                                                    </div>

                                                                </div>

                                                                <div className='lg:flex   lg:space-x-6'>
                                                                    <div className='flex flex-col '>
                                                                        <p className='formhead  mt-[25px] '>Stock</p>
                                                                        <input
                                                                            type='text'
                                                                            className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                                        // value={shapeObj.shape}
                                                                        // onChange={handleInputChange}
                                                                        />
                                                                    </div>
                                                                    <div className='flex flex-col '>
                                                                        <p className='formhead  mt-[25px] '>Stock Status</p>
                                                                        <input
                                                                            type='text'
                                                                            className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                                        // value={shapeObj.shape}
                                                                        // onChange={handleInputChange}
                                                                        />
                                                                    </div>
                                                                    <div className='flex flex-col '>
                                                                        <p className='formhead  mt-[25px] '>Status</p>
                                                                        <input
                                                                            type='text'
                                                                            className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                                            value={productObj.status}
                                                                            onChange={handleInputChange}
                                                                            name='status'
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className=' h-[41px] w-[120px]  mt-[30px] mx-auto'>
                                                                    <button className='rounded-lg text-[white] bg-[blue] text-[17px] font-semibold h-[41px] w-[120px] '
                                                                        onClick={() => {
                                                                            UpdateProduct();
                                                                            close();

                                                                        }}
                                                                    >
                                                                        Save</button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )}
                                                </Popup> */}

                                            </TableCell>
                                            {/* <TableCell>
                                           

                                            <Popup trigger={<div className='text-center'>
                                                <Button

                                                    style={{
                                                        backgroundColor: 'orange',
                                                        color: 'white',
                                                    }}
                                                // onClick={() => { getEarn(art.artId) }}
                                                >
                                                    View
                                                </Button>
                                            </div>}
                                                modal
                                                closeOnDocumentClick
                                            >

                                                {(close) => (<div className='h-[500px] w-[1395px]  rounded-lg  bg-[white] shadow pt-[80px] pl-[40px] pr-[40px] pb-[40px] overflow-y-auto'>
                                                    <p className='text-[26px]  text-[#808080] font-semibold'>Product SubCategory Master</p>

                                                    <TableContainer className=' mt-[10px] '>
                                                        <Table size='small' className='border border-[#CECECE] rounded-lg' stickyHeader>
                                                            <TableHead >

                                                                <TableRow className=' '>
                                                                    <TableCell className='border border-[#C0C0C0]'  >product SubCategory Id</TableCell>
                                                                    <TableCell className='border border-[#C0C0C0]'  >product SubCategory Name </TableCell>
                                                                    <TableCell className='border border-[#C0C0C0]'  >Image</TableCell>
                                                                    <TableCell className='border border-[#C0C0C0]'  >Status</TableCell>
                                                                    <TableCell className='border border-[#C0C0C0]'  >Logo Image</TableCell>
                                                                    <TableCell className='border border-[#C0C0C0]'  >Type</TableCell>
                                                                    <TableCell className='border border-[#C0C0C0]'  >product MainCategory Master</TableCell>
                                                                    <TableCell className='border border-[#C0C0C0]'  >Index</TableCell>


                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {Array.isArray(prod.productSubCategoryMaster) && prod.productSubCategoryMaster.map((subprod, index) => (
                                                                    <tr key={subprod.productSubCategoryId}>
                                                                        <TableCell className='border border-[#C0C0C0]'><div className=''>{subprod.productSubCategoryId}</div></TableCell>
                                                                        <TableCell className='border border-[#C0C0C0]'><div className=''>{subprod.productSubCategoryName}</div></TableCell>
                                                                        <TableCell className='border border-[#C0C0C0]'><div className=''>{subprod.image}</div></TableCell>
                                                                        <TableCell className='border border-[#C0C0C0]'><div className=''>{subprod.status}</div></TableCell>
                                                                        <TableCell className='border border-[#C0C0C0]'><div className=''>{subprod.logoImage}</div></TableCell>
                                                                        <TableCell className='border border-[#C0C0C0]'><div className=''>{subprod.type}</div></TableCell>
                                                                        <TableCell>


                                                                            <Popup trigger={<div className='text-center'>
                                                                                <Button

                                                                                    style={{
                                                                                        backgroundColor: 'orange',
                                                                                        color: 'white',
                                                                                    }}
                                                                                // onClick={() => { getEarn(art.artId) }}
                                                                                >
                                                                                    View
                                                                                </Button>
                                                                            </div>}
                                                                                modal
                                                                                closeOnDocumentClick
                                                                            >

                                                                                {(close) => (<div className='h-[500px] w-[1395px]  rounded-lg  bg-[white] shadow pt-[80px] pl-[40px] pr-[40px] pb-[40px] overflow-y-auto'>
                                                                                    <p className='text-[26px]  text-[#808080] font-semibold'>Product MainCategory Master</p>

                                                                                    <TableContainer className=' mt-[10px] '>
                                                                                        <Table size='small' className='border border-[#CECECE] rounded-lg' stickyHeader>
                                                                                            <TableHead >

                                                                                                <TableRow className=' '>
                                                                                                    <TableCell className='border border-[#C0C0C0]'  >product MainCategory Id</TableCell>
                                                                                                    <TableCell className='border border-[#C0C0C0]'  >product MainCategory Name </TableCell>
                                                                                                    <TableCell className='border border-[#C0C0C0]'  >Image</TableCell>
                                                                                                    <TableCell className='border border-[#C0C0C0]'  >Status</TableCell>


                                                                                                </TableRow>
                                                                                            </TableHead>
                                                                                            <TableBody>
                                                                                                {prod?.subprod?.productMainCategoryMaster?.map((mainprod, index) => (
                                                                                                    <tr key={mainprod.productMainCategoryId}>
                                                                                                        <TableCell className='border border-[#C0C0C0]'><div className=''>{mainprod.productMainCategoryId}</div></TableCell>
                                                                                                        <TableCell className='border border-[#C0C0C0]'><div className=''>{mainprod.productMainCategoryName}</div></TableCell>
                                                                                                        <TableCell className='border border-[#C0C0C0]'><div className=''>{mainprod.image}</div></TableCell>
                                                                                                        <TableCell className='border border-[#C0C0C0]'><div className=''>{mainprod.status}</div></TableCell>




                                                                                                    </tr>
                                                                                                ))}
                                                                                            </TableBody>
                                                                                        </Table>
                                                                                    </TableContainer>
                                                                                </div>
                                                                                )}

                                                                            </Popup>
                                                                        </TableCell>
                                                                        <TableCell className='border border-[#C0C0C0]'><div className=''>{subprod.index}</div></TableCell>


                                                                    </tr>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </div>
                                                )}

                                            </Popup>
                                        </TableCell> */}
                                        </tr>))}


                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className='flex'>

                            <Popup trigger={<div className='text-center'>
                                <Button

                                    style={{
                                        backgroundColor: 'blue',
                                        color: 'white',
                                    }}
                                // onClick={() => {
                                //     setProductObj(prod);

                                // }}
                                >
                                    Add
                                </Button>
                            </div>}
                                modal
                                closeOnDocumentClick
                            >

                                {(close) => (
                                    <div className='w-[300px] lg:w-[900px] lg:h-[500px] h-[590px] rounded-lg bg-[white] shadow p-[30px] relative'>
                                        <div className='flex'>

                                            <p className='text-[23px] font-medium ml-4 '>
                                                Add Product
                                            </p>
                                            <button
                                                className='ml-auto h-[40px] w-[40px]'
                                                onClick={close}
                                            >
                                                &times;
                                            </button>
                                        </div>
                                        <div className='lg:w-[100%] w-[250px] lg:h-[400px] h-[500px] overflow-y-auto '>
                                            <div className='lg:flex   lg:space-x-6'>
                                                <div className='flex flex-col '>
                                                    <p className='formhead  mt-[25px] '>Product Name</p>
                                                    <input
                                                        type='text'
                                                        className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                        // value={productObj.productName}
                                                        onChange={handleInputChange}
                                                        name='productName'
                                                    />
                                                </div>
                                                <div className='flex flex-col '>
                                                    <p className='formhead  mt-[25px] '>Product Style</p>
                                                    <input
                                                        type='text'
                                                        className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                        // value={productObj.style}
                                                        onChange={(e) => handleStyleChange(e.target.value)}
                                                        name='style'
                                                    />
                                                </div>

                                            </div>

                                            <div className='flex flex-col '>
                                                <p className='formhead  mt-[20px] '>Description</p>
                                                <textarea
                                                    type='text'
                                                    className=' lg:w-[820px] w-[100%] h-[70px]  bg-[#F8F8F4] pl-[10px] mt-[10px]'
                                                    // value={productObj.description}
                                                    name='description'
                                                    onChange={handleInputChange}

                                                />


                                            </div>
                                            <p className='text-[18px] font-semibold mt-[20px] '>Product Details :</p>
                                            <div className='lg:flex   lg:space-x-6'>
                                                <div className='flex flex-col '>
                                                    <p className='formhead  mt-[15px] '>Both Sides</p>
                                                    <div className='forminput   mt-3 w-[100%] lg:w-[258px] rounded-lg leading-none pl-[5px]'>
                                                        <input type='file' className='text-[15px] p-[4px]'
                                                            style={{ display: 'none' }}
                                                            accept='image/*'
                                                            onChange={(event) => handleFileChange(event, 1)}
                                                            ref={fileInputRef1}
                                                            name='bothSides' multiple />
                                                        {imageName1 ? (
                                                            <span>{imageName1}</span>
                                                        ) : (
                                                            <a className='cursor-pointer text-[#808080] text-[30px]'
                                                                onClick={handleButtonClick}
                                                            >+</a>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className='flex flex-col '>
                                                    <p className='formhead  mt-[15px] '>Front Image</p>
                                                    <div className='forminput   mt-3 w-[100%] lg:w-[258px] rounded-lg leading-none pl-[5px]'>
                                                        <input type='file' className='text-[15px] p-[4px]'
                                                            style={{ display: 'none' }}
                                                            accept='image/*'
                                                            onChange={(event) => handleFileChange(event, 2)}
                                                            ref={fileInputRef2}
                                                            name='frontImage' multiple />
                                                        {imageName2 ? (
                                                            <span>{imageName2}</span>
                                                        ) : (
                                                            <a className='cursor-pointer text-[#808080] text-[30px]'
                                                                onClick={handleButtonClick1}
                                                            >+</a>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className='flex flex-col '>
                                                    <p className='formhead  mt-[15px] '>Back Image</p>
                                                    <div className='forminput   mt-3 w-[100%] lg:w-[258px] rounded-lg leading-none pl-[5px]'>
                                                        <input type='file' className='text-[15px] p-[4px]'
                                                            style={{ display: 'none' }}
                                                            accept='image/*'
                                                            onChange={(event) => handleFileChange(event, 3)}
                                                            ref={fileInputRef3}
                                                            name='backImage' multiple />
                                                        {imageName3 ? (
                                                            <span>{imageName3}</span>
                                                        ) : (
                                                            <a className='cursor-pointer text-[#808080] text-[30px]'
                                                                onClick={handleButtonClick2}
                                                            >+</a>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='lg:flex   lg:space-x-6'>
                                                <div className='flex flex-col '>
                                                    <p className='formhead  mt-[20px] '>Color</p>
                                                    <input
                                                        type='text'
                                                        className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                        value={productObj?.productDetails?.color}
                                                        onChange={handleInputChange2}
                                                        name='color'
                                                    />
                                                </div>
                                                <div className='flex flex-col '>
                                                    <p className='formhead  mt-[20px] '>HexCode</p>
                                                    <input
                                                        type='text'
                                                        className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                        value={productObj?.productDetails?.hexCode}
                                                        onChange={handleInputChange2}
                                                        name='hexCode'
                                                    />
                                                </div>
                                            </div>
                                            <p className='text-[18px] font-semibold mt-[20px] '>Sizes And Prices :</p>
                                            <div className='lg:flex   lg:space-x-6'>
                                                <div className='flex flex-col '>
                                                    <p className='formhead  mt-[15px] '>Size</p>
                                                    <input
                                                        type='text'
                                                        className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                        value={productObj?.sizeAndPrices?.size}
                                                        onChange={handleInputChange3}
                                                        name='size'
                                                    />
                                                </div>
                                                <div className='flex flex-col '>
                                                    <p className='formhead  mt-[15px] '>Base Price</p>
                                                    <input
                                                        type='text'
                                                        className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                        value={productObj?.sizeAndPrices?.basePrice}
                                                        onChange={handleInputChange3}
                                                        name='basePrice'
                                                    />
                                                </div>
                                                <div className='flex flex-col '>
                                                    <p className='formhead  mt-[15px] '>Sell Price</p>
                                                    <input
                                                        type='text'
                                                        className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                        value={productObj?.sizeAndPrices?.sellPrice}
                                                        onChange={handleInputChange3}
                                                        name='sellPrice'
                                                    />
                                                </div>
                                            </div>
                                            <div className='lg:flex   lg:space-x-6'>
                                                <div className='flex flex-col '>
                                                    <p className='formhead  mt-[20px] '>Size Name</p>
                                                    <input
                                                        type='text'
                                                        className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                        value={productObj?.sizeAndPrices?.sizeName}
                                                        onChange={handleInputChange3}
                                                        name='sizeName'
                                                    />
                                                </div>

                                            </div>

                                            <div className='lg:flex   lg:space-x-6'>
                                                <div className='flex flex-col '>
                                                    <p className='formhead  mt-[25px] '>Stock</p>
                                                    <input
                                                        type='text'
                                                        className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                        // value={shapeObj.shape}
                                                        onChange={handleInputChange}
                                                        name='stock'
                                                    />
                                                </div>
                                                <div className='flex flex-col '>
                                                    <p className='formhead  mt-[25px] '>Stock Status</p>
                                                    <input
                                                        type='text'
                                                        className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                        // value={shapeObj.shape}
                                                        onChange={handleInputChange}
                                                        name='stockStatus'
                                                    />
                                                </div>
                                                <div className='flex flex-col '>
                                                    <p className='formhead  mt-[25px] '>Status</p>
                                                    <input
                                                        type='text'
                                                        className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                        // value={productObj.status}
                                                        onChange={handleInputChange}
                                                        name='status'
                                                    />
                                                </div>
                                            </div>

                                            <div className='lg:flex   lg:space-x-6'>
                                                <div className='flex flex-col '>
                                                    <p className='formhead  mt-[25px] '>Product SubCategory Name</p>
                                                    <select className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]' placeholder='select SubCategory Name'
                                                        name='productSubCategoryId' value={selectProduct} onChange={handleInputChange1}  >
                                                        <option value="" disabled selected hidden > Select Product Name</option>
                                                        {pro.map((pro, index) => (
                                                            <option key={pro.productSubCategoryId} name='productSubCategoryId' value={pro?.productSubCategoryId} >
                                                                {pro.productSubCategoryName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className='flex flex-col '>
                                                    <p className='formhead  mt-[25px] '>Product Art Image  </p>
                                                    <div className='forminput   mt-3 w-[100%] lg:w-[258px] rounded-lg leading-none pl-[5px]'>
                                                        <input type='file' className='text-[15px] p-[4px]'
                                                            style={{ display: 'none' }}
                                                            accept='image/*'
                                                            onChange={handleFileChange2}
                                                            ref={fileInputRef}
                                                            name='imageId' multiple />
                                                        {imageName ? (
                                                            <span>{imageName}</span>
                                                        ) : (
                                                            <a className='cursor-pointer text-[#808080] text-[30px]' onClick={handleButtonClick3}>+</a>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className='flex flex-col '>
                                                    <p className='formhead  mt-[25px] '>Index</p>
                                                    <input
                                                        type='text'
                                                        className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                        // value={productObj.status}
                                                        onChange={handleInputChange}
                                                        name='index'
                                                    />
                                                </div>
                                            </div>

                                            <div className=' h-[41px] w-[120px]  mt-[30px] mx-auto'>
                                                <button className='rounded-lg text-[white] bg-[blue] text-[17px] font-semibold h-[41px] w-[120px] '
                                                    onClick={() => {
                                                        addProduct();
                                                        close();
                                                        handleCloseForm();

                                                    }}
                                                >
                                                    Save</button>
                                            </div>
                                        </div>
                                    </div>

                                )}
                            </Popup>

                            <TablePagination className='ml-auto'
                                rowsPerPageOptions={[5, 10, 15]}
                                component="div"
                                count={product.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    </div>

                </Grid>
            </Grid>
        </>
    )
}

export default ProductMaster