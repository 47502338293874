import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, styled, tableCellClasses } from '@mui/material';
import React, { useState } from 'react'
import FrameData from './FrameData';
import Popup from 'reactjs-popup';
import { CheckBox } from '@mui/icons-material';

const Frame = () => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: 'rgb(115 103 240)',
            color: theme.palette.common.white,
            fontSize: 20,
        },
    }));


    const [frame, setFrame] = useState(FrameData);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [framedata, setFramedata] = useState(
        {

            frameName: " ",
            frameDescription: "",
            frameProductNo: "",
            frameType: "",
            width: "",
            depth: "",
            finish: "",
            style: "",
            glassType: "",
            status: "",
            frameMaterial: "",
            price: 0,
            stockStatus: "",

        },

    );

    const addFrame = () => {
        const newFrame = {

            frameName: framedata.frameName,
            frameDescription: framedata.frameDescription,
            frameProductNo: framedata.frameProductNo,
            frameType: framedata.frameType,
            width: framedata.width,
            depth: framedata.depth,
            finish: framedata.finish,
            style: framedata.style,
            glassType: framedata.glassType,
            status: framedata.status,
            frameMaterial: framedata.frameMaterial,
            price: framedata.price,
            stockStatus: framedata.stockStatus,
            frameColor: [],
            frameImageUrl: framedata.frameImageUrl,
            frameThumbnailUrl: framedata.frameThumbnailUrl,
            frameDrawingUrl: framedata.frameDrawingUrl,
        }
        setFrame([...frame, newFrame]);
        setFramedata(framedata);
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFramedata({
            ...framedata,
            [name]: value,
        })
    }

    const handleDelete = (id) => {
        const updatedFrames = frame.filter((frames) => frames.frameId !== id);
        setFrame(updatedFrames);
        console.log(updatedFrames)
    };

    const filteredData = frame.filter(frm =>
        frm.frameName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const handleSearch = event => {
        setSearchQuery(event.target.value);
        setPage(0);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <>
            <Grid  justifyContent='center' >
            <Grid item xs={12} md={10} lg={8} >
                    <div className='mt-[20px] pageMainDiv'>
                        <p className='text-[25px] font-bold mt-[40px]'>Frame Master</p>
                        <input type="text" name='name' placeholder="Search..."
                            value={searchQuery} onChange={(e) => handleSearch(e)}
                            className='mt-[10px] h-[50px] rounded-md border border-[#ABB2B9] pl-4 w-[100%]' />
                        <div className="mt-2 w-[100%] border rounded-md border-[white] bg-[white]   ">
                            <div className=' w-[100%] overflow-auto lg:overflow-hidden '>
                                <TableContainer className='h-[400px]' >
                                    <Table stickyHeader  className='border border-2 w-[100%]' >
                                        <TableHead className='bg-[#7367F0]' >
                                            <TableRow >

                                                <StyledTableCell>frameName</StyledTableCell>
                                                <StyledTableCell  ><div className='w-[400px]'>Frame Description</div></StyledTableCell>
                                                <StyledTableCell align='center'  >Frame ProductNo</StyledTableCell>
                                                <StyledTableCell align='center' >Frame Type</StyledTableCell>
                                                <StyledTableCell align='center'>Width</StyledTableCell>
                                                <StyledTableCell align='center'>Depth</StyledTableCell>
                                                <StyledTableCell align='center'>Finish</StyledTableCell>
                                                <StyledTableCell align='center'>Style</StyledTableCell>
                                                <StyledTableCell align='center'>Glass Type</StyledTableCell>
                                                <StyledTableCell align='center'>Status</StyledTableCell>
                                                <StyledTableCell align='center'>Frame Material</StyledTableCell>
                                                <StyledTableCell align='center'>Price</StyledTableCell>
                                                <StyledTableCell align='center'>Stock Status</StyledTableCell>
                                                <StyledTableCell align='center'>Size</StyledTableCell>
                                                <StyledTableCell align='center'>Frame Color</StyledTableCell>
                                                <StyledTableCell align='center'>Frame ImageUrl</StyledTableCell>
                                                <StyledTableCell align='center'>Actions</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody >
                                            {filteredData.map((frames) => (
                                                <TableRow key={frames.frameId}>

                                                    <TableCell>
                                                        <span className='text-[16px] text-black'>
                                                            {frames.frameName}
                                                        </span>
                                                    </TableCell>

                                                    <TableCell>
                                                        <span className='text-[16px] text-black'>
                                                            {frames.frameDescription}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className='text-[16px] text-black'>
                                                            {frames.frameProductNo}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className='text-[16px] text-black'>
                                                            {frames.frameType}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className='text-[16px] text-black'>
                                                            {frames.width}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className='text-[16px] text-black'>
                                                            {frames.depth}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className='text-[16px] text-black'>
                                                            {frames.finish}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className='text-[16px] text-black'>
                                                            {frames.style}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className='text-[16px] text-black'>
                                                            {frames.glassType}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        <span className='text-[16px] text-black'>
                                                            {frames.status}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className='text-[16px] text-black'>
                                                            {frames.frameMaterial}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className='text-[16px] text-black'>
                                                            {frames.price}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell align='center'>
                                                        <span className='text-[16px] text-black'>
                                                            {frames.stockStatus}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className='text-[16px] text-black'>
                                                            {frames.size}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div>
                                                            <Popup trigger={<div className='text-center'>
                                                                <button

                                                                    className='bg-[#7367F0] h-[40px] w-[120px] rounded-lg text-[white] font-medium text-[17px]'
                                                                >
                                                                    Select Color
                                                                </button>
                                                            </div>}
                                                                modal
                                                                closeOnDocumentClick
                                                            >
                                                                {(close) => (
                                                                    <div className='rounded-lg flex bg-[white] shadow'>

                                                                        <TableContainer className='h-[400px] shadow' >
                                                                            <Table stickyHeader className='border border-2' >
                                                                                <TableHead className='bg-[#7367F0]' >
                                                                                    <TableRow>
                                                                                        <StyledTableCell></StyledTableCell>
                                                                                        <StyledTableCell>colorName</StyledTableCell>
                                                                                        <StyledTableCell align='center' >colorCode</StyledTableCell>
                                                                                        <StyledTableCell align='center'  >Price</StyledTableCell>
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                    {frames.frameColor.map((color, index) => (
                                                                                        <TableRow key={index}>
                                                                                            <TableCell>
                                                                                                <input type='checkbox' />
                                                                                            </TableCell>
                                                                                            <TableCell>{color.colorName}</TableCell>
                                                                                            <TableCell align='center'>{color.colorCode}</TableCell>
                                                                                            <TableCell align='center'>{color.price}</TableCell>
                                                                                        </TableRow>
                                                                                    ))}
                                                                                </TableBody>
                                                                            </Table>
                                                                        </TableContainer>
                                                                    </div>
                                                                )



                                                                }
                                                            </Popup>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>

                                                        <div>
                                                            <Popup trigger={<div className='text-center'>
                                                                <Button

                                                                    style={{
                                                                        backgroundColor: 'orange',
                                                                        color: 'white',
                                                                    }}
                                                                >
                                                                    View
                                                                </Button>
                                                            </div>}
                                                                modal
                                                                closeOnDocumentClick
                                                            >
                                                                {(close) => (<div
                                                                    className='w-[400px] lg:w-[600px] lg:h-[350px] h-[250px] rounded-lg flex bg-[white] shadow'>

                                                                    <div className='flex-col w-[80px] lg:w-[150px] lg:h-[130px] h-[80px] ml-[30px]'>
                                                                        <img src={frames.frameImageUrl} className='lg:w-[100px] lg:h-[100px] ' />
                                                                        <span className='lg:text-[15px] text-[13px]'>frameImageUrl</span></div>
                                                                    <div className='flex-col w-[80px] lg:w-[150px] lg:h-[130px] h-[80px] ml-[40px]'>
                                                                        <img src={frames.frameThumbnailUrl} className='lg:w-[100px] lg:h-[100px] ' />
                                                                        <span className='lg:text-[15px] text-[13px]'>frameThumbnailUrl</span></div>
                                                                    <div className='flex-col w-[80px] lg:w-[150px] lg:h-[130px] h-[80px] ml-[50px]'>
                                                                        <img src={frames.frameDrawingUrl} className='lg:w-[100px] lg:h-[100px]' />
                                                                        <span className='lg:text-[15px] text-[13px]'>frameDrawingUrl</span></div>
                                                                </div>)}
                                                            </Popup>
                                                        </div>

                                                    </TableCell>
                                                    <TableCell >
                                                        <div>
                                                            <div className='flex space-x-2 '>
                                                                <Popup
                                                                    trigger={
                                                                        <div className='ml-[20px]'>


                                                                            <Button
                                                                                // onClick={() => {
                                                                                //   setUpdatedData(transaction);
                                                                                // }}
                                                                                style={{
                                                                                    backgroundColor: 'orange',
                                                                                    color: 'white',
                                                                                }}
                                                                            >
                                                                                Update
                                                                            </Button>



                                                                        </div>
                                                                    }
                                                                    modal
                                                                    closeOnDocumentClick
                                                                >
                                                                    {(close) => (
                                                                        <div className='w-[370px] lg:w-[651px] lg:h-[542px] h-[820px] rounded-lg bg-[white] shadow'>
                                                                            <div className='flex'>
                                                                                <p className=' pt-[20px] text-[23px] font-medium ml-4 '>
                                                                                    Update frame
                                                                                </p>
                                                                                <button
                                                                                    className='ml-auto h-[40px] w-[40px]'
                                                                                    onClick={close}
                                                                                >
                                                                                    &times;
                                                                                </button>
                                                                            </div>
                                                                            <hr className='mt-[25px] lg:w-[651px] h-[1px] bg-[white]'></hr>
                                                                            <div className="mx-[auto]  lg:h-[305px] h-[590px] w-[350px] lg:w-[585px] lg:mt-[20px] mt-[10px] overflow-y-auto">
                                                                                <div className='lg:flex lg:ml-[22px] ml-[15px]'>
                                                                                    <div className='flex flex-col'>
                                                                                        <p className='formhead lg:mt-[20px] mt-[10px] '>Frame Name </p>
                                                                                        <textarea
                                                                                            type='text'
                                                                                            className='forminput  mt-3 w-[320px] lg:w-[537px] rounded-lg'
                                                                                        // placeholder='Enter name'
                                                                                        // name='name'
                                                                                        // onChange={handleInputChange}

                                                                                        />
                                                                                        <p className='formhead lg:mt-[20px] mt-[10px] '>Frame Discription </p>
                                                                                        <textarea
                                                                                            type='text'
                                                                                            className='forminput  mt-3 w-[320px] lg:w-[537px] rounded-lg'
                                                                                        // placeholder='Enter name'
                                                                                        // name='name'
                                                                                        // onChange={handleInputChange}

                                                                                        />

                                                                                        <div className='lg:flex '>
                                                                                            <div className='flex flex-col'>
                                                                                                <p className='formhead lg:mt-[20px] mt-[10px] '>Frame Product Number</p>
                                                                                                <input type='text' className='forminput  mt-3 w-[320px] lg:w-[258px] rounded-lg' />

                                                                                            </div>
                                                                                            <div className='flex flex-col lg:ml-[20px]'>
                                                                                                <p className='formhead lg:mt-[20px] mt-[10px]'>Frame Type</p>
                                                                                                <input type='text' className='forminput  mt-3  w-[320px] lg:w-[258px] ' /></div>

                                                                                        </div>

                                                                                        <div className='lg:flex '>
                                                                                            <div className='flex flex-col'>
                                                                                                <p className='formhead lg:mt-[20px] mt-[10px] '>Frame Width</p>
                                                                                                <input type='text' className='forminput  mt-3 w-[320px] lg:w-[258px] rounded-lg' />

                                                                                            </div>
                                                                                            <div className='flex flex-col lg:ml-[20px]'>
                                                                                                <p className='formhead lg:mt-[20px] mt-[10px]'>Frame Depth</p>
                                                                                                <input type='text' className='forminput  mt-3  w-[320px] lg:w-[258px] ' /></div>
                                                                                        </div>

                                                                                        <div className='lg:flex '>
                                                                                            <div className='flex flex-col'>
                                                                                                <p className='formhead lg:mt-[20px] mt-[10px] '>Finish</p>
                                                                                                <input type='text' className='forminput  mt-3 w-[320px] lg:w-[258px] rounded-lg' />

                                                                                            </div>
                                                                                            <div className='flex flex-col lg:ml-[20px]'>
                                                                                                <p className='formhead lg:mt-[20px] mt-[10px]'>Frame Style</p>
                                                                                                <input type='text' className='forminput  mt-3  w-[320px] lg:w-[258px] ' /></div>

                                                                                        </div>
                                                                                        <div className='lg:flex '>
                                                                                            <div className='flex flex-col'>
                                                                                                <p className='formhead lg:mt-[20px] mt-[10px] '>Glass Type</p>
                                                                                                <input type='text' className='forminput  mt-3 w-[320px] lg:w-[258px] rounded-lg' />

                                                                                            </div>
                                                                                            <div className='flex flex-col lg:ml-[20px]'>
                                                                                                <p className='formhead lg:mt-[20px] mt-[10px]'>Frame Status</p>
                                                                                                <input type='text' className='forminput  mt-3  w-[320px] lg:w-[258px] ' /></div>
                                                                                        </div>
                                                                                        <div className='lg:flex '>
                                                                                            <div className='flex flex-col'>
                                                                                                <p className='formhead lg:mt-[20px] mt-[10px] '>Frame Material</p>
                                                                                                <input type='text' className='forminput  mt-3 w-[320px] lg:w-[258px] rounded-lg' />

                                                                                            </div>
                                                                                            <div className='flex flex-col lg:ml-[20px]'>
                                                                                                <p className='formhead lg:mt-[20px] mt-[10px]'>Price</p>
                                                                                                <input type='text' className='forminput  mt-3  w-[320px] lg:w-[258px] ' /></div>
                                                                                        </div>
                                                                                        <div className='lg:flex '>
                                                                                            <div className='flex flex-col'>
                                                                                                <p className='formhead lg:mt-[20px] mt-[10px] '>Stock Status</p>
                                                                                                <input type='text' className='forminput  mt-3 w-[320px] lg:w-[258px] rounded-lg' />

                                                                                            </div>

                                                                                        </div>

                                                                                    </div></div>
                                                                            </div>
                                                                            <hr className='mt-[35px] lg:w-[651px] h-[1px] bg-[white]'></hr>
                                                                            <button
                                                                                className=' rounded-lg text-[white] bg-[blue] ml-[280px] text-[18px] font-semibold h-[41px] w-[120px] mt-[20px]'
                                                                                onClick={() => {

                                                                                    close();
                                                                                }}
                                                                            >
                                                                                Save Update
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </Popup>
                                                                <Button
                                                                    onClick={() => handleDelete(frames.frameId)}
                                                                    style={{
                                                                        backgroundColor: 'red',
                                                                        color: 'white',
                                                                    }}
                                                                >
                                                                    Delete
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </TableCell>

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 15]}
                                    component="div"
                                    count={frame.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />

                                <div>
                                    <Popup
                                        trigger={
                                            <button className='bg-[#7367F0] h-[40px] w-[150px] rounded-lg text-[white] text-[16px]'>
                                                Add Frames
                                            </button>
                                        }
                                        modal
                                        closeOnDocumentClick
                                    >
                                        {(close) => (
                                            <div className='w-[370px] lg:w-[651px] lg:h-[542px] h-[820px] rounded-lg bg-[white] shadow'>
                                                <div className='flex'>
                                                    <p className=' pt-[20px] text-[23px] font-medium ml-4 '>
                                                        Add frame
                                                    </p>
                                                    <button
                                                        className='ml-auto h-[40px] w-[40px]'
                                                        onClick={close}
                                                    >
                                                        &times;
                                                    </button>
                                                </div>
                                                <hr className='mt-[25px] lg:w-[651px] h-[1px] bg-[white]'></hr>
                                                <div className="mx-[auto]  lg:h-[305px] h-[590px] w-[350px] lg:w-[585px] lg:mt-[20px] mt-[10px] overflow-y-auto">
                                                    <div className='lg:flex lg:ml-[22px] ml-[15px]'>
                                                        <div className='flex flex-col'>
                                                            <p className='formhead lg:mt-[20px] mt-[10px] '>Frame Name </p>
                                                            <textarea
                                                                type='text'
                                                                className='forminput  mt-3 w-[320px] lg:w-[537px] rounded-lg'

                                                                name='frameName'
                                                                onChange={handleInputChange}
                                                                value={framedata.frameName}
                                                            />
                                                            <p className='formhead lg:mt-[20px] mt-[10px] '>Frame Discription </p>
                                                            <textarea
                                                                type='text'
                                                                className='forminput  mt-3 w-[320px] lg:w-[537px] rounded-lg'

                                                                name='frameDescription'
                                                                onChange={handleInputChange}
                                                                value={framedata.frameDescription}
                                                            />

                                                            <div className='lg:flex '>
                                                                <div className='flex flex-col'>
                                                                    <p className='formhead lg:mt-[20px] mt-[10px] '>Frame Product Number</p>
                                                                    <input type='text' className='forminput  mt-3 w-[320px] lg:w-[258px] rounded-lg'

                                                                        name='frameProductNo'
                                                                        onChange={handleInputChange}
                                                                        value={framedata.frameProductNo} />

                                                                </div>
                                                                <div className='flex flex-col lg:ml-[20px]'>
                                                                    <p className='formhead lg:mt-[20px] mt-[10px]'>Frame Type</p>
                                                                    <input type='text' className='forminput  mt-3  w-[320px] lg:w-[258px] '
                                                                        name='frameType'
                                                                        onChange={handleInputChange}
                                                                        value={framedata.frameType} /></div>

                                                            </div>

                                                            <div className='lg:flex '>
                                                                <div className='flex flex-col'>
                                                                    <p className='formhead lg:mt-[20px] mt-[10px] '>Frame Width</p>
                                                                    <input type='text' className='forminput  mt-3 w-[320px] lg:w-[258px] rounded-lg'
                                                                        name='width'
                                                                        onChange={handleInputChange}
                                                                        value={framedata.width} />

                                                                </div>
                                                                <div className='flex flex-col lg:ml-[20px]'>
                                                                    <p className='formhead lg:mt-[20px] mt-[10px]'>Frame Depth</p>
                                                                    <input type='text' className='forminput  mt-3  w-[320px] lg:w-[258px] '
                                                                        name='depth'
                                                                        onChange={handleInputChange}
                                                                        value={framedata.depth} /></div>
                                                            </div>

                                                            <div className='lg:flex '>
                                                                <div className='flex flex-col'>
                                                                    <p className='formhead lg:mt-[20px] mt-[10px] '>Finish</p>
                                                                    <input type='text' className='forminput  mt-3 w-[320px] lg:w-[258px] rounded-lg'
                                                                        name='finish'
                                                                        onChange={handleInputChange}
                                                                        value={framedata.finish} />

                                                                </div>
                                                                <div className='flex flex-col lg:ml-[20px]'>
                                                                    <p className='formhead lg:mt-[20px] mt-[10px]'>Frame Style</p>
                                                                    <input type='text' className='forminput  mt-3  w-[320px] lg:w-[258px] '
                                                                        name='style'
                                                                        onChange={handleInputChange}
                                                                        value={framedata.style} /></div>

                                                            </div>
                                                            <div className='lg:flex '>
                                                                <div className='flex flex-col'>
                                                                    <p className='formhead lg:mt-[20px] mt-[10px] '>Glass Type</p>
                                                                    <input type='text' className='forminput  mt-3 w-[320px] lg:w-[258px] rounded-lg'
                                                                        name='glassType'
                                                                        onChange={handleInputChange}
                                                                        value={framedata.glassType} />

                                                                </div>
                                                                <div className='flex flex-col lg:ml-[20px]'>
                                                                    <p className='formhead lg:mt-[20px] mt-[10px]'>Frame Status</p>
                                                                    <input type='text' className='forminput  mt-3  w-[320px] lg:w-[258px] '
                                                                        name='status'
                                                                        onChange={handleInputChange}
                                                                        value={framedata.status} /></div>
                                                            </div>
                                                            <div className='lg:flex '>
                                                                <div className='flex flex-col'>
                                                                    <p className='formhead lg:mt-[20px] mt-[10px] '>Frame Material</p>
                                                                    <input type='text' className='forminput  mt-3 w-[320px] lg:w-[258px] rounded-lg'
                                                                        name='frameMaterial'
                                                                        onChange={handleInputChange}
                                                                        value={framedata.frameMaterial} />

                                                                </div>
                                                                <div className='flex flex-col lg:ml-[20px]'>
                                                                    <p className='formhead lg:mt-[20px] mt-[10px]'>Price</p>
                                                                    <input type='text' className='forminput  mt-3  w-[320px] lg:w-[258px] '
                                                                        name='price'
                                                                        onChange={handleInputChange}
                                                                        value={framedata.price} /></div>
                                                            </div>
                                                            <div className='lg:flex '>
                                                                <div className='flex flex-col'>
                                                                    <p className='formhead lg:mt-[20px] mt-[10px] '>Stock Status</p>
                                                                    <input type='text' className='forminput  mt-3 w-[320px] lg:w-[258px] rounded-lg'
                                                                        name='stockStatus'
                                                                        onChange={handleInputChange}
                                                                        value={framedata.stockStatus} />

                                                                </div>

                                                            </div>

                                                        </div></div>
                                                </div>
                                                <hr className='mt-[35px] lg:w-[651px] h-[1px] bg-[white]'></hr>
                                                <button
                                                    className=' rounded-lg text-[white] bg-[blue] ml-[280px] text-[18px] font-semibold h-[41px] w-[80px] mt-[20px]'
                                                    onClick={() => {
                                                        addFrame();
                                                        close();
                                                    }}
                                                >
                                                    Add
                                                </button>
                                            </div>
                                        )}
                                    </Popup>
                                </div>
                            </div></div></div>
                </Grid></Grid>
        </>
    )
}

export default Frame;