import React, { useEffect, useState } from 'react';
import { httpClient } from '../../axios';
import Popup from 'reactjs-popup';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from '@mui/material';
import { Stack } from '@mui/system';
function Faq() {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'rgb(115 103 240)',
      color: theme.palette.common.white,
      fontSize: 20,
    },
  }));
  const [userList, setUser] = useState([]);
  const [updatedData, setUpdatedData] = useState({});
 
  const [type, setType] = useState('art');

  const getMaster = async () => {
    const res = await httpClient.get(
      `/frequently_asked_master/getTypeWiseFaq/${type}`
    );
    // .then((res) => {
    console.log(res.data);
    setUser(res.data);
    // });
  };
  // const handleTypeChange = (event) => {
  //   const type = event.target.value;
  //   setSelectedType(type);
  //   getMaster(type);
  // };
  useEffect(() => {
    console.log(updatedData);
  }, [updatedData]);

  useEffect(() => {
    getMaster();
  }, [type]);

  const UpdateStatus = async (data) => {
    console.log(data);
    const res = await httpClient.put(
      '/frequently_asked_master/update/',
      data
    );
    // .then((res) => {
    console.log(res.data);
    // setUserId(id);
    getMaster();
  };

  const Update = async () => {
    console.log(updatedData);
    const res = await httpClient.put(
      '/frequently_asked_master/update/',
      updatedData
    );
    // if (res) {
    getMaster();
    // }
    // .then((res) => {
    console.log(res.data);
    // setUserId(id);
  };

  const handleInputChange = (e) => {
    setUpdatedData({
      ...updatedData,
      [e.target.name]: e.target.value,
    });
  };
  const setActiveStatus = (transaction) => {
    transaction.status = 'Active';
    // console.log(transaction);

    UpdateStatus(transaction);
  };

  const setInActiveStatus = (transaction) => {
    transaction.status = 'InActive';
    // console.log(transaction);

    UpdateStatus(transaction);
  };

  const handleTypeChange = (event) => {
    setType(event.target.value); // Update the 'type' state with the selected value

    // console.log(event.target.value);
    // getMaster();
  };

  const addUserDetails = async () => {
    const res = await httpClient.post( '/frequently_asked_master/save/',
      { ...updatedData, type }
    );
    // .then((res) => {
    console.log(res.data);
    getMaster(type);
  };
  useEffect(() => {
    getMaster();
  }, []);
  const deleteData = async (faqId) => {
    const sureDelete=window.confirm("Are You Sure The Delete Record..?");
    if(sureDelete){
    const res = await httpClient.delete(
      `/frequently_asked_master/DeleteByFaqId/${faqId}`
    );
    console.log(res.data);
    getMaster(type);}
  };
  return (
    <Grid  justifyContent='center'>
      <Grid item xs={12} md={10} lg={8}>
        <div className='  pageMainDiv '>
          <p className='lg:text-[25px] text-[16px] mt-[45px] font-semibold'>
            Frequenstly Ask Question
          </p>
          <select
            className='lg:h-[50px] lg:w-[300px] h-[40px] w-[140px] bg-[#F8F8F4] mt-[20px] pl-[10px]'
            value={type}
            onChange={handleTypeChange}
          >
            <option
              // onClick={() => setType('art')}
              value='art'
              
            >
              <p>Art</p>
            </option>
            <option
              // onClick={() => setType('product')}
              value='product'
            >
              Product
            </option>
          </select>
          <div className='mt-[20px]  w-[100%]'>
            <TableContainer className=' h-[500px] '>
              <Table className='border border-[#CECECE] ' stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell className=''>
                      Questions
                    </StyledTableCell>
                    <StyledTableCell>Answers</StyledTableCell>
                    <StyledTableCell >
                    Status
                    </StyledTableCell>
                    <StyledTableCell align='center'>Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody className=' '>
                  {userList.map((transaction) => (
                    <tr key={transaction.faqId}>
                      <TableCell>
                        {' '}
                        <span className='text-[16px]'>
                          {transaction.question}
                        </span>
                      </TableCell>
                      <TableCell>
                        {' '}
                        <span>{transaction.answer}</span>
                      </TableCell>
                      <TableCell>
                        {transaction.status === 'Active' ? (
                          <Button 
                            // onClick={() => {
                            //   setInActiveStatus(transaction);
                            // }}
                            style={{
                              backgroundColor: 'green',
                              color: 'white',
                              cursor: 'auto', 
                            }}
                          >
                            Active
                          </Button>
                        ) : (
                          <Button
                            // onClick={() => {
                            //   setActiveStatus(transaction);
                            // }}
                            style={{
                              backgroundColor: 'yellow',
                              color: 'black',
                            }}
                          >
                            InActive
                          </Button>
                        )}
                      </TableCell>
                      <TableCell align='center'>
                      <div className='lg:flex '>
                        <div className='flex space-x-2 mx-auto'>
                          <Popup
                            trigger={
                              <div className='flex '>
                                
                                  <Stack spacing={2} direction='row'>
                                    <Button
                                      onClick={() => {
                                        setUpdatedData(transaction);
                                      }}
                                      style={{
                                        backgroundColor: 'orange',
                                        color: 'white',
                                      }}
                                    >
                                      Update{' '}
                                    </Button>
                                    
                                  </Stack>
                                
                              </div>
                            }
                            modal
                            closeOnDocumentClick
                          >
                            {(close) => (
                              <div className='w-[370px] lg:w-[651px]  rounded-lg bg-[white] shadow p-[30px] relative'>
                                <div className='flex'>
                                  <p className='pt-[23px] text-[23px] font-medium ml-4'>
                                    Update Question
                                  </p>
                                  <button
                                    className='ml-auto h-[40px] w-[40px]'
                                    onClick={close}
                                  >
                                    &times;
                                  </button>
                                </div>
                                <div className=' h-[300px] w-[500px] mx-auto mt-[30px]'>
                                  <textarea
                                    type='text'
                                    className='lg:h-[80px] lg:w-[500px] h-[70px] w-[350px] bg-[#F8F8F4] pl-[10px] lg:text-[18px] text-[16px]'
                                    name='question'
                                    placeholder='Enter Question'
                                    value={updatedData.question}
                                    onChange={handleInputChange}
                                  />
                                  <textarea
                                    type='text'
                                    className='lg:h-[80px] lg:w-[500px] h-[70px] w-[350px] bg-[#F8F8F4] pl-[10px] mt-[20px]'
                                    placeholder='Enter Answer'
                                    name='answer'
                                    value={updatedData.answer}
                                    onChange={handleInputChange}
                                  />
                                  <br />
                                  <div className='flex space-x-2 mt-[20px]'>
                                  <button
                                    className='rounded-lg text-[white] bg-[blue] text-[18px] font-semibold h-[41px] w-[80px]'
                                    onClick={() => {
                                      Update();
                                      close();
                                    }}
                                  >
                                    Update
                                  </button>
                                  {transaction.status === 'Active' ? (
                          <Button className='h-[41px] w-[80px]  '
                            onClick={() => {
                              setInActiveStatus(transaction);
                            }}
                            style={{
                              backgroundColor: 'green',
                              color: 'white',
                            }}
                          >
                            Active
                          </Button>
                        ) : (
                          <Button className='h-[41px] w-[80px]'
                            onClick={() => {
                              setActiveStatus(transaction);
                            }}
                            style={{
                              backgroundColor: 'yellow',
                              color: 'black',
                            }}
                          >
                            InActive
                          </Button>
                        )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </Popup>
                          <Button
                                      onClick={() =>
                                        deleteData(transaction.faqId)
                                      }
                                      style={{
                                        backgroundColor: 'red',
                                        color: 'white',
                                      }}
                                    >
                                      Delete
                                    </Button>
                        </div>
                      </div>
                      </TableCell>
                     
                    </tr>
                  ))}
                </TableBody>
              </Table>
              <Popup
              trigger={
                <button className='bg-[#7367F0] h-[40px] w-[150px] rounded-lg text-[white] text-[16px]'>
                  Add New Question
                </button>
              }
              modal
              closeOnDocumentClick
            >
              {(close) => (
                <div className='w-[370px] lg:w-[651px]  rounded-lg bg-[white] shadow p-[30px] relative'>
                  <div className='flex'>
                    <p className=' text-[23px] font-medium ml-4 '>
                      Add New Question
                    </p>
                    <button
                      className='ml-auto h-[40px] w-[40px]'
                      onClick={close}
                    >
                      &times;
                    </button>
                  </div>
                  <div className=' h-[300px] w-[500px] mx-auto mt-[20px]'>
                    <select
                      className='h-[50px] w-[300px] bg-[#F8F8F4] pl-[10px]'
                      value={type}
                    >
                      <option
                        onClick={() => setType('art')}
                        value='art'
                      >
                        Art
                      </option>
                      <option
                        onClick={() => setType('product')}
                        value='product'
                      >
                        Product
                      </option>
                    </select>
                    <textarea
                      type='text'
                      className='lg:h-[80px] lg:w-[500px] h-[70px] w-[350px]  bg-[#F8F8F4] pl-[10px] mt-[20px] pt-[25px]'
                      name='question'
                      placeholder='Enter Question'
                      onChange={handleInputChange}
                    />
                    <textarea
                      type='text'
                      className='lg:h-[80px] lg:w-[500px] h-[70px] w-[350px] bg-[#F8F8F4] pl-[10px] mt-[20px] pt-[25px]'
                      placeholder='Enter Answer'
                      name='answer'
                      onChange={handleInputChange}
                    />
                    <br />
                    <button
                      className='rounded-lg text-[white] bg-[blue] text-[18px] font-semibold h-[41px] w-[80px] mt-[20px]'
                      onClick={() => {
                        addUserDetails();
                        close();
                      }}
                    >
                      Add
                    </button>
                  </div>
                </div>
              )}
            </Popup>
            </TableContainer>
            
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
export default Faq;
