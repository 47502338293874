import { Table, TableCell, TableContainer, TableHead, TableRow, tableCellClasses, styled, TableBody, Grid, Button, TablePagination } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';

import Popup from 'reactjs-popup';
import { httpClient } from '../../axios';
import EditIcon from '@mui/icons-material/Edit';

function Pincode() {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#4169E1',
      color: theme.palette.common.white,
      fontSize: 20,
    },
    '&.centered': {
      textAlign: 'center',
    },

  }));
  const [updatedData, setupdatedData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [pin2, setPin] = useState([]);
  const [country, setCountry] = useState([]);
  const [selectCountry, setselectCountry] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [pinObj, setPinObj] = useState(
    {
      pinCodeId: "",
      pinCode: 0,
      status: "",
      countryId: ""
    }

  )
  const [formData, setFormData] = useState({
    pinCode: 0,
    countryId: ''
  })
  const updateReviewInActive = (obj) => {
    setPinObj({
      ...pinObj, pinCodeId: obj.pinCodeId, pinCode: obj.pinCode, status: 'InActive',
      countryId: obj.countryMaster.countryId
    })
  }
  const updateReviewActive = (obj) => {
    setPinObj({
      ...pinObj, pinCodeId: obj.pinCodeId, pinCode: obj.pinCode, status: 'Active',
      countryId: obj.countryMaster.countryId
    })
  }
  useEffect(() => {
    // console.log(reviewObj);
    UpdatedStatus();
  }, [pinObj]);

  const UpdatedStatus = async () => {
    try {
      console.log(pinObj);
      await httpClient.put('/pinCode_master/update/', pinObj);

      getPincode();
    } catch (error) {
      console.error('Error updating record:', error);
    }
  };


  const filteredData = pin2.filter(con => {
    if (con && con.pinCode !== undefined && con.pinCode.toString().includes(searchQuery.toLocaleLowerCase())) {
      return true;
    } else {
      return false;
    }
  });
  const handleSearch3 = event => {
    setSearchQuery(event.target.value);
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getPincode = async () => {
    try {
      const res = await httpClient.get('/pinCode_master');
      console.log(res.data);
      setPin(res.data);
    } catch (error) {
      console.error('Error fetching pincode media:', error);

    }
  };
  const getCountry = async () => {
    try {
      const res = await httpClient.get('/country_master/');
      console.log(res.data);
      setCountry(res.data);
    } catch (error) {
      console.error('Error fetching pincode media:', error);

    }
  };
  useEffect(() => {
    getCountry();
  }, []);

  const handlechange2 = (event) => {
    setselectCountry(event.target.value);
  }

  const addRecord = async () => {


    try {
      const selectedCountry = country.find(country => country.countryName === selectCountry);
      if (selectedCountry) {
        const newPincode = {
          ...updatedData,
          countryId: selectedCountry.countryId // Set the countryId based on selectedCountry
        };
        await httpClient.post('/pinCode_master/create/', newPincode);
        getPincode();
      }
    } catch (error) {
      console.error('Error adding record:', error);
    }
  };


  useEffect(() => {
    addRecord();
  }, []);

  const handleChange = (e) => {
    setupdatedData({
      ...updatedData,
      [e.target.name]: e.target.value,
    });


  };
  const handleChange3 = (e) => {
    setPinObj({
      ...pinObj,
      [e.target.name]: e.target.value,
    });
  };


  useEffect(() => {
    getPincode();
  }, []);

  const deleteRecord = async (pinCodeId) => {
    const sureDelete = window.confirm("Are You Sure The Delete Record..?");

    if (sureDelete) {
      const res = await httpClient.delete(`/pinCode_master/deleteByPinCodeId/${pinCodeId}`);
      getPincode();
      console.log('delete');
    }
  }

  const updatePin = (newPin) => {
    setPinObj({
      ...pinObj,
      pinCode: newPin
    });
  };


  useEffect(() => {
    updateRecord();
  }, [pinObj]);

  const updateRecord = async () => {
    try {

      await httpClient.put('/pinCode_master/update', pinObj);
      console.log('Updating pinObj:', pinObj);

      getPincode();
    } catch (error) {
      console.error('Error updating record:', error);
    }
  };



  return (
    <Grid justifyContent="center">
      <Grid item xs={12} md={10} lg={8} >

        <div className='mx-[auto]  pageMainDiv'>
          <h1 className='lg:text-[25px] text-[16px] mt-[57px] font-bold'>Pincode Master</h1>
          <input
            type="text"
            placeholder="Search By Country Name"
            value={searchQuery}
            onChange={handleSearch3}
            className=' h-[50px] rounded-md border border-[#ABB2B9] pl-4 w-[100%]'
          />

          <TableContainer sx={{ maxHeight: 440 }} >
            <Table sx={{ minWidth: 550 }} stickyHeader size="small" className='mt-4 border border-[gray]'   >
              <TableHead>
                <TableRow >
                  <StyledTableCell>Pincode</StyledTableCell>
                  <StyledTableCell align='center'>Country name</StyledTableCell>
                  <StyledTableCell align='center'>Status</StyledTableCell>
                  <StyledTableCell align='center'>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ maxHeight: 440, overflowY: 'auto' }}>
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((p1) => (
                    <TableRow key={p1.pinCodeId} >
                      <TableCell>
                        <span className='text-[16px]'>{p1.pinCode} </span>
                      </TableCell>
                      <TableCell align='center'>
                        <span className='text-[16px]'>{p1.countryMaster ? p1.countryMaster.countryName : ''}
                        </span></TableCell>
                      <TableCell align='center'>
                        {
                          p1.status === 'Active' ? (
                            <Button style={{ backgroundColor: 'green', color: 'white', cursor: 'auto' }}
                            >Active</Button>
                          ) : (
                            <Button style={{ backgroundColor: 'yellow', color: 'black', cursor: 'auto' }} >InActive</Button>
                          )
                        }

                      </TableCell>
                      <TableCell align='center'>
                        <div className='flex space-x-2 '>
                          <Popup
                            trigger={
                              <div className='lg:ml-[50px]'><Button align="center"
                                style={{ backgroundColor: 'orange', color: 'white ', marginLeft: 200 }}
                                onClick={() => updatePin(p1.pinCode)}
                              >Update</Button></div>}
                            modal
                            closeOnDocumentClick
                          >
                            {close => (
                              <div className="lg:w-[450px] w-[370px] lg:h-[100%] h-[100%px] bg-[white] shadow rounded p-[30px]">
                                <p className=' lg:text-[25px] text-[16px]  font-semibold'>Update Records</p>
                                <h className=' mt-[20px] font-semibold lg:text-[18px] text-[12px]'>Pincode : </h>
                                <div className='rounded  mt-[10px] rounded w-[100%] bg-gray-100'>
                                  <textarea
                                    className=' lg:w-[350px] w-[272px]   text-[18px] lg:h-[60px] bg-[#DADBDD] p-[6px]'
                                    name='pinCode'
                                    value={pinObj.pinCode}
                                    placeholder='Enter Pincode'
                                    onChange={handleChange3}
                                  ></textarea>
                                </div>
                                <div className='flex'>
                                  <Button className='h-[40px] rounded w-[120px] mt-[20px] flex flex-col'
                                    onClick={() => { updateRecord(); close() }} style={{ backgroundColor: '#1F45FC', color: 'white', marginTop: 20, marginLeft: 5 }}>

                                    <span className=' '> Update</span></Button>
                                  {
                                    p1.status === 'Active' ? (
                                      <Button onClick={() => updateReviewInActive(p1)}
                                        style={{ backgroundColor: 'green', color: 'white', marginTop: 20, marginLeft: 5 }}
                                        className='h-[40px] w-[120px]'>Active</Button>
                                    ) : (
                                      <Button onClick={() => updateReviewActive(p1)}
                                        style={{ backgroundColor: 'yellow', color: 'black', marginTop: 20, marginLeft: 5 }}
                                        className='h-[40px] w-[120px]'>InActive</Button>
                                    )
                                  }

                                </div>

                              </div>

                            )}
                          </Popup>
                          <Button style={{ backgroundColor: 'red', color: 'white ' }} align="center"
                            onClick={() => deleteRecord(p1.pinCodeId)}>Delete </Button>
                        </div>
                      </TableCell>

                    </TableRow>
                  ))}

              </TableBody>
            </Table>
          </TableContainer>
          <Popup
            trigger={<div className='mt-4'> <button className='bg-[#7367F0] h-[40px] w-[150px] rounded-lg text-[white] text-[16px]'>
              Add Record </button></div>}
            modal
            open={isOpen}
            closeOnDocumentClick
          >
            {close => (
              <div className="lg:w-[400px] w-[400px]  h-[100%] bg-[white] shadow rounded  p-[30px]">
                <p className=' lg:text-[25px] text-[16px]  font-semibold'>Add Records</p>

                <p className='mt-[10px] font-semibold lg:text-[18px] text-[12px]'>Pincode : </p>
                <div className='rounded   rounded '>
                  <input type='text'
                    className='mt-[10px] w-[100%]  pl-4  text-[18px] h-[60px] bg-[#DADBDD]'
                    name='pinCode'
                    value={updatedData.Pincode}
                    placeholder='Enter Pincode'
                    onChange={handleChange}
                  ></input>
                </div>
                <select className='h-[70px] w-[100%] bg-[#DCDCDC] mt-4 text-gray-200  pl-4 '
                  placeholder='select Country'
                  value={selectCountry}
                  onChange={handlechange2}  >
                  <option value="" disabled selected hidden > Select Country Name</option>
                  {country.map((country) => (
                    <option key={country.countryId} value={country.countryName} >
                      {country.countryName}
                    </option>
                  ))}
                </select>

                <button className='h-[40px] rounded w-[110px] mx-[auto] mt-[20px]   bg-[#1F45FC] text-[white]'
                  onClick={() => { addRecord(); close() }} >
                  Add Record
                </button>
              </div>
            )}
          </Popup>

        </div>

        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={pin2.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

      </Grid>
    </Grid >
  )
}

export default Pincode