import React from 'react';
// import Table1 from '../Table/Table1';
import Table2 from '../Table/Table2';

const Table = () => {
  return <div className='pageMainDiv'>
  <Table2/>
  </div>;
};

export default Table;
