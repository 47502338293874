import React, { useEffect, useRef, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, tableCellClasses } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { httpClient } from '../../axios';
import Popup from 'reactjs-popup';
import SendIcon from '@mui/icons-material/Send';
import { setData } from '../../store/Image1Slice';
function Subject() {
  const dispatch = useDispatch();
  const [postObj, setPostObj] = useState({
    link: '', subjectName: '', subjectDescription: '', subjectStatus: 'Active', gridHeight: '', gridWidth: '',
    gridColumn: '', type: '', artDropdown: 'true'
  });
  const [ImageLink, setImageLink] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const tableData = useSelector((state) => state.table.data);
  useEffect(() => {
    // dispatch(setData([]))
    console.log('updated table data: ', tableData)

  }, [tableData])

  useEffect(() => {
    console.log(postObj);
  }, [postObj])


  const changeName = (e, index) => {
    const { name, value } = e.target;
    setPostObj((prevPostObj) => ({
      ...prevPostObj,
      [name]: value,
    }));
  };
  const handleFileChange2 = async () => {
    if (selectedFile) {
      let formData = new FormData();
      formData.append('file', selectedFile);
      try {
        const res = await httpClient.post(
          '/CloudinaryImageUpload?parameter=false',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        console.log(res.data);
        setPostObj({ ...postObj, link: res.data });
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };
  
  const handleDelete = (index) => {
    const sureDelete = window.confirm("Are You Sure The Delete Record..?");
    if (sureDelete) {
    const updatedData = [...tableData];
    updatedData.splice(index, 1); 
    dispatch(setData(updatedData));
    setEditIndex(null);
    }
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    const selectedItem = tableData[index];
    if (selectedItem) {
      setPostObj({
        link: selectedItem.link || '',
        subjectName: selectedItem.subjectName || '',
        subjectDescription: selectedItem.subjectDescription || '',
        subjectStatus: selectedItem.subjectStatus || '',
        gridHeight: selectedItem.gridHeight || '',
        gridWidth: selectedItem.gridWidth || '',
        gridColumn: selectedItem.gridColumn || '',
        type: selectedItem.type || '',
        artDropdown: selectedItem.artDropdown || 'true',
      });
    }
  };
  // const postData = () => {
  //   //    console.log(...tableData,postObj)
  //   dispatch(setData([...tableData, postObj]))
  // }

  const postData = () => {
    // Check if any of the required fields are empty
 
    // If all fields are filled, proceed to add/update record
    if (editIndex !== null) {
      // If editing an existing record, update the data
      const updatedData = [...tableData];
      updatedData[editIndex] = postObj;
      dispatch(setData(updatedData));
    } else {
     
      dispatch(setData([...tableData, postObj]));
    }
  
    setEditIndex(null);
    setPostObj({
      link: '',
      subjectName: '',
      subjectDescription: '',
      subjectStatus: 'Active',
      gridHeight: '',
      gridWidth: '',
      gridColumn: '',
      type: '',
      artDropdown: 'true',
    });
  };
  

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#4169E1',
        color: theme.palette.common.white,
        fontSize: 16,
      },
      '&.centered': {
        textAlign: 'center',
      },

    }));
  const [selectedFile, setSelectedFile] = useState(null);

  return (
    <div className='.pageMainDiv'>
      <Grid justifyContent="center">
      <Grid item xs={12} md={10} lg={8} sx={{ paddingLeft: '20px',paddingRight:'20px' }}>
          <TableContainer sx={{ maxHeight: 440 }} >
          <h1 className='lg:text-[25px] text-[16px] mt-[57px] font-semibold'>Subject Master</h1>

            <Table  stickyHeader size="small" className='mt-4 border border-[gray] w-[100%]'   >
              <TableHead>
                <TableRow>
                  <StyledTableCell>Image</StyledTableCell>
                  <StyledTableCell>Subject Name</StyledTableCell>
                  <StyledTableCell>Subject Description</StyledTableCell>
                  <StyledTableCell>Subject Status</StyledTableCell>
                  <StyledTableCell>Grid Height</StyledTableCell>
                  <StyledTableCell>Grid Width</StyledTableCell>
                  <StyledTableCell>Grid Column</StyledTableCell>
                  <StyledTableCell>Type</StyledTableCell>
                  <StyledTableCell>Art Dropdown</StyledTableCell>
                  <StyledTableCell align='center'>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                {tableData?.map((subject, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Popup
                        trigger={<div className='mt-4'>
                          <Button
                            onClick={() => {
                              setImageLink(subject.link);
                            }}
                            style={{
                              backgroundColor: 'orange',
                              color: 'white',
                            }}
                          >
                            Image
                          </Button>
                        </div>}
                        modal
                        open={isOpen}
                        closeOnDocumentClick
                      >
                        {close => (
                          <div className="lg:w-[300px] w-[270px] lg:h-[300px] h-[200px] bg-[white] shadow rounded  ">
                            <img className='lg:w-[300px] w-[270px] lg:h-[300px] h-[200px] bg-[white] shadow rounded ' src={ImageLink} />
                          </div>
                        )}
                      </Popup>
                    </TableCell>
                    <TableCell>{subject?.subjectName}</TableCell>
                    <TableCell>{subject?.subjectDescription}</TableCell>
                    <TableCell>
                      <Button style={{
                        backgroundColor: 'green',
                        color: 'white',
                      }}>{subject?.subjectStatus}</Button></TableCell>
                    <TableCell>{subject?.gridHeight}</TableCell>
                    <TableCell>{subject?.gridWidth}</TableCell>
                    <TableCell>{subject?.gridColumn}</TableCell>
                    <TableCell>{subject?.type}</TableCell>
                    <TableCell >{subject?.artDropdown}</TableCell>

                    <TableCell>
                      <div className='space-x-2 flex'>
                        <Popup
                          trigger={
                            <div className=''>
                              <Button
                                onClick={() => {
                                  handleEdit(index);
                                }}
                                style={{
                                  backgroundColor: 'orange',
                                  color: 'white',
                                }}
                              >
                                Update
                              </Button>
                            </div>
                          }
                          modal
                          open={isOpen}
                          closeOnDocumentClick
                        >
                          {close => (
                            <div className="  lg:w-[550px] w-[440px] lg:h-[500px] h-[490px] bg-[white] shadow rounded   ">
                              <h1 className='lg:ml-[180px] ml-[100px] lg:text-[25px] text-[16px] lg:pt-[30px] font-semibold'>Update Records</h1>
                              <input
                                type='file'
                                ref={fileInputRef}
                                className='lg:w-[300px] pt-[10px] ml-[50px] w-[272px] pl-4 pt-4 text-[18px] lg:h-[50px] bg-[#DADBDD]'
                                onChange={(e) => {
                                  setSelectedFile(e.target.files[0]);
                                  handleFileChange2(e, index); // Call your file handling function here
                                }}
                                name='fileInput'
                                accept='image/*'
                                multiple
                              />

                              {postObj.link && (
                                <div>

                                </div>
                              )}
                              <a onClick={handleButtonClick} >link</a>

                              <div className=' mt-2 flex'>
                                <div className='flex-col'>
                                  <h className='lg:pt-[10px] lg:mt-[10px] mt-[8px] lg:ml-[35px] ml-[30px] font-semibold lg:text-[18px] text-[12px]'>subjectName : </h>
                                  <div className='rounded lg:mt-[4px] mt-[18px] rounded lg:ml-[35px]  ml-[20px] lg:w-[230px] w-[150px] bg-gray-100'>
                                    <textarea
                                      className='mt-[10px] lg:w-[230px]  w-[180px] pl-4 pt-2 text-[18px] lg:h-[40px] h-[40px] bg-[#DADBDD]'
                                      name='subjectName'
                                      placeholder='Enter subjectName'
                                      onChange={(e) => changeName(e, index)}
                                      value={postObj?.subjectName}
                                    ></textarea>
                                  </div>
                                </div>
                                <div className='flex-col'>
                                  <h className='lg:pt-[10px] mt-[10px] lg:ml-[16px] ml-[60px] font-semibold lg:text-[18px] text-[12px]'>subjectDescription : </h>
                                  <div className='rounded lg:mt-[4px] mt-[18px] rounded lg:ml-[20px]  ml-[50px] lg:w-[230px] bg-gray-100'>
                                    <textarea
                                      className='mt-[10px] lg:w-[230px]  w-[180px]  pl-4 pt-2 text-[18px] h-[40px] lg:h-[40px] bg-[#DADBDD]'
                                      name='subjectDescription'
                                      onChange={(e) => changeName(e, index)}
                                      placeholder='Enter subjectDescription'
                                      value={postObj?.subjectDescription}
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                              <div className=' mt-2 flex'>
                                <div className='flex-col'>
                                  <h className='lg:pt-[10px] lg:mt-[10px] mt-[8px] lg:ml-[35px] ml-[30px] font-semibold lg:text-[18px] text-[12px]'>Grid Height </h>
                                  <div className='rounded lg:mt-[4px] mt-[18px] rounded lg:ml-[35px]  ml-[20px] lg:w-[230px] w-[150px] bg-gray-100'>
                                    <textarea
                                      className='mt-[10px] lg:w-[230px]  w-[180px] pl-4 pt-2 text-[18px] lg:h-[40px] h-[40px] bg-[#DADBDD]'
                                      name='gridHeight'
                                      onChange={(e) => changeName(e, index)}
                                      placeholder='Enter gridHeight'
                                      value={postObj?.gridHeight}
                                    ></textarea>
                                  </div>
                                </div>
                                <div className='flex-col'>
                                  <h className='lg:pt-[10px] mt-[10px] lg:ml-[16px] ml-[60px] font-semibold lg:text-[18px] text-[12px]'>Grid Column </h>
                                  <div className='rounded lg:mt-[4px] mt-[18px] rounded lg:ml-[20px]  ml-[50px] lg:w-[230px] bg-gray-100'>
                                    <textarea
                                      className='mt-[10px] lg:w-[230px]  w-[180px]  pl-4 pt-2 text-[18px] h-[40px] lg:h-[40px] bg-[#DADBDD]'
                                      name='gridColumn'
                                      onChange={(e) => changeName(e, index)}
                                      placeholder='Enter grid column'
                                      value={postObj?.gridColumn}
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                              <div className=' mt-2 flex'>
                                <div className='flex-col'>
                                  <h className='lg:pt-[10px] lg:mt-[10px] mt-[8px] lg:ml-[35px] ml-[30px] font-semibold lg:text-[18px] text-[12px]'>Grid Width : </h>
                                  <div className='rounded lg:mt-[4px] mt-[18px] rounded lg:ml-[35px]  ml-[20px] lg:w-[230px] w-[150px] bg-gray-100'>
                                    <textarea
                                      className='mt-[10px] lg:w-[230px]  w-[180px] pl-4 pt-2 text-[18px] lg:h-[40px] h-[40px] bg-[#DADBDD]'
                                      name='gridWidth'
                                      onChange={(e) => changeName(e, index)}
                                      value={postObj?.gridWidth}
                                      placeholder='Enter gridWidth'
                                    ></textarea>
                                  </div>
                                </div>
                                <div className='flex-col'>
                                  <h className='lg:pt-[10px] mt-[10px] lg:ml-[16px] ml-[60px] font-semibold lg:text-[18px] text-[12px]'>Grid type : </h>
                                  <div className='rounded lg:mt-[4px] mt-[18px] rounded lg:ml-[20px]  ml-[50px] lg:w-[230px] bg-gray-100'>
                                    <textarea
                                      className='mt-[10px] lg:w-[230px]  w-[180px]  pl-4 pt-2 text-[18px] h-[40px] lg:h-[40px] bg-[#DADBDD]'
                                      name='type'
                                      onChange={(e) => changeName(e, index)}
                                      value={postObj?.type}
                                      placeholder='Enter type'
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                              <button className='h-[40px] rounded w-[110px] mx-[auto] mt-[20px] ml-[220px] bg-[#1F45FC] text-[white]' onClick={() => { postData(); close(); }} endIcon={<SendIcon />}  >
                                Update Record
                              </button>
                            </div>
                          )}
                        </Popup>
                        <Button
                          onClick={() => {
                            handleDelete(index);
                          }}

                          style={{
                            backgroundColor: 'red',
                            color: 'white',
                          }}
                        >
                          Delete
                        </Button>
                      </div>


                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>

            </Table>
          </TableContainer>

          <Popup
            trigger={<div className='mt-4'> <button className='bg-[#7367F0] h-[40px] w-[150px] rounded-lg text-[white] text-[16px]'> Add Record </button></div>}
            modal
            open={isOpen}
            closeOnDocumentClick
          >
            {close => (
              <div className="  lg:w-[550px] w-[440px] lg:h-[500px] h-[490px] bg-[white] shadow rounded   ">
                <h1 className='lg:ml-[180px] ml-[150px] lg:text-[25px] text-[16px] lg:pt-[30px] font-semibold'>Add Records</h1>
                <input
                  type='file'
                  className='lg:w-[300px] pt-[10px] ml-[50px] w-[272px] pl-4 pt-4 text-[18px] lg:h-[50px] bg-[#DADBDD]'
                  onChange={handleFileChange2} // Call handleImageChange on file change
                  name='images'
                  multiple
                />
                <a onClick={handleButtonClick} >link</a>
                <div className=' mt-2 flex'>
                  <div className='flex-col'>
                    <h className='lg:pt-[10px] lg:mt-[10px]  lg:ml-[35px] ml-[30px] font-semibold lg:text-[18px] '>Subject Name : </h>
                    <div className='rounded lg:mt-[4px] mt-[18px] rounded lg:ml-[35px]  ml-[20px] lg:w-[230px] w-[150px] bg-gray-100'>
                      <textarea
                        className='mt-[10px] lg:w-[230px]  w-[180px] pl-4 pt-2 text-[18px] lg:h-[40px] h-[40px] bg-[#DADBDD]'
                        name='subjectName'
                        placeholder='Enter subjectName'
                        onChange={changeName}
                      ></textarea>
                    </div>
                  </div>
                  <div className='flex-col'>
                    <h className='lg:pt-[10px] mt-[10px] lg:ml-[16px] ml-[60px] font-semibold lg:text-[18px] text-[12px]'>Subject Description : </h>
                    <div className='rounded lg:mt-[4px] mt-[18px] rounded lg:ml-[20px]  ml-[50px] lg:w-[230px] bg-gray-100'>
                      <textarea
                        className='mt-[10px] lg:w-[230px]  w-[180px]  pl-4 pt-2 text-[18px] h-[40px] lg:h-[40px] bg-[#DADBDD]'
                        name='subjectDescription'
                        onChange={changeName}
                        placeholder='Enter subjectDescription'
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className=' mt-2 flex'>
                  <div className='flex-col'>
                    <h className='lg:pt-[10px] lg:mt-[10px] mt-[8px] lg:ml-[35px] ml-[30px] font-semibold lg:text-[18px] text-[12px]'>Grid Height </h>
                    <div className='rounded lg:mt-[4px] mt-[18px] rounded lg:ml-[35px]  ml-[20px] lg:w-[230px] w-[150px] bg-gray-100'>
                      <textarea
                        className='mt-[10px] lg:w-[230px]  w-[180px] pl-4 pt-2 text-[18px] lg:h-[40px] h-[40px] bg-[#DADBDD]'
                        name='gridHeight'
                        onChange={changeName}
                        placeholder='Enter gridHeight'
                      ></textarea>
                    </div>
                  </div>
                  <div className='flex-col'>
                    <h className='lg:pt-[10px] mt-[10px] lg:ml-[16px] ml-[60px] font-semibold lg:text-[18px] text-[12px]'>Grid Column </h>
                    <div className='rounded lg:mt-[4px] mt-[18px] rounded lg:ml-[20px]  ml-[50px] lg:w-[230px] bg-gray-100'>
                      <textarea
                        className='mt-[10px] lg:w-[230px]  w-[180px]  pl-4 pt-2 text-[18px] h-[40px] lg:h-[40px] bg-[#DADBDD]'
                        name='gridColumn'
                        onChange={changeName}
                        placeholder='Enter grid column'
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className=' mt-2 flex'>
                  <div className='flex-col'>
                    <h className='lg:pt-[10px] lg:mt-[10px] mt-[8px] lg:ml-[35px] ml-[30px] font-semibold lg:text-[18px] text-[12px]'>Grid Width : </h>
                    <div className='rounded lg:mt-[4px] mt-[18px] rounded lg:ml-[35px]  ml-[20px] lg:w-[230px] w-[150px] bg-gray-100'>
                      <textarea
                        className='mt-[10px] lg:w-[230px]  w-[180px] pl-4 pt-2 text-[18px] lg:h-[40px] h-[40px] bg-[#DADBDD]'
                        name='gridWidth'
                        onChange={changeName}
                        placeholder='Enter gridWidth'
                      ></textarea>
                    </div>
                  </div>
                  <div className='flex-col'>
                    <h className='lg:pt-[10px] mt-[10px] lg:ml-[16px] ml-[60px] font-semibold lg:text-[18px] text-[12px]'>Grid type : </h>
                    <div className='rounded lg:mt-[4px] mt-[18px] rounded lg:ml-[20px]  ml-[50px] lg:w-[230px] bg-gray-100'>
                      <textarea
                        className='mt-[10px] lg:w-[230px]  w-[180px]  pl-4 pt-2 text-[18px] h-[40px] lg:h-[40px] bg-[#DADBDD]'
                        name='type'
                        onChange={changeName}
                        placeholder='Enter type'
                      ></textarea>
                    </div>
                  </div>
                </div>
                <button className='h-[40px] rounded w-[110px]  text-[white] mt-[20px] ml-[220px] bg-[#1F45FC]' onClick={() => { postData(); close(); }}   >
                  Add Record
                </button>
              </div>
            )}
          </Popup>
        </Grid>
      </Grid>
    </div>
  )
}

export default Subject