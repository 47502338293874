import facebook from '../FrameMaster/facebook1.png';
import twit from '../FrameMaster/twit.png';
import google from '../FrameMaster/google1.png';

const FrameData=[

    {
        frameId: "64ad32775308e445b1949fa7",
        frameName: "Chelsea Black",
        frameDescription: "Slim and tall, this contemporary Chelsea wood frame has a\nmatte black finish and is well suited for all art types.",
        frameProductNo: "ANS1755626226",
        frameType: "string",
        width: "4",
        depth: "2.8702",
        finish: "Gesso",
        style: "Contemporary",
        glassType: "string",
        status: "Active",
        frameMaterial: null,
        price: 200,
        stockStatus: "string",
        size: null,
        frameColor: [
          {
            colorName: "Black",
            colorCode: "#000000",
            price: "25"
          },
          {
            colorName: "Grey",
            colorCode: "#757575",
            price: "35"
          },
          {
            colorName: "Davy",
            colorCode: "#494F55",
            price: "45"
          },
          {
            colorName: "Chocolate",
            colorCode: "#3D0C02",
            price: "55"
          },
          {
            colorName: "Bluewood",
            colorCode: "#36454F",
            price: "65"
          },
          {
            colorName: "Flint",
            colorCode: "#696969",
            price: "75"
          },
          {
            colorName: "Wenge",
            colorCode: "#555D50",
            price: "85"
          }
        ],
        frameImageUrl:facebook, 
        frameThumbnailUrl: google,
        frameDrawingUrl:twit,
    },

    {
        frameId: "64ad32775308e445b1949fa5",
        frameName: "Chelsea white",
        frameDescription: "Slim and tall, this contemporary Chelsea wood frame has a\nmatte black finish and is well suited for all art types.",
        frameProductNo: "ANS1755626227",
        frameType: "string",
        width: "6",
        depth: "2.8702",
        finish: "Gesso",
        style: "Contemporary",
        glassType: "string",
        status: "Active",
        frameMaterial: null,
        price: 200,
        stockStatus: "string",
        size: null,
        frameColor: [
          {
            colorName: "Black",
            colorCode: "#000000",
            price: "25"
          },
          {
            colorName: "Grey",
            colorCode: "#757575",
            price: "35"
          },
          {
            colorName: "Davy",
            colorCode: "#494F55",
            price: "45"
          },
          {
            colorName: "Chocolate",
            colorCode: "#3D0C02",
            price: "55"
          },
          {
            colorName: "Bluewood",
            colorCode: "#36454F",
            price: "65"
          },
          {
            colorName: "Flint",
            colorCode: "#696969",
            price: "75"
          },
          {
            colorName: "Wenge",
            colorCode: "#555D50",
            price: "85"
          }
        ],
        frameImageUrl:facebook, 
        frameThumbnailUrl: google,
        frameDrawingUrl:twit,
    }
]
export default FrameData;