import { createSlice } from '@reduxjs/toolkit';
const Image3Slice = createSlice({
  name: 'table',
  initialState: {
    data: [],
  }
  ,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    // updateData: (state, action) => {
    //   state.data = action.payload;
    // },
  },
});
export const { setData } = Image3Slice.actions;
export default Image3Slice.reducer;