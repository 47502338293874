import { Button, Grid, Input, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, styled, tableCellClasses } from '@mui/material'
import React from 'react'
import Popup from 'reactjs-popup';
import { useEffect } from 'react';
import { useState } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { httpClient } from '../../axios';



const Country = () => {

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'rgb(115 103 240)',
      color: theme.palette.common.white,
      fontSize: 20,
    },
  }));


  const [country, setCountry] = useState([]);
  const [updatedData, setUpdatedData] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const getCountry = async () => {
    const res = await httpClient
      .get('/country_master')
    // .then((res) => {
    console.log(res.data);
    setCountry(res.data);

    // });
  };
  useEffect(() => {
    getCountry();
  }, []);

  const addDetails = async (close) => {
    if (!updatedData.countryName || !updatedData.currencySymbol) {

      toast.warning("Please fill in all fields", {
        position: 'top-center',
        autoClose: 2000,
      });
      return;
    }
    try {
      const res = await httpClient.post('/country_master/create', { ...updatedData });
      console.log(res.data);
      getCountry();
      close();
      setUpdatedData({
        countryName: '',
        currencySymbol: '',
      });
    } catch (error) {
      console.error("Error adding country:", error);
      toast.error("Error adding country. Please try again.");
    }
  };
  const handleInputChange = (e) => {
    setUpdatedData({
      ...updatedData,
      [e.target.name]: e.target.value,
    });
  };

  const UpdateDetails = async () => {
    console.log(updatedData);
    const res = await httpClient.put(
      '/country_master/update',
      updatedData
    );
    getCountry();
    console.log(res.data);
  };
  const UpdateStatus = async (data) => {
    console.log(data);
    const res = await httpClient.put(
      '/country_master/update',
      data);
    const lin = country.map(li =>
      li.countryId === data.countryId ? data : li);
    console.log(res.data);
    setCountry(lin);
    getCountry();
  };
  const setActiveStatus = (transaction) => {
    transaction.status = 'Active';
    // console.log("active",transaction);

    UpdateStatus(transaction);
  };

  const setInActiveStatus = (transaction) => {
    transaction.status = 'InActive';
    // console.log("Inactive",transaction);

    UpdateStatus(transaction);
    //     deleteData(transaction.socialMediaAdminId);
  };
  const filteredData = country.filter(con => {

    if (con && con.countryName) {
      return con.countryName.toLowerCase().includes(searchQuery.toLowerCase());
    } else {

      return false;
    }
  });
  const handleSearch = event => {
    setSearchQuery(event.target.value);
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const deleteData = async (countryId) => {
    const sureDelete = window.confirm("Are You Sure The Delete Record..?");
    if (sureDelete) {
      const res = await httpClient.delete(
        `/country_master/deleteByCountryId/${countryId}`
      );
      console.log(res.data);
      getCountry();
    }
  };

  return (
    <>
      <Grid justifyContent='center'>
        <Grid item xs={12} md={10} lg={8} >
          <div className='mt-[20px] pageMainDiv'>
            <p className='text-[25px] font-bold mt-[40px]'>Country</p>
            <input type="text" name='name' placeholder="Search Country..."
              value={searchQuery} onChange={(e) => handleSearch(e)}
              className=' h-[50px] rounded-md border border-[#ABB2B9] pl-4 lg:w-[100%]' />
            <TableContainer className='h-[350px] mt-[10px] '>
              <Table size='small' className='border border-[#CECECE] w-[100%] ' stickyHeader>

                <TableHead>
                  <TableRow className='border border-[#CECECE] '>

                    <StyledTableCell>Country name</StyledTableCell>
                    <StyledTableCell align='center'>Country currency</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell align='center'>Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((transaction, index) => (<TableRow key={transaction.countryId}
                    >
                      <TableCell>
                        {transaction.countryName}
                      </TableCell>
                      <TableCell align='center'>{transaction.currencySymbol}</TableCell>

                      <TableCell>
                        {transaction.status === 'Active' ? (
                          <Button style={{ backgroundColor: 'green', color: 'white', }} > Active </Button>
                        ) : (
                          <Button style={{ backgroundColor: 'yellow', color: 'black', }} > InActive</Button>
                        )}
                      </TableCell>
                      <TableCell align='center'>
                        <div>
                          <div className='flex space-x-2 lg:ml-[200px]'>
                            <Popup
                              trigger={
                                <div>
                                  <Button
                                    onClick={() => {
                                      setUpdatedData(transaction);
                                    }}
                                    style={{
                                      backgroundColor: 'orange',
                                      color: 'white',
                                    }}
                                  >
                                    Update
                                  </Button>
                                </div>
                              }
                              modal
                              closeOnDocumentClick
                            >
                              {(close) => (
                                <div className='w-[370px] lg:w-[500px]  h-[100%] rounded-lg bg-[white] shadow p-[30px]'>
                                  <div className='flex'>
                                    <p className=' text-[23px] font-medium '>
                                      Update Data
                                    </p>
                                    <button
                                      className='ml-auto h-[40px] w-[40px]'
                                      onClick={close}
                                    >
                                      &times;
                                    </button>
                                  </div>
                                  <div className=' h-[300px] w-[100%] mx-auto mt-[30px]'>
                                    <input
                                      type='text'
                                      className='lg:h-[80px] w-[100%] h-[60px] bg-[#F8F8F4] pl-[10px] lg:text-[18px] text-[16px]'
                                      name='countryName'
                                      value={updatedData.countryName}
                                      onChange={handleInputChange}
                                    />
                                    <div>
                                      <input
                                        type='text'
                                        className='lg:h-[80px]  w-[100%] h-[70px] bg-[#F8F8F4] pl-[10px] mt-[20px]'
                                        name='currencySymbol'
                                        value={updatedData.currencySymbol}
                                        onChange={handleInputChange}
                                      /></div>
                                    <br />
                                    <Button
                                      className='text-[18px] font-semibold h-[41px] w-[80px] mt-[20px]'
                                      style={{ backgroundColor: '#1F45FC', color: 'white' }}
                                      onClick={() => {

                                        UpdateDetails();
                                        close();
                                      }}
                                    >
                                      Update
                                    </Button>
                                    {transaction.status === 'Active' ? (
                                      <Button onClick={() => { setInActiveStatus(transaction); }}
                                        style={{
                                          backgroundColor: 'green',
                                          color: 'white', marginLeft: 3
                                        }}
                                        className='h-[41px] w-[80px] rounded-lg'
                                      >
                                        Active
                                      </Button>
                                    ) : (
                                      <Button onClick={() => { setActiveStatus(transaction); }}
                                        style={{ backgroundColor: 'yellow', color: 'black', marginLeft: 3 }}
                                        className='h-[41px] w-[80px] rounded-lg'  > InActive </Button>)}
                                  </div>
                                </div>
                              )}
                            </Popup>
                            <Button
                              onClick={() =>
                                deleteData(transaction.countryId)
                              }
                              style={{
                                backgroundColor: 'red',
                                color: 'white',
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>))}
                </TableBody>
              </Table>

            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              count={country.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Popup
              trigger={
                <button className='bg-[#7367F0] h-[40px] w-[150px] rounded-lg text-[white] text-[16px]'>
                  Add Country
                </button>
              }
              modal
              closeOnDocumentClick
            >
              {(close) => (
                <div className='w-[300px] lg:w-[500px]  h-[100%] rounded-lg bg-[white] shadow p-[30px]'>
                  <div className='flex'>
                    <p className=' text-[23px] font-medium '>
                      Add Country
                    </p>
                    <button
                      className='ml-auto h-[40px] w-[40px]'
                      onClick={close}
                    >
                      &times;
                    </button>
                  </div>
                  <div className=' h-[300px] w-[100%] mx-auto mt-[30px]'>
                    <input
                      type='text'
                      className='lg:h-[80px]  h-[70px] w-[100%] bg-[#F8F8F4] pl-[10px] mt-[20px]'
                      placeholder='Enter coyntry name'
                      name='countryName'
                      onChange={handleInputChange}

                    />
                    <input
                      type='text'
                      className='lg:h-[80px]  w-[100%] h-[70px] bg-[#F8F8F4] pl-[10px] mt-[20px]'
                      placeholder='Enter Country currency'
                      name='currencySymbol'
                      onChange={handleInputChange}


                    />
                    <br />
                    <button
                      className='rounded-lg text-[white] bg-[blue] text-[18px] font-semibold h-[41px] w-[80px] mt-[20px]'
                      onClick={() => {

                        addDetails(close);
                      }}
                    >
                      Add
                    </button>
                    <ToastContainer closeOnClick={false} />
                  </div>
                </div>
              )}
            </Popup>
          </div>
        </Grid></Grid>
    </>
  )
}

export default Country