import { createSlice } from '@reduxjs/toolkit';
const Image1Slice = createSlice({
  name: 'table',
  initialState:{
    data:[]
    // table:[]
  }
  ,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    updateData: (state, action) => {
      state.data = action.payload;
    },
  },
});
export const { setData, updateData } = Image1Slice.actions;
export default Image1Slice.reducer;