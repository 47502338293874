import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, styled, tableCellClasses } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { httpClient } from '../../axios';
import Popup from 'reactjs-popup';

const ReleaseMaster = () => {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: 'rgb(115 103 240)',
            color: theme.palette.common.white,
            fontSize: 20,
        },
    }));

    const [release, setRelease] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const getRelease = async () => {
        const res = await httpClient
            .get('/release_master/getAllReleaseMaster')

        console.log(res.data);
        setRelease(res.data);

    };
    useEffect(() => {
        getRelease();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>

            <Grid justifyContent='center'>
                <Grid item xs={12} md={10} lg={8}>
                    <div className='pageMainDiv mt-[20px]'>
                        <p className='text-[25px] font-bold mt-[40px]'>Release Master</p>
                        <TableContainer className='h-[350px] mt-[20px] '>
                            <Table size='small' className='border border-[#CECECE] ' stickyHeader>

                                <TableHead>
                                    <TableRow className='border border-[#CECECE] '>
                                        <StyledTableCell>Release Id</StyledTableCell>
                                        <StyledTableCell align='center'>Release Type</StyledTableCell>
                                        <StyledTableCell align='center'>Name Of Person</StyledTableCell>
                                        <StyledTableCell>Description</StyledTableCell>
                                        <StyledTableCell>Release</StyledTableCell>
                                        <StyledTableCell>Attach Content</StyledTableCell>
                                        <StyledTableCell>User Name</StyledTableCell>
                                        <StyledTableCell>Release Status</StyledTableCell>



                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {release
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((rele, index) => (<TableRow key={rele.releaseId}>
                                            <TableCell>
                                                {' '}
                                                <span className='text-[16px]'>
                                                    {(page * rowsPerPage) + index + 1}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span className='text-[16px]'>
                                                    {rele.type}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span className='text-[16px]'>
                                                    {rele.nameOfPerson}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span className='text-[16px]'>
                                                    {rele.description}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <Popup trigger={<div className='text-center'>
                                                    <Button

                                                        style={{
                                                            backgroundColor: 'orange',
                                                            color: 'white',
                                                        }}
                                                    // onClick={() => { getProduct() }}
                                                    >
                                                        View
                                                    </Button>
                                                </div>}
                                                    modal
                                                    closeOnDocumentClick
                                                >

                                                    {(close) => ( <div  className='w-[300px] lg:w-[400px] lg:h-[350px] h-[250px] rounded-lg  bg-[white] shadow'>
                                                       {rele?.release.map((image,indx)=>(
                                                        <div className='flex' key={indx}>
                                                             <img src={image} className='w-[100px] h-[100px]'/>
                                                        </div>
                                                       ))}
                                                        </div>

                                                  
                                                    )}
                                                </Popup>
                                            </TableCell>

                                            <TableCell>
                                                <Popup trigger={<div className='text-center'>
                                                    <Button

                                                        style={{
                                                            backgroundColor: 'orange',
                                                            color: 'white',
                                                        }}
                                                    // onClick={() => { getProduct() }}
                                                    >
                                                        Show
                                                    </Button>
                                                </div>}
                                                    modal
                                                    closeOnDocumentClick
                                                >

                                                    {(close) => ( <div  className='w-[300px] lg:w-[400px] lg:h-[350px] h-[250px] rounded-lg  bg-[white] shadow'>
                                                       {rele?.attachContent.map((image,indx)=>(
                                                        <div className='flex' key={indx}>
                                                             <img src={image} className='w-[100px] h-[100px]'/>
                                                        </div>
                                                       ))}
                                                        </div>

                                                  
                                                    )}
                                                </Popup>
                                            </TableCell>
                                            <TableCell>
                                                <span className='text-[16px]'>
                                                    {rele?.userMaster?.displayName}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <span className='text-[16px]'>
                                                    {rele?.releaseStatus}
                                                </span>
                                            </TableCell>


                                        </TableRow>))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TablePagination className='ml-auto'
                            rowsPerPageOptions={[5, 10, 15]}
                            component="div"
                            count={release.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                </Grid>
            </Grid>

        </>
    )
}

export default ReleaseMaster