import { Button, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, tableCellClasses } from '@mui/material';
import React from 'react'
import { useState } from 'react';

import { useEffect } from 'react';
import Popup from 'reactjs-popup';
import fb from '../Social-Media/facebook1.png';
import twit from '../Social-Media/twit.png';
import linkin from '../Social-Media/linkin.png';
import insta from '../Social-Media/insta.jpeg';
import { httpClient } from '../axios';



const importedLogos = [
  fb,
  twit,
  linkin,
  insta,
];
const SocialMedia = () => {

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'rgb(115 103 240)',
      color: theme.palette.common.white,
      fontSize: 20,
    },
  }));
  const [updatedData, setUpdatedData] = useState({});

  const [link, setLink] = useState([]);
  const [FormData, setFormData] = useState({
    link: '',
    name: ''
  })

  const getMaster = async () => {
    const res = await httpClient
      .get('/social_media_Master/getAllSocialMedia/')
    // .then((res) => {
    console.log(res.data);
    setLink(res.data);
    // });
  };
  useEffect(() => {
    getMaster();
  }, []);

  const addDetails = async (e) => {
    e.preventDefault(e);
    if (FormData.name === '' || FormData.link === '') {
      alert('Please fill in all fields before adding a record.');
      resetForm();
      return;
    }


    const res = await httpClient.post(
      '/social_media_Master/', { ...FormData });
    resetForm();
    // .then((res) => {
    console.log(res.data);
    console.log(FormData);
    getMaster();

  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...FormData,
      [name]: value,
    });
    setUpdatedData({
      ...updatedData,
      [name]: value,
    });
  };



  const resetForm = () => {
    console.log('Resetting form');
    setFormData(() => ({

      link: '',
      name: '',
    }));

  };


  const UpdateDetails = async () => {

    if (updatedData.name === '' || updatedData.link === '') {
      alert('Please fill in all fields before adding a record.');
      resetForm();
      return;
    }

    console.log(updatedData);
    const res = await httpClient.put(
      '/social_media_Master/',
      updatedData
    );
    resetForm();
    getMaster();
    console.log(res.data);

  };

  const UpdateStatus = async (data) => {
    console.log(data);
    const res = await httpClient.put(
      '/social_media_Master/',
      data);
    const lin = link.map(li =>
      li.socialMediaAdminId === data.socialMediaAdminId ? data : li);
    console.log(res.data);
    setLink(lin);
    getMaster();
  };
  const setActiveStatus = (transaction) => {
    transaction.status = 'Active';
    // console.log("active",transaction);

    UpdateStatus(transaction);
  };

  const setInActiveStatus = (transaction) => {
    transaction.status = 'InActive';
    // console.log("Inactive",transaction);

    UpdateStatus(transaction);
    //     deleteData(transaction.socialMediaAdminId);
  };
  const deleteData = async (socialMediaAdminId) => {
    const sureDelete = window.confirm("Are You Sure The Delete Record..?");
    if (sureDelete) {
      const res = await httpClient.delete(
        `/social_media_Master/deleteSocialMedia/${socialMediaAdminId}`
      );
      console.log(res.data);
      getMaster();
    }
  };


  return (
    <>

      <Grid justifyContent='center'>
        <Grid item xs={12} md={10} lg={8}>
          <div className='pageMainDiv mt-[20px]'>
            <p className='text-[25px] font-bold mt-[40px]'>Social Media</p>
            <TableContainer className='h-[350px] mt-[20px] '>
              <Table size='small' className='border border-[#CECECE] ' stickyHeader>

                <TableHead>
                  <TableRow className='border border-[#CECECE] '>
                    <StyledTableCell>Logo</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Links</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell align='center'>Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {link.map((transaction, index) => (<TableRow key={transaction.socialMediaAdminId}
                  >
                    <TableCell>

                      {/* <span className='text-[16px]'>
                          {transaction.logo}
                        </span> */}
                      <img
                        src={importedLogos[index % importedLogos.length]}
                        alt='logo' className='h-[40px] w-[40px]'></img>

                    </TableCell>
                    <TableCell>

                      <span className='text-[16px]'>
                        {transaction.name}
                      </span>
                    </TableCell>
                    <TableCell>

                      <span className='text-[16px]'>
                        {transaction.link}
                      </span>
                    </TableCell>
                    <TableCell>
                      {transaction.status === 'Active' ? (
                        <Button
                          // onClick={() => {
                          //   setInActiveStatus(transaction);
                          // }}
                          style={{
                            backgroundColor: 'green',
                            color: 'white',
                            cursor: 'default',
                          }}
                        >
                          Active
                        </Button>
                      ) : (
                        <Button
                          // onClick={() => {
                          //   setActiveStatus(transaction);
                          // }}
                          style={{
                            backgroundColor: 'yellow',
                            color: 'black',
                          }}
                        >
                          InActive
                        </Button>
                      )}
                    </TableCell>
                    <TableCell align='center' >
                      <div>
                        <div className='flex space-x-2 '>
                          <Popup
                            trigger={
                              <div className='lg:ml-[220px]'>


                                <Button onClick={() => {
                                  setUpdatedData(transaction);
                                }}
                                  style={{
                                    backgroundColor: 'orange',
                                    color: 'white',
                                  }}
                                >
                                  Update
                                </Button>



                              </div>
                            }
                            modal
                            closeOnDocumentClick
                          >
                            {(close) => (
                              <div className='w-[370px] lg:w-[600px] rounded-lg bg-[white] shadow relative p-[30px]'>
                                <div className='flex'>
                                  <p className='pt-[23px] text-[23px] font-medium ml-4'>
                                    Update Data
                                  </p>
                                  <button
                                    className='ml-auto h-[40px] w-[40px]'
                                    onClick={close}
                                  >
                                    &times;
                                  </button>
                                </div>
                                <div className=' h-[300px] w-[500px] mx-auto mt-[30px]'>
                                  <textarea
                                    type='text'
                                    className='lg:h-[80px] lg:w-[500px] h-[70px] w-[350px] bg-[#F8F8F4] pl-[10px] lg:text-[18px] text-[16px]'
                                    name='name'
                                    value={updatedData.name}
                                    onChange={handleInputChange}
                                  />
                                  <textarea
                                    type='text'
                                    className='lg:h-[80px] lg:w-[500px] h-[70px] w-[350px] bg-[#F8F8F4] pl-[10px] mt-[20px]'
                                    name='link'
                                    value={updatedData.link}
                                    onChange={handleInputChange}
                                  />
                                  <br />
                                  <div className='flex space-x-2 mt-[20px]'>
                                    <button
                                      className='rounded-lg text-[white] bg-[blue] text-[18px] font-semibold h-[41px] w-[80px] '
                                      onClick={() => {

                                        UpdateDetails(); close();
                                      }}
                                    >
                                      Update
                                    </button>
                                    {transaction.status === 'Active' ? (
                                      <Button className='h-[41px] w-[80px] '
                                        onClick={() => {
                                          setInActiveStatus(transaction);
                                        }}
                                        style={{
                                          backgroundColor: 'green',
                                          color: 'white',
                                        }}
                                      >
                                        Active
                                      </Button>
                                    ) : (
                                      <Button className='h-[41px] w-[80px] '
                                        onClick={() => {
                                          setActiveStatus(transaction);
                                        }}
                                        style={{
                                          backgroundColor: 'yellow',
                                          color: 'black',
                                        }}
                                      >
                                        InActive
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </Popup>
                          <Button
                            onClick={() =>
                              deleteData(transaction.socialMediaAdminId)
                            }
                            style={{
                              backgroundColor: 'red',
                              color: 'white',
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                    </TableCell>

                  </TableRow>))}
                </TableBody>
              </Table>
              <Popup
                trigger={
                  <button
                    // onClick={() => {
                    //   resetForm();

                    // }}
                    className='bg-[#7367F0] h-[40px] w-[150px] rounded-lg text-[white] text-[16px]'>
                    Add files
                  </button>
                }
                modal
                closeOnDocumentClick
              >
                {(close) => (
                  <div className='w-[300px] lg:w-[600px] rounded-lg bg-[white] shadow relative p-[30px]'>
                    <div className='flex'>
                      <p className='text-[23px] font-medium ml-4 '>
                        Add files
                      </p>
                      <button
                        className='ml-auto h-[40px] w-[40px]'
                        onClick={close}
                      >
                        &times;
                      </button>
                    </div>
                    <div className=' h-[300px]  w-[100%] mx-auto mt-[30px]'>
                      <textarea
                        type='text'
                        className='lg:h-[80px]  w-[100%] h-[70px] bg-[#F8F8F4] pl-[10px] mt-[20px]'
                        placeholder='Enter name'
                        name='name'
                        onChange={handleInputChange}

                      />
                      <textarea
                        type='text'
                        className='lg:h-[80px]  w-[100%] h-[70px]  bg-[#F8F8F4] pl-[10px] mt-[20px]'
                        placeholder='Enter link'
                        name='link'
                        onChange={handleInputChange}


                      />
                      <br />
                      <button
                        className='rounded-lg text-[white] bg-[blue] text-[18px] font-semibold h-[41px] w-[80px] mt-[20px]'
                        onClick={(e) => {

                          addDetails(e);

                          if (FormData.name && FormData.link) {
                            close();
                          }

                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                )}
              </Popup>
            </TableContainer>
            <div>

            </div>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default SocialMedia;