import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, tableCellClasses } from '@mui/material';
import React, { useEffect } from 'react'
import TopBarData from './TopBarData';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { httpClient } from '../../axios';
import { setLink1, setLink2, setLink3 } from '../../store/imageSlice';


const TopBar = () => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: 'rgb(115 103 240)',
            color: theme.palette.common.white,
            fontSize: 20,
        },
    }));
    const dispatch = useDispatch();
    const images = useSelector((state) => state.images);
    console.log('images from redux', images)
    const [isPopupOpen, setPopupOpen] = useState(false);

    const openPopup = () => {
        setPopupOpen(true);
    };

    const closePopup = () => {
        setPopupOpen(false);
    };

    const fileInputRef = useRef(null);
    const fileInputRef2 = useRef(null);
    const fileInputRef3 = useRef(null);

    const handleButtonClick = () => {

        fileInputRef.current.click();
    };
    const handleButtonClick2 = () => {

        fileInputRef2.current.click();
    };
    const handleButtonClick3 = () => {

        fileInputRef3.current.click();
    };


    const handleFileChange = async (event, linkNumber) => {
        const img = event.target.files[0];
        if (img) {
            let formData = new FormData();
            formData.append('file', img);
            try {
                const res = await httpClient.post(
                    '/CloudinaryImageUpload?parameter=false',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );

                switch (linkNumber) {
                    case 1:
                        dispatch(setLink1(res.data));
                        console.log('Link 1 URL:', res.data.url);
                        break;
                    case 2:
                        dispatch(setLink2(res.data));
                        console.log('Link 2 URL:', res.data);
                        break;
                    case 3:
                        dispatch(setLink3(res.data));
                        console.log('Link 3 URL:', res.data);
                        break;
                    default:
                        break;
                }
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    };
    return (
        <>
            <Grid justifyContent="center">
                <Grid item xs={12} md={10} lg={8} >
                    <div className='pageMainDiv mt-[20px]'>
                        <p className='text-[25px] font-bold mt-[40px]'>TopBar</p>
                        <TableContainer sx={{ maxHeight: 440 }} >
                            <Table size='small' className='border border-[#CECECE] w-[100% ] ' stickyHeader>

                                <TableHead>
                                    <TableRow className='border border-[#CECECE] '>

                                        <StyledTableCell>Image</StyledTableCell>
                                        <StyledTableCell >Link</StyledTableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {TopBarData.map((top) => (
                                        <TableRow key={top.topBarId}>
                                            <TableCell>
                                                <TableRow>
                                                    <TableCell>

                                                        <input type='file' multiple onChange={(e) => handleFileChange(e, 1)} accept='image/*'
                                                            style={{ display: 'none' }}
                                                            ref={fileInputRef}
                                                        />
                                                        <a onClick={() => handleButtonClick()} >Link 1</a>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>

                                                        <input
                                                            type='file'
                                                            multiple
                                                            onChange={(e) => handleFileChange(e, 2)}
                                                            accept='image/*'
                                                            style={{ display: 'none' }}
                                                            ref={fileInputRef2}
                                                        />
                                                        <a onClick={handleButtonClick2}>Link 2</a>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>

                                                        <input
                                                            type='file'
                                                            multiple
                                                            onChange={(e) => handleFileChange(e, 3)}
                                                            accept='image/*'
                                                            style={{ display: 'none' }}
                                                            ref={fileInputRef3}
                                                        />
                                                        <a onClick={handleButtonClick3}>Link 3</a>
                                                    </TableCell>
                                                </TableRow>

                                            </TableCell>
                                            <TableCell>
                                                <TableRow>
                                                    <TableCell>
                                                        <a href={images.link1} onClick={openPopup} alt={`image ${top.topBarId}`}>Image 1</a>

                                                    </TableCell>
                                                    {isPopupOpen && (
                                                        <div onClick={closePopup}>
                                                            <div >
                                                                <img src={images.link1} alt={`image ${top.topBarId}`} />
                                                            </div>
                                                        </div>
                                                    )}
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <a href={images.link2} alt={`image ${top.topBarId}`}>Image 2</a>

                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <a href={images.link3} alt={`image ${top.topBarId}`}>Image 3</a>
                                                    </TableCell>
                                                </TableRow>
                                            </TableCell>

                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default TopBar;