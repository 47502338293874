import { Grid, Table, TableCell, TableContainer, styled, TableHead, TableRow, tableCellClasses, TableBody, TablePagination, Button } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { httpClient } from '../../axios';
import Popup from 'reactjs-popup';
import SendIcon from '@mui/icons-material/Send';


const Style1 = () => {
    const [userList, setUserList] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [searchQuery, setSearchQuery] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const fileInputRef = useRef(null);
    const [updatedData, setUpdatedData] = useState({});
    const [ImageLink, setImageLink] = useState('');


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: 'rgb(115 103 240)',
            color: theme.palette.common.white,
            fontSize: 20,


        },


    }));

    const handleSearch = event => {
        setSearchQuery(event.target.value);
        setPage(0);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getUser = async () => {
        try {
            const res = await httpClient.get('/style_master');
            if (res) {
                setUserList(res.data);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    useEffect(() => {
        getUser();
    }, []);

    const filteredData = userList.filter(list =>
        list.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const handleInputChange = (e) => {
        setUpdatedData({
            ...updatedData,
            [e.target.name]: e.target.value,
        });
    };
    useEffect(() => {
        console.log(updatedData);
    }, [updatedData]);

    const Update = async () => {
        console.log(updatedData);
        const res = await httpClient.put(
            '/style_master/update',
            updatedData
        );
        // if (res) {
        getUser();
        // }
        // .then((res) => {
        console.log(res.data);
        // setUserId(id);
    };

    const handleFileChange = async (event) => {
        // Check if event and event.target exist before accessing properties
        if (event && event.target) {
            const img = event.target.files[0];
            if (img) {
                let formData = new FormData();
                formData.append('file', img);

                try {
                    const res = await httpClient.post(
                        '/CloudinaryImageUpload?parameter=false',
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        }
                    );
                    setImageName(img.name);
                    console.log(res.data);
                    // Set the image URL to updatedData
                    setUpdatedData({ ...updatedData, image: res.data });
                } catch (error) {
                    console.error("Error uploading image:", error);
                }
            }
        }
    };


    const addUserDetails = async () => {
        const res = await httpClient.post(
            '/style_master',
            { ...updatedData }
        );
        // .then((res) => {
        console.log(res.data);
        getUser();
    };
    useEffect(() => {
        getUser();
    }, []);


    const handleButtonClick = () => {

        fileInputRef.current.click();
    };

    const deleteData = async (styleId) => {
        const sureDelete = window.confirm("Are You Sure The Delete Record..?");
        if (sureDelete) {
            const res = await httpClient.delete(
                `/style_master/deleteByStyleId/${styleId}`
            );
            console.log(res.data);
            getUser();
        }
    };
    const [imageName, setImageName] = useState('');
    const resetImageName = () => {
        setImageName('');
    };
    const handleCloseForm = () => {
        resetImageName();
        console.log('........');
    };
    return (

        <>

            <Grid justifyContent='center'>
                <Grid item xs={12} md={10} lg={8} >
                    <div className='pageMainDiv mt-[20px]'>
                        <p className='text-[25px] font-bold mt-[40px]'>Style</p>

                        <div className="mt-4 lg:w-[full]  w-[full] border rounded-md border-[white] bg-[white]   ">
                            <input type="text" name='name' placeholder="Search by Style Name..." value={searchQuery} onChange={(e) => handleSearch(e)}
                                className=' w-[100%] h-[50px] rounded-md border border-[#ABB2B9] pl-4 ' /></div>

                        <TableContainer style={{ height: '400px' }} className='mt-[20px]  '>
                            <Table sx={{ minWidth: 650 }} className='border border-[#CECECE] ' stickyHeader>
                                <TableHead>
                                    <TableRow className=' '>
                                        <StyledTableCell>Name</StyledTableCell>
                                        <StyledTableCell>Image</StyledTableCell>
                                        <StyledTableCell>Status</StyledTableCell>
                                        <StyledTableCell align='center'>ArtDropdown</StyledTableCell>
                                        <StyledTableCell align='center'>Actions</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((transaction, index) => (
                                            <TableRow key={index}>

                                                <TableCell className=''>
                                                    <span className='text-[16px] text-black'>
                                                        {transaction?.name}
                                                    </span>
                                                </TableCell>
                                                <TableCell className='' >
                                                    <div>
                                                        <Popup trigger={<div className='text-left'>
                                                            <Button
                                                                onClick={() => setImageLink(transaction?.image)}
                                                                style={{
                                                                    backgroundColor: 'orange',
                                                                    color: 'white',
                                                                }}
                                                            >
                                                                Image
                                                            </Button>
                                                        </div>}
                                                            modal
                                                            closeOnDocumentClick
                                                        >
                                                            {(close) => (<div
                                                                className='bg-white'>
                                                                <img src={ImageLink}
                                                                    className='w-[100px] lg:w-[400px] lg:h-[400px] h-[100px] rounded-lg  bg-cover shadow' />

                                                            </div>)}
                                                        </Popup>
                                                    </div>
                                                </TableCell>
                                                <TableCell className=''>
                                                    <Button
                                                        style={{
                                                            cursor: 'default',
                                                            backgroundColor: 'green',
                                                            color: 'white',
                                                        }}
                                                    >
                                                        {transaction?.status}
                                                    </Button>
                                                </TableCell>
                                                <TableCell className=''>
                                                    <span className='text-[16px] text-black  ml-[120px]'>
                                                        {transaction?.artDropdown ? 'true' : 'false'}
                                                    </span>
                                                </TableCell>

                                                <TableCell className=''>
                                                    <div className='space-x-2 flex ml-[170px] '>



                                                        <Popup
                                                            trigger={<div >
                                                                <Button
                                                                    onClick={() => {
                                                                        setUpdatedData(transaction);
                                                                    }}
                                                                    style={{
                                                                        backgroundColor: 'orange',
                                                                        color: 'white',
                                                                    }}
                                                                >
                                                                    Update
                                                                </Button></div>
                                                            }
                                                            modal
                                                            closeOnDocumentClick
                                                        >
                                                            {(close) => (
                                                                <div className='w-[300px] lg:w-[600px]  rounded-lg bg-[white] shadow p-[30px] relative'>
                                                                    <div className='flex'>

                                                                        <p className='text-[23px] font-medium ml-4 '>
                                                                            Update Style
                                                                        </p>
                                                                        <button
                                                                            className='ml-auto h-[40px] w-[40px]'
                                                                            onClick={close}
                                                                        >
                                                                            &times;
                                                                        </button>
                                                                    </div>

                                                                    <div className='lg:flex   lg:space-x-7'>
                                                                        <div className='flex flex-col '>
                                                                            <p className='formhead  mt-[25px] '>Art Dropdown</p>
                                                                            {/* <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                    onChange={handleInputChange}
                                                    name='artDropDown' /> */}

                                                                            <select
                                                                                className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                                                value={updatedData.artDropDown}
                                                                                onChange={handleInputChange}
                                                                                name='artDropdown'
                                                                            >
                                                                                <option

                                                                                    value='true'

                                                                                >
                                                                                    true
                                                                                </option>
                                                                                <option

                                                                                    value='false'

                                                                                >
                                                                                    false
                                                                                </option>
                                                                            </select>
                                                                        </div>

                                                                        <div className='flex flex-col '>
                                                                            <p className='formhead  mt-[25px] '>Style Name</p>
                                                                            <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                                                onChange={handleInputChange}
                                                                                name='name'
                                                                                value={updatedData.name} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='lg:flex   lg:space-x-7'>
                                                                        <div className='flex flex-col'>
                                                                            <p className='formhead  mt-[25px] '> Image</p>
                                                                            <div className='forminput   mt-3 w-[100%] lg:w-[258px] rounded-lg leading-none pl-[5px]'>
                                                                                <input type='file' className='text-[15px] p-[4px]'
                                                                                    style={{ display: 'none' }}
                                                                                    accept='image/*'
                                                                                    onChange={handleFileChange}
                                                                                    ref={fileInputRef}
                                                                                    name='image' multiple />
                                                                                {imageName ? (
                                                                                    <span>{imageName}</span>
                                                                                ) : (
                                                                                    <a className='cursor-pointer text-[#808080] text-[20px]' onClick={handleButtonClick}>Link {index + 1}</a>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='mx-[auto] h-[41px] w-[120px]  mt-[20px]'>
                                                                        <button className='rounded-lg text-[white] bg-[blue] text-[17px] font-semibold h-[41px] w-[120px] '
                                                                            onClick={() => {
                                                                                Update();
                                                                                close();
                                                                                handleCloseForm();
                                                                            }}
                                                                        >
                                                                            Update Style</button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Popup>
                                                        <Button


                                                            style={{
                                                                backgroundColor: 'red',
                                                                color: 'white',
                                                                marginLeft: 10
                                                            }}
                                                            onClick={() => deleteData(transaction.styleId)}

                                                        >
                                                            Delete
                                                        </Button>
                                                    </div>
                                                </TableCell>

                                            </TableRow>))}


                                </TableBody>

                            </Table>
                        </TableContainer>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15]}
                            component="div"
                            count={userList.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />

                        <Popup
                            trigger={<div >
                                <button
                                    onClick={() => { handleCloseForm() }}
                                    className='bg-[#7367F0] h-[40px] w-[150px] rounded-lg text-[white] text-[16px]'>
                                    Add Style
                                </button></div>
                            }
                            modal
                            closeOnDocumentClick
                        >
                            {(close) => (
                                <div className='w-[300px] lg:w-[600px]  rounded-lg bg-[white] shadow p-[30px] relative'>
                                    <div className='flex'>

                                        <p className='text-[23px] font-medium ml-4 '>
                                            Add Style
                                        </p>
                                        <button
                                            className='ml-auto h-[40px] w-[40px]'
                                            onClick={close}
                                        >
                                            &times;
                                        </button>
                                    </div>

                                    <div className='lg:flex   lg:space-x-7'>
                                        <div className='flex flex-col '>
                                            <p className='formhead  mt-[25px] '>Art Dropdown</p>
                                            {/* <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                    onChange={handleInputChange}
                                                    name='artDropDown' /> */}

                                            <select
                                                className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                value={updatedData.artDropDown}
                                                onChange={handleInputChange}
                                                name='artDropdown'
                                            >
                                                <option

                                                    value='true'

                                                >
                                                    true
                                                </option>
                                                <option

                                                    value='false'

                                                >
                                                    false
                                                </option>
                                            </select>
                                        </div>

                                        <div className='flex flex-col '>
                                            <p className='formhead  mt-[25px] '>Style Name</p>
                                            <input type='text' className='forminput  mt-3 w-[100%] lg:w-[258px] rounded-lg pl-[6px]'
                                                onChange={handleInputChange}
                                                name='name' />
                                        </div>
                                    </div>
                                    <div className='lg:flex   lg:space-x-7'>
                                        <div className='flex flex-col'>
                                            <p className='formhead  mt-[25px] '> Image</p>
                                            <div className='forminput   mt-3 w-[100%] lg:w-[258px] rounded-lg leading-none pl-[5px]'>
                                                <input type='file' className='text-[15px] p-[4px]'
                                                    style={{ display: 'none' }}
                                                    accept='image/*'
                                                    onChange={handleFileChange}
                                                    ref={fileInputRef}
                                                    name='image' multiple />
                                                {imageName ? (
                                                    <span>{imageName}</span>
                                                ) : (
                                                    <a className='cursor-pointer text-[#808080] text-[30px]' onClick={handleButtonClick}>+</a>
                                                )}
                                            </div>
                                        </div>








                                    </div>
                                    <div className='mx-[auto] h-[41px] w-[120px]  mt-[20px]'>
                                        <button className='rounded-lg text-[white] bg-[blue] text-[17px] font-semibold h-[41px] w-[120px] '
                                            onClick={() => {
                                                addUserDetails();
                                                close();
                                                handleCloseForm();
                                            }}
                                        >
                                            Add Style</button></div>
                                </div>
                            )}
                        </Popup>
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default Style1;