const TopBarData=[
    
{
topBarId: "string",
link1: "https://zplusglobalmarketinsights.com/artnstocksvgimg/SellYourPaintings.svg",
link2: "https://zplusglobalmarketinsights.com/artnstocksvgimg/New%20Arrivals%20(1).svg",
link3: "https://zplusglobalmarketinsights.com/artnstocksvgimg/Visit%20Artnstock%20Collectibles%20(2).svg",
            
}
    
]
export default TopBarData;