import React, { useState } from 'react';
import { useEffect } from 'react';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, styled, tableCellClasses } from '@mui/material';
import { httpClient } from '../axios';


const Table2 = () => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'rgb(115 103 240)',
      color: theme.palette.common.white,
      fontSize: 20,
    },
  }));


  const [userList, setUserList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  useEffect(() => {
    const getUser = async () => {
      const res = await httpClient
        .get('/user_master/getAllUserMasterList/');
      if (res) {
        console.log(res.data);
        setUserList(res.data);
      }

    };
    getUser();
  }, []);

  const filteredData = userList.filter(list =>
    list.userFirstName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const handleSearch = event => {
    setSearchQuery(event.target.value);
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Grid justifyContent='center'>
        <Grid item xs={12} md={10} lg={8}>
          <div className=''>

            <div className="mt-4 lg:w-[full]  w-[full] border rounded-md border-[white] bg-[white]   ">
              <input type="text" name='name' placeholder="Search..." value={searchQuery} onChange={(e) => handleSearch(e)}
                className=' w-[100%] h-[50px] rounded-md border border-[#ABB2B9] pl-4 ' />
              <div className=' w-[100%] overflow-auto lg:overflow-hidden mt-[20px]'>
                <TableContainer className='h-[400px]'>
                  <Table stickyHeader >
                    <TableHead className='bg-[#7367F0]' >
                      <TableRow >
                      <StyledTableCell>User Id</StyledTableCell>
                        <StyledTableCell>FirstName</StyledTableCell>
                        <StyledTableCell align='center' >Last Name</StyledTableCell>
                        <StyledTableCell align='center' >Display Name</StyledTableCell>
                        <StyledTableCell align='center'  >Email</StyledTableCell>
                        <StyledTableCell align='center' >User UniqueNo</StyledTableCell>

                        <StyledTableCell align='center'>Status</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody >
                      {filteredData
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(transaction => (<TableRow key={transaction.userId}>
<TableCell >
                            <span className='text-[20px] font-bold text-[#696969]'>{transaction.userId}</span></TableCell>
                          <TableCell >
                            <span className='text-[20px] font-bold text-[#696969]'>{transaction.userFirstName}</span></TableCell>
                          <TableCell align='center'>
                            <span className='text-[20px] font-bold text-[#696969]'>{transaction.userLastName}</span></TableCell>
                          <TableCell align='center'>
                            <span className='text-[20px] font-bold text-[#454545]'>{transaction.displayName}</span></TableCell>
                          <TableCell align='center' >
                            <span className='text-[17px] font-medium text-[#3A3B3C]'>{transaction.emailAddress} {" "}
                            </span>
                          </TableCell>
                          <TableCell align='center'>
                            <span className='text-[17px] font-bold text-[#3A3B3C]' > {transaction.userUniqueNo}</span></TableCell>
                          <TableCell align='center'><span className={`${transaction.status === "Active" ?
                            "text-[#6698FF] bg-[#E0FFFF] font-bold h-[35px] w-[90px] " : ""} ${transaction.status === "InProcess" &&
                            "text-[#FFCE44] bg-[#FFFF00] h-[35px] w-[100px]"} ${transaction.status === "Out of Stock" &&
                            "text-[#CC338B] bg-[#FFE6E8] h-[35px] w-[130px]"} rounded-lg px-2 py-px text-[18px] lg:ml-6 ml-8 capitalize font-semibold`}>{transaction.status}</span></TableCell>
                        </TableRow>))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15]}
                  component="div"
                  count={userList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>

            </div>
          </div>
        </Grid>
      </Grid>

    </>
  )
}

export default Table2;