import { Button, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, styled, tableCellClasses } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { httpClient } from '../axios';
import Popup from 'reactjs-popup';
import SendIcon from '@mui/icons-material/Send';

const Printing = () => {

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: 'rgb(115 103 240)',
            color: theme.palette.common.white,
            fontSize: 20,
        },
    }));

    const [updatedData, setUpdatedData] = useState({});
    const [isOpen, setIsOpen] = useState(false);

    const [print, setprint] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);



    const getUser = async () => {
        const res = await httpClient.get(
            '/printing_material_master');
        // .then((res) => {
        console.log(res.data);
        setprint(res.data);
        // });
    };

    useEffect(() => {
        getUser();
    }, []);


    const addUserDetails = async () => {
        const res = await httpClient.post(
            '/printing_material_master/create',
            { ...updatedData }
        );
        // .then((res) => {
        console.log(res.data);
        getUser();
    };
    useEffect(() => {
        getUser();
    }, []);

    const filteredData = print.filter(list =>
        list.printingMaterialName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const handleSearch = event => {
        setSearchQuery(event.target.value);
        setPage(0);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };



    const handleInputChange = (e) => {
        setUpdatedData({
            ...updatedData,
            [e.target.name]: e.target.value,
        });
    };


    const Update = async () => {
        console.log(updatedData);
        const res = await httpClient.put(
            '/printing_material_master/update',
            updatedData
        );
        // if (res) {
        getUser();
        // }
        // .then((res) => {
        console.log(res.data);
        // setUserId(id);
    };
    useEffect(() => {
        console.log(updatedData);
    }, [updatedData]);


    return (
        <>
            <Grid justifyContent="center">
                <Grid item xs={12} md={10} lg={8} >
                    <div className='pageMainDiv '>


                        <p className='lg:text-[25px] text-[16px] mt-[20px] font-semibold'>
                            Printing Master
                        </p>
                        <div className="mt-4   w-[100%] border rounded-md border-[white] bg-[white]   ">

                            <input type="text" name='name' placeholder="Search by Material Name..."
                                value={searchQuery}
                                onChange={(e) => handleSearch(e)}
                                className=' w-[100%] h-[50px] rounded-md border border-[#ABB2B9] pl-4 ' />
                            <div className=' w-[100%] overflow-auto lg:overflow-hidden mt-[10px]'>
                                <TableContainer style={{ height: '400px' }}>
                                    <Table stickyHeader >
                                        <TableHead className='bg-[#7367F0]' >
                                            <TableRow >
                                                <StyledTableCell  >Printing Material Name</StyledTableCell>
                                                <StyledTableCell align='center' >Printing Material Price</StyledTableCell>
                                                <StyledTableCell  >Printing Material Status</StyledTableCell>
                                                <StyledTableCell >Description</StyledTableCell>
                                                <StyledTableCell align='center' >Action</StyledTableCell>


                                            </TableRow>
                                        </TableHead>
                                        <TableBody >
                                            {filteredData
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((trans1, index) => (<TableRow key={index}>


                                                    <TableCell >
                                                        <span className='text-[16px]  font-normal text-[#696969]'>{trans1.printingMaterialName}</span></TableCell>
                                                    <TableCell align='center'>
                                                        <span className='text-[16px]  font-normal text-[#454545]'>{trans1.printingMaterialPrice}</span></TableCell>
                                                    <TableCell align='center'>
                                                        {
                                                            trans1.printingMaterialStatus === 'Active' ? (
                                                                <Button
                                                                    style={{ backgroundColor: 'green', color: 'white' }}>Active</Button>
                                                            ) : (
                                                                <Button
                                                                    style={{ backgroundColor: 'yellow', color: 'black' }} >InActive</Button>
                                                            )
                                                        }
                                                    </TableCell>

                                                    <TableCell align='center'>
                                                        <span className='text-[16px]  font-normal text-[#454545]'>{trans1.description}</span></TableCell>

                                                    <TableCell align='center'>
                                                        <Popup
                                                            trigger={
                                                                <div className='flex lg:ml-[50px]'>

                                                                    <Button
                                                                        onClick={() => {
                                                                            setUpdatedData(trans1);
                                                                        }}
                                                                        style={{
                                                                            backgroundColor: 'orange',
                                                                            color: 'white',
                                                                        }}
                                                                    >
                                                                        Update{' '}
                                                                    </Button>


                                                                </div>
                                                            }
                                                            modal
                                                            closeOnDocumentClick
                                                        >
                                                            {(close) => (
                                                                <>
                                                                    <div className='flex'>

                                                                        <button
                                                                            className='ml-auto h-[40px] w-[40px]'
                                                                            onClick={close}
                                                                        >
                                                                            &times;
                                                                        </button>
                                                                    </div>
                                                                    <div className="  lg:w-[550px] w-[440px]  h-[100%] bg-[white] shadow rounded  p-[20px] ">
                                                                        <p className=' lg:text-[25px] text-[16px]  font-semibold'>Update Records</p>
                                                                        <div className=' mt-2 flex '>

                                                                            <div className='flex-col'>
                                                                                <p className=' mt-[10px]  font-semibold lg:text-[18px] text-[12px]'> Material Name : </p>
                                                                                <div className='rounded   mt-[4px] lg:w-[230px] w-[150px] bg-gray-100'>
                                                                                    <input
                                                                                        className=' lg:w-[240px] w-[180px] pl-4  text-[18px] lg:h-[40px] h-[40px] bg-[#DADBDD]'
                                                                                        name='printingMaterialName'
                                                                                        placeholder='Enter m name'
                                                                                        onChange={handleInputChange}
                                                                                        value={updatedData.printingMaterialName} // Set initial value
                                                                                    />
                                                                                </div></div>


                                                                            <div className='flex-col'>

                                                                                <p className=' mt-[10px]  lg:ml-[35px] ml-[60px] font-semibold lg:text-[18px] text-[12px]'>Material Price : </p>
                                                                                <div className='rounded mt-[4px]  rounded lg:ml-[35px] ml-[60px]  lg:w-[230px] w-[200px] bg-gray-100'>
                                                                                    <input
                                                                                        className='lg:w-[240px]  w-[180px] pl-4  text-[18px] lg:h-[40px] h-[40px] bg-[#DADBDD]'
                                                                                        name='printingMaterialPrice'
                                                                                        placeholder='Enter Material Price'
                                                                                        value={updatedData.printingMaterialPrice}

                                                                                        onChange={handleInputChange}
                                                                                    /> </div></div></div>



                                                                        <div className='flex-col'>

                                                                            <p className=' mt-[10px]   font-semibold lg:text-[18px] text-[12px]'>Description: </p>
                                                                            <div className='rounded mt-[4px] w-[100%] bg-gray-100'>
                                                                                <textarea
                                                                                    className='w-[100%] pl-4  text-[18px] h-[70px] bg-[#DADBDD]'
                                                                                    name='description'
                                                                                    placeholder='Enter description'
                                                                                    value={updatedData.description}
                                                                                    onChange={handleInputChange}
                                                                                ></textarea>
                                                                            </div>

                                                                        </div>

                                                                        <div className='flex-col'>
                                                                            <h className='mt-[10px] font-semibold lg:text-[18px] text-[12px]'>Material Status : </h>
                                                                            <div className='rounded mt-[4px]  lg:w-[230px] w-[150px] bg-gray-100'>
                                                                                {/* <textarea
                                                                                    className=' lg:w-[230px]  w-[180px] pl-4  text-[18px] lg:h-[40px] h-[40px] bg-[#DADBDD]'
                                                                                    name='printingMaterialStatus'
                                                                                    placeholder='Enter printingMaterialStatus'
                                                                                    onChange={handleInputChange}
                                                                                    value={updatedData.printingMaterialStatus}
                                                                                ></textarea> */}

                                                                                <select
                                                                                    className='lg:w-[230px]  w-[180px] pl-4  text-[18px] lg:h-[40px] h-[40px] bg-[#DADBDD]'
                                                                                    value={updatedData.printingMaterialStatus}
                                                                                    onChange={handleInputChange}
                                                                                    name='printingMaterialStatus'
                                                                                >
                                                                                    <option
                                                                                        value='Active'

                                                                                    >
                                                                                        Active
                                                                                    </option>
                                                                                    <option
                                                                                        value='InActive'

                                                                                    >
                                                                                        InActive
                                                                                    </option>
                                                                                </select>


                                                                            </div>
                                                                        </div>
                                                                        {/* <h className='lg:pt-[10px] lg:mt-[10px] mt-[8px] lg:ml-[35px] ml-[40px] font-semibold lg:text-[18px] text-[12px]'>checked: </h>
                                                                            <div className='rounded lg:mt-[4px] mt-[18px] rounded lg:ml-[35px]  ml-[20px] lg:w-[230px] w-[350px] bg-gray-100'>
                                                                                <textarea
                                                                                    className='mt-[10px] lg:w-[230px]  w-[180px] pl-4 lg:ml-[4px] ml-[20px] pt-2 text-[18px] lg:h-[40px] h-[40px] bg-[#DADBDD]'
                                                                                    name='checked'
                                                                                    placeholder='Enter checked'
                                                                                    onChange={handleInputChange}
                                                                                ></textarea>
                                                                            </div> */}

                                                                        <button className='h-[40px] rounded w-[110px] mx-[auto] mt-[20px]  bg-[#1F45FC] text-[white]'
                                                                            endIcon={<SendIcon />}
                                                                            onClick={() => {
                                                                                Update();
                                                                                close();
                                                                            }}
                                                                        >
                                                                            update Record
                                                                        </button>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </Popup>

                                                    </TableCell>

                                                </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 15]}
                                    component="div"
                                    count={print.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </div>

                        </div>


                        <Popup
                            trigger={
                                <Button

                                    style={{
                                        backgroundColor: 'blue',
                                        color: 'white',
                                    }}
                                >
                                    Add
                                </Button>
                            }
                            modal
                            closeOnDocumentClick
                        >
                            {(close) => (
                                <div className="  lg:w-[550px] w-[440px]  lg:h-[100%] h-[490px] bg-[white] shadow rounded  p-[20px] ">
                                    <p className=' lg:text-[25px] text-[16px]  font-semibold'>Add Records</p>
                                    <div className=' mt-2 flex'>

                                        <div className='flex-col'>
                                            <p className=' mt-[10px] font-semibold lg:text-[18px] text-[12px]'> Material Name : </p>
                                            <div className='rounded mt-[4px] lg:w-[230px] w-[150px] bg-gray-100'>
                                                <input
                                                    className=' lg:w-[240px]  w-[180px] pl-4  text-[18px] lg:h-[40px] h-[40px] bg-[#DADBDD]'
                                                    name='printingMaterialName'
                                                    placeholder='Enter Material Name'

                                                    onChange={handleInputChange}
                                                ></input>
                                            </div></div>
                                        <div className='flex-col'>

                                            <p className=' mt-[10px]  lg:ml-[35px] ml-[60px] font-semibold lg:text-[18px] text-[12px]'>Material Price : </p>
                                            <div className='rounded mt-[4px]  lg:ml-[35px] ml-[60px]  lg:w-[230px] w-[350px] bg-gray-100'>
                                                <input
                                                    className=' lg:w-[240px]  w-[180px] pl-4  text-[18px] lg:h-[40px] h-[40px] bg-[#DADBDD]'
                                                    name='printingMaterialPrice'
                                                    placeholder='Enter Material Price'
                                                   
                                                    onChange={handleInputChange}
                                                ></input> </div></div></div>

                                    <div className='flex-col'>

                                        <p className='mt-[10px]  font-semibold lg:text-[18px] text-[12px]'>Description: </p>
                                        <div className='rounded mt-[4px] rounded  w-[100%] bg-gray-100'>
                                            <textarea
                                                className=' w-[100%] pl-4  pt-2 text-[18px]  h-[70px] bg-[#DADBDD]'
                                                name='description'
                                                placeholder='Enter description'
                                                onChange={handleInputChange}
                                            ></textarea>
                                        </div>
                                    </div>


                                    <div className='flex-col'>
                                        <h className='mt-[10px]   font-semibold lg:text-[18px] text-[12px]'>Material Status : </h>
                                        <div className='rounded mt-[4px]  lg:w-[230px] w-[150px] bg-gray-100'>
                                            {/* <textarea
                                                className=' lg:w-[230px]  w-[180px] pl-4  text-[18px] lg:h-[40px] h-[40px] bg-[#DADBDD]'
                                                name='printingMaterialStatus'
                                                placeholder='Enter Material Status'
                                                onChange={handleInputChange}
                                            ></textarea> */}
                                            <select
                                                className='lg:w-[230px]  w-[180px] pl-4  text-[18px] lg:h-[40px] h-[40px] bg-[#DADBDD]'
                                                value={updatedData.printingMaterialStatus}
                                                onChange={handleInputChange}
                                                name='printingMaterialStatus'
                                            >
                                                <option
                                                    value='Active'

                                                >
                                                    Active
                                                </option>
                                                <option
                                                    value='InActive'

                                                >
                                                    InActive
                                                </option>
                                            </select>

                                        </div>
                                    </div>

                                    {/* <h className='lg:pt-[10px] lg:mt-[10px] mt-[8px] lg:ml-[35px] ml-[40px] font-semibold lg:text-[18px] text-[12px]'>checked: </h>
                                        <div className='rounded lg:mt-[4px] mt-[18px] rounded lg:ml-[35px]  ml-[20px] lg:w-[230px] w-[350px] bg-gray-100'>
                                            <textarea
                                                className='mt-[10px] lg:w-[230px]  w-[180px] pl-4 lg:ml-[4px] ml-[20px] pt-2 text-[18px] lg:h-[40px] h-[40px] bg-[#DADBDD]'
                                                name='checked'
                                                placeholder='Enter checked'
                                                onChange={handleInputChange}
                                            ></textarea>
                                        </div> */}


                                    <button className='h-[40px] rounded w-[110px]  mt-[20px] ml-[220px] bg-[#1F45FC] text-[white]'
                                        endIcon={<SendIcon />}
                                        onClick={() => {
                                            addUserDetails();
                                            close();
                                        }}
                                    >
                                        Add Record
                                    </button>
                                </div>
                            )}
                        </Popup>
                    </div>
                </Grid>
            </Grid>
        </>

    )
}

export default Printing