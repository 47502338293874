import React from 'react';
import card1 from '../Dashboard/DashImg/card.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import card2 from '../Dashboard/DashImg/card2.png';
import card3 from '../Dashboard/DashImg/card3.png';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import LinkIcon from '@mui/icons-material/Link';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import {
  LinearProgress,
  linearProgressClasses,
  styled,
} from '@mui/material';
import 'apexcharts/dist/apexcharts.css';
import ReactApexChart from 'react-apexcharts';
import { httpClient } from './../axios';
import { useState, useEffect } from 'react';

// import SockJS from 'sockjs-client';
// import { Stomp } from 'stompjs';
import { Client } from '@stomp/stompjs';

import { io } from 'socket.io-client';

const FirstComp = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: null,
    nextArrow: null,
  };

  const BorderLinearProgress = styled(LinearProgress)(
    ({ theme }) => ({
      height: 10,
      borderRadius: 5,
      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
          theme.palette.grey[
            theme.palette.mode === 'light' ? 200 : 800
          ],
      },
      [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor:
          theme.palette.mode === 'light' ? '#00BFFF' : '#308fe8',
      },
    })
  );

  const options = {
    chart: {
      height: 150,
      type: 'area',
      toolbar: {
        show: false, // Hide toolbar
      },
    },
    dataLabels: {
      enabled: false,
    },
    series: [
      {
        name: 'Series 1',
        data: [45, 52, 38, 45, 19, 23, 2],
      },
    ],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },
    xaxis: {
      labels: {
        show: false, // Hide x-axis labels
      },
      axisBorder: {
        show: false, // Hide x-axis border
      },
    },
    yaxis: {
      labels: {
        show: false, // Hide y-axis labels
      },
      axisBorder: {
        show: false, // Hide y-axis border
      },
    },
    tooltip: {
      enabled: false, // Disable tooltip
    },
  };

  const [liveCount, setLiveCount] = useState();

  const getLiveUserCountonArtnstock = async () => {
    try {
      const res = await httpClient.get(
        `/user_master/getLoginUsersCount`
      );
      console.log(res.data);
      setLiveCount(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  // // "undefined" means the URL will be computed from the `window.location` object
  // const URL = 'http://192.168.0.16:3000';
  // const socket = io(URL);

  // const [isConnected, setIsConnected] = useState(socket.connected);
  // const [fooEvents, setFooEvents] = useState([]);
  // const [sockett, setSocket] = useState(null);

  // useEffect(() => {
  //   // Event handler for 'userConnected' event
  //   socket.on('userConnected', (message) => {
  //     console.log(message); // Handle the message from the server
  //   });
  //   // Event handler for 'message' event
  //   socket.on('message', (msg) => {
  //     console.log('Received message:', msg); // Handle the message from the server
  //   });
  //   // Event handler for 'userDisconnected' event
  //   socket.on('userDisconnected', (message) => {
  //     console.log(message); // Handle the message from the server
  //   });

  //   // setSocket(newSocket);

  //   // Cleanup function
  //   return () => {
  //     if (socket) {
  //       socket.close();
  //     }
  //   };
  // }, []);

  // useEffect(() => {
  //   // const socket = new WebSocket(
  //   //   'ws://192.168.0.15:5000/stomp-endpoint'
  //   // );
  //   // socket.onopen = () => {
  //   //   console.log('WebSocket connected');
  //   // };
  //   // socket.onmessage = (event) => {
  //   //   console.log('Message received:', event.data);
  //   // };
  //   // socket.onclose = () => {
  //   //   console.log('WebSocket connection closed');
  //   // };
  //   // socket.onerror = (error) => {
  //   //   console.error('WebSocket error:', error);
  //   // };
  //   // return () => {
  //   //   socket.close();
  //   // };
  //   // Declare stompClient variable
  //   // Use either http:// or https:// scheme based on your server configuration
  //   // const socket = new SockJS(
  //   //   'http://192.168.0.15:5000/stomp-endpoint'
  //   // );
  //   // let stompClient;
  //   // const socket = new SockJS(
  //   //   'http://192.168.0.15:5000/stomp-endpoint'
  //   // );
  //   // stompClient = new Client({
  //   //   webSocketFactory: () =>
  //   //     new WebSocket('ws://192.168.0.15:5000/stomp-endpoint'),
  //   // });
  //   // stompClient.onConnect = function (frame) {
  //   //   console.log('Connected: ' + frame);
  //   //   stompClient.subscribe(
  //   //     'ws://192.168.0.15:5000/topic/logincount',
  //   //     function (greeting) {
  //   //       console.log(JSON.parse(greeting.body));
  //   //     }
  //   //   );
  //   // };
  //   // stompClient.activate();
  //   // }
  //   // connect();
  // }, []);

  useEffect(() => {
    getLiveUserCountonArtnstock();
  }, []);

  return (
    <>
      <div className=''>
        <div className='lg:grid grid-cols-2 gap-7 mt-[20px]'>
          <Slider {...settings}>
            <div className='relative w-[100%] bg-[#7367F0] rounded-lg p-[20px]  '>
              <div className='lg:flex rounded-lg'>
                <div className='text-left flex flex-col justify-center'>
                  <p className='text-[white] font-semibold text-[25px] mt-[20px]'>
                    Website Analytics
                  </p>
                  <p className='text-[white] font-normal text-[20px] leading-tight'>
                    Total 28.5 Conversion Rate
                  </p>
                  <p className='text-[white] font-medium text-[21px]  mt-[20px]'>
                    Traffic
                  </p>

                  <div className='flex mt-[20px]'>
                    <div className='h-[35px] w-[60px] bg-[#5539CC] rounded-lg  text-center'>
                      <p className='text-[white] font-medium text-[21px] '>
                        28%
                      </p>
                    </div>
                    <p className='text-[white] font-normal text-[21px] ml-[6px]'>
                      Sessions
                    </p>
                    <div className='h-[35px] w-[60px] bg-[#5539CC] rounded-lg  text-center ml-[20px]'>
                      <p className='text-[white] font-medium text-[21px] '>
                        3.1k
                      </p>
                    </div>
                    <p className='text-[white] font-normal lg:text-[20px] ml-[5px]'>
                      Page View
                    </p>
                  </div>
                  <div className='flex mt-[30px]'>
                    <div className='h-[35px] w-[60px] bg-[#5539CC] rounded-lg  text-center'>
                      <p className='text-[white] font-medium text-[21px] '>
                        1.2k
                      </p>
                    </div>
                    <p className='text-[white] font-normal text-[21px] ml-[6px]'>
                      Leads
                    </p>
                    <div className='h-[35px] w-[60px] bg-[#5539CC] rounded-lg  text-center ml-[23px]'>
                      <p className='text-[white] font-medium text-[21px] '>
                        12%
                      </p>
                    </div>
                    <p className='text-[white] font-normal text-[21px] ml-[6px]'>
                      Conversions
                    </p>
                  </div>
                </div>

                <div className='h-[255px] w-[255px] mt-[20px] ml-[25px]'>
                  <img
                    src={card3}
                    alt='Card Image'
                    className='h-[255px] w-[255px]'
                  ></img>
                </div>
              </div>
            </div>
            <div className='relative w-[100%] bg-[#7367F0] rounded-lg p-[20px] '>
              <div className='lg:flex'>
                <div className='text-left '>
                  <p className='text-[white] font-semibold text-[25px] mt-[20px]'>
                    Website Analytics
                  </p>
                  <p className='text-[white] font-normal text-[20px] leading-tight'>
                    Total 28.5 Conversion Rate
                  </p>
                  <p className='text-[white] font-medium text-[21px]  mt-[20px]'>
                    Spending
                  </p>

                  <div className='flex mt-[20px]'>
                    <div className='h-[35px] w-[60px] bg-[#5539CC] rounded-lg  text-center'>
                      <p className='text-[white] font-medium text-[21px] '>
                        12h
                      </p>
                    </div>
                    <p className='text-[white] font-normal text-[21px] ml-[6px]'>
                      Spend
                    </p>
                    <div className='h-[35px] w-[60px] bg-[#5539CC] rounded-lg  text-center ml-[20px]'>
                      <p className='text-[white] font-medium text-[21px] '>
                        18
                      </p>
                    </div>
                    <p className='text-[white] font-normal text-[21px] ml-[6px]'>
                      Order Size
                    </p>
                  </div>
                  <div className='flex mt-[30px]'>
                    <div className='h-[35px] w-[60px] bg-[#5539CC] rounded-lg  text-center'>
                      <p className='text-[white] font-medium text-[21px] '>
                        127
                      </p>
                    </div>
                    <p className='text-[white] font-normal text-[21px] ml-[6px]'>
                      Order
                    </p>
                    <div className='h-[35px] w-[60px] bg-[#5539CC] rounded-lg  text-center ml-[23px]'>
                      <p className='text-[white] font-medium text-[21px] '>
                        2.3k
                      </p>
                    </div>
                    <p className='text-[white] font-normal text-[21px] ml-[6px]'>
                      Items
                    </p>
                  </div>
                </div>

                <div className='h-[255px] w-[255px]  mt-[20px] ml-[25px]'>
                  <img
                    src={card1}
                    alt='Card Image '
                    className='h-[255px] w-[255px]'
                  ></img>
                </div>
              </div>
            </div>
            <div className='relative w-[100%] bg-[#7367F0] rounded-lg p-[20px] '>
              <div className='lg:flex'>
                <div className='text-left '>
                  <p className='text-[white] font-semibold text-[25px] mt-[20px]'>
                    Website Analytics
                  </p>
                  <p className='text-[white] font-normal text-[20px] leading-tight'>
                    Total 28.5 Conversion Rate
                  </p>
                  <p className='text-[white] font-medium text-[21px]  mt-[20px]'>
                    Revenue Sources
                  </p>

                  <div className='flex mt-[20px]'>
                    <div className='h-[35px] w-[60px] bg-[#5539CC] rounded-lg  text-center'>
                      <p className='text-[white] font-medium text-[21px] '>
                        268
                      </p>
                    </div>
                    <p className='text-[white] font-normal text-[21px] ml-[6px]'>
                      Direct
                    </p>
                    <div className='h-[35px] w-[60px] bg-[#5539CC] rounded-lg  text-center ml-[20px]'>
                      <p className='text-[white] font-medium text-[21px] '>
                        690
                      </p>
                    </div>
                    <p className='text-[white] font-normal text-[21px] ml-[6px]'>
                      Organic
                    </p>
                  </div>
                  <div className='flex mt-[30px]'>
                    <div className='h-[35px] w-[60px] bg-[#5539CC] rounded-lg  text-center'>
                      <p className='text-[white] font-medium text-[21px] '>
                        62
                      </p>
                    </div>
                    <p className='text-[white] font-normal text-[21px] ml-[6px]'>
                      Referral
                    </p>
                    <div className='h-[35px] w-[60px] bg-[#5539CC] rounded-lg  text-center ml-[23px]'>
                      <p className='text-[white] font-medium text-[21px] '>
                        1.2k
                      </p>
                    </div>
                    <p className='text-[white] font-normal text-[21px] ml-[6px]'>
                      Campaign
                    </p>
                  </div>
                </div>

                <div className='h-[255px] w-[255px] mt-[20px] ml-[25px]'>
                  <img
                    src={card2}
                    className='h-[255px] w-[255px]'
                  ></img>
                </div>
              </div>
            </div>
          </Slider>
          <div className='lg:flex gap-7'>
            <div className='relative lg:w-[300px] w-[100%] bg-[white] rounded-lg p-[20px] shadow-regCardShadow mt-[23px]  lg:mt-[0px]'>
              <div className='flex'>
                <p className='text-[#C0C0C0] text-[18px] font-normal'>
                  Sales Overview
                </p>
                <p className='text-[green] text-[20px] font-medium ml-[auto]'>
                  +18.2%
                </p>
              </div>
              <p className='text-[#4D4D4F] text-[25px] font-medium leading-tight'>
                $42.5k
              </p>
              <div className='flex mt-[40px] ml-[10px]'>
                <div className='flex flex-col'>
                  <div className=' flex'>
                    <div className='h-[40px] w-[40px] bg-[#BDEDFF] rounded p-[8px] '>
                      <AddShoppingCartIcon
                        style={{ color: '#5CB3FF' }}
                      />
                    </div>
                    <p className='text-[22px] font-normal text-[#808080] ml-[8px]'>
                      Order
                    </p>
                  </div>
                  <p className='text-[#4D4D4F] text-[24px] font-medium leading-tight mt-[20px]'>
                    62.2%
                  </p>
                  <p className='text-[#C0C0C0] text-[20px] font-normal leading-tight '>
                    6,440
                  </p>
                </div>
                <div className='flex flex-col ml-[10px] lg:ml-[0px]'>
                  <div className='w-[0.5px] h-[40px] bg-[#C0C0C0] ml-[20px]'></div>
                  <div className='h-[40px] w-[40px] rounded-full bg-[#EEEEEE] mt-[4px] p-[7px] text-center'>
                    <p className='text-[18px] text-[#C0C0C0] font-normal'>
                      VS
                    </p>
                  </div>
                  <div className='w-[0.5px] h-[40px] bg-[#C0C0C0] ml-[20px] mt-[4px]'></div>
                </div>
                <div className='flex flex-col ml-[20px] lg:ml-[0px]'>
                  <div className='flex'>
                    <p className='text-[22px] font-normal text-[#808080] '>
                      Visits
                    </p>
                    <div className='h-[40px] w-[40px] bg-[#C8C4DF] rounded p-[7px] ml-[10px]'>
                      <LinkIcon style={{ color: '#967BB6' }} />
                    </div>
                  </div>
                  <p className='text-[#4D4D4F] text-[24px] font-medium leading-tight mt-[20px] ml-[20px]'>
                    25.5%
                  </p>
                  <p className='text-[#C0C0C0] text-[20px] font-normal leading-tight  ml-[25px]'>
                    12,749
                  </p>
                </div>
              </div>
              <div className='  w-[100%] h-[10px] mt-[20px]'>
                <BorderLinearProgress
                  variant='determinate'
                  value={70}
                  style={{ backgroundColor: '#A74AC7' }}
                />
              </div>
            </div>
            <div className='relative lg:w-[300px]  w-[100%] bg-[white] rounded-lg   shadow-regCardShadow mt-[23px]  lg:mt-[0px]'>
              <div className='h-[48px] w-[48px] rounded-full bg-[#C3FDB8] p-[11px] ml-[20px] mt-[20px]'>
                <Inventory2OutlinedIcon
                  style={{ color: '#12AD2B' }}
                />
              </div>
              <p className='text-[#4D4D4F] text-[25px] font-medium leading-tight mt-[8px]  ml-[20px]'>
                {liveCount}
              </p>
              <p className='text-[20px] font-normal text-[#808080]  mt-[8px]  ml-[20px]'>
                Live User Count
              </p>

              <div id='chart' className='w-[100%]'>
                <ReactApexChart
                  options={options}
                  series={options.series}
                  type='area'
                  height={150}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FirstComp;
