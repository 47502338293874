import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import Sidebar from './nav-components/Sidebar';
import Dashboard from './pages/Dashboard';

import Table from './pages/Table';
import Login from './components/Login';
import SocialMedial from './Social-Media/SocialMedial';
import Review from './Review-Master/Review';
import Print from './Printong-Master/Print';
import Frame from './components/FrameMaster/Frame';
import Country from './components/CountryMaster/Country';
import TopBar from './components/TopBar/TopBar';
import Faq from './components/FAQ/Faq';
import Pincode from './components/Pincode-Master/Pincode';
import Subject from './components/Subject/Subject';
import Giftcode from './components/Gift-Master/Giftcode';
import ArtForm from './components/ArtMaster/ArtForm';
import { setLogin } from './store/Styleslice';
import Style from './components/Style/Style';
import PromoCode from './components/Promocode/PromoCode';
import ArtMaster from './components/ArtMaster/ArtMaster';
import Style1 from './components/Style/Style1';
import Subject1 from './components/Subject/Subject1';
import Printing from './Printong-Master/Printing';
import Contributorearning from './components/Contributorearning/Contributorearning';
import MediumMaster from './components/MediumMaster/MediumMaster';
import Orientation from './components/Orientation/Orientation';
import ProductMaster from './components/ProductMaster/ProductMaster';
import Discount from './components/DiscountMaster/Discount';
import Keyword from './components/KeywordMaster/Keyword';
import MainCategory from './components/MainCategory/MainCategory';
import ProductSub from './components/Product-Sub-Master/ProductSub';
import ReleaseMaster from './components/ReleaseMaster/ReleaseMaster';
import AdminArtProduct from './components/AdminArtProductMaster1/AdminArtProduct';
import FileUploadLimit from './components/FileUploadLimit/FileUploadLimit';
import AddDetails from './components/AddDetails/AddDetails';
import Draft from './components/DraftMaster/Draft';
import TableT from './components/Table/TableT';

import ArtProduct from './components/ArtProductMaster/ArtProduct';



function App() {
  const currentUser = useSelector((state) => state.style.loginState);
  const dispatch = useDispatch();

  const handleLoginSuccess = () => {
    dispatch(setLogin(true));
  };

  const ProtectedRoute = ({ children }) => {
    if (!currentUser) {
      return <Navigate to='/login' />;
    }
    return children;
  };

  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route
            path='/login'
            element={<Login onLoginSuccess={handleLoginSuccess} />}
          />
          <Route
            path='/'
            element={
              <ProtectedRoute>
                <Sidebar />
              </ProtectedRoute>
            }
          >
            <Route index element={<Dashboard />} />
            <Route path='/table' element={<TableT />} />
            <Route path='/user' element={<Table />} />
            <Route path='/faq' element={<Faq />} />
            <Route path='/social' element={<SocialMedial />} />
            <Route path='/review' element={<Review />} />
            <Route path='/pin' element={<Pincode />} />
            <Route path='/country' element={<Country />} />
            <Route path='/frame' element={<Frame />} />
            <Route path='/print' element={<Printing />} />
            <Route path='/top' element={<TopBar />} />
            <Route path='/sub' element={<Subject1 />} />
            <Route path='/gift' element={<Giftcode />} />
            <Route path='/promo' element={<PromoCode />} />

            <Route path='/style' element={<Style1 />} />

            <Route path='/art' element={<ArtMaster />} />
            <Route path='/contri' element={<Contributorearning />} />
            <Route path='/medium' element={<MediumMaster />} />
            <Route path='/orient' element={<Orientation />} />
            <Route path='/product' element={<ProductMaster />} />
            <Route path='/discount' element={<Discount />} />
            <Route path='/key' element={<Keyword />} />
            <Route path='/maincat' element={<MainCategory />} />
            <Route path='/subcat' element={<ProductSub />} />
            <Route path='/release' element={<ReleaseMaster />} />
            <Route
              path='/adminartproduct'
              element={<AdminArtProduct />}
            />
            <Route path='/artproduct' element={<ArtProduct />} />
            <Route
              path='/fileuploadlimit'
              element={<FileUploadLimit />}
            />
            <Route path='/adddetails' element={<AddDetails />} />
            <Route path='/draft' element={<Draft />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
